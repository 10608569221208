import { api } from './api'
import { Tag } from 'Models/vendor';

export const tagApi = api.injectEndpoints({
    endpoints: (build) => ({
        getTags: build.query<Array<Tag>, { organizationId: string, companyId: string, estimateId: string }>({
            query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/tag` }),
            providesTags: ['Tags']
        }),
        
    })
});

export const {
    useGetTagsQuery
} = tagApi