import { Autocomplete, Box, Button, CircularProgress, MenuItem, TextField } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import CurrencyConversionModal from "./CurrencyConversionModal";
import { Estimate } from "Models/estimate";
import * as yup from 'yup';
import { useFormik } from "formik";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useGetUnitsQuery } from "State/Services/unit";
import { useGetCompanyCurrenciesQuery } from "State/Services/company-currency";
import { useCallback, useEffect, useState } from "react";
import { CompanyCurrency } from "Models/company-currency";
import SaveIcon from '@mui/icons-material/Save';
import { Master } from "Models/master";
import { Selections } from "./CreateEstimateSelection";

export interface CreateUpdateEstimateProps {
    estimate: Estimate | undefined;
    master?: Master | undefined;
    selectedOption: "estimate" | "master" | "edit" | "duplicate";
    save: (estimate: Estimate) => Promise<void>;
    close: () => void;
    secondaryButtonText: string;
    selections?: Selections | null | undefined;
}

const estimateValidationSchema = yup.object<Estimate>({
    name: yup
        .string()
        .required('Name is required'),
    noOfLevels: yup
        .number()
        .min(0, 'No of categories cannot be negative')
        .max(4, 'A maximum of 4 categories is supported')
        .required('Categories are required'),
    indirectItemNoOfLevels: yup
        .number()
        .min(0, 'No of categories cannot be negative')
        .max(4, 'A maximum of 4 categories is supported')
        .required('Categories are required'),
});

export default function CreateUpdateEstimate(props: CreateUpdateEstimateProps) {
    const { data: user } = useGetUserDetailsQuery();
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: companyCurrencies } = useGetCompanyCurrenciesQuery({ companyId: (user && user.companyId) ? user.companyId : '', orgId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [companyCurrenciesExtended, setCompanyCurrenciesExtended] = useState<Array<CompanyCurrency & { currencyOption: string }>>([]);
    const [showAdditionalFields, setShowAdditionalFields] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState(false);
    const [directLevelsDisabled, setDirectLevelsDisabled] = useState(false);
    const [indirectLevelsDisabled, setIndirectLevelsDisabled] = useState(false);

    const handleClose = useCallback(() => {
        props.close();
        setShowAdditionalFields(false);
    }, [props])

    useEffect(() => {
        if (companyCurrencies) {
            setCompanyCurrenciesExtended(companyCurrencies.map((currency) => ({ ...currency, currencyOption: `${currency.alphabeticalCode} - ${currency.currency}` })))
        }
    }, [companyCurrencies])

    const { setFieldValue, handleSubmit, resetForm, setValues, setFieldError, ...formik } = useFormik<Estimate>({
        initialValues: {
            id: '',
            name: '',
            noOfLevels: 0,
            indirectItemNoOfLevels: 0,
            client: '',
            area: undefined,
            areaUnit: undefined,
            projectName: '',
            companyCurrencyId: '',
            currencyExchangeRate: undefined,
            unitIds: [],
            updateResourceBasicRates: false
        },
        enableReinitialize: true,
        validationSchema: estimateValidationSchema,
        onSubmit: async (values) => {
            try {
                setIsSaving(true);
                await props.save(values);
                setIsSaving(false);
            } catch (error: any) {
                setIsSaving(false);
                if ('name' in error.data) {
                    setFieldError('name', error.data.name);
                }
            }
        },
    });

    useEffect(() => {
        switch (props.selectedOption) {
            case "duplicate":
                if (props.estimate) {
                    setValues({ ...props.estimate, name: `${props.estimate.name} - Copy` });
                    setDirectLevelsDisabled(true);
                    setIndirectLevelsDisabled(true);
                }
                break;
            case "master":
                if (props.master) {
                    if (props.selections) {
                        setDirectLevelsDisabled(props.selections.direct);
                        setIndirectLevelsDisabled(props.selections.indirect);
                    }
                    setValues({
                        id: '',
                        area: undefined,
                        areaUnit: undefined,
                        client: '',
                        indirectItemNoOfLevels: props.master.indirectItemNoOfLevels,
                        name: '',
                        noOfLevels: props.master.noOfLevels,
                        unitIds: [],
                        companyCurrencyId: props.master?.CompanyCurrency?.id ?? '',
                        companyCurrencyOption: props.master?.CompanyCurrency ? { ...props.master?.CompanyCurrency, currencyOption: `${props.master?.CompanyCurrency.Currency.alphabeticalCode} - ${props.master?.CompanyCurrency.Currency.currency}` } : undefined,
                        updateResourceBasicRates: false,
                        projectName: ''
                    });
                }
                break
            default:
            case "edit":
                if (props.estimate) {
                    setValues(props.estimate);
                    setDirectLevelsDisabled(!!props.estimate.id);
                    setIndirectLevelsDisabled(!!props.estimate.id);
                }
                break;
            case "estimate":
                if (props.estimate) {
                    if (props.selections) {
                        setDirectLevelsDisabled(props.selections.direct);
                        setIndirectLevelsDisabled(props.selections.indirect);
                    }
                    let unitIds = new Array<string>()
                    const filteredUnits = units?.filter(unit => unit.description !== 'PCT');
                    if (filteredUnits) {
                        filteredUnits.forEach((unit) => {
                            if (unit?.id) {
                                unitIds.push(unit.id);
                            }
                        })
                    }
                    setValues({
                        ...props.estimate,
                        name: '',
                        unitIds: unitIds,
                        areaUnit: units?.find((unit) => (unit.id === props?.estimate?.areaUnitId)),
                        companyCurrencyId: props.estimate?.CompanyCurrency?.id ?? '',
                        companyCurrencyOption: props.estimate?.CompanyCurrency ? { ...props.estimate?.CompanyCurrency, currencyOption: `${props.estimate?.CompanyCurrency?.Currency.alphabeticalCode} - ${props.estimate?.CompanyCurrency?.Currency.currency}` } : undefined,
                    });
                }
                break;
        }

    }, [props.estimate, props.master, props.selectedOption, props.selections, setValues, units])

    const currencyChanged = useCallback((event: any, value: CompanyCurrency & { currencyOption: string } | null) => {
        if (value) {
            setFieldValue('companyCurrency', value);
            setFieldValue('companyCurrencyId', value.id);
        }
    }, [setFieldValue])

    const OnEditSave = useCallback((convert?: boolean) => {
        setFieldValue('updateResourceBasicRates', convert);
        handleSubmit();
    }, [handleSubmit, setFieldValue])

    return <>
        <form noValidate onSubmit={handleSubmit}>
            <TextField
                style={{ marginBottom: '10px' }}
                fullWidth
                id="name"
                name="name"
                label="Estimate Name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
                style={{ marginBottom: '10px' }}
                fullWidth
                id="client"
                name="client"
                label="Client"
                value={formik.values.client}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
            />
            <Box display="flex" justifyContent="space-between" marginBottom="10px">
                <TextField style={{ marginBottom: '10px', marginRight: '10px' }}
                    fullWidth
                    id="area"
                    name="area"
                    label="Area"
                    value={formik.values.area ?? ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    inputProps={{ type: 'number' }} />
                {units && units.length > 0 && <Autocomplete
                    fullWidth
                    className="ag-input-field-input ag-text-field-input"
                    sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                    getOptionLabel={(option) => option.description ?? ''}
                    onChange={(event, value) => {
                        setFieldValue('areaUnit', value);
                    }}
                    options={units}
                    isOptionEqualToValue={(option, value) => (option.id === value.id)}
                    value={formik.values.areaUnit ?? null}
                    renderInput={(params) => <TextField name="areaUnit" sx={{ height: "100%" }} placeholder='Select Unit' {...params} />}
                    renderOption={(props, option, { inputValue }) => {
                        const matches = match(option.description ?? '', inputValue, {
                            insideWords: true,
                        });
                        const parts = parse(option.description ?? '', matches);

                        return (
                            <li {...props}>
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                />
                }
            </Box>
            <Box display="flex" gap={showAdditionalFields ? '10px' : '0px'}>
                {companyCurrenciesExtended && companyCurrenciesExtended.length > 0 && <Box flex="1">
                    <Autocomplete
                        fullWidth
                        className="ag-input-field-input ag-text-field-input"
                        sx={{
                            height: "inherit", "& .MuiTextField-root:": { height: "inherit" },
                            marginBottom: '15px'
                        }}
                        onChange={currencyChanged}
                        disabled={!!props.estimate?.id || !!props.master?.id}
                        options={companyCurrenciesExtended}
                        getOptionLabel={(option) => option.currencyOption ?? ''}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        value={formik.values.companyCurrencyOption ?? null}
                        renderInput={(params) => <TextField name="Seccurrency" sx={{ height: "100%" }} placeholder='Select Currency' {...params} />}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.currencyOption ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.currencyOption ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    /></Box>
                }
                {showAdditionalFields && <Box display="flex" flex="1" alignItems="center">
                    <TextField style={{ marginBottom: '15px', marginRight: '10px' }}
                        fullWidth
                        id="currencyExchangeRate"
                        name="currencyExchangeRate"
                        label="Exchange rate"
                        placeholder="Exchange rate"
                        value={formik.values.currencyExchangeRate}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        inputProps={{ type: 'number' }} />
                    <Box marginBottom="15px">
                        {/* <UnitsModal select={onUnitsSelected} /> */}
                    </Box>
                </Box>
                }
            </Box>

            <TextField style={{ marginBottom: '10px' }}
                select
                fullWidth
                disabled={directLevelsDisabled}
                id="noOfLevels"
                name="noOfLevels"
                label="Direct Item Category Levels"
                value={formik.values.noOfLevels}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}>
                <MenuItem value={0}>None</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
            </TextField>
            <TextField style={{ marginBottom: '10px' }}
                select
                fullWidth
                id="indirectItemNoOfLevels"
                disabled={indirectLevelsDisabled}
                name="indirectItemNoOfLevels"
                label="Indirect Item Category Levels"
                value={formik.values.indirectItemNoOfLevels}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.indirectItemNoOfLevels && Boolean(formik.errors.indirectItemNoOfLevels)}
                helperText={formik.touched.indirectItemNoOfLevels && formik.errors.indirectItemNoOfLevels}
                inputProps={{ type: 'number' }}>
                <MenuItem value={0}>None</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
            </TextField>
            <Box display="flex" justifyContent="space-evenly">
                {formik.values.id && showAdditionalFields && <CurrencyConversionModal onSave={OnEditSave} />}
                <Button variant="outlined" onClick={handleClose}>
                    {props.secondaryButtonText}
                </Button>
                {(!formik.values.id || !showAdditionalFields) && <Button disabled={isSaving} color="primary" variant="contained" type="submit" startIcon={!isSaving ? <SaveIcon /> : <CircularProgress sx={{ color: "white" }} size="1rem" />}>
                    Submit
                </Button>}
            </Box>
        </form>
    </>
}