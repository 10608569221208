import { Box, Radio } from '@mui/material';
import { SupplierPackageResourceDetailsView } from 'Models/package';
import { Column } from 'ag-grid-enterprise';
import { CustomHeaderProps } from 'ag-grid-react';
import { useCallback, useEffect, useState } from 'react';

export interface SupplierPackageCheckedHeaderProps extends CustomHeaderProps {
    vendorHash: string;
    onSelectAll: (column: Column<SupplierPackageResourceDetailsView>) => void;
    checked: boolean;
    disabled: () => boolean;
}
export default function SupplierPackageCheckedHeader(props: SupplierPackageCheckedHeaderProps) {
    const [selectedValue, setSelectedValue] = useState(props.checked);

    useEffect(() =>{
        setSelectedValue(props.checked);
    }, [props])

    const onSelect = useCallback(() =>{
        setSelectedValue(true);
        props.onSelectAll(props.column);
    }, [props])

    const isDisabled = useCallback(() =>{
        if (typeof props.disabled==='function'){
            return props.disabled();
        }
        return false;
    }, [props])
    
    return <><Box display="flex" justifyContent="center" width="100%">
        <Radio disabled={isDisabled()} checked={selectedValue} onChange={onSelect} value={selectedValue}/>
    </Box></>
}