import { Box, Button, Checkbox, FormControlLabel, FormGroup, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Modal, Typography, useTheme } from "@mui/material";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useCallback, useEffect, useState } from "react";
import { tokens } from "theme";
import CloseIcon from '@mui/icons-material/Close';
import { useGetPackagesQuery } from "State/Services/subcontractor";

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    p: 4,
    height: '50%',
};

export interface SubcontractorPackageModalProps {
    select: (packageIds?: Array<string>) => void;
    packageIds: Array<string> | undefined;
    estimateId: string | undefined;
}

export default function SubcontractorPackageModal(props: SubcontractorPackageModalProps){
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const { data: packages } = useGetPackagesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId })
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [open, setOpen] = useState(false);
    const [checked, setChecked] = useState<Array<string> | undefined>([]);
    const [selectAll, setSelectAll] = useState(false);

    useEffect(() =>{
        setChecked(props.packageIds);
    }, [props.packageIds])

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleToggle = useCallback((value: string) => () => {
        if (checked){
            const currentIndex = checked.indexOf(value);
            const newChecked = [...checked];
    
            if (currentIndex === -1) {
                newChecked.push(value);
            } else {
                newChecked.splice(currentIndex, 1);
            }
    
            setChecked(newChecked);
        }

    }, [checked]);

    const cancel = useCallback(() =>{
        handleClose();
    }, [])

    const save = useCallback(() =>{
        props.select(checked);
        handleClose();
    }, [checked, props])

    const selectAllChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            if (packages) {
                const all = packages.map((pckg) => (pckg.id ?? ''));
                if (all) {
                    setChecked(all);
                }
            }
        } else {
            setChecked([]);
        }
        setSelectAll(event.target.checked);
    }, [packages])

    return <>
        <Button onClick={handleOpen}>Packages</Button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
        >
            <Box sx={modalStyle}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Packages</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="delete" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <Box paddingLeft="16px">
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={selectAll}
                            onChange={selectAllChanged}
                            inputProps={{ 'aria-label': 'controlled' }} />} label="Select All" />
                    </FormGroup>
                </Box>
                <Box display="flex" flexDirection="column" height="calc(100% - 60px)" gap="15px">
                    <Box overflow="auto" height="100%">
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {packages?.map((packageInfo) => {
                                const labelId = `checkbox-list-label-${packageInfo}`;
                                return (
                                    <Box key={packageInfo.id}>{packageInfo.id && <ListItem
                                        disablePadding
                                    >
                                        <ListItemButton role={undefined} onClick={handleToggle(packageInfo.id)} dense>
                                            <ListItemIcon>
                                                <Checkbox
                                                    edge="start"
                                                    checked={checked?.some((id) => (id===packageInfo.id))}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={labelId} primary={`${packageInfo.name}`} />
                                        </ListItemButton>
                                    </ListItem>}</Box>
                                );
                            })}
                        </List>
                    </Box>
                    <Box display="flex" justifyContent="space-evenly">
                        <Button onClick={save} variant="contained">Save</Button>
                        <Button onClick={cancel} variant="contained" sx={{ color: "black", backgroundColor: `${colors?.gray[1000]}` }}>Cancel</Button>
                    </Box>
                </Box>
            </Box>
        </Modal>

    </>
}