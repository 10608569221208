import { BaseCompanyCurrency, CompanyCurrency } from 'Models/company-currency';
import { api } from './api'

export const companyCurrencyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCompanyCurrencies: build.query<Array<CompanyCurrency>, { orgId: string, companyId: string }>({
      query: ({ orgId, companyId }: { orgId: string, companyId: string }) => ({ url: `organization/${orgId}/company/${companyId}/currency` }),
      providesTags: ['CompanyCurrency'],
    }),
    createCompanyCurrency: build.mutation<BaseCompanyCurrency, Partial<{ body: BaseCompanyCurrency, companyId: string, orgId: string }>>({
      query({ body, orgId, companyId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/currency`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['CompanyCurrency', 'SupplierPackages', 'SubPackages'],
    }),
    updateCompanyCurrency: build.mutation<BaseCompanyCurrency, Partial<{ body: BaseCompanyCurrency, orgId: string, companyId: string, companyCurrencyId: string }>>({
      query({ body, orgId, companyId, companyCurrencyId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/currency/${companyCurrencyId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['CompanyCurrency', 'Estimate', 'SupplierPackages', 'SubPackages'],
    }),
    deleteCompanyCurrency: build.mutation<void, Partial<{ orgId: string, companyId: string, companyCurrencyId: string }>>({
      query({ orgId, companyId, companyCurrencyId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/currency/${companyCurrencyId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: ['CompanyCurrency'],
    }),
  })
});

export const {
  useGetCompanyCurrenciesQuery,
  useCreateCompanyCurrencyMutation,
  useUpdateCompanyCurrencyMutation,
  useDeleteCompanyCurrencyMutation
} = companyCurrencyApi