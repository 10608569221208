import { Tag, VendorView } from "Models/vendor";
import { IRowNode } from "ag-grid-enterprise";
import * as yup from 'yup';
import { useFormik } from "formik";
import { useCallback } from "react";
import { Autocomplete, Box, Button, Chip, FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField } from "@mui/material";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useGetTagsQuery } from "State/Services/tag";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
export interface VendorFilterSettings {
    type: string | undefined;
    tags: Tag[] | undefined;
}

export interface VendorFilterProps {
    estimateId: string | undefined;
    onFilterChanged: (settings: VendorFilterSettings) => void;
    editing?: IRowNode<VendorView> | undefined;
}

const filterValidationSchema = yup.object<VendorFilterSettings>({
    type: yup
        .string().optional(),
    tags: yup
        .array<Tag>().optional()
});

export default function VendorFilters(props: VendorFilterProps) {
    const { data: user } = useGetUserDetailsQuery();
    const { data: tags } = useGetTagsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });

    const filterForm = useFormik<VendorFilterSettings>({
        initialValues: {
            type: 'All',
            tags: undefined
        },
        validationSchema: filterValidationSchema,
        onSubmit: (values) => {
            props.onFilterChanged(values);
        },
    });

    const resetForm = () => {
        filterForm.resetForm();
        props.onFilterChanged({
            type: 'All',
            tags: undefined
        });
    }

    const handleOnTagChanged = useCallback((event: React.SyntheticEvent, newTags: string[] | null) => {
        const tagsToSet = new Array<Tag>();
        if (newTags && newTags.length > 0) {
            newTags?.forEach((newTag) => {
                const tag = tags?.find((tg) => (tg.description === newTag));
                if (tag) {
                    tagsToSet.push(tag);
                } else {
                    tagsToSet.push({ description: newTag });
                }
            });
        }
        filterForm.setFieldValue('tags', tagsToSet);
        filterForm.handleSubmit();
    }, [filterForm, tags])

    const onChange = useCallback((event: SelectChangeEvent) => {
        filterForm.setFieldValue('type', event.target.value as string);
        filterForm.handleSubmit();
    }, [filterForm])

    return <>
        <form noValidate onSubmit={filterForm.handleSubmit} style={{ display: "flex" }} autoComplete="off">
            <Box margin="10px" marginLeft="5px" marginRight="0px" display="flex" gap="15px" width="100%">
                <Box flex="0.3">
                    <FormControl variant="standard" sx={{ height: 'inherit', width: "100%" }}>
                        <Select
                            size="small"
                            defaultValue={'All'}
                            labelId="type-select-label"
                            id="type-simple-select"
                            value={filterForm.values?.type ?? undefined}
                            label="Type"
                            onChange={onChange}
                            input={<OutlinedInput sx={{ height: "inherit", backgroundColor: "white" }} />}
                        >
                            <MenuItem value={'All'}>All</MenuItem>
                            <MenuItem value={'Subcontractor'}>Subcontractor</MenuItem>
                            <MenuItem value={'Supplier'}>Supplier</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box flex="0.6">
                    <Autocomplete
                        fullWidth
                        size="small"
                        className="ag-input-field-input ag-text-field-input"
                        renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        multiple
                        limitTags={2}
                        value={filterForm.values.tags?.map((val) => (val.description)) ?? []}
                        onChange={handleOnTagChanged}
                        options={tags?.map((tag) => tag.description) ?? []}
                        renderInput={(params) => <TextField placeholder='Select Tag' {...params} />}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option, inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option, matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />
                </Box>
                <Box flex="0.2">
                    <Button
                        sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                        disabled={!!props?.editing}
                        variant="text"
                        onClick={resetForm}
                        type="reset">
                        Clear All
                    </Button>
                </Box>
            </Box>
        </form>
    </>
}