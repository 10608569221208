import * as React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { Avatar, ListItemIcon } from '@mui/material';

export interface MenuOption {
  option: string;
  disabled: boolean;
  avatarText?: string;
  avatarColor?: string;
  icon?: React.ReactNode;
}

export interface SplitButtonOptionsProps {
  buttonText: string;
  options: Array<MenuOption>;
  onButtonClick: () => void;
  buttonIcon?: React.ReactNode;
  onMenuItemClick: (option: string) => void;
  buttonHeight?: string;
  disabled?: boolean;
  variant?: "text" | "contained" | "outlined";
}

export default function SplitButton(props: SplitButtonOptionsProps) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleClick = () => {
    props.onButtonClick();
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: string,
  ) => {
    setOpen(false);
    props.onMenuItemClick(option);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <React.Fragment>
      <ButtonGroup
        variant={props.variant ?? "contained"}
        ref={anchorRef}
        disabled={props.disabled ?? false}
        aria-label="Save"
        sx={{ display: "flex", height: (props.buttonHeight) ? props.buttonHeight : '100%' }}
      >
        <Button sx={{ flex: 1 }} startIcon={(props.buttonIcon) ? props.buttonIcon : undefined} onClick={handleClick}>{props.buttonText}</Button>
        <Button
          sx={{ minWidth: "25px !important", maxWidth: "25px" }}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="save"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {props.options.map((menuOption, index) => (
                    <MenuItem
                      key={index}
                      disabled={menuOption.disabled}
                      onClick={(event) => handleMenuItemClick(event, menuOption.option)}
                    >{menuOption.avatarText && menuOption.avatarColor &&
                      <ListItemIcon>
                        {menuOption.avatarText && menuOption.avatarColor && <Avatar sx={{ bgcolor: menuOption.avatarColor, width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">{menuOption.avatarText}</Avatar>}
                      </ListItemIcon>
                      }
                      {menuOption.icon &&
                      <ListItemIcon>
                        {menuOption.icon}
                      </ListItemIcon>
                      }
                      {menuOption.option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}