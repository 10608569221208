import {
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Box, FormControl, InputAdornment, List, ListItem, OutlinedInput, useTheme } from '@mui/material';
import { tokens } from 'theme';
import { HtmlTooltip } from 'Components/HtmlToolltip';
import WarningIcon from '@mui/icons-material/Warning';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { useGetSettingsQuery } from 'State/Services/settings';
import { useGetEstimateQuery } from 'State/Services/estimate';
import { ActivityView } from 'Models/activity';
import { useLazyGetActivityMaxIdQuery } from 'State/Services/activity';

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';

export interface ActivityListIdEditCellRendererParams extends ICellEditorParams<ActivityView> {
    estimateId: string;
}

export default memo(
    forwardRef((props: ActivityListIdEditCellRendererParams, ref) => {
        const { data: user } = useGetUserDetailsQuery();
        const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
        const [getMaxId] = useLazyGetActivityMaxIdQuery();
        const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
        const [colors, setColors] = useState<any>();
        const theme = useTheme();
        useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
        const [error, setError] = useState<string | undefined>(undefined);

        const createInitialState = () => {
            let startValue;
            let highlightAllOnFocus = true;
            const eventKey = props.eventKey;

            if (eventKey === KEY_BACKSPACE) {
                // if backspace or delete pressed, we clear the cell
                startValue = '';
            } else if (eventKey && eventKey.length === 1) {
                // if a letter was pressed, we start with the letter
                startValue = props.eventKey;
                highlightAllOnFocus = false;
            } else {
                // otherwise we start with the current value
                startValue = props.value;
                if (eventKey === KEY_F2) {
                    highlightAllOnFocus = false;
                }
            }

            return {
                value: startValue,
                highlightAllOnFocus,
            };
        };

        const initialState = createInitialState();
        const [value, setValue] = useState(initialState.value);
        const refInput = useRef<any>(null);

        const onChange = useCallback((value: string) => {
            setValue(value);
        }, [])

        /* Component Editor Lifecycle methods */
        useImperativeHandle(ref, () => {
            return {
                // the final value to send to the grid, on completion of editing
                getValue() {
                    return value === '' || value == null ? null : value;
                },
                setFocusOnAdd() {
                    if (refInput.current) {
                        refInput.current.focus();
                        refInput.current.select();
                    }
                },
                setInputValue(value: string) {
                    setValue(value);
                },
                setError(message: string) {
                    if (message) {
                        setError(message);
                    }
                },
            };
        });

        useEffect(() => {
            let prefix = estimate?.isMaster ? `${settings?.masterIdPrefix}-` : '';
            if (!props.value) {
                getMaxId({
                    companyId: user?.companyId ?? '',
                    estimateId: props.estimateId,
                    organizationId: user?.organizationId ?? ''
                }).then((response) => {
                    setValue((response.data) ? `${prefix}${(response.data + 1).toString()}` : `${prefix}1`);
                });
            }
        }, [estimate?.isMaster, getMaxId, props.estimateId, props.value, settings?.masterIdPrefix, user?.companyId, user?.organizationId])

        return (
            <Box sx={{ width: "100%", height: "100% !important" }}>
                <FormControl sx={{ height: 'inherit', width: "100%" }}>
                    <OutlinedInput
                        sx={{ height: "inherit", backgroundColor: "white" }}
                        className="ag-input-field-input ag-text-field-input"
                        placeholder="ID"
                        onChange={(event) => onChange(event.target.value)}
                        endAdornment={error &&
                            <InputAdornment position="end">
                                <HtmlTooltip color={colors?.redAccent[500]}
                                    title={
                                        <List sx={{ listStyleType: 'disc', pl: 1 }}>
                                            <ListItem
                                                sx={{
                                                    padding: 0,
                                                    textAlign: "center",
                                                    listStyleType: "disc",
                                                    display: "list-item",
                                                }}
                                            >{error}</ListItem>
                                        </List>
                                    }>
                                    <WarningIcon sx={{ color: `${colors?.redAccent[500]}`, cursor: "pointer" }} />
                                </HtmlTooltip>
                            </InputAdornment>
                        }
                        value={value}
                        inputRef={refInput}
                    />
                </FormControl>
            </Box>
        );
    })
);
