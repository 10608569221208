import { Box, IconButton, Tooltip } from "@mui/material";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from "react";
import { PackageItemDetailsView } from "Models/package";

export interface ISubcontractorPackageDeleteCellRendererProps extends ICellRendererParams<PackageItemDetailsView> {
    delete: (node: IRowNode<PackageItemDetailsView>) => Promise<void>;
    canDelete: (node: IRowNode<PackageItemDetailsView>) => boolean;
    disabled: () => boolean;
}

export default function SubcontractorPackageDeleteCellRenderer(props: ISubcontractorPackageDeleteCellRendererProps) {
    const { node } = props;
    const [isDeleting, setIsDeleting] = useState(false);

    const deleteRec = async () => {
        setIsDeleting(true);
        await props.delete(node);
        setIsDeleting(false);
    }

    return (<>
        <Box display="flex" alignItems="center" justifyContent="center">

            {!isDeleting && node.data &&
                <Tooltip title={!props.canDelete(props.node) ? 'Item is currently being used and cannot be deleted.' : ''}>
                    <span>
                        <IconButton aria-label="delete" onClick={deleteRec} disabled={!props.canDelete(props.node) || props.disabled()}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            }
            {isDeleting &&
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress size="1rem" />
                </Box>
            }
        </Box>
    </>
    );
};
