import { Currency } from 'Models/currency';
import { api } from './api'

export const currencyApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCurrencies: build.query<Array<Currency>, null>({
      query: () => ({ url: `currency` }),
      providesTags: ['Currencies'],
    }),
  })
});

export const {
    useGetCurrenciesQuery
} = currencyApi