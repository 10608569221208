
import { Margin } from 'Models/margin';
import { api } from './api'

export const marginApi = api.injectEndpoints({
  endpoints: (build) => ({
    upsertMargin: build.mutation<Margin, Partial<{ body: Array<Margin>, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/margin`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'Margins'],
    }),
    getMargins: build.query<Array<Margin>, {organizationId: string, companyId: string, estimateId: string}>({
        query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/margin` }),
        providesTags: ['Margins']
      }),
    deleteMargin: build.mutation<Margin, Partial<{ orgId: string, companyId: string, estimateId: string, marginId: string }>>({
      query({ orgId, companyId, estimateId, marginId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/margin/${marginId}`,
          method: 'DELETE', 
        }
      },
      invalidatesTags: ['Pricing', 'Margins'],
    }),
  })
});

export const {
useUpsertMarginMutation,
useGetMarginsQuery,
useDeleteMarginMutation
} = marginApi