import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import VendorList from "./Vendors/VendorList";
import SubcontractorList from "./Subcontractors/SubcontractorList";
import SupplierList from "./Suppliers/SupplierList";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";

export default function Vendor() {
    const { state } = useLocation();
    const [value, setValue] = useState<string>('Vendors');
    const { estimateId } = useParams();
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                setHasViewAccess(true);
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Vendors,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
            }
        } else {
            setHasViewAccess(true);
        }
        setIsReady(true);
    }, [estimate, estimate?.EstimateUserRole, user])

    useEffect(() => {
        if (state) {
            const { tab } = state;
            setValue(tab ?? "Vendors");
        }
    }, [state])

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    if (!isReady){
        return <></>
    }

    if (!hasViewAccess){
        return <Box m="10px">Unauthorized access.</Box>
    }
    
    return <>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} sx={{ height: "50px" }}>
                    <Tab sx={{ textTransform: "none", padding: "15px", minHeight: "50px", maxHeight: "50px" }} icon={<HandshakeOutlinedIcon sx={{ width: "22px", height: "18px", fontSize: "smaller" }} />} iconPosition="start" label="Vendors" value="Vendors" />
                    <Tab sx={{ textTransform: "none", padding: "15px", minHeight: "50px", maxHeight: "50px" }} icon={<EngineeringOutlinedIcon sx={{ width: "22px", height: "18px", fontSize: "smaller" }} />} iconPosition="start" label="Subcontractor Quotes" value="Subcontractor Quotes" />
                    <Tab sx={{ textTransform: "none", padding: "15px", minHeight: "50px", maxHeight: "50px" }} icon={<LocalShippingOutlinedIcon sx={{ width: "22px", height: "18px", fontSize: "smaller" }} />} iconPosition="start" label="Supplier Quotes" value="Supplier Quotes" />
                </TabList>
            </Box>
            <TabPanel value="Vendors" sx={{ height: 'calc(100% - 175px)', padding: "10px" }}>
                <VendorList estimateId={estimateId} />
            </TabPanel>
            <TabPanel value="Subcontractor Quotes" sx={{ height: 'calc(100% - 175px)', padding: "10px" }}>
                <SubcontractorList estimateId={estimateId} />
            </TabPanel>
            <TabPanel value="Supplier Quotes" sx={{ height: 'calc(100% - 175px)', padding: "10px" }}>
                <SupplierList estimateId={estimateId} />
            </TabPanel>
        </TabContext>
    </>
}