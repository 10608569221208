import { Box, Typography, Button, useTheme } from "@mui/material";
import { MarginOverrides, MarginView, PercentageOf, Totals } from "Models/margin";
import { CalculationOption } from "Models/price";
import { useCallback, useEffect, useState } from "react";
import { tokens } from "theme";
import { v4 as uuidv4 } from "uuid";
import MarginInfoRow from "./MarginInfoRow";
import { useDeleteMarginMutation, useUpsertMarginMutation } from "State/Services/margin";
import { useParams } from "react-router-dom";
import { useGetUserDetailsQuery } from "State/Services/user";
import { maxBy } from "lodash";
import { useGetEstimateQuery } from "State/Services/estimate";
import { rounder } from "Helpers/rounder";

export interface MarginInfoProps {
    calculatedOption: CalculationOption;
    estimateId?: string;
    options: Array<{ id: string, description: string }>;
    margins: Array<MarginView>;
    totals?: Totals;
    marginOverrides: MarginOverrides;
    disabled: boolean;
}
export interface MarginInfoView {
    id: string;
    displayId?: string;
    description?: string;
    percentage?: number;
    percentageOf?: PercentageOf;
    calculationOption: CalculationOption;
    pricePercentageTotal?: number;
    estimatedPricePercentageTotal?: number;
    directIndirectMarginTotal?: number;
    profitMarginOverride?: number | null;
    priceOverride?: number | null;
    calculatedTotal?: number;
}

export default function MarginInfo(props: MarginInfoProps) {
    const [margins, setMargins] = useState<Array<MarginInfoView>>();
    const { estimateId } = useParams();
    const { data: user } = useGetUserDetailsQuery();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [options] = useState<Array<{ id: string, description: string }>>(props.options);
    const [deleteMargin] = useDeleteMarginMutation();
    const [saveUpdateMargins] = useUpsertMarginMutation();
    const [grandTotal, setGrandTotal] = useState<string>();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId });

    const calculateProfitMarginPercentage = useCallback((margin: MarginInfoView) => {
        switch (margin.percentageOf) {
            case PercentageOf.DirectCost:
                return ((margin.profitMarginOverride ?? 0) / (props.totals?.directCostTotal ?? 1)) * 100;
            case PercentageOf.InDirectCost:
                return ((margin.profitMarginOverride ?? 0) / (props.totals?.indirectCostTotal ?? 1)) * 100;
            case PercentageOf.EstimatedDirectCost:
                return ((margin.profitMarginOverride ?? 0) / (props.totals?.estimatedDirectCostTotal ?? 1)) * 100;
            case PercentageOf.EstimatedInDirectCost:
                return ((margin.profitMarginOverride ?? 0) / (props.totals?.estimatedIndirectCostTotal ?? 1)) * 100;
            case PercentageOf.EstimatedPrice:
                return ((margin.profitMarginOverride ?? 0) / (props.marginOverrides.estimatedQuantityEstimatedPriceOverride ?? 1)) * 100;
            case PercentageOf.Price:
                return ((margin.profitMarginOverride ?? 0) / (props.marginOverrides.quantityPriceOverride ?? 1)) * 100;
            default:
                break;
        }
    }, [props.marginOverrides.estimatedQuantityEstimatedPriceOverride, props.marginOverrides.quantityPriceOverride, props.totals?.directCostTotal, props.totals?.estimatedDirectCostTotal, props.totals?.estimatedIndirectCostTotal, props.totals?.indirectCostTotal])

    useEffect(() => {
        if (props.margins.length === 0) {
            const marginId = uuidv4();
            let profitMarginOverride = undefined;
            let priceOverride = undefined;
            if (props.calculatedOption === CalculationOption.overrideQuantity) {
                profitMarginOverride = props.marginOverrides?.quantityMarginOverride;
                priceOverride = props.marginOverrides?.quantityPriceOverride;
            } else if (props.calculatedOption === CalculationOption.overrideEstimatedQuantity) {
                profitMarginOverride = props.marginOverrides?.estimatedQuantityMarginOverride;
                priceOverride = props.marginOverrides?.estimatedQuantityEstimatedPriceOverride;
            }
            const profitMargin: MarginInfoView = {
                id: marginId,
                description: 'Profit Margin',
                displayId: "1",
                percentage: undefined,
                calculationOption: props.calculatedOption,
                percentageOf: undefined,
                pricePercentageTotal: 0,
                profitMarginOverride: profitMarginOverride,
                priceOverride: priceOverride,
            };
            setMargins([profitMargin]);
        } else {
            const marginStates = new Array<MarginInfoView>();
            let pricePercentageTotal = 0;
            let estimatedPricePercentageTotal = 0;
            let directIndirectMarginTotal = 0;
            props.margins.forEach((margin) => {
                let amount = 0;
                const marginDetail = margin.marginDetails.find((md) => (md.calculationOption === props.calculatedOption));
                if (marginDetail && margin.percentage) {
                    switch (marginDetail.percentageOf) {
                        case PercentageOf.DirectCost:
                            if (props.totals?.directCostTotal && margin.percentage) {
                                amount = (props.totals?.directCostTotal * (margin.percentage / 100));
                                directIndirectMarginTotal += amount;
                            }
                            break;
                        case PercentageOf.InDirectCost:
                            if (props.totals?.indirectCostTotal && margin.percentage) {
                                amount = (props.totals?.indirectCostTotal * (margin.percentage / 100));
                                directIndirectMarginTotal += amount;
                            }
                            break;
                        case PercentageOf.EstimatedDirectCost:
                            if (props.totals?.estimatedDirectCostTotal && margin.percentage) {
                                amount = (props.totals?.estimatedDirectCostTotal * (margin.percentage / 100));
                                directIndirectMarginTotal += amount;
                            }
                            break;
                        case PercentageOf.EstimatedInDirectCost:
                            if (props.totals?.estimatedIndirectCostTotal && margin.percentage) {
                                amount = (props.totals?.estimatedIndirectCostTotal * (margin.percentage / 100));
                                directIndirectMarginTotal += amount;
                            }
                            break;
                        default:
                            break;
                    }
                    if (marginDetail.percentageOf === PercentageOf.Price) {
                        pricePercentageTotal += margin.percentage;
                    } else if (marginDetail.percentageOf === PercentageOf.EstimatedPrice) {
                        estimatedPricePercentageTotal += margin.percentage;
                    }
                }

                marginStates.push({
                    calculationOption: marginDetail?.calculationOption ?? props.calculatedOption,
                    description: margin.description,
                    displayId: margin.displayId,
                    id: margin.id ?? '',
                    percentage: margin.percentage,
                    percentageOf: marginDetail?.percentageOf,
                });
            });
            let marginTotal = 0;
            marginStates.forEach((margin) => {
                if (margin.description !== 'Profit Margin' && props.marginOverrides) {
                    switch (margin.percentageOf) {
                        case PercentageOf.DirectCost:
                            if (props.totals?.directCostTotal && margin.percentage) {
                                marginTotal += (props.totals?.directCostTotal * (margin.percentage / 100));
                            }
                            break;
                        case PercentageOf.InDirectCost:
                            if (props.totals?.indirectCostTotal && margin.percentage) {
                                marginTotal += (props.totals?.indirectCostTotal * (margin.percentage / 100));
                            }
                            break;
                        case PercentageOf.EstimatedDirectCost:
                            if (props.totals?.estimatedDirectCostTotal && margin.percentage) {
                                marginTotal += (props.totals?.estimatedDirectCostTotal * (margin.percentage / 100));
                            }
                            break;
                        case PercentageOf.EstimatedInDirectCost:
                            if (props.totals?.estimatedIndirectCostTotal && margin.percentage) {
                                marginTotal += props.totals?.estimatedIndirectCostTotal * (margin.percentage / 100);
                            }
                            break;
                        case PercentageOf.EstimatedPrice:
                            marginTotal += (props.marginOverrides.estimatedQuantityEstimatedPriceOverride ?? 0) * ((margin?.percentage ?? 0) / 100);
                            break;
                        case PercentageOf.Price:
                            marginTotal += (props.marginOverrides.quantityPriceOverride ?? 0) * ((margin?.percentage ?? 0) / 100);
                            break;
                        default:
                            break;
                    }
                }
                if (margin.percentageOf === PercentageOf.Price) {
                    margin.pricePercentageTotal = pricePercentageTotal;
                    margin.directIndirectMarginTotal = directIndirectMarginTotal;
                } else if (margin.percentageOf === PercentageOf.EstimatedPrice) {
                    margin.estimatedPricePercentageTotal = estimatedPricePercentageTotal;
                    margin.directIndirectMarginTotal = directIndirectMarginTotal;
                }
            });

            if ((props.calculatedOption === CalculationOption.overrideQuantity || props.calculatedOption === CalculationOption.overrideEstimatedQuantity)) {
                const profitMargin = marginStates.find((m) => (m.description === 'Profit Margin'));
                if (profitMargin) {
                    switch (profitMargin.calculationOption) {
                        case CalculationOption.overrideQuantity:
                            profitMargin.profitMarginOverride = (props.marginOverrides.quantityMarginOverride ?? 0) - marginTotal;
                            break;
                        case CalculationOption.overrideEstimatedQuantity:
                            profitMargin.profitMarginOverride = (props.marginOverrides.estimatedQuantityMarginOverride ?? 0) - marginTotal;
                            break;
                        default:
                            break;
                    }
                    profitMargin.percentage = calculateProfitMarginPercentage(profitMargin);
                }
            }
            setMargins(marginStates);
        }

    }, [calculateProfitMarginPercentage, props.calculatedOption, props.marginOverrides, props.marginOverrides.estimatedQuantityMarginOverride, props.marginOverrides.estimatedQuantityEstimatedPriceOverride, props.marginOverrides.quantityMarginOverride, props.marginOverrides.quantityPriceOverride, props.margins, props.totals?.directCostTotal, props.totals?.estimatedDirectCostTotal, props.totals?.estimatedIndirectCostTotal, props.totals?.indirectCostTotal, props.disabled])

    const delMargin = useCallback(async (margin: MarginInfoView) => {
        if (user) {
            if (margin.id) {
                await deleteMargin({
                    companyId: user.companyId,
                    estimateId: estimateId,
                    marginId: margin.id,
                    orgId: user.organizationId
                });
            } else {
                if (margins) {
                    const marginWithout = margins.filter((m) => (m.displayId !== margin.displayId));
                    setMargins(marginWithout);
                }
            }
        }
    }, [deleteMargin, estimateId, margins, user])

    const addMargin = useCallback(() => {
        const marginId = uuidv4();
        const max = maxBy(margins, (margin) => {
            return margin.displayId;
        });
        if (margins) {
            setMargins([...margins, {
                calculationOption: props.calculatedOption,
                description: '',
                displayId: max?.displayId ? (parseInt(max.displayId) + 1).toString() : "2",
                id: marginId,
                percentage: undefined,
                percentageOf: undefined
            } as MarginInfoView]);
        }
    }, [margins, props.calculatedOption])

    const onMarginChange = useCallback((margin: MarginInfoView) => {
        if (margins) {
            let existingMargin = margins.find((m) => (m.id === margin.id));
            if (existingMargin) {
                existingMargin.description = margin.description;
                existingMargin.displayId = margin.displayId;
                existingMargin.percentage = margin.percentage;
                existingMargin.percentageOf = margin.percentageOf;
            }
        }
    }, [margins])

    const saveMargins = useCallback(async () => {
        if (user && user.companyId && user.organizationId) {
            const marginsToSave: Array<MarginView> = props.margins.map((m) => {
                return {
                    marginDetails: m.marginDetails.map((md) => ({ ...md })),
                    description: m.description,
                    displayId: m.displayId,
                    percentage: m.percentage,
                    id: m.id
                }
            });

            margins?.forEach((margin) => {
                const existingMargin = marginsToSave.find((m) => (m.id === margin.id));
                if (existingMargin) {
                    existingMargin.description = margin.description;
                    existingMargin.displayId = margin.displayId;
                    if (!(existingMargin.description === 'Profit Margin' && (margin.calculationOption === CalculationOption.overrideEstimatedQuantity || margin.calculationOption === CalculationOption.overrideQuantity))) {
                        existingMargin.percentage = margin.percentage;
                    }
                    let existingMarginDetail = existingMargin.marginDetails.find((md) => (md.calculationOption === margin.calculationOption));
                    if (existingMarginDetail) {
                        existingMarginDetail.calculationOption = margin.calculationOption;
                        existingMarginDetail.percentageOf = margin.percentageOf;
                    } else {
                        existingMargin.marginDetails.push({
                            calculationOption: margin.calculationOption,
                            marginId: margin.id ?? "",
                            percentageOf: margin.percentageOf
                        });
                    }
                } else {
                    marginsToSave.push({
                        id: margin.id,
                        description: margin.description,
                        displayId: margin.displayId,
                        percentage: margin.percentage,
                        marginDetails: [{
                            calculationOption: margin.calculationOption,
                            marginId: margin.id ?? "",
                            percentageOf: margin.percentageOf
                        }]
                    });
                }
            });

            await saveUpdateMargins({
                body: marginsToSave,
                companyId: user?.companyId,
                estimateId: estimateId,
                orgId: user?.organizationId
            });
        }
    }, [estimateId, margins, props, saveUpdateMargins, user])

    const onUpdateTotal = useCallback((totalDetail: { id: string, total: number }) => {
        if (margins && margins.length > 0) {
            const margin = margins?.find(m => m.id === totalDetail.id);
            if (margin) {
                margin.calculatedTotal = totalDetail.total;
            }
            setGrandTotal(margins?.reduce((accum, currentValue) => {
                return accum + (currentValue?.calculatedTotal ?? 0);
            }, 0).toString());
        }
    }, [margins])

    return <Box width="50vw">
        <Box display="flex" justifyContent="space-between" paddingTop="16px" paddingBottom="16px" paddingLeft="24px" paddingRight="24px" borderBottom={`1px solid ${colors.gray[800]}`}>
            <Box>
                <Typography component="div" sx={{ display: "flex" }}>
                    <Box sx={{ fontWeight: 600, fontSize: "16px", color: `${colors.gray[100]} !important` }}>Set Margins</Box>
                </Typography>
            </Box>
            <Box>
                <Button color="primary" variant="contained" onClick={saveMargins} disabled={props.disabled}>Save</Button>
            </Box>
        </Box>
        <Box>
            {margins && margins.map((margin, index) => {
                return <MarginInfoRow
                    disabled={props.disabled}
                    estimateId={estimateId}
                    index={index}
                    totals={props.totals}
                    key={index.toString()}
                    options={options}
                    onUpdateTotal={onUpdateTotal}
                    marginOverrides={props.marginOverrides}
                    margin={margin}
                    delete={delMargin}
                    addMargin={addMargin}
                    onChange={onMarginChange} />
            })}
        </Box>
        <Box display="flex" padding="20px" width="calc(100% - 20px)" fontWeight="bold" justifyContent="end">
            <Box display="flex" width="250px">
                <Box marginRight="20px" display="flex">Total&nbsp;&nbsp;=</Box>
                <Box display="flex">
                    {(grandTotal && !isNaN(parseFloat(grandTotal))) ? (rounder(parseFloat(grandTotal), (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)) : grandTotal}
                </Box>
            </Box>
        </Box>
    </Box>
}