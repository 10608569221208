import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import { EstimateView } from "Models/estimate";
import { useCallback, useEffect, useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { hasSystemRolePermission } from "Helpers/role-permissions";
import { useGetUserDetailsQuery } from "State/Services/user";

export enum EstimateMenuOptions {
    edit = "edit",
    duplicate = "duplicate",
    master = "master",
    delete = "delete",
    changeCurrency = "change-currency",
    sharingPermissions = "sharing-permissions",
    new = "new"
}

export interface ICancelCellRendererProps extends ICellRendererParams<EstimateView> {
    onAction: (node: IRowNode<EstimateView>, option: EstimateMenuOptions) => Promise<void>;
}

export default function SaveCancelEstimateCellRenderer(props: ICancelCellRendererProps) {
    const { node } = props;
    const { data: user } = useGetUserDetailsQuery();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const [actionsDisabled, setActionsDisabled] = useState(false);
    const [sharingDisabled, setSharingDisabled] = useState(false);
    const [disableMasterActions, setDisableMasterActions] = useState<boolean>(true);

    const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onActionClick = (option: EstimateMenuOptions) => {
        props.onAction(node, option);
        handleMenuClose();
    }

    useEffect(() =>{
        if (user && props.node.data){
            if (user.role==="Admin" || user.role==="Account Owner"){
                setActionsDisabled(false);
                setSharingDisabled(false);
                setDisableMasterActions(false);
                return;
            }
            const hasActionsAccess = hasSystemRolePermission(user, [101, 102]);
            setActionsDisabled(!(hasActionsAccess && user.userId===props.node.data.ownerId));
            const hasSharingAccess = hasSystemRolePermission(user, [103]);
            setSharingDisabled(!(hasSharingAccess && user.userId===props.node.data.ownerId));
            const hasMasterCreate = hasSystemRolePermission(user, [200]);
            setDisableMasterActions(!hasMasterCreate);
        }
    }, [props.node, user])

    return (<>
        {!node.group && node.data &&
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={menuOpen ? 'long-menu' : undefined}
                    aria-expanded={menuOpen ? 'true' : undefined}
                    aria-haspopup="true"
                    disabled={actionsDisabled}
                    onClick={handleMenuClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem disabled={actionsDisabled} onClick={() => onActionClick(EstimateMenuOptions.edit)}>Edit</MenuItem>
                    <MenuItem disabled={actionsDisabled} onClick={() => onActionClick(EstimateMenuOptions.delete)}>Delete</MenuItem>
                    <MenuItem disabled={actionsDisabled} onClick={() => onActionClick(EstimateMenuOptions.duplicate)}>Duplicate</MenuItem>
                    <MenuItem disabled={disableMasterActions || !!props.data?.masterEstimateId} onClick={() => onActionClick(EstimateMenuOptions.master)}>Duplicate as template</MenuItem>
                    <MenuItem disabled={actionsDisabled} onClick={() => onActionClick(EstimateMenuOptions.changeCurrency)}>Change currency</MenuItem>
                    <MenuItem disabled={sharingDisabled} onClick={() => onActionClick(EstimateMenuOptions.sharingPermissions)}>Sharing & Permissions</MenuItem>
                </Menu>
            </Box>}
    </>
    );
};
