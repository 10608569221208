import { CellClickedEvent, CellEditingStartedEvent, CellEditingStoppedEvent, CellKeyDownEvent, ColDef, GetRowIdParams, ICellRendererParams, IRowNode, SuppressKeyboardEventParams } from "ag-grid-community";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import { useCreateCategoryMutation, useDeleteCategoryMutation, useGetCategoriesQuery, useUpdateCategoryMutation } from 'State/Services/category';
import { useGetUserDetailsQuery } from "State/Services/user";
import { CategoryLevel, EstimateCategory, EstimateCategoryView } from "Models/estimate";
import SaveCancelCategoryCellRenderer from "./SaveCancelCategoryCellRenderer";
import CategoryIdEditCellRenderer from "./CategoryIdEditCellRenderer";
import { useGetEstimateQuery } from "State/Services/estimate";
import { tokens } from "theme";
import { ServerError } from "Models/error-info";
import React from "react";
import CategoryDescriptionEditCellRenderer from "./CategoryDescriptionEditCellRenderer";

export interface CategoriesProps {
    estimateId: string | undefined;
    noOfLevels: number | undefined;
    isImport: boolean;
}

export function Categories(props: CategoriesProps) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [currentCategoryEditing1, setCurrentCategoryEditing1] = useState<{ node: IRowNode<EstimateCategoryView> | undefined, column?: string }>();
    const [currentCategoryEditing2, setCurrentCategoryEditing2] = useState<{ node: IRowNode<EstimateCategoryView> | undefined, column?: string }>();
    const [currentCategoryEditing3, setCurrentCategoryEditing3] = useState<{ node: IRowNode<EstimateCategoryView> | undefined, column?: string }>();
    const [currentCategoryEditing4, setCurrentCategoryEditing4] = useState<{ node: IRowNode<EstimateCategoryView> | undefined, column?: string }>();
    const [category1s, setCategory1s] = useState<EstimateCategoryView[]>();
    const [category2s, setCategory2s] = useState<EstimateCategoryView[]>();
    const [category3s, setCategory3s] = useState<EstimateCategoryView[]>();
    const [category4s, setCategory4s] = useState<EstimateCategoryView[]>();
    const category1GridRef = useRef<AgGridReact<EstimateCategoryView>>(null);
    const category2GridRef = useRef<AgGridReact<EstimateCategoryView>>(null);
    const category3GridRef = useRef<AgGridReact<EstimateCategoryView>>(null);
    const category4GridRef = useRef<AgGridReact<EstimateCategoryView>>(null);
    const { data: storedCategories } = useGetCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId });
    const [deleteCategory] = useDeleteCategoryMutation();
    const [createCategory] = useCreateCategoryMutation();
    const [updateCategory] = useUpdateCategoryMutation();
    const [category1Errors, setCategory1Errors] = useState<Array<{ field: string, error: string }>>([]);
    const [isCategory1CancelClicked, setIsCategory1CancelClicked] = useState(false);
    const [category2Errors, setCategory2Errors] = useState<Array<{ field: string, error: string }>>([]);
    const [isCategory2CancelClicked, setIsCategory2CancelClicked] = useState(false);
    const [category3Errors, setCategory3Errors] = useState<Array<{ field: string, error: string }>>([]);
    const [isCategory3CancelClicked, setIsCategory3CancelClicked] = useState(false);
    const [category4Errors, setCategory4Errors] = useState<Array<{ field: string, error: string }>>([]);
    const [isCategory4CancelClicked, setIsCategory4CancelClicked] = useState(false);

    useEffect(() => {
        if (currentCategoryEditing1 && typeof currentCategoryEditing1.node?.rowIndex === 'number') {
            if (currentCategoryEditing1.node.isRowPinned()) {
                category1GridRef.current!.api.startEditingCell({
                    rowIndex: currentCategoryEditing1.node.rowIndex,
                    colKey: currentCategoryEditing1.column ?? 'displayId',
                    rowPinned: 'top'
                });
            } else {
                category1GridRef.current!.api.startEditingCell({
                    rowIndex: currentCategoryEditing1.node.rowIndex,
                    colKey: currentCategoryEditing1.column ?? 'displayId',
                });
            }
        }
    }, [currentCategoryEditing1])

    useEffect(() => {
        if (currentCategoryEditing2 && typeof currentCategoryEditing2.node?.rowIndex === 'number') {
            if (currentCategoryEditing2.node.isRowPinned()) {
                category2GridRef.current!.api.startEditingCell({
                    rowIndex: currentCategoryEditing2.node.rowIndex,
                    colKey: currentCategoryEditing2.column ?? 'displayId',
                    rowPinned: 'top'
                });
            } else {
                category2GridRef.current!.api.startEditingCell({
                    rowIndex: currentCategoryEditing2.node.rowIndex,
                    colKey: currentCategoryEditing2.column ?? 'displayId',
                });
            }
        }
    }, [currentCategoryEditing2])

    useEffect(() => {
        if (currentCategoryEditing3 && typeof currentCategoryEditing3.node?.rowIndex === 'number') {
            if (currentCategoryEditing3.node.isRowPinned()) {
                category3GridRef.current!.api.startEditingCell({
                    rowIndex: currentCategoryEditing3.node.rowIndex,
                    colKey: currentCategoryEditing3.column ?? 'displayId',
                    rowPinned: 'top'
                });
            } else {
                category3GridRef.current!.api.startEditingCell({
                    rowIndex: currentCategoryEditing3.node.rowIndex,
                    colKey: currentCategoryEditing3.column ?? 'displayId',
                });
            }
        }
    }, [currentCategoryEditing3])

    useEffect(() => {
        if (currentCategoryEditing4 && typeof currentCategoryEditing4.node?.rowIndex === 'number') {
            if (currentCategoryEditing4.node.isRowPinned()) {
                category4GridRef.current!.api.startEditingCell({
                    rowIndex: currentCategoryEditing4.node.rowIndex,
                    colKey: currentCategoryEditing4.column ?? 'displayId',
                    rowPinned: 'top'
                });
            } else {
                category4GridRef.current!.api.startEditingCell({
                    rowIndex: currentCategoryEditing4.node.rowIndex,
                    colKey: currentCategoryEditing4.column ?? 'displayId',
                });
            }
        }
    }, [currentCategoryEditing4])

    useEffect(() => {
        let categoryErrors = new Array<{
            field: string;
            error: string;
        }>();
        let gridRef: React.RefObject<AgGridReact<EstimateCategoryView>>;
        if (category1Errors.length > 0) {
            categoryErrors = category1Errors;
            gridRef = category1GridRef;
        } 
        else if (category2Errors.length > 0) {
            categoryErrors = category2Errors;
            gridRef = category2GridRef;
        }
        else if (category3Errors.length > 0) {
            categoryErrors = category3Errors;
            gridRef = category3GridRef;
        }
        else if (category4Errors.length > 0) {
            categoryErrors = category4Errors;
            gridRef = category4GridRef;
        }
        if (categoryErrors.length > 0) {
            categoryErrors.forEach((errorDetails) => {
                switch (errorDetails.field) {
                    case 'category':
                        const categoryInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['category']
                        });
                        if (categoryInstances && categoryInstances.length > 0 && categoryInstances[0] && typeof (categoryInstances[0] as any).setError === 'function') {
                            (categoryInstances[0] as any).setError(errorDetails.error);
                        }
                        break;
                    case 'displayId':
                        const displayIdInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['displayId']
                        });
                        if (displayIdInstances && displayIdInstances.length > 0 && displayIdInstances[0] && typeof (displayIdInstances[0] as any).setError === 'function') {
                            (displayIdInstances[0] as any).setError(errorDetails.error);
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    }, [category1Errors, category2Errors, category3Errors, category4Errors])

    const saveRow = useCallback((nodeToSave: IRowNode<EstimateCategoryView>, categoryLevel: CategoryLevel, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<EstimateCategoryView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            let gridRef: React.RefObject<AgGridReact<EstimateCategoryView>>;
            let setCurrentCategoryEditing: React.Dispatch<React.SetStateAction<{
                node: IRowNode<EstimateCategoryView> | undefined;
                column?: string;
            } | undefined>>;
            let setCategoryErrors;
            switch (categoryLevel) {
                default:
                case CategoryLevel.category1:
                    gridRef = category1GridRef;
                    setCurrentCategoryEditing = setCurrentCategoryEditing1;
                    setCategoryErrors = setCategory1Errors;
                    break;
                case CategoryLevel.category2:
                    gridRef = category2GridRef;
                    setCurrentCategoryEditing = setCurrentCategoryEditing2;
                    setCategoryErrors = setCategory2Errors;
                    break;
                case CategoryLevel.category3:
                    gridRef = category3GridRef;
                    setCurrentCategoryEditing = setCurrentCategoryEditing3;
                    setCategoryErrors = setCategory3Errors;
                    break;
                case CategoryLevel.category4:
                    gridRef = category4GridRef;
                    setCurrentCategoryEditing = setCurrentCategoryEditing4;
                    setCategoryErrors = setCategory4Errors;
                    break;
            }
            try {
                if (gridRef && gridRef.current) {
                    gridRef.current!.api.stopEditing();
                    if (nodeToSave.data) {
                        if (!nodeToSave.data?.displayId) {
                            const error: ServerError = { data: { displayId: 'ID is required' } };
                            throw error;
                        }
                        if (!nodeToSave.data?.category) {
                            const error: ServerError = { data: { description: 'Category is required' } };
                            throw error;
                        }
                        if (!nodeToSave.data.isNew) {
                            await updateCategory({
                                categoryId: nodeToSave.data.id,
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                body: {
                                    id: nodeToSave.data.id,
                                    displayId: nodeToSave.data.displayId,
                                    category: nodeToSave.data.category?.trim().replace('\t', ''),
                                    level: categoryLevel,
                                    repetitions: nodeToSave.data.repetitions
                                }
                            }).unwrap();
                        } else {
                            await createCategory({
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                body: {
                                    displayId: nodeToSave.data.displayId,
                                    category: nodeToSave.data.category?.trim().replace('\t', ''),
                                    level: categoryLevel,
                                    repetitions: nodeToSave.data.repetitions
                                }
                            }).unwrap();
                        }

                        if (nodeToSave.rowPinned) {
                            gridRef.current!.api.applyTransaction({ add: [nodeToSave.data] });
                            nodeToSave.updateData({
                                category: '',
                                displayId: undefined,
                                id: undefined,
                                estimateId: props.estimateId,
                                level: nodeToSave.data.level,
                                repetitions: undefined,
                                errors: [],
                                isNew: true
                            });
                        }
                    }
                }
                resolve();
                if (toEditAfterSave) {
                    setCurrentCategoryEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                } else {
                    setCurrentCategoryEditing(undefined);
                }
            } catch (error: any) {
                let errorMessage = '';
                let column = 'displayId';
                if (error) {
                    if (error.data.displayId) {
                        errorMessage = error.data.displayId;
                        column = 'displayId';
                    } else if (error.data.category) {
                        errorMessage = error.data.category;
                        column = 'category';
                    }
                }

                if (errorMessage && typeof nodeToSave.rowIndex === 'number') {
                    if (nodeToSave.isRowPinned()) {
                        gridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: column,
                            rowPinned: 'top'
                        });
                    } else {
                        gridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: column,
                        });
                    }
                    setCategoryErrors([{ field: column, error: errorMessage }]);
                }
                reject(error);
            }
        });
    }, [createCategory, props.estimateId, updateCategory, user?.companyId, user?.organizationId])

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<EstimateCategoryView>, categoryLevel: CategoryLevel) => {
        if (params.event.key === 'Enter' && params.node) {
            params.event.stopPropagation();
            saveRow(params.node, categoryLevel);
            return true;
        }
        return false;
    }, [saveRow])

    const getColumnDefs = (categoryLevel: CategoryLevel): ColDef<EstimateCategoryView>[] => {
        return [
            { field: 'id', hide: true, cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } },
            {
                field: 'displayId',
                editable: true,
                cellEditor: CategoryIdEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId,
                    level: categoryLevel
                },
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                headerName: 'ID',
                cellDataType: 'number',
                width: 150,
                sortable: true,
                sort: "asc",
                suppressKeyboardEvent: (params: SuppressKeyboardEventParams<EstimateCategoryView>) => saveOnEnter(params, categoryLevel),
            },
            {
                field: 'category',
                editable: true,
                cellRenderer: (params: ICellRendererParams<EstimateCategoryView>) =>{
                    if (params.node.isRowPinned()){
                        return <i>{params.node.data?.category}</i>
                    }
                    return params.node.data?.category;
                },
                cellEditor: CategoryDescriptionEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId
                },
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                flex: 1,
                suppressKeyboardEvent: (params) => saveOnEnter(params, categoryLevel),
            },
            ...categoryLevel === CategoryLevel.category1 ? [{
                field: 'repetitions',
                cellDataType: 'number',
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                editable: true,
                width: 100,
                suppressKeyboardEvent: (params: SuppressKeyboardEventParams<EstimateCategoryView>) => saveOnEnter(params, categoryLevel),
            }] as ColDef<EstimateCategoryView>[] : [] as ColDef<EstimateCategoryView>[],
            {
                field: 'actions',
                suppressKeyboardEvent: (params: SuppressKeyboardEventParams<EstimateCategoryView>) => saveOnEnter(params, categoryLevel),
                resizable: true,
                width: 60,
                editable: false,
                headerName: '',
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRendererSelector: () => {
                    return {
                        component: SaveCancelCategoryCellRenderer,
                        params: {
                            delete: (node: IRowNode<EstimateCategoryView>) => deleteRow(node, categoryLevel),
                            save: (node: IRowNode<EstimateCategoryView>) => saveRow(node, categoryLevel),
                            cancel: (node: IRowNode<EstimateCategoryView>) => cancelEditing(node, categoryLevel),
                        },
                    };
                },
            },
        ]
    }
    const [category1Defs] = useState<ColDef<EstimateCategoryView>[]>(getColumnDefs(CategoryLevel.category1));
    const [category2Defs] = useState<ColDef<EstimateCategoryView>[]>(getColumnDefs(CategoryLevel.category2));
    const [category3Defs] = useState<ColDef<EstimateCategoryView>[]>(getColumnDefs(CategoryLevel.category3));
    const [category4Defs] = useState<ColDef<EstimateCategoryView>[]>(getColumnDefs(CategoryLevel.category4));

    const deleteRow = useCallback((params: IRowNode<EstimateCategoryView>, categoryLevel: CategoryLevel) => {
        return new Promise<void>(async (resolve) => {
            if (params.data) {
                let gridRef: React.RefObject<AgGridReact<EstimateCategoryView>>;
                switch (categoryLevel) {
                    default:
                    case CategoryLevel.category1:
                        gridRef = category1GridRef;
                        break;
                    case CategoryLevel.category2:
                        gridRef = category2GridRef;
                        break;
                    case CategoryLevel.category3:
                        gridRef = category3GridRef;
                        break;
                    case CategoryLevel.category4:
                        gridRef = category4GridRef;
                        break;
                }
                await deleteCategory({
                    companyId: user?.companyId,
                    estimateId: props.estimateId,
                    orgId: user?.organizationId,
                    categoryId: params.data?.id,
                    deleteEmptyItems: true
                });
                gridRef.current!.api.applyTransaction({ remove: [params.data] });
            }

            resolve();
        });
    }, [deleteCategory, props.estimateId, user?.companyId, user?.organizationId])

    const resetGrid = useCallback((categoryLevel: CategoryLevel) => {
        const categories = new Array<EstimateCategoryView>();
        let setCategories: (value: React.SetStateAction<EstimateCategoryView[] | undefined>) => void;
        switch (categoryLevel) {
            default:
            case CategoryLevel.category1:
                setCategories = setCategory1s;
                break;
            case CategoryLevel.category2:
                setCategories = setCategory2s;
                break;
            case CategoryLevel.category3:
                setCategories = setCategory3s;
                break;
            case CategoryLevel.category4:
                setCategories = setCategory4s;
                break;
        }

        if (storedCategories && storedCategories.length > 0) {
            for (let i = 0; i < storedCategories.length; i++) {
                const category = storedCategories[i];
                if (category.level === categoryLevel) {
                    categories.push({
                        category: category.category,
                        actions: '',
                        displayId: category.displayId,
                        estimateId: category.estimateId,
                        id: category.id,
                        level: category.level,
                        repetitions: category.repetitions,
                        errors: [],
                        isNew: false,
                        hasItems: category.hasItems
                    });
                }
            }
            setCategories(categories);
        } else {
            setCategories([]);
        }
    }, [storedCategories])

    useEffect(() => {
        switch (estimate?.noOfLevels) {
            case 1:
                resetGrid(CategoryLevel.category1);
                break;
            case 2:
                resetGrid(CategoryLevel.category1);
                resetGrid(CategoryLevel.category2);
                break;
            case 3:
                resetGrid(CategoryLevel.category1);
                resetGrid(CategoryLevel.category2);
                resetGrid(CategoryLevel.category3);
                break;
            case 4:
                resetGrid(CategoryLevel.category1);
                resetGrid(CategoryLevel.category2);
                resetGrid(CategoryLevel.category3);
                resetGrid(CategoryLevel.category4);
                break;
            default:
                break;
        }
    }, [estimate?.noOfLevels, resetGrid])

    const onGridReady = useCallback((categoryLevel: CategoryLevel) => {
        resetGrid(categoryLevel);
    }, [resetGrid]);

    const cancelEditing = useCallback((node: IRowNode<EstimateCategoryView>, categoryLevel: CategoryLevel) => {
        let gridRef: React.RefObject<AgGridReact<EstimateCategoryView>>;
        let setIsCategoryCancelClicked;
        let setCurrentCategoryEditing;
        switch (categoryLevel) {
            default:
            case CategoryLevel.category1:
                gridRef = category1GridRef;
                setIsCategoryCancelClicked = setIsCategory1CancelClicked;
                setCurrentCategoryEditing = setCurrentCategoryEditing1;
                break;
            case CategoryLevel.category2:
                gridRef = category2GridRef;
                setIsCategoryCancelClicked = setIsCategory2CancelClicked;
                setCurrentCategoryEditing = setCurrentCategoryEditing2;
                break;
            case CategoryLevel.category3:
                gridRef = category3GridRef;
                setIsCategoryCancelClicked = setIsCategory3CancelClicked;
                setCurrentCategoryEditing = setCurrentCategoryEditing3;
                break;
            case CategoryLevel.category4:
                gridRef = category4GridRef;
                setIsCategoryCancelClicked = setIsCategory4CancelClicked;
                setCurrentCategoryEditing = setCurrentCategoryEditing4;
                break;
        }
        gridRef.current!.api.stopEditing(true);
        if (node.rowPinned) {
            node.updateData({
                category: 'Enter Category Name',
                displayId: undefined,
                id: undefined,
                estimateId: props.estimateId,
                level: undefined,
                repetitions: undefined,
                errors: [],
                isNew: true
            });
        } else {
            setIsCategoryCancelClicked(true);
            const data = storedCategories?.find((cat) => (cat.id === node.data?.id));
            if (data) {
                gridRef.current!.api.applyTransaction({ update: [{ ...data }] });
            }
            gridRef.current!.api.stopEditing(true);
        }
        setCurrentCategoryEditing(undefined);
    }, [props.estimateId, storedCategories])

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            editable: true,
            resizable: true
        };
    }, []);

    const onCategoryCellClicked = useCallback(async (event: CellClickedEvent) => {
        try {
            let currentCategoryEditing: {
                node: IRowNode<EstimateCategoryView> | undefined;
                column?: string;
            } | undefined;
            let isCategoryCancelClicked: boolean;
            let setIsCategoryCancelClicked;
            let setCurrentCategoryEditing;
            switch (event.node.data.level) {
                default:
                case CategoryLevel.category1:
                    currentCategoryEditing = currentCategoryEditing1;
                    isCategoryCancelClicked = isCategory1CancelClicked;
                    setIsCategoryCancelClicked = setIsCategory1CancelClicked;
                    setCurrentCategoryEditing = setCurrentCategoryEditing1;
                    break;
                case CategoryLevel.category2:
                    currentCategoryEditing = currentCategoryEditing2;
                    isCategoryCancelClicked = isCategory2CancelClicked;
                    setIsCategoryCancelClicked = setIsCategory2CancelClicked;
                    setCurrentCategoryEditing = setCurrentCategoryEditing2;
                    break;
                case CategoryLevel.category3:
                    currentCategoryEditing = currentCategoryEditing3;
                    isCategoryCancelClicked = isCategory3CancelClicked;
                    setIsCategoryCancelClicked = setIsCategory3CancelClicked;
                    setCurrentCategoryEditing = setCurrentCategoryEditing3;
                    break;
                case CategoryLevel.category4:
                    currentCategoryEditing = currentCategoryEditing4;
                    isCategoryCancelClicked = isCategory4CancelClicked;
                    setIsCategoryCancelClicked = setIsCategory4CancelClicked;
                    setCurrentCategoryEditing = setCurrentCategoryEditing4;
                    break;
            }

            if (currentCategoryEditing?.node === event.node) {
                return;
            }
            if (!currentCategoryEditing?.node) {
                if (event.node.data?.masterReferenceId) return;

                if (isCategoryCancelClicked) {
                    setIsCategoryCancelClicked(false);
                    return;
                }

                if (event.column.getColId() === 'actions') {
                    return;
                }
                setCurrentCategoryEditing({ node: event.node, column: event.column.getColId() });
            } else {
                await saveRow(currentCategoryEditing.node, event.node.data.level, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
            }
        } catch (error) { }
    }, [currentCategoryEditing1, currentCategoryEditing2, currentCategoryEditing3, currentCategoryEditing4, isCategory1CancelClicked, isCategory2CancelClicked, isCategory3CancelClicked, isCategory4CancelClicked, saveRow])

    const onRowEditingStarted = (event: CellEditingStartedEvent) => {
        let currentCategoryEditing: {
            node: IRowNode<EstimateCategoryView> | undefined;
            column?: string;
        } | undefined;
        let setIsCategoryCancelClicked;
        let gridRef: React.RefObject<AgGridReact<EstimateCategoryView>>;
        switch (event.node.level) {
            default:
            case CategoryLevel.category1:
                currentCategoryEditing = currentCategoryEditing1;
                setIsCategoryCancelClicked = setIsCategory1CancelClicked;
                gridRef = category1GridRef;
                break;
            case CategoryLevel.category2:
                currentCategoryEditing = currentCategoryEditing2;
                setIsCategoryCancelClicked = setIsCategory2CancelClicked;
                gridRef = category2GridRef;
                break;
            case CategoryLevel.category3:
                currentCategoryEditing = currentCategoryEditing3;
                setIsCategoryCancelClicked = setIsCategory3CancelClicked;
                gridRef = category3GridRef;
                break;
            case CategoryLevel.category4:
                currentCategoryEditing = currentCategoryEditing4;
                setIsCategoryCancelClicked = setIsCategory4CancelClicked;
                gridRef = category4GridRef;
                break;
        }
        setIsCategoryCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
        setTimeout(() => {
            const displayInstances = gridRef.current!.api.getCellEditorInstances({
                columns: [currentCategoryEditing?.column ?? 'displayId']
            });
            if (displayInstances && displayInstances.length > 0 && displayInstances[0] && typeof (displayInstances[0] as any).setFocusOnAdd === 'function') {
                (displayInstances[0] as any).setFocusOnAdd();
            }
        }, 100);
    }

    const onRowEditingStopped = (event: CellEditingStoppedEvent) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }

    const getRowId = useCallback(function (params: GetRowIdParams<EstimateCategoryView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    const createCategory1PinnedData = useMemo<EstimateCategoryView[]>(() => {
        return [{ isNew: true, category: 'Enter Category Name', level: CategoryLevel.category1 }];
    }, [])

    const createCategory2PinnedData = useMemo<EstimateCategoryView[]>(() => {
        return [{ isNew: true, category: 'Enter Category Name', level: CategoryLevel.category2 }];
    }, [])

    const createCategory3PinnedData = useMemo<EstimateCategoryView[]>(() => {
        return [{ isNew: true, category: 'Enter Category Name', level: CategoryLevel.category3 }];
    }, [])

    const createCategory4PinnedData = useMemo<EstimateCategoryView[]>(() => {
        return [{ isNew: true, category: 'Enter Category Name', level: CategoryLevel.category4 }];
    }, [])

    const onCategoryCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        let setIsCategoryCancelClicked;
        switch (e.node.level) {
            default:
            case CategoryLevel.category1:
                setIsCategoryCancelClicked = setIsCategory1CancelClicked;
                break;
            case CategoryLevel.category2:
                setIsCategoryCancelClicked = setIsCategory2CancelClicked;
                break;
            case CategoryLevel.category3:
                setIsCategoryCancelClicked = setIsCategory3CancelClicked;
                break;
            case CategoryLevel.category4:
                setIsCategoryCancelClicked = setIsCategory4CancelClicked;
                break;
        }
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelEditing(e.node, e.node.data.level);
            setIsCategoryCancelClicked(false);
        }
    }, [cancelEditing]);

    const renderSwitch = () => {
        switch (props.noOfLevels) {
            case 1:
            default:
                return <Box className="ag-theme-alpine ag-theme-bidbow" height="calc(100% - 100px)" width="100%" padding="10px">
                    {props.isImport && <Box marginBottom="10px">
                        <Typography variant="subtitle1">To map categories to items automatically, enter the category names exactly as they appear in the file. All items following a category will be assumed to belong to it, until the next category is found. Items that do not map automatically can be assigned categories manually after import.</Typography>
                    </Box>}
                    <AgGridReact<EstimateCategory>
                        ref={category1GridRef}
                        editType={'fullRow'}
                        rowData={category1s}
                        suppressRowClickSelection={true}
                        columnDefs={category1Defs}
                        defaultColDef={defaultColDef}
                        onCellClicked={onCategoryCellClicked}
                        onCellKeyDown={onCategoryCellKeyDown}
                        onGridReady={() => onGridReady(CategoryLevel.category1)}
                        pinnedTopRowData={createCategory1PinnedData}
                        onRowEditingStarted={onRowEditingStarted}
                        onRowEditingStopped={onRowEditingStopped}
                        getRowId={getRowId}
                    />
                </Box>;
            case 2:
                return <Box height="100%" flex="1" display="flex" flexDirection="column">
                    {props.isImport && <Box marginLeft="20px" marginTop="20px">
                        <Typography variant="subtitle1">To map categories to items automatically, enter the category names exactly as they appear in the file. All items following a category will be assumed to belong to it, until the next category is found. Items that do not map automatically can be assigned categories manually after import.</Typography>
                    </Box>}
                    <Box height="100%" flex="1" display="flex" marginLeft="10px" marginRight="10px" marginBottom="10px" flexWrap="wrap">
                        <Box className="ag-theme-alpine ag-theme-bidbow" flex="1" margin="10px" height="calc(100% - 40px)">
                            <Typography sx={{ paddingBottom: "10px" }}>Level 1 Categories</Typography>
                            <AgGridReact<EstimateCategory>
                                ref={category1GridRef}
                                editType={'fullRow'}
                                rowData={category1s}
                                suppressRowClickSelection={true}
                                onGridReady={() => onGridReady(CategoryLevel.category1)}
                                columnDefs={category1Defs}
                                defaultColDef={defaultColDef}
                                onCellClicked={onCategoryCellClicked}
                                onCellKeyDown={onCategoryCellKeyDown}
                                pinnedTopRowData={createCategory1PinnedData}
                                onRowEditingStarted={onRowEditingStarted}
                                onRowEditingStopped={onRowEditingStopped}
                                getRowId={getRowId}
                            />
                        </Box>
                        <Box className="ag-theme-alpine ag-theme-bidbow" flex="1" margin="10px" height="calc(100% - 40px)">
                            <Typography sx={{ paddingBottom: "10px" }}>Level 2 Categories</Typography>
                            <AgGridReact<EstimateCategory>
                                ref={category2GridRef}
                                editType={'fullRow'}
                                rowData={category2s}
                                suppressRowClickSelection={true}
                                onGridReady={() => onGridReady(CategoryLevel.category2)}
                                columnDefs={category2Defs}
                                onCellClicked={onCategoryCellClicked}
                                onCellKeyDown={onCategoryCellKeyDown}
                                defaultColDef={defaultColDef}
                                pinnedTopRowData={createCategory2PinnedData}
                                onRowEditingStarted={onRowEditingStarted}
                                onRowEditingStopped={onRowEditingStopped}
                                getRowId={getRowId}
                            />
                        </Box>
                    </Box>
                </Box>;
            case 3:
                return <Box height="100%" flex="1" display="flex" flexDirection="column">
                    {props.isImport && <Box marginLeft="20px" marginTop="20px">
                        <Typography variant="subtitle1">To map categories to items automatically, enter the category names exactly as they appear in the file. All items following a category will be assumed to belong to it, until the next category is found. Items that do not map automatically can be assigned categories manually after import.</Typography>
                    </Box>}
                    <Box height="100%" flex="1" display="flex" marginLeft="10px" marginRight="10px" marginBottom="10px" flexWrap="wrap">
                        <Box className="ag-theme-alpine ag-theme-bidbow" flex="1" margin="10px" height="calc(100% - 40px)">
                            <Typography sx={{ paddingBottom: "10px" }}>Level 1 Categories</Typography>
                            <AgGridReact<EstimateCategory>
                                ref={category1GridRef}
                                editType={'fullRow'}
                                rowData={category1s}
                                suppressRowClickSelection={true}
                                onCellClicked={onCategoryCellClicked}
                                onCellKeyDown={onCategoryCellKeyDown}
                                onGridReady={() => onGridReady(CategoryLevel.category1)}
                                columnDefs={category1Defs}
                                defaultColDef={defaultColDef}
                                pinnedTopRowData={createCategory1PinnedData}
                                onRowEditingStarted={onRowEditingStarted}
                                onRowEditingStopped={onRowEditingStopped}
                                getRowId={getRowId}
                            />
                        </Box>
                        <Box className="ag-theme-alpine ag-theme-bidbow" flex="1" margin="10px" height="calc(100% - 40px)">
                            <Typography sx={{ paddingBottom: "10px" }}>Level 2 Categories</Typography>
                            <AgGridReact<EstimateCategory>
                                ref={category2GridRef}
                                editType={'fullRow'}
                                rowData={category2s}
                                suppressRowClickSelection={true}
                                onCellClicked={onCategoryCellClicked}
                                onCellKeyDown={onCategoryCellKeyDown}
                                onGridReady={() => onGridReady(CategoryLevel.category2)}
                                columnDefs={category2Defs}
                                defaultColDef={defaultColDef}
                                pinnedTopRowData={createCategory2PinnedData}
                                onRowEditingStarted={onRowEditingStarted}
                                onRowEditingStopped={onRowEditingStopped}
                                getRowId={getRowId}
                            />
                        </Box>
                        <Box className="ag-theme-alpine" flex="1" margin="10px" height="calc(100% - 40px)">
                            <Typography sx={{ paddingBottom: "10px" }}>Level 3 Categories</Typography>
                            <AgGridReact<EstimateCategory>
                                ref={category3GridRef}
                                editType={'fullRow'}
                                rowData={category3s}
                                suppressRowClickSelection={true}
                                onCellClicked={onCategoryCellClicked}
                                onCellKeyDown={onCategoryCellKeyDown}
                                onGridReady={() => onGridReady(CategoryLevel.category3)}
                                columnDefs={category3Defs}
                                defaultColDef={defaultColDef}
                                pinnedTopRowData={createCategory3PinnedData}
                                onRowEditingStarted={onRowEditingStarted}
                                onRowEditingStopped={onRowEditingStopped}
                                getRowId={getRowId}
                            />
                        </Box>
                    </Box>
                </Box>
            case 4:
                return <Box height="100%" flex="1" display="flex" padding="10px" flexDirection="column">
                    {props.isImport && <Box marginBottom="10px">
                        <Typography variant="subtitle1">To map categories to items automatically, enter the category names exactly as they appear in the file. All items following a category will be assumed to belong to it, until the next category is found. Items that do not map automatically can be assigned categories manually after import.</Typography>
                    </Box>}
                    <Box height="calc(100% - 40px)" flex="1" display="flex" flexWrap="wrap" >
                        <Box className="ag-theme-alpine ag-theme-bidbow" flex="1" marginBottom="40px" minWidth="500px" marginRight="10px" height="calc(50% - 40px)">
                            <Typography component="div" sx={{ marginBottom: "10px" }}>Level 1 Categories</Typography>
                            <AgGridReact<EstimateCategory>
                                ref={category1GridRef}
                                editType={'fullRow'}
                                rowData={category1s}
                                suppressRowClickSelection={true}
                                onGridReady={() => onGridReady(CategoryLevel.category1)}
                                columnDefs={category1Defs}
                                onCellClicked={onCategoryCellClicked}
                                onCellKeyDown={onCategoryCellKeyDown}
                                defaultColDef={defaultColDef}
                                pinnedTopRowData={createCategory1PinnedData}
                                onRowEditingStarted={onRowEditingStarted}
                                onRowEditingStopped={onRowEditingStopped}
                                getRowId={getRowId}
                            />
                        </Box>
                        <Box className="ag-theme-alpine" flex="1" marginBottom="40px" minWidth="500px" height="calc(50% - 40px)">
                            <Typography component="div" sx={{ marginBottom: "10px" }}>Level 2 Categories</Typography>
                            <AgGridReact<EstimateCategory>
                                ref={category2GridRef}
                                editType={'fullRow'}
                                rowData={category2s}
                                suppressRowClickSelection={true}
                                onCellClicked={onCategoryCellClicked}
                                onCellKeyDown={onCategoryCellKeyDown}
                                onGridReady={() => onGridReady(CategoryLevel.category2)}
                                columnDefs={category2Defs}
                                defaultColDef={defaultColDef}
                                pinnedTopRowData={createCategory2PinnedData}
                                onRowEditingStarted={onRowEditingStarted}
                                onRowEditingStopped={onRowEditingStopped}
                                getRowId={getRowId}
                            />
                        </Box>
                        <Box className="ag-theme-alpine ag-theme-bidbow" flex="1" marginBottom="40px" minWidth="500px" marginRight="10px" height="calc(50% - 40px)">
                            <Typography component="div" sx={{ marginBottom: "10px" }}>Level 3 Categories</Typography>
                            <AgGridReact<EstimateCategory>
                                ref={category3GridRef}
                                editType={'fullRow'}
                                rowData={category3s}
                                suppressRowClickSelection={true}
                                columnDefs={category3Defs}
                                onCellClicked={onCategoryCellClicked}
                                onCellKeyDown={onCategoryCellKeyDown}
                                defaultColDef={defaultColDef}
                                onGridReady={() => onGridReady(CategoryLevel.category3)}
                                pinnedTopRowData={createCategory3PinnedData}
                                onRowEditingStarted={onRowEditingStarted}
                                onRowEditingStopped={onRowEditingStopped}
                                getRowId={getRowId}
                            />
                        </Box>
                        <Box className="ag-theme-alpine ag-theme-bidbow" flex="1" marginBottom="40px" minWidth="500px" height="calc(50% - 40px)">
                            <Typography component="div" sx={{ marginBottom: "10px" }}>Level 4 Categories</Typography>
                            <AgGridReact<EstimateCategory>
                                ref={category4GridRef}
                                editType={'fullRow'}
                                rowData={category4s}
                                suppressRowClickSelection={true}
                                columnDefs={category4Defs}
                                onCellClicked={onCategoryCellClicked}
                                onCellKeyDown={onCategoryCellKeyDown}
                                onGridReady={() => onGridReady(CategoryLevel.category4)}
                                defaultColDef={defaultColDef}
                                pinnedTopRowData={createCategory4PinnedData}
                                onRowEditingStarted={onRowEditingStarted}
                                onRowEditingStopped={onRowEditingStopped}
                                getRowId={getRowId}
                            />
                        </Box>
                    </Box>
                </Box>
        }
    }

    return <>
        {renderSwitch()}
    </>
}