import { Box, useTheme } from "@mui/material";
import { rounder } from "Helpers/rounder";
import { BaseReportResource, CatSubCatAggregate, CategoryAmountTotal, SubCategoryAmountTotal } from "Models/report";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ColDef, SideBarDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { groupBy } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { tokens } from "theme";

export interface ProjectResourceLevel3Props {
    baseReportResources: Array<BaseReportResource>;
    estimateId: string | undefined;
}

export default function ProjectResourceLevel3(props: ProjectResourceLevel3Props) {
    const [rowData, setRowData] = useState<Array<BaseReportResource>>([]);
    const groupDefaultExpanded = -1;
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ width: '100%' }), []);
    const [totalAmount] = useState(props.baseReportResources.reduce((accumulator, currentValue) => (accumulator + (currentValue?.amount ?? 0)), 0));
    const [totalEstimatedAmount] = useState(props.baseReportResources.reduce((accumulator, currentValue) => (accumulator + (currentValue?.estimatedAmount ?? 0)), 0));

    useEffect(() => {
        if (props.baseReportResources && props.baseReportResources.length > 0) {
            setRowData([...props.baseReportResources]);
        }
    }, [props.baseReportResources])

    const getTotals = useCallback(() => {
        if (props.baseReportResources) {
            const totalAmountPerCategories = new Array<CategoryAmountTotal>();
            const totalAmountPerSubCategories = new Array<SubCategoryAmountTotal>();
            const totalEstAmountPerCategories = new Array<CategoryAmountTotal>();
            const totalEstAmountPerSubCategories = new Array<SubCategoryAmountTotal>();
            const categoryGrouped = groupBy(props.baseReportResources, (res) => {
                return res.categoryDescription;
            });

            Object.keys(categoryGrouped).forEach((value) => {
                let total = 0;
                let estTotal = 0;
                categoryGrouped[value].forEach((val) => {
                    total += val?.amount ?? 0;
                    estTotal += val?.estimatedAmount ?? 0;
                    const subCatTotalAmount = totalAmountPerSubCategories.find(cat => cat.subCategory === val.subCategoryDescription);
                    if (subCatTotalAmount) {
                        subCatTotalAmount.total += val?.amount ?? 0;
                    } else {
                        totalAmountPerSubCategories.push({ subCategory: val.subCategoryDescription, total: val?.amount ?? 0 });
                    }
                    const subCatTotalEstAmount = totalEstAmountPerSubCategories.find(cat => cat.subCategory === val.subCategoryDescription);
                    if (subCatTotalEstAmount) {
                        subCatTotalEstAmount.total += val?.estimatedAmount ?? 0;
                    } else {
                        totalEstAmountPerSubCategories.push({ subCategory: val.subCategoryDescription, total: val?.estimatedAmount ?? 0 });
                    }
                });
                totalAmountPerCategories.push({ category: value, total: total });
                totalEstAmountPerCategories.push({ category: value, total: estTotal });
            });
            return { totalAmountPerCategories, totalAmountPerSubCategories, totalEstAmountPerCategories, totalEstAmountPerSubCategories };
        }
    }, [props.baseReportResources])

    const [totalAmountPerCategory] = useState<CatSubCatAggregate | undefined>(getTotals());
    const [dataTypeDefinitions] = useState<any>({
        amountPercentage: {
            extendsDataType: 'number',
            baseDataType: 'number',
            valueFormatter: (params: any) => {
                if (params.node.group && totalAmountPerCategory) {
                    if (params.node?.level === 0) {
                        let category = totalAmountPerCategory?.totalAmountPerCategories?.find((cat) => (cat.category === params.node.key));
                        if (category) {
                            return `${((category.total / totalAmount) * 100).toFixed(2)}%`
                        }
                    } else if (params?.node.level === 1 && params?.node?.key && params.node.parent.key) {
                        const subCategory = totalAmountPerCategory?.totalAmountPerSubCategories?.find((cat) => (cat.subCategory === params.node.key));
                        if (subCategory) {
                            return `${((subCategory.total / totalAmount) * 100).toFixed(2)}%`
                        }
                    }
                }
                if (params.node.level === -1) {
                    return '';
                }
                return currencyFormatter(params.value, '');
            }
        },
        estAmountPercentage: {
            extendsDataType: 'number',
            baseDataType: 'number',
            valueFormatter: (params: any) => {
                if (params.node.group && totalAmountPerCategory) {
                    if (params.node?.level === 0) {
                        let category = totalAmountPerCategory?.totalEstAmountPerCategories?.find((cat) => (cat.category === params.node.key));
                        if (category) {
                            return `${((category.total / totalEstimatedAmount) * 100).toFixed(2)}%`
                        }
                    } else if (params?.node.level === 1 && params?.node?.key && params.node.parent.key) {
                        const subCategory = totalAmountPerCategory?.totalEstAmountPerSubCategories?.find((cat) => (cat.subCategory === params.node.key));
                        if (subCategory) {
                            return `${((subCategory.total / totalEstimatedAmount) * 100).toFixed(2)}%`
                        }
                    }
                }
                if (params.node.level === -1) {
                    return '';
                }
                return currencyFormatter(params.value, '');
            }
        },
        amount: {
            extendsDataType: 'number',
            baseDataType: 'number',
            valueFormatter: (params: any) => {
                return rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2);
            }
        },
    })

    const onGridReady = useCallback(() => {
        setRowData([...props.baseReportResources]);
    }, [props.baseReportResources])

    const currencyFormatter = useCallback((currency: number | undefined, sign: string) => {
        if (currency) {
            var sansDec = Number.parseFloat(currency.toString()).toFixed(3);
            var formatted = sansDec?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            return sign + `${formatted}`;
        }
        return '';

    }, [])

    const getRowStyle = (params: any) => {
        if (params.node.group) {
            return { fontWeight: 'bold' };
        }
    };

    const [columnDefs] = useState<Array<ColDef<BaseReportResource>>>([
        {
            field: 'id',
            hide: true,
            suppressColumnsToolPanel: true
        },
        {
            field: 'displayId',
            headerName: 'ID',
            hide: false,
            cellRenderer: 'agGroupCellRenderer',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            width: 250,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            showRowGroup: true,
            colSpan: (params: any) => {
                return 1;
            }
        },
        {
            field: 'description',
            flex: 1,
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'unitDescription',
            headerName: 'Unit',
            width: 100,
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'rate',
            width: 100,
            cellDataType: 'amount',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'quantity',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            aggFunc: 'sum',
            width: 175,
            cellDataType: "amountPercentage",
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'estimatedQuantity',
            width: 175,
            aggFunc: 'sum',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellDataType: "estAmountPercentage",
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'amount',
            width: 175,
            aggFunc: 'sum',
            enableValue: true,
            cellDataType: 'amount',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'estimatedAmount',
            width: 175,
            aggFunc: 'sum',
            cellDataType: 'amount',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'categoryDescription',
            editable: true,
            rowGroup: true,
            hide: true
        },
        {
            field: 'subCategoryDescription',
            editable: true,
            rowGroup: true,
            hide: true
        },
    ]);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    const columnToolPanelParams = useMemo<SideBarDef>(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
        };
    }, []);

    return <>
        <Box style={gridStyle} className="ag-theme-alpine ag-theme-bidbow">
            <AgGridReact<BaseReportResource>
                dataTypeDefinitions={dataTypeDefinitions}
                rowData={rowData}
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={columnToolPanelParams}
                groupDisplayType={'custom'}
                suppressAggFuncInHeader={true}
                groupDefaultExpanded={groupDefaultExpanded}
                suppressClickEdit={true}
                getRowStyle={getRowStyle}
                groupIncludeTotalFooter={true}
            />
        </Box>
    </>;
}