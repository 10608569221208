import {
    forwardRef,
    memo,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Box, Checkbox, FormControl, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { tokens } from 'theme';
import { VendorView } from 'Models/vendor';
export interface VendorTypeEditCellRendererParams extends ICellEditorParams<VendorView, string> {
    estimateId: string;
}

export default memo(
    forwardRef((props: VendorTypeEditCellRendererParams, ref) => {
        const [colors, setColors] = useState<any>();
        const theme = useTheme();
        useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
        const [selectedType, setSelectedType] = useState<string[]>([]);
        const refInput = useRef<any>(null);
        const [open, setOpen] = useState(false);

        const onChange = (event: SelectChangeEvent<typeof selectedType>) => {
            const {
                target: { value },
            } = event;
            setSelectedType(
                // On autofill we get a stringified value.
                typeof value === 'string' ? value.split(',') : value,
            );
        };

        /* Component Editor Lifecycle methods */
        useImperativeHandle(ref, () => {
            return {
                // the final value to send to the grid, on completion of editing
                getValue() {
                    return selectedType.join(',')
                },
                setFocusOnAdd() {
                    if (refInput.current) {
                        refInput.current.focus();
                        handleOpen();
                    }
                }
            };
        });

        const handleClose = () => {
            setOpen(false);
          };
        
          const handleOpen = () => {
            setOpen(true);
          };

        useEffect(() => {
            if (props.value) {
                setSelectedType(props.value.split(","));
            }
        }, [props.value])

        return (
            <>{colors && <Box sx={{ width: "100%", height: "100% !important" }} className='ag-custom-component-popup'>
                <FormControl variant="standard" sx={{ height: 'inherit', width: "100%" }} className='ag-custom-component-popup'>
                    <Select
                        labelId="type-multiple-checkbox-label"
                        id="type-multiple-checkbox"
                        className='ag-custom-component-popup'
                        multiple
                        ref={refInput}
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        value={selectedType}
                        onChange={onChange}
                        input={<OutlinedInput sx={{ height: "inherit", backgroundColor: "white" }} />}
                        renderValue={(selected) => selected.join(', ')}
                    >
                        {["Subcontractor", "Supplier"].map((name) => (
                            <MenuItem key={name} value={name} className='ag-custom-component-popup'>
                                <Checkbox checked={selectedType.indexOf(name) > -1} />
                                <ListItemText primary={name} sx={{ marginLeft: "2px" }} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>}
            </>
        );
    })
);
