import { Box, IconButton, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from "react";
import { CompanyCurrencyView } from "Models/company-currency";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
export interface ICancelCellRendererProps extends ICellRendererParams<CompanyCurrencyView> {
    delete: (node: IRowNode<CompanyCurrencyView>) => Promise<void>;
    save: (node: IRowNode<CompanyCurrencyView>) => Promise<void>;
    cancel: (node: IRowNode<CompanyCurrencyView>) => void;
    disabled: () => boolean;
}

export default function SaveCancelCurrencyCellRenderer(props: ICancelCellRendererProps) {
    const { node } = props;
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    let editingCells = props.api.getEditingCells();
    let isCurrentRowEditing = editingCells.some((cell) => {
        return cell.rowIndex === node.rowIndex;
    });

    const deleteRec = async () => {
        if (isCurrentRowEditing || props.node.rowPinned) {
            props.cancel(node);
        } else {
            setIsDeleting(true);
            await props.delete(node);
            setIsDeleting(false);
        }
    }

    const saveRec = async () => {
        try {
            setIsSaving(true);
            await props.save(node);
            setIsSaving(false);
        } catch (error) {
            setIsSaving(false);
        }
    }

    return (<>
        <Box display="flex" alignItems="center" justifyContent="center">
            {isCurrentRowEditing && !isSaving &&
                <IconButton aria-label="save" color="primary" onClick={saveRec}>
                    <SaveIcon />
                </IconButton>
            }
            {isSaving &&
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress size="1rem" />
                </Box>
            }
            {!isDeleting &&
                <Tooltip title={!node.data?.canDelete ? 'Deleting a currency associated with resources is not permitted.' : ''}>
                    <span>
                        <IconButton aria-label="delete" onClick={deleteRec} disabled={!node.data?.canDelete || props.disabled()}>
                            {isCurrentRowEditing && <CloseIcon />}
                            {!isCurrentRowEditing && <DeleteOutlineIcon />}
                        </IconButton>
                    </span>
                </Tooltip>
            }
            {isDeleting &&
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress size="1rem" />
                </Box>
            }
        </Box>
    </>
    );
};
