import { Settings } from 'Models/settings';
import { api } from './api'

export const settingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getSettings: build.query<Settings, {organizationId: string, companyId: string}>({
      query: ({organizationId, companyId}) => ({ url: `organization/${organizationId}/company/${companyId}/settings` }),
      providesTags: ['Settings'],
    }),
    updateMasterSettings: build.mutation<Settings, Partial<{ body: {masterIdPrefix: string}, orgId: string, companyId: string }>>({
      query({ body, orgId, companyId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/settings/master`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Settings'],
    }),
  })
});

export const {
  useUpdateMasterSettingsMutation,
  useGetSettingsQuery
} = settingsApi
