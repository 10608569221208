import {
    forwardRef,
    memo,
    useCallback,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Box, FormControl, InputAdornment, List, ListItem, OutlinedInput, useTheme } from '@mui/material';
import { HtmlTooltip } from 'Components/HtmlToolltip';
import { tokens } from 'theme';
import WarningIcon from '@mui/icons-material/Warning';
import { VendorView } from 'Models/vendor';

export interface VendorEditCellRendererProps extends ICellEditorParams<VendorView>{
    placeholder: string;
}

export default memo(
    forwardRef((props: VendorEditCellRendererProps, ref) => {
        const createInitialState = () => {
            return {
                value: props.value
            };
        };
        const theme = useTheme();
        const [colors] = useState<any>(tokens(theme.palette.mode));
        const initialState = createInitialState();
        const [value, setValue] = useState(initialState.value);
        const refInput = useRef<HTMLInputElement>(null);
        const [error, setError] = useState<string | undefined>(undefined);

        const onChange = useCallback((value: string) => {
            setValue(value);
        }, [])

        /* Component Editor Lifecycle methods */
        useImperativeHandle(ref, () => {
            return {
                // the final value to send to the grid, on completion of editing
                getValue() {
                    return value === '' || value == null ? null : value;
                },
                focusIn() {
                    refInput.current!.focus();
                },
                setFocusOnAdd() {
                    if (refInput.current) {
                        refInput.current.focus();
                        refInput.current.select();
                    }
                },
                setErrors(message: string) {
                    if (message) {
                        setError(message);
                    }
                },
            };
        });

        return (
            <Box sx={{ width: "100%", height: "100% !important" }}>
                <FormControl sx={{ height: 'inherit', width: "100%" }}>
                    <OutlinedInput
                        sx={{ height: "inherit", backgroundColor: "white" }}
                        className="ag-input-field-input ag-text-field-input"
                        placeholder={props.placeholder}
                        onChange={(event) => onChange(event.target.value)}
                        value={value}
                        inputRef={refInput}
                        endAdornment={error &&
                            <InputAdornment position="end">
                                <HtmlTooltip color={colors?.redAccent[500]}
                                    title={
                                        <List sx={{ listStyleType: 'disc', pl: 1 }}>
                                            <ListItem
                                                sx={{
                                                    padding: 0,
                                                    textAlign: "center",
                                                    listStyleType: "disc",
                                                    display: "list-item",
                                                }}
                                            >{error}</ListItem>
                                        </List>
                                    }>
                                    <WarningIcon sx={{ color: `${colors?.redAccent[500]}`, cursor: "pointer" }} />
                                </HtmlTooltip>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </Box>
        );
    })
);
