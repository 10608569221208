import { Box, Card, CardActions, CardContent, Link, Typography } from "@mui/material";
import fullLogo from '../Images/logo.png';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback } from "react";

export default function UserLockedRedirect() {
    const { logout } = useAuth0();

    const userLogout = useCallback(() => {
        logout({ logoutParams: { returnTo: window.location.origin } });
    }, [logout])

    return <Box display="flex" alignItems="center" justifyContent="center" alignContent="center" width="100%" height="100%" justifyItems="center" sx={{ backgroundColor: "gray" }}>
        <Card sx={{ maxWidth: 500, height: 400, padding: "50px 100px 100px 100px" }}>
            <CardContent>
                <Box width="100%" display="flex" justifyContent="center" marginBottom="30px">
                    <img src={fullLogo} alt="Bidbow" />
                </Box>
                <Box width="100%" display="flex" justifyContent="center" marginBottom="20px">
                    <ErrorOutlineOutlinedIcon sx={{ fontSize: "150px" }} />
                </Box>
                <Typography gutterBottom variant="h4" component="div" sx={{ display: "flex", alignContent: "center", justifyContent: "center", textAlign: "Center" }}>
                    Your subscription is locked
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: "center" }}>
                    You do not have an active subscription seat to access BidBow. Please contact the account owner at your company to request a seat, or reach out to support@bidbow.com for assistance.
                </Typography>
                <CardActions sx={{ display: "flex", justifyContent: "center", flexDirection: "column", marginTop: "10px" }}>
                    <Link variant="body2" component="button" onClick={userLogout}>Logout</Link>
                </CardActions>
            </CardContent>
        </Card>
    </Box>
}