import { Autocomplete, Backdrop, Box, Button, CircularProgress, TextField, Typography, useTheme } from "@mui/material";
import { CompanyCurrency } from "Models/company-currency";
import { PackageItemDetails, PackageItemDetailsRateCalculationView, PackageItemDetailsView } from "Models/package";
import { SubcontractorActivityResourceView, SubcontractorPackageVendor } from "Models/subcontractor";
import { Vendor } from "Models/vendor";
import { useGetCompanyCurrenciesQuery } from "State/Services/company-currency";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useGetVendorsQuery } from "State/Services/vendor";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { tokens } from "theme";
import SubcontractorResourceActivity from "./SubcontractorResourceActivity";
import { useDeleteSubcontractorResourceMutation, useDeleteSubcontractorActivityMutation, useUpdateSubcontractorItemMutation } from "State/Services/subcontractor";
import * as yup from 'yup';
export interface PackageItemCalculationView extends PackageItemDetailsView {
    currency: CompanyCurrency;
    currencyExchangeRate: number;
    subcontractorFactor: number;
    adjustmentOverride: number;
    adjustment: number;
    totalRate: number;

}
export interface SubcontractorRateProps {
    estimateId: string;
    packageVendor: SubcontractorPackageVendor;
    close: () => void;
    item: PackageItemDetails;
    disabled: boolean;
}

const subcontractorValidationSchema = yup.object<PackageItemCalculationView>({
        factor: yup
        .number()
        .nullable()
        .optional()
        .test(
            'is-greater-than-zero',
            'Factor must be greater than zero',
            value => value === null || value === undefined || value > 0
        )
});

export default function SubcontractorRate(props: SubcontractorRateProps) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const { data: user } = useGetUserDetailsQuery();
    const { data: existingVendors } = useGetVendorsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId })
    const { data: companyCurrencies } = useGetCompanyCurrenciesQuery({ companyId: (user && user.companyId) ? user.companyId : '', orgId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [isSaving] = useState(false);
    const [vendor, setVendor] = useState<Vendor>();
    const [companyCurrenciesExtended, setCompanyCurrenciesExtended] = useState<Array<CompanyCurrency>>([]);
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [exchangeRate, setExchangeRate] = useState<number>(1);
    const [subcontractorFactor, setSubcontractorFactor] = useState<number>();
    const [deleteSubcontractorResource] = useDeleteSubcontractorResourceMutation();
    const [deleteSubcontractorActivity] = useDeleteSubcontractorActivityMutation();
    const [updateSubcontractorItem] = useUpdateSubcontractorItemMutation();

    useEffect(() => {
        if (existingVendors && existingVendors.length > 0 && props.packageVendor) {
            const vendor = existingVendors?.find((ven: Vendor) => (ven.id === props.packageVendor.vendorId));
            setVendor(vendor);
            setSubcontractorFactor(props.packageVendor?.factor);
        }
    }, [existingVendors, props])

    const initializeValues = useCallback(() => {
        const adjustmentByValue = parseFloat((props.item.adjustment?.toString() ?? "0"));
        const adjustmentByElements = parseFloat((props.item.adjustmentOverride?.toString() ?? "0"));
        const rate = (parseFloat(props.item.rate?.toString() ?? "0")) * (exchangeRate ?? 1) * parseFloat((props.item.factor?.toString() ?? "1")) * parseFloat((props.packageVendor?.factor?.toString() ?? "1")) + (adjustmentByValue + adjustmentByElements);
        return (props.packageVendor) ? {
            adjustment: adjustmentByValue,
            adjustmentOverride: adjustmentByElements,
            factor: props.item.factor,
            isChecked: props.item.isChecked,
            itemDisplayId: props.item.itemDisplayId,
            itemId: props.item.itemId,
            itemName: props.item.itemName,
            quantity: props.item.quantity,
            rate: props.item.rate,
            companyCurrency: undefined,
            remarks: props.item.remarks,
            vendorId: props.packageVendor.vendorId,
            vendorName: props.item.vendorName,
            totalRate: rate
        } : {
            adjustment: undefined,
            adjustmentOverride: undefined,
            factor: undefined,
            isChecked: false,
            itemDisplayId: undefined,
            itemId: undefined,
            itemName: undefined,
            quantity: undefined,
            rate: undefined,
            remarks: undefined,
            companyCurrency: undefined,
            vendorId: undefined,
            vendorName: undefined,
            totalRate: undefined
        };
    }, [exchangeRate, props.item.adjustment, props.item.adjustmentOverride, props.item.factor, props.item.isChecked, props.item.itemDisplayId, props.item.itemId, props.item.itemName, props.item.quantity, props.item.rate, props.item.remarks, props.item.vendorName, props.packageVendor])

    const { setFieldValue, resetForm, values, handleSubmit, handleBlur, touched, errors, handleChange } = useFormik<PackageItemDetailsRateCalculationView>({
        enableReinitialize: false,
        validateOnMount: true,
        initialValues: initializeValues(),
        validationSchema: subcontractorValidationSchema,
        onSubmit: async (subItem) => {
            if (user) {
                setOpenBackdrop(true);
                await updateSubcontractorItem({
                    companyId: user?.companyId,
                    estimateId: props.estimateId,
                    itemId: props.item.itemId,
                    orgId: user.organizationId,
                    packageId: props.packageVendor.subcontractorPackageId,
                    subcontractorPackageVendorId: props.packageVendor.id,
                    body: {
                        adjustment: (subItem.adjustment?.toString()) ? parseFloat(subItem.adjustment?.toString()) : undefined,
                        adjustmentOverride: (subItem.adjustmentOverride?.toString()) ? parseFloat(subItem.adjustmentOverride?.toString()) : undefined,
                        companyCurrencyId: subItem.companyCurrency?.id,
                        factor: (subItem.factor?.toString()) ? parseFloat(subItem.factor.toString()) : undefined,
                        isChecked: subItem.isChecked,
                        itemId: subItem.itemId,
                        rate: (subItem.rate?.toString()) ? parseFloat(subItem.rate?.toString()) : undefined,
                        remarks: subItem.remarks,
                        totalRate: subItem.totalRate,
                        vendorId: subItem.vendorId
                    }
                });
                setOpenBackdrop(false);
                props.close();
            }
        },
    });

    useEffect(() => {
        if (companyCurrencies) {
            setCompanyCurrenciesExtended([...companyCurrencies])
            if (props.item.companyCurrencyId) {
                const currency = companyCurrencies.find((curr) => (curr.id === props.item.companyCurrencyId));
                if (currency) {
                    setFieldValue('companyCurrency', currency);
                }
            } else {
                if (estimate?.CompanyCurrency?.Currency) {
                    setFieldValue('companyCurrency', companyCurrencies.find((curr) => (curr.id === estimate?.CompanyCurrency?.id)));
                }
            }
            
        }
    }, [companyCurrencies, estimate?.CompanyCurrency, props.item.companyCurrencyId, setFieldValue])

    const calculateRate = useCallback(() => {
        if (values.rate) {
            const adjustmentByElements = parseFloat((values.adjustmentOverride?.toString() ?? "0"));
            const adjustmentByValue = parseFloat((values.adjustment?.toString() ?? "0"));
            const rate = (parseFloat(values.rate.toString())) * (exchangeRate ?? 1) * parseFloat((values.factor?.toString() ?? "1")) * parseFloat((props.packageVendor?.factor?.toString() ?? "1")) + (adjustmentByValue + adjustmentByElements);
            setFieldValue('totalRate', rate);
        } else {
            setFieldValue('totalRate', 0);
        }
    }, [exchangeRate, values.adjustment, values.adjustmentOverride, values.factor, values.rate, props.packageVendor?.factor, setFieldValue])

    useEffect(() => {
        if (parseFloat(values.companyCurrency?.primaryToSecondaryRate?.toString() ?? "1") !== 0) {
            let numerator = estimate?.CompanyCurrency?.primaryToSecondaryRate ?? 1;
            let denominator = values.companyCurrency?.primaryToSecondaryRate ?? 1;
            setExchangeRate(parseFloat((numerator / denominator).toFixed(2)));
        }
    }, [estimate?.CompanyCurrency?.primaryToSecondaryRate, values.companyCurrency?.primaryToSecondaryRate])

    const currencyChanged = useCallback((event: any, value: CompanyCurrency | null) => {
        if (value) {
            let numerator = estimate?.CompanyCurrency?.primaryToSecondaryRate ?? 1;
            let denominator = value.primaryToSecondaryRate ?? 1;
            setExchangeRate(parseFloat((numerator / denominator).toFixed(2)));
            setFieldValue('companyCurrency', value);
        }
        calculateRate();
    }, [calculateRate, estimate?.CompanyCurrency?.primaryToSecondaryRate, setFieldValue])

    const setSubcontractorActivitiesResources = useCallback((subcontractorItemActivitiesResources: Array<SubcontractorActivityResourceView>) => {
        let calculatedAdjustment = 0;
        subcontractorItemActivitiesResources.forEach((res) => {
            if (res.amount) {
                calculatedAdjustment += res.amount;
            }
        });
        setFieldValue('adjustmentOverride', calculatedAdjustment);
        const rate = (parseFloat(values.rate?.toString() ?? "0")) * (exchangeRate ?? 1) * parseFloat((values.factor?.toString() ?? "1")) * parseFloat((props.packageVendor?.factor?.toString() ?? "1")) + calculatedAdjustment + parseFloat(((values.adjustment && values.adjustment?.toString() !== '') ? values.adjustment?.toString() : "0"));
        setFieldValue('totalRate', rate);
    }, [exchangeRate, values.adjustment, values.factor, values.rate, props.packageVendor?.factor, setFieldValue])

    const deleteResource = useCallback(async (resourceId: string) => {
        if (user) {
            await deleteSubcontractorResource({
                orgId: user.organizationId,
                companyId: user.companyId,
                estimateId: props.estimateId,
                itemId: props.item.itemId,
                resourceId: resourceId,
                packageId: props.packageVendor.subcontractorPackageId,
                subcontractorPackageVendorId: props.packageVendor.id
            });
        }
    }, [deleteSubcontractorResource, props.estimateId, props.item.itemId, props.packageVendor.id, props.packageVendor.subcontractorPackageId, user])

    const deleteActivity = useCallback(async (activityId: string) => {
        if (user) {
            await deleteSubcontractorActivity({
                orgId: user.organizationId,
                companyId: user.companyId,
                itemId: props.item.itemId,
                estimateId: props.estimateId,
                activityId: activityId,
                packageId: props.packageVendor.subcontractorPackageId,
                subcontractorPackageVendorId: props.packageVendor.id
            });
        }
    }, [deleteSubcontractorActivity, props.estimateId, props.item.itemId, props.packageVendor.id, props.packageVendor.subcontractorPackageId, user])

    const handleQuotedRateChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue('rate', event.target.value);
        if (event.target.value) {
            const adjustmentByElements = parseFloat((values.adjustmentOverride?.toString() ?? "0"));
            const adjustmentByValue = parseFloat((event.target.value ?? "0"));
            const rate = (parseFloat(event.target.value)) * (exchangeRate ?? 1) * parseFloat((values?.factor?.toString() ?? "1")) * parseFloat((props.packageVendor?.factor?.toString() ?? "1")) + (adjustmentByValue + adjustmentByElements);
            setFieldValue('totalRate', rate);
        } else {
            setFieldValue('totalRate', 0);
        }
    }, [exchangeRate, values.adjustmentOverride, values?.factor, props.packageVendor?.factor, setFieldValue])

    const handleFactorChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue('factor', event.target.value);
        const adjustmentByElements = parseFloat((values.adjustmentOverride?.toString() ?? "0"));
        const adjustmentByValue = parseFloat((event.target.value ?? "0"));
        const rate = (parseFloat(values.rate?.toString() ?? "0")) * (exchangeRate ?? 1) * parseFloat((event.target.value ?? "1")) * parseFloat((props.packageVendor?.factor?.toString() ?? "1")) + (adjustmentByValue + adjustmentByElements);
        setFieldValue('totalRate', rate);
    }, [exchangeRate, values.adjustmentOverride, values.rate, props.packageVendor?.factor, setFieldValue])

    const handleAdjustmentChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue('adjustment', event.target.value);
        const adjustmentByElements = parseFloat((values.adjustmentOverride?.toString() ?? "0"));
        const adjustmentByValue = parseFloat((event.target.value !== '' ? event.target.value : "0"));
        const rate = (parseFloat(values.rate?.toString() ?? "0")) * (exchangeRate ?? 1) * parseFloat((values.factor?.toString() ?? "1")) * parseFloat((props.packageVendor?.factor?.toString() ?? "1")) + (adjustmentByValue + adjustmentByElements);
        setFieldValue('totalRate', rate);
    }, [exchangeRate, values.adjustmentOverride, values.factor, values.rate, props.packageVendor?.factor, setFieldValue])

    const currencyOptionEqual = useCallback((option: CompanyCurrency, value: CompanyCurrency) => {
        return option.id === value.id;
    }, [])

    useEffect(() => {
        return () => {
            resetForm();
            setVendor(undefined);
            setFieldValue('companyCurrency', undefined);
            setSubcontractorFactor(1);
        }
    }, [resetForm, setFieldValue])

    return <>{colors && vendor && <form onSubmit={handleSubmit} noValidate style={{ height: "100%" }}>
        <Box width="45vw" height="100%">
            <Box display="flex" justifyContent="space-between" paddingTop="16px" paddingBottom="16px" paddingLeft="24px" paddingRight="24px" borderBottom={`1px solid ${colors.gray[800]}`}>
                <Box>
                    <Typography component="div" sx={{ display: "flex" }}>
                        <Box sx={{ fontWeight: 600, fontSize: "16px", color: `${colors.gray[100]} !important` }}>{vendor.name}</Box>
                    </Typography>
                </Box>
                <Box>
                    <Button disabled={props.disabled} type="submit" color="primary" variant="contained" >{isSaving ? <CircularProgress sx={{ color: "white" }} size="1rem" /> : 'Save'}</Button>
                </Box>
            </Box>
            <Box display="flex" gap="10px" padding="16px" flexDirection="column" height="calc(100% - 80px)">
                <Box display="flex" flexDirection="column" flex="0.1" marginRight="10px" gap="16px">
                    <Box fontWeight="bold">Item ID: {props.item.itemDisplayId}</Box>
                    <Box>Item Description: {props.item.itemName}</Box>
                </Box>
                <Box display="flex" flexDirection="column" flex="0.1" marginRight="10px" gap="16px" justifyContent="center">
                    <Box display="flex" alignItems="center" flexDirection="row" gap="8px">
                        <Box display="flex" alignItems="center" flex="0.4">
                            <label color={colors.gray[100]} style={{ width: "80px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Quoted Rate:</label>
                            <TextField
                                id="rate"
                                name="rate"
                                size="small"
                                disabled={props.disabled}
                                value={values.rate}
                                autoComplete="off"
                                placeholder="Quoted rate"
                                inputProps={{ type: "number" }}
                                onChange={handleQuotedRateChange}
                                onBlur={handleBlur}
                            />
                        </Box>
                        <Box display="flex" alignItems="center" gap="10px" flex="0.6" justifyItems="center" alignContent="center">
                            <Box display="flex" alignItems="center" flex="0.5">
                                <label color={colors.gray[100]} style={{ width: "80px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Currency:</label>
                                {companyCurrenciesExtended && companyCurrenciesExtended.length > 0 && <Autocomplete
                                    fullWidth
                                    size="small"
                                    disabled={props.disabled}
                                    className="ag-input-field-input ag-text-field-input"
                                    sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                                    onChange={currencyChanged}
                                    options={companyCurrenciesExtended}
                                    getOptionLabel={(option) => option.alphabeticalCode ?? ''}
                                    isOptionEqualToValue={currencyOptionEqual}
                                    value={values.companyCurrency ?? null}
                                    renderInput={(params) => <TextField name="Seccurrency" sx={{ height: "100%" }} placeholder='Select Currency' {...params} />}
                                    renderOption={(props, option, { inputValue }) => {
                                        const matches = match(option.alphabeticalCode ?? '', inputValue, {
                                            insideWords: true,
                                        });
                                        const parts = parse(option.alphabeticalCode ?? '', matches);

                                        return (
                                            <li {...props}>
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span
                                                            key={index}
                                                            style={{
                                                                fontWeight: part.highlight ? 700 : 400,
                                                            }}
                                                        >
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </li>
                                        );
                                    }}
                                />
                                }
                            </Box>
                            <Box display="flex" flex="0.2" alignItems="center">
                                <label color={colors.gray[100]} style={{ width: "150px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Exchange Rate:</label>
                                <TextField
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    disabled={true}
                                    value={exchangeRate} />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="8px">
                        <Box flex="0.4" display="flex" alignItems="center">
                            <label color={colors.gray[100]} style={{ width: "80px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Factor:</label>
                            <TextField
                                id="factor"
                                name="factor"
                                size="small"
                                value={values.factor}
                                autoComplete="off"
                                disabled={props.disabled}
                                placeholder="Factor"
                                inputProps={{ type: "number" }}
                                onChange={handleFactorChange}
                                onBlur={handleBlur}
                                error={touched.factor && Boolean(errors.factor)}
                                helperText={touched.factor && errors.factor}
                            />
                        </Box>
                        <Box flex="0.6" display="flex" alignItems="center">
                            <label color={colors.gray[100]} style={{ width: "120px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Subcontractor Factor:</label>
                            <TextField
                                id="subcontractorFactor"
                                name="subcontractorFactor"
                                size="small"
                                value={subcontractorFactor}
                                autoComplete="off"
                                disabled={true}
                                inputProps={{ type: "number" }}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="8px">
                        <Box flex="0.4" display="flex" alignItems="center">
                            <label color={colors.gray[100]} style={{ width: "80px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Rate:</label>
                            <TextField
                                id="totalRate"
                                name="totalRate"
                                size="small"
                                disabled={true}
                                value={values.totalRate}
                                autoComplete="off"
                                inputProps={{ type: "number" }}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="8px">
                        <Box flex="1" display="flex" alignItems="center">
                            <label color={colors.gray[100]} style={{ width: "90px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Remarks:</label>
                            <TextField
                                id="remarks"
                                name="remarks"
                                fullWidth
                                size="small"
                                disabled={props.disabled}
                                value={values.remarks}
                                autoComplete="off"
                                multiline
                                rows={2}
                                placeholder="Remarks"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={touched.remarks && Boolean(errors.remarks)}
                                helperText={touched.remarks && errors.remarks}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box display="flex">
                    <label color={colors.gray[100]} style={{ width: "90px", fontWeight: "bold" }}>Adjustments:</label>
                </Box>
                <Box display="flex" alignItems="center" gap="8px">
                    <Box flex="1" display="flex" flexDirection="column" gap="10px">
                        <Box display="flex" alignItems="center" gap="10px">
                            <label>By value:</label>
                            <TextField
                                sx={{ width: "150px" }}
                                size="small"
                                disabled={props.disabled}
                                value={values.adjustment}
                                autoComplete="off"
                                placeholder="Adjustment"
                                onChange={handleAdjustmentChange}
                                onBlur={handleBlur}
                                error={touched.adjustment && Boolean(errors.adjustment)}
                                helperText={touched.adjustment && errors.adjustment}
                            />
                        </Box>
                        <Box display="flex" alignItems="center" gap="10px">
                            <label>By adding elements:</label>
                            <TextField
                                name="adjustmentOverride"
                                id="adjustmentOverride"
                                sx={{ width: "150px" }}
                                size="small"
                                onBlur={handleBlur}
                                disabled={true}
                                value={values.adjustmentOverride}
                                autoComplete="off"
                                onChange={handleChange}
                            />
                        </Box>
                    </Box>
                </Box>
                <SubcontractorResourceActivity
                    deleteActivity={deleteActivity}
                    deleteResource={deleteResource}
                    item={props.item}
                    disabled={props.disabled}
                    packageVendor={props.packageVendor}
                    estimateId={props.estimateId}
                    setSubcontractorActivitiesResources={setSubcontractorActivitiesResources} />
            </Box>
            <Backdrop
                sx={{ color: colors?.primary[1000], zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openBackdrop}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Box>
    </form>}</>
}