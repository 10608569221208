import { Box } from "@mui/material";
import ResourceList from "Components/Resources/ResourceList";
import { PanelState } from "Models/panel";
import { useState } from "react";
import { useParams } from "react-router-dom";

export default function ResourceLanding(){
    const { estimateId } = useParams();
    const [panelState] = useState<PanelState>({position: {state: 'full'}});
    
    return <Box height="calc(100% - 130px)" margin="10px"><ResourceList estimateId={estimateId} panelState={panelState}/></Box>
}