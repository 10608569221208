import { BaseMaster, Master } from 'Models/master';
import { api } from './api'
import { Estimate } from 'Models/estimate';

export const masterApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllMastersForCompany: build.query<Array<Master>, {organizationId: string, companyId: string}>({
      query: ({organizationId, companyId}: {organizationId: string, companyId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/master/all` }),
      providesTags: ['Masters'],
    }),
    createMaster: build.mutation<Estimate, Partial<{ body: BaseMaster, orgId: string, companyId: string }>>({
      query({body, orgId, companyId}) {
        return {
          url: `organization/${orgId}/company/${companyId}/master`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Masters'],
    }),
    updateMaster: build.mutation<BaseMaster, Partial<{ body: BaseMaster, orgId: string, companyId: string, masterId: string }>>({
      query({body, orgId, companyId, masterId}) {
        return {
          url: `organization/${orgId}/company/${companyId}/master/${masterId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Masters'],
    }),
    deleteMaster: build.mutation<void, Partial<{ orgId: string, companyId: string, masterId: string }>>({
      query({ orgId, companyId, masterId }) {
          return {
              url: `organization/${orgId}/company/${companyId}/master/${masterId}`,
              method: 'DELETE',
          }
      },
      invalidatesTags: ['Masters'],
  }),
  })
});


export const {
  useDeleteMasterMutation,
  useCreateMasterMutation,
  useGetAllMastersForCompanyQuery,
  useUpdateMasterMutation
} = masterApi