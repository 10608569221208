
import { BaseReportResource, CategorySubcategoryTotal, CategoryTotal, ReportEstimate } from 'Models/report';
import { api } from './api'
export const reportApi = api.injectEndpoints({
  endpoints: (build) => ({
    
    getEstimatesForReport: build.query<Array<ReportEstimate>, {organizationId: string, companyId: string}>({
      query: ({organizationId, companyId}: {organizationId: string, companyId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimates` }),
      providesTags: ['ReportEstimates']
    }),
    getProjectResourceLevel3Report: build.query<Array<BaseReportResource>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/projectResourceLevel3` }),
    }),
    getProjectResourceLevel2Report: build.query<Array<CategorySubcategoryTotal>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/projectResourceLevel2` }),
    }),
    getProjectResourceLevel1Report: build.query<Array<CategoryTotal>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/report/estimate/${estimateId}/projectResourceLevel1` }),
    }),
  })
});

export const {
  useGetEstimatesForReportQuery,
  useLazyGetEstimatesForReportQuery,
  useLazyGetProjectResourceLevel3ReportQuery,
  useLazyGetProjectResourceLevel2ReportQuery,
  useLazyGetProjectResourceLevel1ReportQuery
} = reportApi