import { Box, IconButton } from "@mui/material";
import { CustomHeaderProps } from "ag-grid-react";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useGetUserDetailsQuery } from "State/Services/user";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { useEffect, useState } from "react";
import { useGetEstimateQuery } from "State/Services/estimate";
import { Entity } from "Models/estimate";

export interface MarkupMarginLockedProps extends CustomHeaderProps {
    marginSet: () => void;
    lockUnlock: (lock: boolean) => void;
    estimateId: string;
}

export default function MarkupItemLocked(props: MarkupMarginLockedProps) {
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [editDisabled, setEditDisabled] = useState(false);

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                setEditDisabled(true);
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Pricing,
                    requiredPermissions: [502]
                });
                setEditDisabled(!hasEditAccess);
            }
        } else {
            setEditDisabled(true);
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    return <Box display="flex" alignItems="center" justifyItems="space-between" gap="10px">
        <Box display="flex" alignItems="center" justifyItems="space-between">
            <IconButton disabled={editDisabled} aria-label="lock" onClick={() => props.lockUnlock(true)}>
                <LockIcon />
            </IconButton>
            <IconButton disabled={editDisabled} aria-label="unlock" onClick={() => props.lockUnlock(false)}>
                <LockOpenIcon />
            </IconButton>
        </Box>
    </Box>
}
