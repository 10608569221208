import { Alert, Box, useTheme } from '@mui/material';
import { SystemRole, UserInfoView } from 'Models/user-details';
import { useAssignPermissionsToUserMutation, useGetUserDetailsQuery, useGetUsersForCompanyQuery } from 'State/Services/user';
import { ColDef, IRowNode } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { tokens } from 'theme';
import UserRoleEditCellRenderer from './UserRoleEditCellRenderer';
import SaveCancelUserRoleCellRenderer from './SaveCancelUserRoleCellRenderer';
import { CellClickedEvent, CellKeyDownEvent, GetRowIdParams, RowEditingStartedEvent, RowEditingStoppedEvent, SuppressKeyboardEventParams } from 'ag-grid-community';
import { Errors } from 'Models/errors';
import UserLockedCellRenderer from './UserLockedCellRenderer';

export default function Users() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data: user } = useGetUserDetailsQuery();
    const { data: users } = useGetUsersForCompanyQuery({ companyId: (user && user.companyId) ? user.companyId : '', orgId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridRef = useRef<AgGridReact<UserInfoView>>(null);
    const [rowData, setRowData] = useState<UserInfoView[]>();
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [currentEditingNode, setCurrentEditingNode] = useState<IRowNode<UserInfoView> | undefined>();
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const [assignPermissions] = useAssignPermissionsToUserMutation();
    const [pageError, setPageError] = useState<string | undefined>();
    
    const defaultUserRoleColDef = useMemo<ColDef>(() => {
        return {
            editable: true
        };
    }, []);

    useEffect(() => {
        if (users && users.length > 0) {
            setRowData(users.map((usr) => ({ ...usr, name: (usr.id === user?.userId) ? 'Me' : usr.name, actions: '' })));
        } else {
            setRowData([]);
        }
    }, [user?.userId, users])

    useEffect(() => {
        if (currentEditingNode && typeof currentEditingNode.rowIndex === 'number') {
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditingNode.rowIndex,
                colKey: 'systemRole',
            });
        }
    }, [currentEditingNode])

    const getUserRoleRowId = useCallback(function (params: GetRowIdParams<UserInfoView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    const saveUpdateUserRole = useCallback(async (node: IRowNode<UserInfoView>, nodeToEditAfterSave?: IRowNode<UserInfoView>) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                setPageError(undefined);
                if (user && node.data) {
                    gridRef.current!.api.stopEditing();
                    await assignPermissions({
                        companyId: user?.companyId,
                        orgId: user.organizationId,
                        userId: node.data.id,
                        body: {
                            email: node.data.email,
                            name: node.data.name,
                            id: node.data.id,
                            systemRole: node.data.systemRole,
                        }
                    }).unwrap();
                    resolve();
                    if (nodeToEditAfterSave) {
                        setCurrentEditingNode(nodeToEditAfterSave);
                    } else {
                        setCurrentEditingNode(undefined);
                    }
                }
            } catch (error: any) {
                if (error.status === 500) {
                    setPageError(Errors.generic);
                    return;
                }
                if (error && error.data) {
                    if (typeof node.rowIndex === 'number') {
                        gridRef.current!.api.startEditingCell({
                            rowIndex: node.rowIndex,
                            colKey: 'systemRole',
                        });
                    }
                }
                reject(error);
            }
        });
    }, [assignPermissions, user])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const saveUpdateUserRoleRef = useRef<any>();
    saveUpdateUserRoleRef.current = saveUpdateUserRole;

    const onCellClicked = useCallback(async (event: CellClickedEvent<UserInfoView>) => {
        if (event.node.data?.id===user?.userId || event.node.data?.systemRole === SystemRole.AccountOwner){
            return;
        }
        if (isCancelClicked) {
            setIsCancelClicked(false);
            return;
        }

        if (currentEditingNode === event.node) {
            return;
        }

        if (event.column.getColId() === 'actions') {
            return;
        }

        if (!currentEditingNode) {
            setCurrentEditingNode(event.node);
        } else {
            await saveUpdateUserRole(currentEditingNode, event.node);
        }
    }, [currentEditingNode, isCancelClicked, saveUpdateUserRole, user?.userId])

    const onUserRoleRowEditingStarted = useCallback((event: RowEditingStartedEvent<UserInfoView>) => {
        setIsCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
        setTimeout(() => {
            const displayInstances = gridRef.current!.api.getCellEditorInstances({
                columns: ['systemRole']
            });
            if (displayInstances && displayInstances.length > 0 && displayInstances[0] && typeof (displayInstances[0] as any).setFocusOnAdd === 'function') {
                (displayInstances[0] as any).setFocusOnAdd();
            }
        }, 100);
    }, [])

    const onUserRoleRowEditingStopped = useCallback(async (event: RowEditingStoppedEvent<UserInfoView>) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<UserInfoView>) => {
        if (params.event.key === 'Enter' && params.node) {
            params.event.stopPropagation();

            const save = async () => {
                await saveUpdateUserRole(params.node, undefined);
            }
            save();
        }
        return true;
    }, [saveUpdateUserRole])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const saveOnEnterRef = useRef<any>();
    saveOnEnterRef.current = saveOnEnter;

    const cancelUserRoleEditing = useCallback((node: IRowNode<UserInfoView>) => {
        if (node && node.data) {
            setIsCancelClicked(true);
            gridRef.current!.api.stopEditing(true);
            setCurrentEditingNode(undefined);
        }
    }, [])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const cancelUserRoleEditingRef = useRef<any>();
    cancelUserRoleEditingRef.current = cancelUserRoleEditing;

    const [columnDefs] = useState<ColDef<UserInfoView>[]>([
        {
            field: 'name',
            headerName: 'Name',
            suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
            flex: 1,
            editable: false
        },
        {
            field: 'email',
            headerName: 'Email',
            suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
            flex: 1,
            editable: false
        },
        {
            field: 'systemRole',
            headerName: 'Role',
            suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
            flex: 1,
            cellEditor: UserRoleEditCellRenderer,
            editable: (params) => {
                return params.data?.id !== user?.userId
            }
        },
        {
            field: 'locked',
            headerName: 'Status',
            suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
            width: 90,
            cellRenderer: UserLockedCellRenderer,
            editable: false
        },
        {
            field: 'actions',
            headerName: '',
            resizable: true,
            maxWidth: 60,
            editable: (params) => {
                return params.data?.id !== user?.userId
            },
            cellStyle: { textAlign: "left", padding: "0px" } as any,
            cellEditor: SaveCancelUserRoleCellRenderer,
            cellEditorParams: {
                save: (node: IRowNode<UserInfoView>) => saveUpdateUserRoleRef.current(node),
                cancel: (node: IRowNode<UserInfoView>) => cancelUserRoleEditingRef.current(node)
            },
        },
    ]);

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelUserRoleEditing(e.node);
            setIsCancelClicked(false);
        }
    }, [cancelUserRoleEditing]);

    return <>{colors && <Box padding="10px" display="flex" flexDirection="column" height="calc(100% - 100px)" width="100%">
        {pageError && <Box marginBottom="10px"><Alert severity="error">{pageError}</Alert></Box>}
        <div style={gridStyle} className="ag-theme-alpine ag-theme-bidbow">
            <AgGridReact<UserInfoView>
                ref={gridRef}
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultUserRoleColDef}
                onRowEditingStarted={onUserRoleRowEditingStarted}
                onRowEditingStopped={onUserRoleRowEditingStopped}
                onCellClicked={onCellClicked}
                suppressRowClickSelection={true}
                animateRows={true}
                editType={'fullRow'}
                suppressClickEdit={true}
                getRowId={getUserRoleRowId}
                onCellKeyDown={onCellKeyDown}
            />
        </div>
    </Box>}</>
}