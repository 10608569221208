import { forwardRef, useCallback, useEffect, useRef } from 'react';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';
import { Box, IconButton } from '@mui/material';
import CalculateIcon from '@mui/icons-material/Calculate';
import { IndirectItemDetail } from 'Models/indirect-item';

export interface IIndirectItemDetailQuantityPerUnitCellRendererParams extends ICellRendererParams<IndirectItemDetail> {
    calculate: (node: IRowNode<IndirectItemDetail>) => void;
}

export default forwardRef((props: IIndirectItemDetailQuantityPerUnitCellRendererParams, ref) => {
    const buttonRef = useRef<any>();
    
    const calculate = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        props.calculate(props.node);
    }, [props])

    useEffect(() =>{
        if (buttonRef?.current){
            buttonRef.current.onclick = calculate;
        }
    }, [calculate])

    return <><Box display="flex" justifyContent="space-between">
        <Box>
            {props.value}
        </Box>
        <Box>
            {props.node.level===0 && <IconButton ref={buttonRef} onClick={calculate} color="primary">
                {<CalculateIcon />}
            </IconButton>}
        </Box>
    </Box></>;
});