import { Box, Card, CardContent, Typography } from "@mui/material";
import "./index.css";
import { useGetUserDetailsQuery } from "State/Services/user";
import fullLogo from '../../../Images/logo.png';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

export default function Profile() {
    const { data: user } = useGetUserDetailsQuery();

    return <>
        {!user?.accountUnavailable && <Box overflow="auto" height="85vh" paddingBottom="20px">
            <Box data-embed="outseta"></Box>
        </Box>}
        {user?.accountUnavailable && <Box overflow="auto" height="calc(100% - 20px)" padding="0px">
            <Box display="flex" alignItems="center" justifyContent="center" alignContent="center" width="100%" height="100%" justifyItems="center" sx={{ backgroundColor: "gray" }}>
                <Card sx={{ maxWidth: 500, height: 'auto', padding: "50px 100px 50px 100px" }}>
                    <CardContent sx={{ padding: "0px", paddingBottom: "0px !important" }}>
                        <Box width="100%" display="flex" justifyContent="center" marginBottom="30px">
                            <img src={fullLogo} alt="Bidbow" />
                        </Box>
                        <Box width="100%" display="flex" justifyContent="center" marginBottom="20px">
                            <ErrorOutlineOutlinedIcon sx={{ fontSize: "150px" }} />
                        </Box>
                        <Typography gutterBottom variant="h4" component="div" sx={{ display: "flex", alignContent: "center", justifyContent: "center", textAlign: "Center" }}>
                            Account Service Unavailable
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: "center" }}>
                            Account service is temporarily unavailable. Please re-login to try again later.
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
        </Box>}
    </>

}