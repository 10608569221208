export const sort = (a: {displayId?: string}, b: {displayId?: string}) => {
    if (a.displayId && b.displayId){
        const aIsLetter = /[A-Za-z]/.test(a.displayId);
        const bIsLetter = /[A-Za-z]/.test(b.displayId);
    
        if (aIsLetter && !bIsLetter) {
            return 1;
        }
        if (!aIsLetter && bIsLetter) {
            return -1;
        }
        if (aIsLetter && bIsLetter) {
            // If both are letters, sort alphabetically
            return a.displayId.localeCompare(b.displayId);
        }
        // If neither are letters, sort by character code
        return parseFloat(a.displayId) - parseFloat(b.displayId);
    }
    return 0;
}