import { Autocomplete, Box } from "@mui/material";
import { ICellEditorParams } from "ag-grid-enterprise";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { GridTextField } from "Components/GridTextField";
import { ResourceView } from "Models/resource";
import { ResourceSubCategory } from "Models/resource-category";
import { forwardRef, MutableRefObject, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useGetResourceCategoriesQuery } from "State/Services/resource-category";
import { useGetUserDetailsQuery } from "State/Services/user";

export interface ResourceListSubCategoryEditCellRendererParams extends ICellEditorParams<ResourceView, ResourceSubCategory> {
    estimateId: string;
    setRef: (ref: MutableRefObject<any>) => void;
}

export default forwardRef((props: ResourceListSubCategoryEditCellRendererParams, ref) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: storedResourceCategories } = useGetResourceCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId });
    const [options, setOptions] = useState<Array<ResourceSubCategory>>([]);
    const [open, setOpen] = useState(false);
    const inputRef = useRef<any>();

    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                return value?.description ?? null;
            },
            focusIn() {
                inputRef.current.focus();
                handleOpen();
            },
            setFocusOnAdd() {
                if (inputRef.current) {
                    inputRef.current.focus();
                    setOpen(true);
                }
            },
            loadSubcategories(categoryId: string | undefined) {
                loadOptions(categoryId);
                setValue(null);
            }
        };
    });

    const loadOptions = useCallback((categoryId: string | undefined) =>{
        const category = storedResourceCategories?.find((cat) => cat.id === categoryId);
        if (category && category.resourceSubCategories && category.resourceSubCategories.length > 0) {
            setOptions(category.resourceSubCategories);
        }else{
            setOptions([]);
        }
    }, [storedResourceCategories])

    const getInitialValue = useCallback((props: ICellEditorParams<ResourceView, ResourceSubCategory>) => {
        let value: ResourceSubCategory | null = null;
        const resCategory = storedResourceCategories?.find((resCategory) => (resCategory.id === props.data?.categoryId));
        if (resCategory && resCategory.resourceSubCategories && resCategory.resourceSubCategories.length > 0) {
            value = resCategory.resourceSubCategories.find((sub) => sub.id === props.node.data?.subCategoryId) ?? null;
        }
        return value;
    }, [storedResourceCategories]);

    const [value, setValue] = useState<ResourceSubCategory | null>(getInitialValue(props));

    const onChange = useCallback((event: React.SyntheticEvent, newValue: ResourceSubCategory | null) =>{
        if (newValue && options) {
            const resSubCategory = options.find((sub) => (sub.id === newValue.id));
            if (resSubCategory) {
                setValue(resSubCategory);
                props.node.setDataValue('subCategoryId', resSubCategory.id);
            } else {
                setValue(null);
                props.node.setDataValue('subCategoryId', undefined);
            }
        } else {
            setValue(null);
            props.node.setDataValue('subCategoryId', undefined);
        }
    }, [options, props.node])

    useEffect(() =>{
        if (value){
            loadOptions(value.resourceCategoryId);
        }
    }, [loadOptions, value])

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (inputRef) {
            props.setRef(inputRef);
        }
    }, [props])

    return <Box height="100%">
        <Autocomplete
            fullWidth
            className="ag-input-field-input ag-text-field-input"
            sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
            value={value || null}
            size="small"
            onClose={handleClose}
            isOptionEqualToValue={(option, value) => (option.id === value.id)}
            onOpen={handleOpen}
            open={open}
            onChange={onChange}
            options={options ?? []}
            getOptionLabel={(resCategory) => {
                if (typeof resCategory === 'string') {
                    return resCategory;
                }
                return resCategory.description ?? '';
            }}
            renderInput={(params) => <GridTextField sx={{ height: "100%" }} inputRef={inputRef} placeholder='Select Subcategory' {...params} />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.description ?? '', inputValue, {
                    insideWords: true,
                });
                const parts = parse(option.description ?? '', matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
    </Box>
});