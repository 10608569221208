import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const GridTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    padding: "0px",
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    paddingLeft: "0px !important",
    paddingRight: "0px !important",
    height: "100%"
  },
  '& .MuiInputBase-input': {
    height: "100%",
    backgroundColor: "white",
  },
}));