import {
    forwardRef,
    memo,
    useCallback,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Box, FormControl, InputAdornment, List, ListItem, OutlinedInput, useTheme } from '@mui/material';
import { BaseIndirectItem, IndirectItemDetailView } from 'Models/indirect-item';
import { tokens } from 'theme';
import { HtmlTooltip } from 'Components/HtmlToolltip';
import WarningIcon from '@mui/icons-material/Warning';

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ARROW_LEFT = 'ArrowLeft';
const KEY_ARROW_RIGHT = 'ArrowRight';
const KEY_DELETE = "Delete";

export interface IndirectItemDetailFactorEditCellRendererParams extends ICellEditorParams<IndirectItemDetailView> {
    indirectItem(): BaseIndirectItem;
}

export default memo(
    forwardRef((props: IndirectItemDetailFactorEditCellRendererParams, ref) => {
        const [colors, setColors] = useState<any>();
        const theme = useTheme();
        useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
        const [error, setError] = useState<string | undefined>(undefined);
        const createInitialState = () => {
            let startValue;
            let highlightAllOnFocus = true;
            const eventKey = props.eventKey;

            if (eventKey === KEY_BACKSPACE) {
                // if backspace or delete pressed, we clear the cell
                startValue = '';
            } else if (eventKey && eventKey.length === 1) {
                // if a letter was pressed, we start with the letter
                startValue = props.eventKey;
                highlightAllOnFocus = false;
            } else {
                // otherwise we start with the current value
                startValue = props.value;
                if (eventKey === KEY_F2) {
                    highlightAllOnFocus = false;
                }
            }
            if (!props.value) {
                startValue = '';
            }

            return {
                value: startValue,
                highlightAllOnFocus,
            };
        };

        const initialState = createInitialState();
        const [value, setValue] = useState(initialState.value);
        const refInput = useRef<HTMLInputElement>(null);

        const onChange = useCallback((value: string) => {
            setValue(value);
            if (value==="0") return;
            if (!props.node.data?.rate) {
                props.node.setDataValue('estimatedAmount', undefined);
                return;
            };
            
            if (isNaN(parseFloat(props.node.data.rate.toString()))) {
                props.node.setDataValue('estimatedAmount', undefined);
                return;
            };
            let quantityPerUnit: number|undefined;
            const quantityPerUnitInstances = props.api.getCellEditorInstances({
                columns: ['quantityPerUnit']
            });
            if (quantityPerUnitInstances.length>0 && quantityPerUnitInstances[0]){
                quantityPerUnit = quantityPerUnitInstances[0].getValue();
            }
            if (!quantityPerUnit) {
                props.node.setDataValue('estimatedAmount', undefined);
                return;
            }
            
            if (isNaN(parseFloat(quantityPerUnit.toString()))) {
                props.node.setDataValue('estimatedAmount', undefined);
                return;
            }
            let amount: number|undefined = undefined;
            if (value && !isNaN(parseFloat(value))){
                amount = quantityPerUnit * parseFloat(props.node.data.rate.toString()) * parseFloat(value);
            }else{
                amount = quantityPerUnit * parseFloat(props.node.data.rate.toString());
            }
            props.node.setDataValue('estimatedAmount', amount);

        }, [props.api, props.node])

        /* Utility Methods */
        const isCharacter = props.eventKey && props.eventKey.length === 1;
        const cancelBeforeStart =
            isCharacter && '1234567890'.indexOf(props.eventKey!) < 0;

        const isCharNumeric = (charStr: string) => {
            return !!/^[0-9.]$/.test(charStr);
        };

        const isNumericKey = (event: any) => {
            const charStr = event.key;
            return isCharNumeric(charStr);
        };

        const isLeftOrRight = (event: any) => {
            return [KEY_ARROW_LEFT, KEY_ARROW_RIGHT].indexOf(event.key) > -1;
        };

        const isBackspaceOrDelete = (event: any) => {
            return event.key === KEY_BACKSPACE || event.key === KEY_DELETE;
        };

        const onKeyDown = (event: any) => {
            if (isLeftOrRight(event) || isBackspaceOrDelete(event)) {
                return;
            }

            if (!isNumericKey(event)) {
                if (event.preventDefault) event.preventDefault();
            }
        };

        /* Component Editor Lifecycle methods */
        useImperativeHandle(ref, () => {
            return {
                // the final value to send to the grid, on completion of editing
                getValue() {
                    return value === '' || value == null ? null : parseFloat(value);
                },

                // Gets called once before editing starts, to give editor a chance to
                // cancel the editing before it even starts.
                isCancelBeforeStart() {
                    return cancelBeforeStart;
                },
                changeValue(value: number) {
                    setValue(value);
                },
                focusIn() {
                    refInput.current!.focus();
                },
                setFocusOnAdd(){
                    if (refInput.current){
                        refInput.current.focus();
                        refInput.current.select();
                    }
                },
                setError(message: string) {
                    if (message) {
                        setError(message);
                    }
                },
            };
        });

        return (
            <Box sx={{ width: "100%", height: "100% !important" }}>
                <FormControl sx={{ height: 'inherit', width: "100%" }}>
                    <OutlinedInput
                        sx={{ height: "inherit", backgroundColor: "white" }}
                        className="ag-input-field-input ag-text-field-input"
                        placeholder="Factor"
                        onChange={(event) => onChange(event.target.value)}
                        value={value}
                        endAdornment={error &&
                            <InputAdornment position="end">
                                <HtmlTooltip color={colors?.redAccent[500]}
                                    title={
                                        <List sx={{ listStyleType: 'disc', pl: 1 }}>
                                            <ListItem
                                                sx={{
                                                    padding: 0,
                                                    textAlign: "center",
                                                    listStyleType: "disc",
                                                    display: "list-item",
                                                }}
                                            >{error}</ListItem>
                                        </List>
                                    }>
                                    <WarningIcon sx={{ color: `${colors?.redAccent[500]}`, cursor: "pointer" }} />
                                </HtmlTooltip>
                            </InputAdornment>
                        }
                        inputRef={refInput}
                        onKeyDown={(event) => onKeyDown(event)}
                    />
                </FormControl>
            </Box>
        );
    })
);
