import { api } from './api'
import { StandardItemCategory } from 'Models/standard-item-category';

export const standardItemCategoryApi = api.injectEndpoints({
    endpoints: (build) => ({
        getStandardItemCategories: build.query<Array<StandardItemCategory>, { organizationId: string, companyId: string, estimateId: string }>({
            query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/standard-item-category` }),
            providesTags: ['StandardItemCategories']
        }),
        getStandardItemCategoryMaxId: build.query<number, { organizationId: string, companyId: string, estimateId: string, level: number }>({
            query: ({ organizationId, companyId, estimateId, level }: { organizationId: string, companyId: string, estimateId: string, level: number }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/standard-item-category/level/${level}/max-id` }),
            providesTags: ['StandardItemCategories']
        }),
        createStandardItemCategory: build.mutation<StandardItemCategory, Partial<{ body: StandardItemCategory, orgId: string, companyId: string, estimateId: string }>>({
            query({ body, orgId, companyId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/standard-item-category`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: (result) => (result ? ['StandardItemCategories'] : []),
        }),
        updateStandardItemCategory: build.mutation<StandardItemCategory, Partial<{ body: StandardItemCategory, orgId: string, companyId: string, estimateId: string, standardItemCategoryId: string }>>({
            query({ body, orgId, companyId, estimateId, standardItemCategoryId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/standard-item-category/${standardItemCategoryId}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: (result) => (result ? ['StandardItemCategories'] : []),
        }),
        deleteStandardItemCategory: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, standardItemCategoryId: string }>>({
            query({ orgId, companyId, estimateId, standardItemCategoryId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/standard-item-category/${standardItemCategoryId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result) => (result ? ['StandardItemCategories'] : []),
        }),

    })
});

export const {
    useGetStandardItemCategoriesQuery,
    useGetStandardItemCategoryMaxIdQuery,
    useLazyGetStandardItemCategoriesQuery,
    useCreateStandardItemCategoryMutation,
    useDeleteStandardItemCategoryMutation,
    useUpdateStandardItemCategoryMutation
} = standardItemCategoryApi