import { ChangeEvent, useState } from 'react';
import { Box, Checkbox } from '@mui/material';
import { CustomHeaderProps } from 'ag-grid-react';

export interface SubcontractorHeaderCellRendererProps extends CustomHeaderProps {
    vendorId: string;
    disabled: () => boolean;
    checkAll: (checked: boolean, vendorId: string) => Promise<void>;
}

export default function SubcontractorHeaderCellRenderer(props: SubcontractorHeaderCellRendererProps) {
    const [checked, setChecked] = useState(false);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        props.checkAll(event.target.checked, props.vendorId);
    };
    
    return (
        <Box display="flex" alignItems="center" justifyContent="center"  width="15px">
            <Checkbox checked={checked} disabled={props.disabled()}
                onChange={handleChange} />
        </Box>
    );
};
