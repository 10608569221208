import { Unit } from 'Models/unit';
import { api } from './api'


export const unitApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUnits: build.query<Array<Unit>, { organizationId: string, companyId: string }>({
      query: ({ organizationId, companyId }: { organizationId: string, companyId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/unit` }),
      providesTags: ['Units']
    }),
    updateUnit: build.mutation<Unit, Partial<{ body: Unit, orgId: string, companyId: string, unitId: string }>>({
      query({ body, orgId, companyId, unitId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/unit/${unitId}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Units', 'UnitsMaxOrder'],
    }),
    reorderUnits: build.mutation<Array<{unitId: string, order:number}>, Partial<{ body: Array<{unitId: string, order:number}>, orgId: string, companyId: string, unitId: string }>>({
      query({ body, orgId, companyId, unitId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/unit/reorder`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Units', 'UnitsMaxOrder'],
    }),
    createUnit: build.mutation<Unit, Partial<{ body: Unit, orgId: string, companyId: string }>>({
      query({ body, orgId, companyId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/unit`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Units', 'UnitsMaxOrder'],
    }),
    deleteUnit: build.mutation<void, Partial<{ orgId: string, companyId: string, unitId: string }>>({
      query({ orgId, companyId, unitId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/unit/${unitId}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result) => (result ? ['Units', 'UnitsMaxOrder'] : []),
    }),
  })
});

export const {
  useLazyGetUnitsQuery,
  useReorderUnitsMutation,
  useDeleteUnitMutation,
  useGetUnitsQuery,
  useCreateUnitMutation,
  useUpdateUnitMutation
} = unitApi