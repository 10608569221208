import { BaseEstimate, ChangeCurrency, ClearPreliminaryRate, CopyEstimate, Estimate, Lock, Markup, MarkupDto } from 'Models/estimate';
import { api } from './api'
import { BaseItem } from 'Models/item';
import { BaseIndirectItem } from 'Models/indirect-item';
import { IServerSideGetRowsRequest } from 'ag-grid-enterprise';

export const estimateApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllEstimatesForCompany: build.query<Array<Estimate>, {organizationId: string, companyId: string}>({
      query: ({organizationId, companyId}: {organizationId: string, companyId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/all` }),
      providesTags: ['Estimates'],
    }),
    getEstimate: build.query<Estimate, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}` }),
      providesTags: ['Estimate'],
    }),
    getGroupedItemsForEstimate: build.mutation<{ results: Array<BaseItem>; totalCount: number }, Partial<{ body: IServerSideGetRowsRequest, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/items-grouped`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['Items'] : []),
    }),
    getPagedItemsForEstimate: build.mutation<{ results: Array<BaseItem>; totalCount: number }, Partial<{ body: IServerSideGetRowsRequest, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/items-paged`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['Items'] : []),
    }),
    getGroupedIndirectItemsForEstimate: build.mutation<{ results: Array<BaseIndirectItem>; totalCount: number }, Partial<{ body: IServerSideGetRowsRequest, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-items-grouped`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['IndirectItems'] : []),
    }),
    getPagedIndirectItemsForEstimate: build.mutation<{ results: Array<BaseIndirectItem>; totalCount: number }, Partial<{ body: IServerSideGetRowsRequest, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/indirect-items-paged`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: (result) => (result ? ['IndirectItems'] : []),
    }),
    getItemsForEstimate: build.query<Array<BaseItem>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/items` }),
      providesTags: ['Items']
    }),
    getIndirectItemsForEstimate: build.query<Array<BaseIndirectItem>, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/indirect-items` }),
      providesTags: ['IndirectItems']
    }),
    createEstimate: build.mutation<Estimate, Partial<{ body: BaseEstimate, orgId: string, companyId: string }>>({
      query({body, orgId, companyId}) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Estimates'],
    }),
    copyEstimate: build.mutation<Estimate, Partial<{ body: CopyEstimate, orgId: string, companyId: string, fromEstimateId: string }>>({
      query({body, orgId, companyId, fromEstimateId}) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/copy-from/${fromEstimateId}`,
          method: 'POST',
          body,
        }
      },
      invalidatesTags: ['Estimates', 'Masters'],
    }),
    updateEstimate: build.mutation<Estimate, Partial<{ body: BaseEstimate, orgId: string, companyId: string, estimateId: string, updateResourceBasicRates: boolean }>>({
      query({body, orgId, companyId, estimateId, updateResourceBasicRates}) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}?updateResourceBasicRates=${updateResourceBasicRates}`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'Resources', 'Resources', 'Activities', 'CompositeResources', 'ActivityResources', 'IndirectItemDetails', 'ItemDetails', 'FilteredResources', "StandardItems", "StandardItemActivityResources", 'Estimates', 'Estimate'],
    }),
    updateMarkup: build.mutation<Markup, Partial<{ body: Markup, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/update-markup`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'Items'],
    }),
    lock: build.mutation<Lock, Partial<{ body: Lock, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/lock`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'Items'],
    }),
    clearPreliminaryRate: build.mutation<ClearPreliminaryRate, Partial<{ body: ClearPreliminaryRate, orgId: string, companyId: string, estimateId: string }>>({
      query({ body, orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/clear-pre-rate`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'Items'],
    }),
    updateMarkupItem: build.mutation<MarkupDto, Partial<{ body: MarkupDto, orgId: string, companyId: string, estimateId: string, itemId: string }>>({
      query({ body, orgId, companyId, estimateId, itemId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/item/${itemId}/markup-item`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'Items'],
    }),
    updateMarkupForAllItems: build.mutation<MarkupDto, Partial<{ orgId: string, companyId: string, estimateId: string, markup: number }>>({
      query({ orgId, companyId, estimateId, markup }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/markup-all`,
          method: 'PUT',
          body: {
            markup,
          },
        }
      },
      invalidatesTags: ['Pricing', 'Items'],
    }),
    clearMarkupForAllItems: build.mutation<undefined, Partial<{ orgId: string, companyId: string, estimateId: string }>>({
      query({ orgId, companyId, estimateId }) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/markup-all-clear`,
          method: 'PUT'
        }
      },
      invalidatesTags: ['Pricing', 'Items'],
    }),
    changeCurrency: build.mutation<void, Partial<{ body: ChangeCurrency, orgId: string, companyId: string, estimateId: string }>>({
      query({body, orgId, companyId, estimateId}) {
        return {
          url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/change-currency`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: ['Pricing', 'Resources', 'Resources', 'Activities', 'CompositeResources', 'ActivityResources', 'IndirectItemDetails', 'ItemDetails', 'FilteredResources', "StandardItems", "StandardItemActivityResources", 'Estimates', 'Estimate', 'SupplierPackages', 'SubPackages', 'Items'],
    }),
    deleteEstimate: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string }>>({
      query({ orgId, companyId, estimateId }) {
          return {
              url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}`,
              method: 'DELETE',
          }
      },
      invalidatesTags: ['Estimates'],
  }),
  })
});

export const {
  endpoints: { getAllEstimatesForCompany, getEstimate, getItemsForEstimate },
} = estimateApi

export const {
  useGetPagedIndirectItemsForEstimateMutation,
  useGetGroupedIndirectItemsForEstimateMutation,
  useGetPagedItemsForEstimateMutation,
  useGetGroupedItemsForEstimateMutation,
  useDeleteEstimateMutation,
  useChangeCurrencyMutation,
  useCopyEstimateMutation,
  useUpdateEstimateMutation,
  useGetAllEstimatesForCompanyQuery,
  useGetIndirectItemsForEstimateQuery,
  useGetEstimateQuery,
  useLazyGetEstimateQuery,
  useGetItemsForEstimateQuery,
  useCreateEstimateMutation,
  useUpdateMarkupMutation,
  useLockMutation,
  useClearPreliminaryRateMutation,
  useUpdateMarkupItemMutation,
  useUpdateMarkupForAllItemsMutation,
  useClearMarkupForAllItemsMutation
} = estimateApi