import { ChangeEvent, forwardRef } from 'react';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';
import { PackageItemDetailsView } from 'Models/package';
import { Box, Checkbox } from '@mui/material';

export interface SubcontractorSelectCellRendererProps extends ICellRendererParams<PackageItemDetailsView, boolean>{
    disabled: () => boolean;
    checked: (checked: boolean, node: IRowNode<PackageItemDetailsView>, vendorId: string) => void;
    vendorId: string;
}

export default forwardRef((props: SubcontractorSelectCellRendererProps, ref) => {
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        props.checked(event.target.checked, props.node, props.vendorId);
    };

    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Checkbox checked={!!props.value} disabled={props.disabled()}
                onChange={handleChange} />
        </Box>
    );
});