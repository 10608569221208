import { CalculationOption } from "./price";

export interface Margin {
  id: string;
  displayId?: string;
  description?: string;
  percentage?: number;
  marginDetails: Array<MarginDetail>;
}

export interface MarginDetail {
  marginId: string;
  percentageOf?: PercentageOf;
  calculationOption: CalculationOption;
}

export interface MarginView extends Margin {
}

export interface Totals {
  directCostTotal?: number | null;
  estimatedIndirectCostTotal?: number | null;
  estimatedDirectCostTotal?: number | null;
  indirectCostTotal?: number | null;
}

export interface MarginOverrides {
  quantityMarginOverride?: number | null;
  quantityPriceOverride?: number | null;
  estimatedQuantityMarginOverride?: number | null;
  estimatedQuantityEstimatedPriceOverride?: number | null;
}

export enum PercentageOf {
  DirectCost = 'DirectCost',
  InDirectCost = 'InDirectCost',
  Price = 'Price',
  EstimatedDirectCost = 'EstimatedDirectCost',
  EstimatedInDirectCost = 'EstimatedInDirectCost',
  EstimatedPrice = 'EstimatedPrice',
}
