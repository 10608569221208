import { MarginOverrides, PercentageOf, Totals } from "Models/margin";
import { MarginInfoView } from "./MarginInfo";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Box, Button, FormControl, IconButton, Select, SelectChangeEvent, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import { CalculationOption } from "Models/price";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";
import { rounder } from "Helpers/rounder";

export interface MarginInfoRowProps {
    margin: MarginInfoView;
    totals?: Totals;
    marginOverrides: MarginOverrides;
    delete?: (margin: MarginInfoView) => void;
    addMargin: () => void;
    onUpdateTotal: (totalDetail: {id: string, total: number}) => void;
    onChange: (margin: MarginInfoView) => void;
    options: Array<{ id: string, description: string }>;
    index: number;
    estimateId?: string;
    disabled: boolean;
}

export default function MarginInfoRow(props: MarginInfoRowProps) {
    const [total, setTotal] = useState<string | undefined>();
    const [margin, setMargin] = useState<MarginInfoView>(props.margin);
    const [disablePercentage, setDisablePercentage] = useState(false);
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId });

    const calculateTotal = useCallback((margin: MarginInfoView): number => {
        let currentTotal = 0;
        if ((margin.calculationOption === CalculationOption.overrideQuantity || margin.calculationOption === CalculationOption.overrideEstimatedQuantity) && margin.description === 'Profit Margin') {
            if (margin.profitMarginOverride) {
                currentTotal = margin.profitMarginOverride;
            } else {
                currentTotal = 0;
            }
            setTotal(currentTotal.toString());
            return currentTotal;
        }

        switch (margin.percentageOf) {
            case PercentageOf.DirectCost:
                if (props.totals?.directCostTotal && margin.percentage) {
                    currentTotal = props.totals?.directCostTotal * (margin.percentage / 100);
                } else {
                    currentTotal = 0;
                }
                break;
            case PercentageOf.InDirectCost:
                if (props.totals?.indirectCostTotal && margin.percentage) {
                    currentTotal = props.totals?.indirectCostTotal * (margin.percentage / 100);
                } else {
                    currentTotal = 0;
                }
                break;
            case PercentageOf.EstimatedDirectCost:
                if (props.totals?.estimatedDirectCostTotal && margin.percentage) {
                    currentTotal = props.totals?.estimatedDirectCostTotal * (margin.percentage / 100);
                } else {
                    currentTotal = 0;
                }
                break;
            case PercentageOf.EstimatedInDirectCost:
                if (props.totals?.estimatedIndirectCostTotal && margin.percentage) {
                    currentTotal = props.totals?.estimatedIndirectCostTotal * (margin.percentage / 100);
                } else {
                    currentTotal = 0;
                }
                break;
            case PercentageOf.EstimatedPrice:
                if (props.marginOverrides?.estimatedQuantityEstimatedPriceOverride) {
                    let estimatedPriceTotalOverride = props.marginOverrides?.estimatedQuantityEstimatedPriceOverride * ((props.margin?.percentage ?? 0) / 100);
                    setTotal(estimatedPriceTotalOverride.toString());
                    return estimatedPriceTotalOverride;
                }
                if (props.margin.estimatedPricePercentageTotal === undefined) {
                    setTotal("To be calculated");
                    return 0;
                }
                if (props.margin.estimatedPricePercentageTotal === 0) {
                    setTotal("0");
                    return 0;
                }
                let estimatedTotal = (props.totals?.estimatedDirectCostTotal ?? 0) + (props.totals?.estimatedIndirectCostTotal ?? 0) + (props.margin?.directIndirectMarginTotal ?? 0)
                currentTotal = estimatedTotal / (1 - ((props.margin?.estimatedPricePercentageTotal ?? 0) / 100)) * ((props.margin?.percentage ?? 0) / 100);
                break;
            case PercentageOf.Price:
                if (props.marginOverrides?.quantityPriceOverride) {
                    let quantityPriceTotalOverride = props.marginOverrides?.quantityPriceOverride * ((props.margin?.percentage ?? 0) / 100);
                    setTotal(quantityPriceTotalOverride.toString());
                    return quantityPriceTotalOverride;
                }
                if (props.margin.pricePercentageTotal === undefined) {
                    setTotal("To be calculated");
                    return 0;
                }
                if (props.margin.pricePercentageTotal === 0) {
                    setTotal("0");
                    return 0;
                }
                let total = (props.totals?.directCostTotal ?? 0) + (props.totals?.indirectCostTotal ?? 0) + (props.margin?.directIndirectMarginTotal ?? 0)
                currentTotal = (total / (1 - ((props.margin?.pricePercentageTotal ?? 0) / 100))) * ((props.margin?.percentage ?? 0) / 100);
                break;
            default:
                break;
        }
        setTotal(currentTotal.toString());

        return currentTotal;
    }, [props.margin?.directIndirectMarginTotal, props.margin.estimatedPricePercentageTotal, props.margin?.percentage, props.margin.pricePercentageTotal, props.marginOverrides?.estimatedQuantityEstimatedPriceOverride, props.marginOverrides?.quantityPriceOverride, props.totals?.directCostTotal, props.totals?.estimatedDirectCostTotal, props.totals?.estimatedIndirectCostTotal, props.totals?.indirectCostTotal])

    useEffect(() => {
        if (props.margin.description === 'Profit Margin' && (props.margin.calculationOption === CalculationOption.overrideQuantity || props.margin.calculationOption === CalculationOption.overrideEstimatedQuantity)) {
            setDisablePercentage(true);
        } else {
            setDisablePercentage(false);
        }
        const total = calculateTotal(props.margin);
        props.onUpdateTotal({id: props.margin.id, total: total});
    }, [calculateTotal, props, props.margin])

    const onPercentageChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const updatedMargin = { ...margin, percentage: (!isNaN(parseFloat(event.target.value))) ? parseFloat(event.target.value) : undefined };
        setMargin(updatedMargin);
        props.onChange(updatedMargin);
        const total = calculateTotal(updatedMargin);
        props.onUpdateTotal({id: props.margin.id, total: total});
    }, [calculateTotal, margin, props])

    const onDescriptionChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        const updatedMargin = { ...margin, description: event.target.value };
        if (updatedMargin) {
            setMargin(updatedMargin);
            props.onChange(updatedMargin);
        }
    }, [margin, props])

    const onPercentageOfChange = (event: SelectChangeEvent) => {
        const updatedMargin = { ...margin, percentageOf: (event.target.value as PercentageOf) };
        setMargin(updatedMargin);
        props.onChange(updatedMargin);
        const total = calculateTotal(updatedMargin);
        props.onUpdateTotal({id: props.margin.id, total: total});
    };

    const onDelete = useCallback(() => {
        if ('delete' in props && typeof props.delete === 'function' && margin) {
            props.delete(margin);
        }
    }, [margin, props])

    return <>{margin && <Box display="flex" alignItems="center" margin="20px">
        <Box width="15px" marginRight="10px">{props.index + 1}.</Box>
        <Box marginRight="15px" flex="1">
            <TextField size="small"
                fullWidth
                disabled={props.disabled}
                onChange={onDescriptionChange}
                InputProps={{
                    readOnly: props.margin.description === 'Profit Margin'
                }}
                value={margin.description}
            />
        </Box>
        <Box marginRight="15px">
            <TextField size="small"
                sx={{ width: "100px" }}
                required
                disabled={disablePercentage || props.disabled}
                onChange={onPercentageChange}
                value={props.margin.percentage}
                inputProps={{ type: 'number' }} />
        </Box>
        <Box marginRight="15px">
            % of
        </Box>
        <Box marginRight="15px">
            <FormControl fullWidth>
                <Select
                    native
                    fullWidth
                    required
                    sx={{ width: "150px" }}
                    size="small"
                    disabled={props.disabled}
                    value={props.margin.percentageOf}
                    onChange={onPercentageOfChange}>
                    <option value={''}></option>
                    {props.options.map((option) => {
                        return <option value={option.id}>{option.description}</option>
                    })}
                </Select>
            </FormControl>
        </Box>
        <Box marginRight="10px">
            =
        </Box>
        <Box>
            <TextField size="small"
                sx={{ width: "200px" }}
                value={(total && !isNaN(parseFloat(total))) ? (rounder(parseFloat(total), (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)) : total}
                inputProps={{ readOnly: true }} />
        </Box>
        <Box width="20px">
            {props.margin.description !== 'Profit Margin' && <IconButton aria-label="delete" onClick={onDelete}>
                <CloseIcon />
            </IconButton>}
        </Box>
    </Box>}
        {margin?.description === 'Profit Margin' && <>
            <Box marginLeft="45px">
                <Button color="primary" disabled={props.disabled} variant="outlined" onClick={props.addMargin} startIcon={<AddIcon />}>Add</Button>
            </Box>
        </>
        }
    </>;
}