import { Entity, EstimateUserRole } from "Models/estimate";

export interface CheckPermissionRequest{
    requiredPermissions: number[];
    entity: Entity;
}

export function hasEstimatePermission(userId: string, estimateUserRoles: EstimateUserRole[], request: CheckPermissionRequest){
    // If the user doesnt exist for the estimate then return false
    const estimateUser = estimateUserRoles.find((user) => (user.userId===userId));
    if (!estimateUser) return false;

    // Find the role perm record for the entity
    const estimateRolePerm = estimateUser.EstimateRole.EstimateRolePermission.filter(r => r.EstimateEntity.name===request.entity);
    if (!estimateRolePerm) return false;

    // Check if the permission is present
    const perms = estimateRolePerm.find(estimateRolePerm => request.requiredPermissions.includes(parseInt(estimateRolePerm.EstimatePermission.code)));
    if (!perms) return false;

    return true;
}