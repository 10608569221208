import { Alert, Box, IconButton, Typography, useTheme } from "@mui/material";
import { Estimate } from "Models/estimate";
import { useCallback, useState } from "react";
import { tokens } from "theme";
import CloseIcon from '@mui/icons-material/Close';
import CreateUpdateEstimate from "./CreateUpdateEstimate";
import { Errors } from "Models/errors";
import { useGetUserDetailsQuery } from "State/Services/user";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    p: 2,
};

export interface CreateEstimateModalProps {
    estimate: Estimate | undefined;
    selectedOption: "edit" | "duplicate";
    close: () => void;
    save: (estimate: Estimate) => Promise<void>;
}

export default function CreateUpdateEstimateModal(props: CreateEstimateModalProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [pageError, setPageError] = useState<string | undefined>();
    const { data: user } = useGetUserDetailsQuery();

    const handleClose = useCallback(() => {
        props.close();
    }, [props])

    const save = useCallback(async (estimate: Estimate) => {
        try {
            if (user) {
                await props.save(estimate);
                props.close();
            }
        } catch (error: any) {
            if ('name' in error.data) {
                throw error;
            }else{
                setPageError(Errors.generic);
            }
            
        }
    }, [props, user])

    return <Box sx={style}>
        <Box
            display="flex"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            marginBottom="10px"
            borderBottom={`1px solid ${colors?.gray[800]}`}>
            <Box padding="10px">
                {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>{props.selectedOption === "edit" ? (props.estimate?.id ? 'Edit estimate' : 'Create new estimate') : 'Duplicate estimate'}</Typography>}
            </Box>
            <Box>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>
        {pageError && <Box marginTop="10px" marginBottom="15px"><Alert severity="error">{pageError}</Alert></Box>}
        <CreateUpdateEstimate selectedOption={props.selectedOption} secondaryButtonText="Cancel" close={props.close} estimate={props.estimate} save={save} />
    </Box>
}