import { Box, Typography, useTheme } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { tokens } from "theme";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
    Link as RouterLink,
} from 'react-router-dom';
import RequestQuoteIcon from '@mui/icons-material/RequestQuoteOutlined';
import NodeAddedIcon from '@mui/icons-material/NoteAddOutlined';
import PriceChangeIcon from '@mui/icons-material/PriceChangeOutlined';
import SummarizeIcon from '@mui/icons-material/SummarizeOutlined';
import { useCallback, useState } from "react";
import SplitButton, { MenuOption } from "Components/SplitButton";
import { ProjectPrice } from "Components/Items/ProjectPrice";
import { useRouteMatch } from "Helpers/route";
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

export default function Estimate() {
    const navigate = useNavigate();
    const basePath = `organization/:organizationId/company/:companyId/estimate/:estimateId`;
    const { estimateId } = useParams();
    const { data: user } = useGetUserDetailsQuery();
    const { data } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const routeMatch = useRouteMatch([`${basePath}/resources`, `${basePath}/activities`, `${basePath}/direct-items`, `${basePath}/indirect-items`, `${basePath}/margin`, `${basePath}/pricing`, `${basePath}/reports`, `${basePath}/library`, `${basePath}/vendor`]);
    const currentTab = routeMatch?.pattern?.path;
    const [addMenuItems] = useState<Array<MenuOption>>([{ option: 'Resources', disabled: false, avatarText: "R", avatarColor: colors.blueAccent[500] }, { option: 'Activities', avatarText: 'A', avatarColor: colors.primary[300], disabled: false }, { option: 'Items', avatarText: "I", avatarColor: colors.purpleAccent[500], disabled: false }]);
    const [vendorMenuItems] = useState<Array<MenuOption>>([{ option: 'Vendors', disabled: false, icon: <HandshakeOutlinedIcon fontSize="small" /> }, { option: 'Subcontractor Quotes', icon: <EngineeringOutlinedIcon fontSize="small" />, disabled: false }, { option: 'Supplier Quotes', icon: <LocalShippingOutlinedIcon fontSize="small" />, disabled: false }]);

    const handleDropdownClose = useCallback((option?: string) => {
        navigate(`/organization/${user?.organizationId}/company/${user?.companyId}/estimate/${estimateId}/library`, { state: { tab: option } });
    }, [estimateId, navigate, user?.companyId, user?.organizationId]);

    const handleVendorsDropdownClose = useCallback((option?: string) => {
        navigate(`/organization/${user?.organizationId}/company/${user?.companyId}/estimate/${estimateId}/vendor`, { state: { tab: option } });
    }, [estimateId, navigate, user?.companyId, user?.organizationId]);

    const breadcrumbs = [
        <Link
            component={RouterLink}
            to={`/organization/${user?.organizationId}/company/${user?.companyId}/estimate/dashboard`}
            underline="hover"
            key="1"
            color="inherit"
        >
            Estimates
        </Link>,
        <Typography key="2" color="text.primary">
            {data?.name}
        </Typography>,
    ];

    return (
        <Box height="100%" width="100%" overflow="auto" borderLeft={`1px solid ${colors.gray[800]}`} sx={{ boxShadow: `${colors.gray[800]} 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`, }}>
            {currentTab && <>
                <Box justifyContent="space-between" display="flex" width="100%" alignItems='center' paddingLeft="24px" paddingRight="0px" borderBottom={`1px solid ${colors.gray[800]}`} height="57px">
                    <Breadcrumbs
                        sx={{
                            " & .MuiBreadcrumbs-li": {
                                color: `${colors.gray[500]}`
                            },
                            " & .MuiBreadcrumbs-separator": {
                                color: `${colors.gray[700]}`
                            }
                        }}
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        {breadcrumbs}
                    </Breadcrumbs>
                    <ProjectPrice estimateId={estimateId} />
                </Box>
                <Box display="flex" width="100%">
                    <Tabs
                        sx={{
                            height: "50px",
                            minHeight: '50px',
                            padding: '14px',
                            width: "100%",
                            alignItems: 'center',
                            " & .Mui-selected": {
                                borderRadius: '8px',
                                background: `${colors.primary[900]} !important`,
                                color: `${colors.primary[100]} !important`
                            },
                            " & .MuiTab-root": {
                                padding: '6px 10px',
                                minHeight: '30px',
                                height: '30px',
                                marginRight: '8px',
                                textTransform: 'none',
                                color: `${colors.gray[500]}`
                            },
                            borderBottom: `1px solid ${colors.gray[800]}`
                        }}
                        TabIndicatorProps={{
                            style: {
                                display: 'none'
                            }
                        }}
                        value={currentTab}
                        aria-label="Estimate"
                    >
                        <Tab icon={<RequestQuoteIcon />} iconPosition="start" label="Estimate" value={`${basePath}/direct-items`} to="direct-items" component={RouterLink} />
                        <Tab icon={<NodeAddedIcon />} iconPosition="start" label="Indirect" value={`${basePath}/indirect-items`} to="indirect-items" component={RouterLink} />
                        <Tab icon={<PriceChangeIcon />} iconPosition="start" label="Pricing" value={`${basePath}/pricing`} to="pricing" component={RouterLink} />
                        <Tab icon={<SummarizeIcon />} iconPosition="start" label="Reports" value={`${basePath}/reports`} to="reports" component={RouterLink} />
                    </Tabs>
                    <Box borderBottom={`1px solid ${colors.gray[800]}`} alignItems="center" display="flex" paddingRight="10px" gap="24px">
                        <SplitButton buttonHeight="35px" variant="outlined" options={vendorMenuItems} buttonText="Vendors" onButtonClick={handleVendorsDropdownClose} onMenuItemClick={handleVendorsDropdownClose} />
                        <SplitButton buttonHeight="35px" variant="outlined" options={addMenuItems} buttonText="Library" onButtonClick={handleDropdownClose} onMenuItemClick={handleDropdownClose} />
                    </Box>
                </Box>
            </>
            }
            <Outlet />
        </Box>
    )
}