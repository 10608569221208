import { Autocomplete, Avatar, Button, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, TextField, useTheme } from "@mui/material";
import { PackageItemDetails } from "Models/package";
import { FilterField } from "Models/resource";
import { SubcontractorPackageVendor } from "Models/subcontractor";
import { useGetActivitiesByFilterQuery, useGetActivitiesPagedQuery } from "State/Services/activity";
import { useGetResourcesByFilterQuery, useGetResourcesPagedQuery } from "State/Services/resource";
import { useAppendSubcontractorResourcesActivitiesMutation } from "State/Services/subcontractor";
import { useGetUserDetailsQuery } from "State/Services/user";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useRef, useState, useEffect, useCallback, SyntheticEvent } from "react";
import { tokens } from "theme";

export interface SubFilteredEntity {
    id?: string;
    description: string;
    displayId?: string;
    type?: "resource" | "activity";
}

export interface SubcontractorResourceActivityAppendProps {
    estimateId: string;
    packageVendor: SubcontractorPackageVendor;
    item: PackageItemDetails;
    disabled: boolean;
}

export default function SubcontractorResourceActivityAppend(props: SubcontractorResourceActivityAppendProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [selectedOption, setSelectedOption] = useState<"resource" | "activity">("resource");
    const [searchValue, setSearchValue] = useState<SubFilteredEntity>({ id: '', description: '' });
    const [options, setOptions] = useState<Array<SubFilteredEntity>>([]);
    const inputRef = useRef<any>();
    const [open, setOpen] = useState<boolean>(false);
    const { data: filteredResources } = useGetResourcesByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', field: FilterField.both, estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || selectedOption !== "resource" || !(searchValue && searchValue.description && searchValue.description.length > 1) });
    const { data: filteredActivities } = useGetActivitiesByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || selectedOption !== "activity" || !(searchValue && searchValue.description && searchValue.description.length > 1) });
    const { data: defaultResources } = useGetResourcesPagedQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: (props && props.estimateId) ? props.estimateId : '', skip: 0, take: 100 }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || selectedOption !== "resource" });
    const { data: defaultActivities } = useGetActivitiesPagedQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: (props && props.estimateId) ? props.estimateId : '', skip: 0, take: 100 }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || selectedOption !== "activity" });
    const [appendResourceActivity] = useAppendSubcontractorResourcesActivitiesMutation();

    useEffect(() => {
        if (filteredResources && filteredResources.length > 0) {
            setOptions(filteredResources.map((res) => ({
                description: `${res.displayId} - ${res.description}`,
                id: res.resourceId,
                displayId: res.displayId,
                type: "resource",
                disableSubElements: !res.isComposite
            })));
        }
    }, [filteredResources])

    useEffect(() => {
        if (filteredActivities && filteredActivities.length > 0) {
            setOptions(filteredActivities.map((act) => ({
                description: `${act.displayId} - ${act.description}`,
                id: act.activityId,
                displayId: act.displayId,
                type: "activity",
                disableSubElements: !act.hasResources
            })));
        }
    }, [filteredActivities])

    const setDefaultResources = useCallback(async () => {
        if (defaultResources && defaultResources?.length > 0) {
            setOptions(defaultResources.map((res) => ({
                description: `${res.displayId} - ${res.description}`,
                id: res.resourceId,
                displayId: res.displayId,
                type: "resource",
                disableSubElements: !res.isComposite
            })));
        }
    }, [defaultResources])

    const setDefaultActivities = useCallback(async () => {
        if (defaultActivities && defaultActivities.length > 0) {
            setOptions(defaultActivities.map((act) => ({
                description: `${act.displayId} - ${act.description}`,
                id: act.activityId,
                displayId: act.displayId,
                type: "activity",
                disableSubElements: !act.hasResources
            })));
        }
    }, [defaultActivities])

    useEffect(() => {
        setDefaultResources();
    }, [defaultResources, setDefaultResources])

    useEffect(() => {
        setDefaultActivities();
    }, [defaultActivities, setDefaultActivities])

    const populateDefaultValues = useCallback(async () => {
        switch (selectedOption) {
            case "resource":
                await setDefaultResources();
                break;
            case "activity":
                await setDefaultActivities();
                break;
            default:
                break;
        }
    }, [selectedOption, setDefaultActivities, setDefaultResources])

    useEffect(() => {
        populateDefaultValues();
    }, [populateDefaultValues])

    const handleOptionChange = useCallback((event: any) => {
        setSelectedOption(event.target.value as "resource" | "activity");
        setSearchValue({ id: '', description: '' });
        setOptions([]);
    }, [])

    const onInputChange = useCallback(async (event: SyntheticEvent, newValue: string | null) => {
        if (newValue) {
            setSearchValue({ description: newValue, type: selectedOption });
        } else {
            setSearchValue({ description: '' });
            setOpen(false);
            await populateDefaultValues();
        }
    }, [populateDefaultValues, selectedOption])


    const onChange = useCallback(async (event: SyntheticEvent, newValue: SubFilteredEntity) => {
        if (newValue) {
            setSearchValue(newValue);
        } else {
            setSearchValue({ id: '', description: '' });
        }
        setOpen(false);
    }, []);

    const handleClose = useCallback(async (event: any) => {
        if (searchValue?.type && user) {
            await appendResourceActivity({
                orgId: user.organizationId,
                companyId: user.companyId,
                estimateId: props.estimateId,
                packageId: props.packageVendor.subcontractorPackageId,
                itemId: props.item.itemId,
                subcontractorPackageVendorId: props.packageVendor.id,
                body: {
                    id: searchValue.id,
                    type: selectedOption
                }
            });
            setSearchValue({ id: '', description: '' });
        }
    }, [appendResourceActivity, props.estimateId, props.item.itemId, props.packageVendor.id, props.packageVendor.subcontractorPackageId, searchValue?.id, searchValue?.type, selectedOption, user]);

    return <>
        <FormControl sx={{
            marginRight: "10px", width: 180, height: 45, '& .MuiInputBase-root': {
                height: 45
            },
        }} size="small">
            <InputLabel id="option-multiple-icon-label">Select option</InputLabel>
            <Select
                disabled={props.disabled}
                size="small"
                sx={{
                    '& .MuiSelect-select': {
                        height: 45,
                        display: "flex",
                        alignItems: "center"
                    },
                    '& .MuiSelect-select .MuiListItemIcon-root': {
                        minWidth: "35px",
                        alignItems: "center",
                    }
                }}
                labelId="option-multiple-icon-label"
                value={selectedOption}
                onChange={handleOptionChange}
                input={<OutlinedInput label="Select option" />}
            >
                <MenuItem key="resource" value="resource">
                    <ListItemIcon>
                        <Avatar sx={{ bgcolor: colors.blueAccent[500], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">R</Avatar>
                    </ListItemIcon>
                    <ListItemText>Resource</ListItemText>
                </MenuItem>
                <MenuItem key="activity" value="activity">
                    <ListItemIcon>
                        <Avatar sx={{ bgcolor: colors.primary[300], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">A</Avatar>
                    </ListItemIcon>
                    <ListItemText>Activity</ListItemText>
                </MenuItem>
            </Select>
        </FormControl>
        <FormControl sx={{ width: 250, height: 45, marginRight: "10px" }} size="small">
            <Autocomplete
                disabled={props.disabled}
                fullWidth
                sx={{ height: "100%" }}
                disableClearable
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                value={searchValue}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.description ?? ''}
                filterOptions={(x) => x}
                size="small"
                onChange={onChange}
                onInputChange={onInputChange}
                options={options}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        sx={{ '& .MuiInputBase-root': { height: 45 } }}
                        inputRef={inputRef}
                        placeholder='Search...' />}
                renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.description, inputValue, { insideWords: true });
                    const parts = parse(option.description, matches);

                    return (
                        <li {...props}>
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        </li>
                    );
                }}
            />
        </FormControl>
        <FormControl>
            <Button variant="contained" disabled={!searchValue?.id || props.disabled} onClick={handleClose}>Add</Button>
        </FormControl>
    </>
}