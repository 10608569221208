import { forwardRef, useCallback, useEffect, useRef } from 'react';
import { Column, ICellRendererParams, IRowNode } from 'ag-grid-community';
import { Box, IconButton } from '@mui/material';
import { PackageItemDetailsView } from 'Models/package';
import CalculateIcon from '@mui/icons-material/Calculate';
export interface ISubcontractorPackageCellRendererParams extends ICellRendererParams<PackageItemDetailsView> {
    calculateRate: (node: IRowNode<PackageItemDetailsView>, column: Column<PackageItemDetailsView> | undefined) => void;
    package: () => PackageItemDetailsView;
}

export default forwardRef((props: ISubcontractorPackageCellRendererParams, ref) => {
    const buttonRef = useRef<any>();
    
    const calculate = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        props.calculateRate(props.node, props.column);
    }, [props])

    useEffect(() =>{
        if (buttonRef?.current){
            buttonRef.current.onclick = calculate;
        }
    }, [calculate])

    return <><Box display="flex" justifyContent="space-between">
        <Box>
            {props.value}
        </Box>
        <Box>
            <IconButton ref={buttonRef} color="primary" disabled={!props.package()?.id}>
                {<CalculateIcon />}
            </IconButton>
        </Box>
    </Box></>;
});