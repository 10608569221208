import { Box, Button, FormControl, IconButton, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Typography, useTheme } from "@mui/material";
import { tokens } from "theme";
import CloseIcon from '@mui/icons-material/Close';
import { useGetUserDetailsQuery } from "State/Services/user";
import { LoadingButton } from "@mui/lab";
import { useCallback, useState } from "react";
import SaveIcon from '@mui/icons-material/Save';
import { useGetEstimateQuery } from "State/Services/estimate";

export interface ProjectListReportDecimalsProps {
    save: (decimals: number | undefined) => void;
    close: () => void;
    estimateId?: string;
}

const decimalModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    p: 2,
};

export default function ProjectListReportDecimals(props: ProjectListReportDecimalsProps) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [selectedOption, setSelectedOption] = useState<number>(estimate?.CompanyCurrency?.Currency?.minorUnit ?? 0);
    const [loading] = useState(false);

    const handleOptionChange = useCallback((event: any) => {
        setSelectedOption(event.target.value);
    }, [])

    const submit = useCallback(async () => {
        props.save(selectedOption);
    }, [props, selectedOption])

    const cancel = useCallback(() => {
        props.close();
    }, [props])

    const generateMenuItems = useCallback(() => {
        const menuItems = [
            <MenuItem key={0} value={0}>
                <ListItemText>None</ListItemText>
            </MenuItem>
        ];
        if (estimate?.CompanyCurrency?.Currency?.minorUnit) {
            for (let i = 1; i <= estimate?.CompanyCurrency?.Currency?.minorUnit; i++) {
                menuItems.push(
                    <MenuItem key={i} value={i}>
                        <ListItemText>{i}</ListItemText>
                    </MenuItem>
                );
            }
        }
        return menuItems;
    }, [estimate?.CompanyCurrency?.Currency?.minorUnit]);

    return <Box sx={{ ...decimalModalStyle }}>
        <Box
            display="flex"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            marginBottom="10px"
            borderBottom={`1px solid ${colors?.gray[800]}`}>
            <Box padding="10px">
                {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Report Settings</Typography>}
            </Box>
            <Box>
                <IconButton aria-label="delete" onClick={props.close}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>
        <Box display="flex" flexDirection="column" gap="10px" alignItems="center">
            <Box display="flex">
                <FormControl sx={{
                    marginRight: "10px", width: 180, height: 45, '& .MuiInputBase-root': {
                        height: 45
                    },
                }} size="small">
                    <InputLabel id="option-multiple-icon-label">Rate Decimals</InputLabel>
                    <Select
                        size="small"
                        sx={{
                            '& .MuiSelect-select': {
                                height: 45,
                                display: "flex",
                                alignItems: "center"
                            },
                            '& .MuiSelect-select .MuiListItemIcon-root': {
                                minWidth: "35px",
                                alignItems: "center",
                            }
                        }}
                        labelId="option-multiple-icon-label"
                        value={selectedOption}
                        onChange={handleOptionChange}
                        input={<OutlinedInput label="Select option" />}
                    >
                        {generateMenuItems()}
                    </Select>
                </FormControl>
            </Box>
            <Box display="flex" justifyContent="space-evenly" gap="10px">
                <LoadingButton
                    onClick={submit}
                    loading={loading}
                    aria-label="loading"
                    endIcon={<SaveIcon />}
                    color="primary"
                    loadingPosition="end"
                    variant="contained">
                    Submit
                </LoadingButton>
                <Button variant="contained" sx={{ color: "black", backgroundColor: `${colors?.gray[1000]}` }} onClick={cancel}>Cancel</Button>
            </Box>
        </Box>
    </Box>
}