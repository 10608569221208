import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

// Define a type for the slice state
interface IndirectItemState {
  isRowValid: boolean;
  errorMessages: Array<string>;
}

// Define the initial state using that type
const initialState: IndirectItemState = {
    isRowValid: true,
    errorMessages: []
}

export const indirectItemSlice = createSlice({
  name: 'indirectItem',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setIndirectItemRowValidState: (state, action: PayloadAction<boolean>) =>{
        state.isRowValid  = action.payload;
    },
    setIndirectItemRowValidStateWithError: (state, action: PayloadAction<{isValid: boolean, errors: Array<string>}>) =>{
        state.isRowValid  = action.payload.isValid;
        state.errorMessages = action.payload.errors;
    },
    clearIndirectItemRowValidState: (state, action: PayloadAction) =>{
        state.isRowValid  = true;
        state.errorMessages = [];
    }
  },
})

export const { setIndirectItemRowValidStateWithError } = indirectItemSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectIndirectItemState = (state: RootState) => state.indirectItems;

export default indirectItemSlice.reducer