import { Alert, Box, Button, TextField } from "@mui/material";
import { Errors } from "Models/errors";
import { useGetSettingsQuery, useUpdateMasterSettingsMutation } from "State/Services/settings";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ChangeEvent, useCallback, useEffect, useState } from "react";

export default function Master() {
    const { data: user } = useGetUserDetailsQuery();
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [updateMasterSettings] = useUpdateMasterSettingsMutation();
    const [prefix, setPrefix] = useState<string>();
    const [pageError, setPageError] = useState<string | undefined>();

    useEffect(() => {
        if (settings) {
            setPrefix(settings.masterIdPrefix);
        }
    }, [settings])

    const onPrefixChanged = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setPrefix(event.target.value);
    }, [])

    const save = useCallback(async () => {
        try {
            if (user && prefix) {
                await updateMasterSettings({
                    orgId: user.organizationId,
                    companyId: user.companyId,
                    body: {
                        masterIdPrefix: prefix
                    }
                });
            }
        } catch (error) {
            setPageError(Errors.generic);
        }
    }, [prefix, updateMasterSettings, user])

    return <Box>
        {pageError && <Box margin="10px 20px 10px 20px"><Alert severity="error">{pageError}</Alert></Box>}
        <Box display="flex" width="100%" alignItems="center" padding="20px" gap="15px">
            <label htmlFor="prefix" style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>ID Prefix</label>
            <TextField
                sx={{ height: "40px" }}
                size="small"
                autoComplete="off"
                name="prefix"
                onChange={onPrefixChanged}
                value={prefix}
                placeholder="Prefix" />
            <Button variant="contained" disabled={!prefix} onClick={save}>Save</Button>
        </Box>
    </Box>
}