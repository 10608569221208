import { FilterModel } from "ag-grid-enterprise";
import { CompanyCurrency } from "./company-currency";
import { Currency } from "./currency";
import { FileDetails } from "./file";
import { Unit } from "./unit";
import { Owner } from "./user-details";

export interface BaseEstimate {
    name: string;
    projectName: string;
    noOfLevels: number;
    indirectItemNoOfLevels: number;
    area?: number;
    areaUnitId?: string;
    client?: string;
    companyCurrencyId: string;
    currencyExchangeRate?: number;
    unitIds: Array<string>;
    masterId?: string;
}

export interface CopyEstimate extends BaseEstimate {
    resources: boolean;
    activities: boolean;
    direct: boolean;
    indirect: boolean;
    items: boolean;
    margins: boolean;
    vendorQuotations: boolean;
    vendors: boolean;
    excludeDirectItemCostQuantities: boolean;
    excludeDirectItemQuantities: boolean;
    excludeIndirectItemCostQuantities: boolean;
    excludeIndirectItemQuantities: boolean;
    excludeResourceFactor: boolean;
    pricing: boolean;
    toMaster: boolean;
    fromMaster: boolean;
    isMasterDuplicate: boolean;
    description?: string;
}
export interface Estimate extends BaseEstimate {
    id: string;
    createDate?: Date;
    lastUpdated?: Date;
    areaUnit?: Unit;
    companyCurrencyOption?: CompanyCurrency & { currencyOption: string };
    File?: FileDetails[];
    CompanyCurrency?: CompanyCurrency & { Currency: Currency };
    unitIds: Array<string>;
    updateResourceBasicRates: boolean;
    isMaster?: boolean;
    masterEstimateId?: string;
    ownerId?: string;
    Owner?: Owner;
    EstimateUserRole?: EstimateUserRole[];
    itemStatusCounts?: ItemStatusCounts;
}

export interface ItemStatusCounts {
    notStarted: number;
    inProgress: number;
    completed: number;
  }

export interface EstimateView extends Estimate {
    actions: string;
    createdBy: string;
}
export interface EstimateCategory {
    id?: number;
    displayId?: number;
    level?: number;
    estimateId?: string;
    category: string;
    repetitions?: number;
    hasItems?: boolean;
}

export interface EstimateCategoryView extends EstimateCategory {
    actions?: string;
    errors?: Array<string>;
    isNew?: boolean;
}

export enum CategoryLevel {
    category1 = 1, category2 = 2, category3 = 3, category4 = 4
}

export interface IndirectItemCategory {
    id?: number;
    displayId?: number;
    level?: number;
    estimateId?: string;
    category: string;
    repetitions?: number;
    hasItems?: boolean;
}

export interface IndirectItemCategoryView extends IndirectItemCategory {
    actions?: string;
    errors?: Array<string>;
    isNew?: boolean;
}

export interface Markup {
    filters: FilterModel;
    markup: number | null;
}

export interface Lock {
    filter?: FilterModel;
    idsToUpdate?: Array<string>;
    lock: boolean;
}

export interface ClearPreliminaryRate {
    filter: FilterModel;
}

export interface MarkupDto {
    preliminaryRate?: number;
    markup?: number;
    locked: boolean;
}

export interface ChangeCurrency {
    companyCurrencyId?: string;
    unitIds?: Array<string>;
    packageIds: Array<string>;
}

export interface ChangeCurrencyView extends ChangeCurrency {
    companyCurrencyOption?: CompanyCurrency & { currencyOption: string };
    currencyExchangeRate?: string;
}

export interface EstimateUserRoleView {
    id: string;
    userId: string;
    user: string;
    role: string;
    actions: string;
}

export interface EstimateUserRole {
    id: string;
    estimateId: string;
    userId: string;
    estimateRoleId: string;
    EstimateRole: EstimateRole;
    User: EstimateUser;
}

export interface EstimateRole {
    id: string;
    name: string;
    EstimateRolePermission: EstimateRolePermission[];
}

export interface EstimateUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface EstimateRolePermission {
    estimateRoleId: string;
    estimateEntityId: string;
    estimatePermissionId: string;
    EstimateEntity: EstimateEntity;
    EstimatePermission: EstimatePermissionInfo;
}

export interface EstimateEntity {
    name: Entity;
}

export interface EstimatePermissionInfo {
    code: string;
}

export enum Entity {
    Activities = "Activities",
    Direct = "Direct",
    Indirect = "Indirect",
    Items = "Items",
    Pricing = "Pricing",
    Reports = "Reports",
    Resources = "Resources",
    SubcontractorQuotes = "Subcontractor Quotes",
    SupplierQuotes = "Supplier Quotes",
    Vendors = "Vendors"
}