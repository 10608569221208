import {
    forwardRef,
    memo,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Box, FormControl, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { tokens } from 'theme';
import { UserInfoView } from 'Models/user-details';
export interface UserRoleEditCellRendererParams extends ICellEditorParams<UserInfoView, string> {
    estimateId: string;
}

export default memo(
    forwardRef((props: UserRoleEditCellRendererParams, ref) => {
        const [colors, setColors] = useState<any>();
        const theme = useTheme();
        useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
        const [selectedType, setSelectedType] = useState<string | null>('Estimator');
        const refInput = useRef<any>(null);
        const [open, setOpen] = useState(false);

        const onChange = (event: SelectChangeEvent<typeof selectedType>) => {
            const { target: { value } } = event;
            setSelectedType(value);
        };

        /* Component Editor Lifecycle methods */
        useImperativeHandle(ref, () => {
            return {
                // the final value to send to the grid, on completion of editing
                getValue() {
                    return selectedType
                },
                setFocusOnAdd() {
                    if (refInput.current) {
                        refInput.current.focus();
                        handleOpen();
                    }
                }
            };
        });

        useEffect(() => {
            if (props.value) {
                setSelectedType(props.value);
            }
        }, [props.value])

        const handleClose = () => {
            setOpen(false);
          };
        
          const handleOpen = () => {
            setOpen(true);
          };

        return (
            <>{colors && <Box sx={{ width: "100%", height: "100% !important" }}>
                <FormControl variant="standard" sx={{ height: 'inherit', width: "100%" }}>
                    <Select
                        labelId="type-multiple-checkbox-label"
                        id="type-multiple-checkbox"
                        value={selectedType}
                        open={open}
                        ref={refInput}
                        onClose={handleClose}
                        onOpen={handleOpen}
                        onChange={onChange}
                        input={<OutlinedInput sx={{ height: "inherit", backgroundColor: "white" }} />}
                    >
                        {["Estimator", "Template Manager", "Admin"].map((name) => (
                            <MenuItem key={name} value={name}>
                                <ListItemText primary={name} sx={{ marginLeft: "2px" }} />
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>}
            </>
        );
    })
);
