import { Box, Button, FormControl, FormControlLabel, FormLabel, Modal, Radio, RadioGroup, useTheme } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { tokens } from "theme";

const currencyConversionStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    p: 4,
    height: '25%',
};

export interface CurrencyConversionModalProps {
    onSave: (convert?: boolean) => void;
}

export default function CurrencyConversionModal(props: CurrencyConversionModalProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [open, setOpen] = useState(false);

    const handleOpen = useCallback(() => {
        setOpen(true);
    }, []);

    const handleClose = () => {
        setOpen(false);
    };
    const [value, setValue] = useState('convert');

    useEffect(() => {
        return () => {
            setOpen(false);
        }
    }, [])

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    }, []);

    const save = useCallback(() => {
        props.onSave(value === 'convert');
    }, [props, value])

    const cancel = useCallback(() => {
        handleClose();
    }, [])

    return <>
        <Button color="primary" variant="contained" onClick={handleOpen}>Submit</Button>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="conversion-modal-title"
            aria-describedby="conversion-modal-description">
            <Box sx={currencyConversionStyle}>
                <FormControl>
                    <FormLabel sx={{marginBottom: "10px"}} id="convert-radio-buttons-group-label">Conversion options</FormLabel>
                    <RadioGroup
                        value={value}
                        onChange={handleChange}
                        aria-labelledby="convert-radio-buttons-group-label"
                        defaultValue="convert"
                        name="radio-buttons-group">
                        <FormControlLabel value="convert" control={<Radio />} label="Update the estimate currency and convert resources with the selected units" />
                        <FormControlLabel value="no-convert" control={<Radio />} label="Update the estimate currency without converting resource basic rates" />
                    </RadioGroup>
                </FormControl>
                <Box display="flex" justifyContent="space-evenly" marginTop="20px">
                    <Button variant="contained" onClick={save}>Save</Button>
                    <Button variant="contained" sx={{ color: "black", backgroundColor: `${colors?.gray[1000]}` }} onClick={cancel}>Cancel</Button>
                </Box>
            </Box>
        </Modal>
    </>;
}