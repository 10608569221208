import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { ResourceView } from 'Models/resource';

// Define a type for the slice state
interface SupplierPackageState {
  packageId: string | null | undefined;
  resources: ResourceView[];
  option: "create" | "edit" | "none";
}

// Define the initial state using that type
const initialState: SupplierPackageState = {
  packageId: undefined,
  resources: [],
  option: "none"
}

export const supplierState = createSlice({
  name: 'supplier',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSupplierState: (state, action: PayloadAction<SupplierPackageState>) => {
      state.resources = action.payload.resources;
      state.packageId = action.payload.packageId;
      state.option = action.payload.option;
    },
    clearSupplierState: (state) => {
      state.resources = [];
      state.packageId = undefined;
      state.option = "none";
    }
  },
})

export const { setSupplierState, clearSupplierState } = supplierState.actions

// Other code such as selectors can use the imported `RootState` type
export const selectSupplierState = (state: RootState) => state.supplier;

export default supplierState.reducer