import { Autocomplete, Avatar, Box, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, TextField, useTheme } from "@mui/material";
import SplitButton, { MenuOption } from "Components/SplitButton";
import { AddOptions } from "Models/button-options";
import { FilterField } from "Models/resource";
import { useGetActivitiesByFilterQuery, useLazyGetActivitiesPagedQuery } from "State/Services/activity";
import { useGetResourcesByFilterQuery, useLazyGetResourcesPagedQuery } from "State/Services/resource";
import { useAppendStandardItemActivityResourceMutation, useGetStandardItemByFilterQuery, useLazyGetStandardItemPagedQuery } from "State/Services/standard-item";
import { useGetUserDetailsQuery } from "State/Services/user";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useState, useRef, useEffect, useCallback, SyntheticEvent } from "react";
import { tokens } from "theme";


export interface FilteredEntity {
    id?: string;
    description: string;
    displayId?: string;
    type?: "resource" | "activity" | "item";
    disableSubElements?: boolean;
}

export interface StandardItemAppendProps {
    estimateId: string | undefined;
    standardItemId: string | undefined;
    disabled: boolean;
}

export default function StandardItemAppend(props: StandardItemAppendProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [selectedOption, setSelectedOption] = useState<"resource" | "activity" | "item">("resource");
    const [searchValue, setSearchValue] = useState<FilteredEntity | null>(null);
    const [options, setOptions] = useState<Array<FilteredEntity>>([]);
    const inputRef = useRef<any>();
    const [addMenuItems, setAddMenuItems] = useState<Array<MenuOption>>([{ option: AddOptions.AddElement, disabled: false }, { option: AddOptions.AddSubElements, disabled: false }]);
    const [open, setOpen] = useState<boolean>(false);
    const { data: filteredResources } = useGetResourcesByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', field: FilterField.both, estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || selectedOption !== "resource" || !(searchValue && searchValue.description && searchValue.description.length > 1) });
    const { data: filteredActivities } = useGetActivitiesByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || selectedOption !== "activity" || !(searchValue && searchValue.description && searchValue.description.length > 1) });
    const { data: filteredStandardItems } = useGetStandardItemByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', field: FilterField.both, estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || selectedOption !== "item" || !(searchValue && searchValue.description && searchValue.description.length > 1) });
    const [getResourcesPaged] = useLazyGetResourcesPagedQuery();
    const [getActivitiesPaged] = useLazyGetActivitiesPagedQuery();
    const [getStandardItemsPaged] = useLazyGetStandardItemPagedQuery();
    const [appendStandardItemResourceActivity] = useAppendStandardItemActivityResourceMutation();
    const [error, setError] = useState('');

    useEffect(() => {
        if (filteredResources && filteredResources.length > 0) {
            setOptions(filteredResources.map((res) => ({
                description: `${res.displayId} - ${res.description}`,
                id: res.resourceId,
                displayId: res.displayId,
                type: "resource",
                disableSubElements: !res.isComposite
            })));
        }
    }, [filteredResources])

    useEffect(() => {
        if (filteredActivities && filteredActivities.length > 0) {
            setOptions(filteredActivities.map((act) => ({
                description: `${act.displayId} - ${act.description}`,
                id: act.activityId,
                displayId: act.displayId,
                type: "activity",
                disableSubElements: !act.hasResources
            })));
        }
    }, [filteredActivities])

    useEffect(() => {
        if (filteredStandardItems && filteredStandardItems.length > 0) {
            const filtered = filteredStandardItems.filter((standardItem) => (standardItem.itemId !== props.standardItemId));
            setOptions(filtered.map((item) => ({
                description: `${item.displayId} - ${item.description}`,
                id: item.itemId,
                displayId: item.displayId,
                type: "item",
                disableSubElements: !item.hasResourcesActivities
            })));
        }
    }, [filteredStandardItems, props.standardItemId])

    const setDefaultResources = useCallback(async () => {
        const response = await getResourcesPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : ''
        }, true);
        if ('data' in response) {
            const results = response.data;
            if (results && results.length > 0) {
                setOptions(results.map((res) => ({
                    description: `${res.displayId} - ${res.description}`,
                    id: res.resourceId,
                    displayId: res.displayId,
                    type: "resource",
                    disableSubElements: !res.isComposite
                })));
            }
        }
    }, [getResourcesPaged, props, user])

    const setDefaultActivities = useCallback(async () => {
        const response = await getActivitiesPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : ''
        }, true);
        if ('data' in response) {
            const results = response.data;
            if (results && results.length > 0) {
                setOptions(results.map((act) => ({
                    description: `${act.displayId} - ${act.description}`,
                    id: act.activityId,
                    displayId: act.displayId,
                    type: "activity",
                    disableSubElements: !act.hasResources
                })));
            }
        }
    }, [getActivitiesPaged, props, user])

    const setDefaultStandardItems = useCallback(async () => {
        const response = await getStandardItemsPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : ''
        }, true);
        if ('data' in response) {
            const results = response.data;
            if (results && results.length > 0) {
                const filtered = results.filter((standardItem) => (standardItem.itemId !== props.standardItemId));
                setOptions(filtered.map((item) => ({
                    description: `${item.displayId} - ${item.description}`,
                    id: item.itemId,
                    displayId: item.displayId,
                    type: "item",
                    disableSubElements: !item.hasResourcesActivities
                })));
            }
        }
    }, [getStandardItemsPaged, props, user])

    const populateDefaultValues = useCallback(async () => {
        switch (selectedOption) {
            case "resource":
                await setDefaultResources();
                break;
            case "activity":
                await setDefaultActivities();
                break;
            case "item":
                await setDefaultStandardItems();
                break;
            default:
                break;
        }
    }, [selectedOption, setDefaultActivities, setDefaultResources, setDefaultStandardItems])

    useEffect(() => {
        populateDefaultValues();
    }, [populateDefaultValues])

    const handleOptionChange = useCallback((event: any) => {
        setError('');
        setSelectedOption(event.target.value as "resource" | "activity" | "item");
        const menuOption = addMenuItems.find((menuItem) => (menuItem.option === AddOptions.AddElement));
        if (menuOption) {
            menuOption.disabled = event.target.value === "item";
        }

        setAddMenuItems([...addMenuItems]);
        setSearchValue({ id: '', description: '' });
        setOptions([]);
    }, [addMenuItems])

    const onInputChange = useCallback(async (event: SyntheticEvent, newValue: string | null) => {
        setError('');
        if (newValue) {
            setSearchValue({ description: newValue, type: selectedOption });
        } else {
            setSearchValue({ description: '' });
            setOpen(false);
            await populateDefaultValues();
        }
    }, [populateDefaultValues, selectedOption])

    const onChange = useCallback(async (event: SyntheticEvent, newValue: FilteredEntity) => {
        setError('');
        if (newValue) {
            setSearchValue(newValue);
            const menuOption = addMenuItems.find((menuItem) => (menuItem.option === AddOptions.AddSubElements));
            if (menuOption) {
                menuOption.disabled = !!newValue.disableSubElements;
                setAddMenuItems([...addMenuItems]);
            }
        } else {
            setSearchValue({ id: '', description: '', disableSubElements: false });
        }
        setOpen(false);
    }, [addMenuItems]);

    const handleClose = useCallback(async (option?: string) => {
        try {
            if (searchValue?.type && user) {
                const menuSubOption = addMenuItems.find((menuItem) => (menuItem.option === AddOptions.AddSubElements));
                const menuAddOption = addMenuItems.find((menuItem) => (menuItem.option === AddOptions.AddElement));
                if (menuSubOption?.disabled && menuAddOption?.disabled) return;

                let optionToUse: 'sub-element' | 'element' = option === "Add sub-elements" ? 'sub-element' : 'element';
                if (selectedOption === "item") {
                    optionToUse = "sub-element";
                }
                await appendStandardItemResourceActivity({
                    orgId: user.organizationId,
                    standardItemId: props.standardItemId,
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    body: {
                        addOption: optionToUse,
                        id: searchValue.id,
                        type: selectedOption
                    }
                }).unwrap();
                setSearchValue({ id: '', description: '' });
            }
        } catch (error: any) {
            if (error && error.data && error.data.description) {
                setError(error.data.description);
            }
        }
    }, [addMenuItems, appendStandardItemResourceActivity, props.estimateId, props.standardItemId, searchValue?.id, searchValue?.type, selectedOption, user]);

    return <Box display="flex" flexDirection="column"><Box display="flex" alignItems="center">
        <FormControl sx={{
            marginRight: "10px", width: 180, height: 45, '& .MuiInputBase-root': {
                height: 45
            },
        }} size="small">
            <InputLabel id="option-multiple-icon-label">Select option</InputLabel>
            <Select
                size="small"
                sx={{
                    '& .MuiSelect-select': {
                        height: 45,
                        display: "flex",
                        alignItems: "center"
                    },
                    '& .MuiSelect-select .MuiListItemIcon-root': {
                        minWidth: "35px",
                        alignItems: "center",
                    }
                }}
                labelId="option-multiple-icon-label"
                value={selectedOption}
                disabled={props.disabled}
                onChange={handleOptionChange}
                input={<OutlinedInput label="Select option" />}
            >
                <MenuItem key="resource" value="resource">
                    <ListItemIcon>
                        <Avatar sx={{ bgcolor: colors.blueAccent[500], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">R</Avatar>
                    </ListItemIcon>
                    <ListItemText>Resource</ListItemText>
                </MenuItem>
                <MenuItem key="activity" value="activity">
                    <ListItemIcon>
                        <Avatar sx={{ bgcolor: colors.primary[300], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">A</Avatar>
                    </ListItemIcon>
                    <ListItemText>Activity</ListItemText>
                </MenuItem>
                <MenuItem key="item" value="item">
                    <ListItemIcon>
                        <Avatar sx={{ bgcolor: colors.purpleAccent[500], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">I</Avatar>
                    </ListItemIcon>
                    <ListItemText>Item</ListItemText>
                </MenuItem>
            </Select>
        </FormControl>
        <FormControl sx={{ width: 250, height: 45, marginRight: "10px" }} size="small">
            <Autocomplete
                fullWidth
                sx={{ height: "100%" }}
                disableClearable
                open={open}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                disabled={props.disabled}
                value={searchValue ?? undefined}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.description}
                filterOptions={(x) => x}
                size="small"
                onChange={onChange}
                onInputChange={onInputChange}
                options={options}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        sx={{ '& .MuiInputBase-root': { height: 45 } }}
                        inputRef={inputRef}
                        placeholder='Search...' />}
                renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.description, inputValue, { insideWords: true });
                    const parts = parse(option.description, matches);

                    return (
                        <li {...props}>
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        </li>
                    );
                }}
            />
        </FormControl>
        <FormControl>
            <SplitButton disabled={!searchValue?.id} buttonHeight="35px" options={addMenuItems} buttonText="Add" onButtonClick={handleClose} onMenuItemClick={handleClose} />
        </FormControl>
    </Box>
    {error && <Box color={colors.redAccent[400]}>{error}</Box>}
    </Box>
}