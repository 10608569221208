import { Box, Button, CircularProgress, IconButton, Link } from '@mui/material';
import { CustomHeaderProps } from 'ag-grid-react';
import { useCallback, useEffect, useState } from 'react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { PackageItemDetailsView } from 'Models/package';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { md5 } from 'js-md5';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { SupplierPackageVendor } from 'Models/supplier';
import { useGetSupplierPackageDetailsQuery, useGetSupplierPackageFilesQuery, useGetSupplierPackageVendorsQuery } from 'State/Services/supplier';
export interface SupplierPackageVendorHeaderProps extends CustomHeaderProps {
    vendorName: string;
    vendorHash: string;
    estimateId: string;
    packageVendors: () => SupplierPackageVendor[];
    package: () => PackageItemDetailsView;
    attach: (vendorHash: string) => void;
    openVendor: () => void;
    delete: (packageVendor: SupplierPackageVendor | null, vendorHash: string, local: boolean) => Promise<void>;
    packageId: string;
    disabled: boolean;
}
export default function SupplierPackageVendorHeader(props: SupplierPackageVendorHeaderProps) {
    const { data: user } = useGetUserDetailsQuery();
    const { data: packageDetails } = useGetSupplierPackageDetailsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', packageId: props.packageId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.packageId });
    const { data: packageVendors } = useGetSupplierPackageVendorsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', packageId: props.packageId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.packageId })
    const [packageVendor, setPackageVendor] = useState<SupplierPackageVendor>();
    const [canDelete] = useState(packageDetails?.packageResources?.some(p => p.isChecked && md5(p.vendorId ?? '') === props.vendorHash));
    const { data: files } = useGetSupplierPackageFilesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', supplierPackageVendorId: packageVendor?.id ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !packageVendor?.id });
    const [isDeleting, setIsDeleting] = useState(false);

    const attach = useCallback(() => {
        props.attach(props.vendorHash);
    }, [props])

    useEffect(() => {
        const packageVendors = props.packageVendors();
        if (packageVendors) {
            const packageVendor = packageVendors.find((ven) => (md5(ven.vendorId) === props.vendorHash));
            if (packageVendor) {
                setPackageVendor(packageVendor);
            }
        }
    }, [props, props.packageVendors, packageVendors])

    const deletePackageVendor = useCallback(async () => {
        setIsDeleting(true);
        if (packageVendor) {
            await props.delete(packageVendor, props.vendorHash, false);
        } else {
            await props.delete(null, props.vendorHash, true);
        }
        setIsDeleting(false);
    }, [packageVendor, props])

    return <Box display="flex" width="100%" alignItems="center" justifyContent="space-between">
        <Box alignContent="center" display="flex">
            <Box alignContent="center">{files && files?.length > 0 && <AttachFileIcon />}</Box>
            <Button component={Link} onClick={attach} disabled={!!props.package()?.packageId}>
                {props.vendorName}
            </Button>
        </Box>
        <Box width="200px">Factor: {packageVendor?.factor ?? 'Not set'}</Box>
        {!isDeleting && <IconButton aria-label="delete" size="small" onClick={deletePackageVendor} disabled={canDelete || props.disabled}>
            <DeleteOutlineIcon />
        </IconButton>}
        {isDeleting &&
            <Box sx={{ display: 'flex', alignItems: "center" }}>
                <CircularProgress size="1rem" />
            </Box>
        }
    </Box>
}