import { Box, IconButton, Menu, MenuItem } from "@mui/material";
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import { MasterView } from "Models/master";
import { useCallback, useEffect, useState } from "react";
import { useGetUserDetailsQuery } from "State/Services/user";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { hasSystemRolePermission } from "Helpers/role-permissions";

export enum MasterMenuOptions {
    newEdit = "newEdit",
    sharingPermissions = "sharing-permissions",
    duplicate = "duplicate",
    changeCurrency = "change-currency",
    delete = "delete",
}

export interface ICancelCellRendererProps extends ICellRendererParams<MasterView> {
    onAction: (node: IRowNode<MasterView>, option: MasterMenuOptions) => Promise<void>,
}

export default function SaveCancelMasterCellRenderer(props: ICancelCellRendererProps) {
    const { node } = props;
    const { data: user } = useGetUserDetailsQuery();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const [actionsDisabled, setActionsDisabled] = useState(false);
    const [sharingDisabled, setSharingDisabled] = useState(false);

    const handleMenuClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleMenuClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const onActionClick = (option: MasterMenuOptions) => {
        props.onAction(node, option);
        handleMenuClose();
    }

    useEffect(() =>{
        if (user && props.node.data){
            if (user.role==="Admin" || user.role==="Account Owner"){
                setActionsDisabled(false);
                setSharingDisabled(false);
                return;
            }
            const hasActionsAccess = hasSystemRolePermission(user, [201, 202]);
            setActionsDisabled(!(hasActionsAccess && user.userId===props.node.data.ownerId));
            const hasSharingAccess = hasSystemRolePermission(user, [203]);
            setSharingDisabled(!(hasSharingAccess && user.userId===props.node.data.ownerId));
        }
    }, [props.node, user])

    return (<>
{!node.group && node.data &&
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={menuOpen ? 'long-menu' : undefined}
                    aria-expanded={menuOpen ? 'true' : undefined}
                    aria-haspopup="true"
                    disabled={actionsDisabled}
                    onClick={handleMenuClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={handleMenuClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                    }}
                >
                    <MenuItem disabled={actionsDisabled} onClick={() => onActionClick(MasterMenuOptions.newEdit)}>Edit</MenuItem>
                    <MenuItem disabled={actionsDisabled || !props.data?.canDelete} onClick={() => onActionClick(MasterMenuOptions.delete)}>Delete</MenuItem>
                    <MenuItem disabled={actionsDisabled} onClick={() => onActionClick(MasterMenuOptions.duplicate)}>Duplicate</MenuItem>
                    <MenuItem disabled={actionsDisabled} onClick={() => onActionClick(MasterMenuOptions.changeCurrency)}>Change currency</MenuItem>
                    <MenuItem disabled={sharingDisabled} onClick={() => onActionClick(MasterMenuOptions.sharingPermissions)}>Sharing & Permissions</MenuItem>
                </Menu>
            </Box>}
    </>
    );
};
