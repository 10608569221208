import { Autocomplete, Box, Button, TextField, useTheme } from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { intersection } from "lodash";
import { ItemView } from "Models/item";
import { UserInfo } from "Models/user-details";
import { useCallback, useEffect, useState } from "react";
import { useAssignMutation } from "State/Services/item";
import { useGetUserDetailsQuery, useGetUsersForCompanyQuery } from "State/Services/user";
import { tokens } from "theme";

export interface AssignUsersProps {
    items: ItemView[];
    estimateId: string;
    closeModal: (deselectAll: boolean) => void;
}

export default function AssignUsers(props: AssignUsersProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const { data: user } = useGetUserDetailsQuery();
    const { data: usersInfos } = useGetUsersForCompanyQuery({ companyId: (user && user.companyId) ? user.companyId : '', orgId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [assignUsers] = useAssignMutation();
    const [users, setUsers] = useState<Array<UserInfo>>([]);
    const [selectedUsers, setSelectedUsers] = useState<Array<UserInfo>>([]);

    useEffect(() => {
        const selectedUsers = new Array<UserInfo>();
        const itemUserIds = new Array<Array<string>>();
        const userIds = usersInfos?.map((userInfo) => (userInfo.id));
        if (userIds && userIds.length > 0) {
            itemUserIds.push(userIds);
        }
        if (usersInfos && usersInfos.length > 0) {
            setUsers(usersInfos);
            props.items.forEach((item) => {
                if (item?.assignedTo && item?.assignedTo?.length > 0) {
                    itemUserIds.push(item.assignedTo.map((us) => (us.id)));
                }else{
                    itemUserIds.push(['']);
                }
            });

            if (itemUserIds.length > 1) {
                const intersections = intersection(...itemUserIds);
                intersections.forEach((userId) => {
                    const usr = usersInfos.find((uInfo) => (uInfo.id === userId));
                    if (usr) {
                        selectedUsers.push(usr);
                    }
                });
            }
        }
        setSelectedUsers(selectedUsers);
    }, [props.items, usersInfos])

    const save = useCallback(async () => {
        if (user && props.items && props.items.length > 0) {
            await assignUsers({
                companyId: user.companyId,
                estimateId: props.estimateId,
                orgId: user.organizationId,
                body: {
                    assignedTo: selectedUsers.map((user) => (user.id)),
                    itemIds: props.items.map((item) => (item.id ?? ''))
                }
            });
            props.closeModal(true);
        }
    }, [assignUsers, props, selectedUsers, user])

    const cancel = useCallback(() => {
        props.closeModal(false);
    }, [props])

    const onChange = useCallback((event: React.SyntheticEvent, newUsers: UserInfo[] | null) => {
        if (newUsers && newUsers.length > 0) {
            setSelectedUsers(newUsers);
        } else {
            setSelectedUsers([]);
        }
    }, [])

    return <Box margin="10px">
        <Box>
            <Autocomplete
                fullWidth
                multiple
                limitTags={2}
                value={selectedUsers}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                size="small"
                filterSelectedOptions
                onChange={onChange}
                options={users}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        placeholder='Select Users' />}
                renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.name, inputValue, { insideWords: true });
                    const parts = parse(option.name, matches);

                    return (
                        <li {...props}>
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        </li>
                    );
                }}
            />
        </Box>

        <Box display="flex" justifyContent="space-evenly" marginTop="20px">
            <Button variant="contained" onClick={save}>Save</Button>
            <Button variant="contained" sx={{ color: "black", backgroundColor: `${colors?.gray[1000]}` }} onClick={cancel}>Cancel</Button>
        </Box>
    </Box>
}