import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import { useCallback, useState } from "react";
import { ResourceView } from "Models/resource";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
export interface IResourceActionsCellRendererProps extends ICellRendererParams<ResourceView> {
    delete: (node: IRowNode<ResourceView>) => Promise<void>;
    disabled: () => boolean;
    save: (node: IRowNode<ResourceView>) => Promise<void>;
    cancel: (node: IRowNode<ResourceView>) => void;
}

export default function ResourceActionsCellRenderer(props: IResourceActionsCellRendererProps) {
    const { node } = props;
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    let editingCells = props.api.getEditingCells();
    let isCurrentRowEditing = editingCells.some((cell) => {
        return cell.rowIndex === node.rowIndex;
    });

    const deleteRow = async () => {
        try {
            if (isCurrentRowEditing) {
                props.cancel(node);
            } else {
                setIsDeleting(true);
                await props.delete(node);
                setIsDeleting(false);
            }
        } catch (error) {
            setIsDeleting(false);
            console.log(error);
        }
    }

    const saveRec = async () => {
        try {
            setIsSaving(true);
            await props.save(node);
            setIsSaving(false);
        } catch (error) {
            setIsSaving(false);
        }
    }

    const shouldDisable = useCallback(() => {
        if (isCurrentRowEditing) {
            return { state: false, message: "Cancel" }
        }

        if (!node.data?.canDelete || !!node.data.masterReferenceId || props.disabled()) {
            return { state: true, message: 'Deleting a resource associated with other elements is not permitted.' }
        }

        return { state: false, message: "Delete" }

    }, [isCurrentRowEditing, node.data?.canDelete, node.data?.masterReferenceId, props])

    return (<>
        {node.data &&
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                {isCurrentRowEditing && !isSaving &&
                    <IconButton aria-label="save" color="primary" onClick={saveRec} data-button-action="save">
                        <SaveIcon />
                    </IconButton>
                }
                {isSaving &&
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress size="1rem" />
                    </Box>
                }
                {!isDeleting && (isCurrentRowEditing || !props.node.isRowPinned()) &&
                    <Tooltip title={shouldDisable().message}>
                        <IconButton aria-label="delete" onClick={deleteRow} disabled={shouldDisable().state} data-button-action="cancel">
                            {isCurrentRowEditing && <CloseIcon />}
                            {!isCurrentRowEditing && <DeleteOutlineIcon />}
                        </IconButton>
                    </Tooltip>
                }
            </Box>}
    </>
    );
};
