import { useParams } from "react-router-dom";
import { useGetUserDetailsQuery } from "State/Services/user";
import { Alert, Backdrop, Box, Button, Drawer, FormControl, FormControlLabel, FormGroup, IconButton, InputAdornment, Switch, TextField, Typography, useTheme } from "@mui/material";
import { tokens } from "theme";
import { useCallback, useEffect, useState } from "react";
import { useGetMarginsQuery } from "State/Services/margin";
import CircularProgress from '@mui/material/CircularProgress';
import { Margin, MarginDetail, MarginOverrides, MarginView, PercentageOf, Totals } from "Models/margin";
import { useGetPricingQuery, useUpsertPricingMutation } from "State/Services/pricing";
import { useFormik } from "formik";
import { CalculationOption, Price, PriceView } from "Models/price";
import { v4 as uuidv4 } from 'uuid';
import { useGetEstimateQuery } from "State/Services/estimate";
import Markup from "./Markup";
import MarginInfo from "./MarginInfo";
import { rounder } from "Helpers/rounder";
import { useGetSettingsQuery } from "State/Services/settings";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import CalculateIcon from '@mui/icons-material/Calculate';
import PriceCheckOutlinedIcon from '@mui/icons-material/PriceCheckOutlined';
import { Errors } from "Models/errors";

const directOptions = [{
    description: 'Direct Cost',
    id: 'DirectCost'
},
{
    description: 'Indirect Cost',
    id: 'InDirectCost'
},
{
    description: 'Price',
    id: 'Price'
}];

const indirectOptions = [{
    description: 'Estimated Direct Cost',
    id: 'EstimatedDirectCost'
},
{
    description: 'Estimated Indirect Cost',
    id: 'EstimatedInDirectCost'
},
{
    description: 'Estimated Price',
    id: 'EstimatedPrice'
}];

export default function Pricing() {
    const { estimateId } = useParams();
    const { data: user } = useGetUserDetailsQuery();
    const theme = useTheme();
    const { data: marginData } = useGetMarginsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const colors = tokens(theme.palette.mode);
    const { data: price } = useGetPricingQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });
    const [updatePrice, { isLoading: isUpdatingPrice }] = useUpsertPricingMutation();
    const [openMargin, setOpenMargin] = useState(false);
    const [marginViews, setMarginViews] = useState<Array<MarginView>>([]);
    const [totals, setTotals] = useState<Totals | undefined>();
    const [options, setOptions] = useState<Array<{ id: string, description: string }>>();
    const [openMarkup, setOpenMarkup] = useState(false);
    const [totalPrice, setTotalPrice] = useState<number | undefined | null>();
    const [indirectMarginSum, setIndirectMarginSum] = useState<number | undefined>();
    const [priceLabel, setPriceLabel] = useState<string>("");
    const [directCostLabel, setDirectCostLabel] = useState<string>("");
    const [currentPriceLabel, setCurrentPriceLabel] = useState<string>("");
    const [marginOverrides, setMarginOverrides] = useState<MarginOverrides>({});
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const [editDisabled, setEditDisabled] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [calculationOption, setCalculationOption] = useState<CalculationOption>(CalculationOption.undefined);
    const [pageError, setPageError] = useState<string | undefined>();

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                setEditDisabled(true);
                setHasViewAccess(true);
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Pricing,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Pricing,
                    requiredPermissions: [502]
                });
                setEditDisabled(!hasEditAccess);
            }
        } else {
            setEditDisabled(true);
            setHasViewAccess(true);
        }
        setIsReady(true);
    }, [estimate, estimate?.EstimateUserRole, user])

    const calculateMarginAmount = useCallback((margin: Margin, marginDetail: MarginDetail) => {
        let marginTotal = 0;
        switch (marginDetail.percentageOf) {
            case PercentageOf.DirectCost:
                if (margin.percentage && price?.directItemsTotal) {
                    marginTotal += (margin.percentage / 100) * price.directItemsTotal;
                }
                break;
            case PercentageOf.InDirectCost:
                if (margin.percentage && price?.indirectItemsTotal) {
                    marginTotal += (margin.percentage / 100) * price.indirectItemsTotal;
                }
                break;
            case PercentageOf.EstimatedDirectCost:
                if (margin.percentage && price?.estimatedDirectItemsTotal) {
                    marginTotal += (margin.percentage / 100) * price.estimatedDirectItemsTotal;
                }
                break;
            case PercentageOf.EstimatedInDirectCost:
                if (margin.percentage && price?.estimatedIndirectItemsTotal) {
                    marginTotal += (margin.percentage / 100) * price.estimatedIndirectItemsTotal;
                }
                break;
            default:
                break;
        }

        return marginTotal;
    }, [price?.directItemsTotal, price?.estimatedDirectItemsTotal, price?.estimatedIndirectItemsTotal, price?.indirectItemsTotal])

    const calculateMargins = useCallback(() => {
        let quantityMarginTotal = 0;
        let estimatedMarginTotal = 0;
        let priceMarginPercentageTotal = 0;
        let estimatedPriceMarginPercentageTotal = 0;
        marginData?.forEach((margin) => {
            margin.marginDetails.forEach((marginDetail) => {
                switch (marginDetail.calculationOption) {
                    case CalculationOption.quantity:
                        if (marginDetail.percentageOf === PercentageOf.Price) {
                            priceMarginPercentageTotal += ((margin?.percentage ?? 0) / 100);
                        }
                        quantityMarginTotal += calculateMarginAmount(margin, marginDetail);
                        break;
                    case CalculationOption.estimatedQuantity:
                        if (marginDetail.percentageOf === PercentageOf.EstimatedPrice) {
                            estimatedPriceMarginPercentageTotal += ((margin?.percentage ?? 0) / 100);
                        }
                        estimatedMarginTotal += calculateMarginAmount(margin, marginDetail);
                        break;
                    case CalculationOption.overrideQuantity:
                    case CalculationOption.overrideEstimatedQuantity:
                    default:
                        break;
                }
            });
        });

        const priceMarginTotal = (((price?.directItemsTotal ?? 0) + (price?.indirectItemsTotal ?? 0) + quantityMarginTotal) / (1 - priceMarginPercentageTotal)) * (priceMarginPercentageTotal);
        const estimatedPriceMarginTotal = ((price?.estimatedDirectItemsTotal ?? 0) + (price?.estimatedIndirectItemsTotal ?? 0) + estimatedMarginTotal) / (1 - estimatedPriceMarginPercentageTotal) * estimatedPriceMarginPercentageTotal;

        return { quantityMarginTotalFinal: quantityMarginTotal + priceMarginTotal, estimatedMarginTotalFinal: estimatedMarginTotal + estimatedPriceMarginTotal };
    }, [calculateMarginAmount, marginData, price?.directItemsTotal, price?.estimatedDirectItemsTotal, price?.estimatedIndirectItemsTotal, price?.indirectItemsTotal])

    const getInitialValues = useCallback((price: Price): PriceView => {
        const totals = calculateMargins();
        const quantityPrice = (price.directItemsTotal ?? 0) + (price.indirectItemsTotal ?? 0) + totals.quantityMarginTotalFinal;
        const estimatedQuantityEstimatedPrice = (price.estimatedDirectItemsTotal ?? 0) + (price.estimatedIndirectItemsTotal ?? 0) + totals.estimatedMarginTotalFinal;
        let quantityOverridePriceDiff: number | undefined = undefined;
        let quantityOverridePriceMessage = "";
        if (price.quantityPriceOverride) {
            quantityOverridePriceDiff = (price.quantityPriceOverride ?? 0) - quantityPrice;
            quantityOverridePriceMessage = `${rounder(Math.abs(quantityOverridePriceDiff), (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)} ${quantityOverridePriceDiff > 0 ? 'above' : 'below'} calculated`;
        }
        let estQuantityPriceMessageDiff: number | undefined = undefined;
        let estQuantityPriceMessage = "";
        if (price.estimatedQuantityPrice) {
            estQuantityPriceMessageDiff = (price.estimatedQuantityPrice ?? 0) - quantityPrice;
            estQuantityPriceMessage = `${rounder(Math.abs(estQuantityPriceMessageDiff), (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)} ${estQuantityPriceMessageDiff > 0 ? 'above' : 'below'} calculated`;
        }
        let estQuantityOverridePriceMessageDiff: number | undefined = undefined;
        let estQuantityOverridePriceMessage = "";
        if (price.estimatedQuantityPriceOverride) {
            estQuantityOverridePriceMessageDiff = (parseFloat(price.estimatedQuantityPriceOverride) ?? 0) - quantityPrice;
            estQuantityOverridePriceMessage = `${rounder(Math.abs(estQuantityOverridePriceMessageDiff), (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)} ${estQuantityOverridePriceMessageDiff > 0 ? 'above' : 'below'} calculated`;
        }
        let quantityEstPriceMessageDiff: number | undefined = undefined;
        let quantityEstPriceMessage = "";
        if (price.quantityEstimatedPrice) {
            quantityEstPriceMessageDiff = (price.quantityEstimatedPrice ?? 0) - estimatedQuantityEstimatedPrice;
            quantityEstPriceMessage = `${rounder(Math.abs(quantityEstPriceMessageDiff), (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)} ${quantityEstPriceMessageDiff > 0 ? 'above' : 'below'} calculated`;
        }
        let quantityOverrideEstPriceMessageDiff: number | undefined = undefined;
        let quantityOverrideEstPriceMessage = "";
        if (price.quantityEstimatedPriceOverride) {
            quantityOverrideEstPriceMessageDiff = (parseFloat(price.quantityEstimatedPriceOverride) ?? 0) - estimatedQuantityEstimatedPrice;
            quantityOverrideEstPriceMessage = `${rounder(Math.abs(quantityOverrideEstPriceMessageDiff), (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)} ${quantityOverrideEstPriceMessageDiff > 0 ? 'above' : 'below'} calculated`;
        }
        let estQuantityOverrideEstPriceMessageDiff: number | undefined = undefined;
        let estQuantityOverrideEstPriceMessage = "";
        if (price.estimatedQuantityEstimatedPriceOverride) {
            estQuantityOverrideEstPriceMessageDiff = (price.estimatedQuantityEstimatedPriceOverride ?? 0) - estimatedQuantityEstimatedPrice;
            estQuantityOverrideEstPriceMessage = `${rounder(Math.abs(estQuantityOverrideEstPriceMessageDiff), (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)} ${estQuantityOverrideEstPriceMessageDiff > 0 ? 'above' : 'below'} calculated`;
        }
        return {
            id: price.id,
            directItemsTotal: price.directItemsTotal ?? 0,
            estimatedDirectItemsTotal: price.estimatedDirectItemsTotal ?? 0,
            estimatedIndirectItemsTotal: price.estimatedIndirectItemsTotal ?? 0,
            estimatedQuantityEstimatedPrice: estimatedQuantityEstimatedPrice,
            indirectItemsTotal: price.indirectItemsTotal,
            quantityMargin: totals.quantityMarginTotalFinal,
            quantityEstimatedMargin: totals.estimatedMarginTotalFinal,
            quantityPrice: quantityPrice,
            quantityPerArea: ((price.directItemsTotal ?? 0) + (price.indirectItemsTotal ?? 0) + totals.quantityMarginTotalFinal) / ((estimate?.area) ? estimate.area : 1),
            estimatedQuantityPerArea: ((price.estimatedDirectItemsTotal ?? 0) + (price.estimatedIndirectItemsTotal ?? 0) + totals.estimatedMarginTotalFinal) / ((estimate?.area) ? estimate.area : 1),
            quantityMarkup: ((price.indirectItemsTotal ?? 0) + totals.quantityMarginTotalFinal) / (price.directItemsTotal ? price.directItemsTotal : 1),
            estimatedQuantityMarkup: ((price.estimatedIndirectItemsTotal ?? 0) + totals.estimatedMarginTotalFinal) / (price.estimatedDirectItemsTotal ? price.estimatedDirectItemsTotal : 1),
            estimatedQuantityMarginOverride: price.estimatedQuantityMarginOverride ?? null,
            estimatedQuantityEstimatedPriceOverride: price.estimatedQuantityEstimatedPriceOverride,
            estimatedQuantityMarkupOverride: price.estimatedQuantityMarkupOverride,
            estimatedQuantityPerAreaOverride: price.estimatedQuantityPerAreaOverride,
            estimatedQuantityPriceOverride: price.estimatedQuantityPriceOverride,
            quantityEstimatedPriceOverride: price.quantityEstimatedPriceOverride,
            quantityMarginOverride: price.quantityMarginOverride,
            quantityMarkupOverride: price.quantityMarkupOverride,
            quantityPerAreaOverride: price.quantityPerAreaOverride,
            quantityPriceOverride: price.quantityPriceOverride,
            enablePriceOverride: price.enablePriceOverride ?? false,
            quantityEstimatedPrice: price.quantityEstimatedPrice,
            estimatedQuantityPrice: price.estimatedQuantityPrice,
            currentPrice: price.currentPrice,
            currentEstimatedPrice: price.currentEstimatedPrice,
            quantityOverridePriceMessage: { isAbove: (quantityOverridePriceDiff && quantityOverridePriceDiff > 0) ? true : false, message: quantityOverridePriceMessage },
            estQuantityPriceMessage: { isAbove: (estQuantityPriceMessageDiff && estQuantityPriceMessageDiff > 0) ? true : false, message: estQuantityPriceMessage },
            estQuantityOverridePriceMessage: { isAbove: (estQuantityOverridePriceMessageDiff && estQuantityOverridePriceMessageDiff > 0) ? true : false, message: estQuantityOverridePriceMessage },
            quantityEstPriceMessage: { isAbove: (quantityEstPriceMessageDiff && quantityEstPriceMessageDiff > 0) ? true : false, message: quantityEstPriceMessage },
            quantityOverrideEstPriceMessage: { isAbove: (quantityOverrideEstPriceMessageDiff && quantityOverrideEstPriceMessageDiff > 0) ? true : false, message: quantityOverrideEstPriceMessage },
            estQuantityOverrideEstPriceMessage: { isAbove: (estQuantityOverrideEstPriceMessageDiff && estQuantityOverrideEstPriceMessageDiff > 0) ? true : false, message: estQuantityOverrideEstPriceMessage },
        }
    }, [calculateMargins, estimate?.CompanyCurrency?.Currency?.minorUnit, estimate?.area])

    const { setValues, ...formik } = useFormik<PriceView>({
        initialValues: {
            id: uuidv4(),
            directItemsTotal: undefined,
            estimatedDirectItemsTotal: undefined,
            estimatedIndirectItemsTotal: undefined,
            indirectItemsTotal: undefined,
            estimatedQuantityMarginOverride: undefined,
            estimatedQuantityEstimatedPriceOverride: undefined,
            estimatedQuantityMarkupOverride: undefined,
            estimatedQuantityPerAreaOverride: undefined,
            estimatedQuantityPriceOverride: undefined,
            quantityEstimatedPriceOverride: undefined,
            quantityMarginOverride: undefined,
            quantityMarkupOverride: undefined,
            quantityPerAreaOverride: undefined,
            quantityPriceOverride: undefined,
            quantityMargin: undefined,
            estimatedQuantityEstimatedPrice: undefined,
            currentPrice: undefined,
            currentEstimatedPrice: undefined
        },
        onSubmit: async (price) => {
            await save(price);
        },
    });

    useEffect(() => {
        if (price) {
            setValues(getInitialValues(price));
        }
    }, [getInitialValues, price, setValues])

    useEffect(() => {
        if (marginData && marginData.length > 0) {
            setMarginViews(marginData.map((margin: Margin) => {
                return {
                    ...margin
                }
            }));
            if (price) {
                setTotals({
                    directCostTotal: price.directItemsTotal,
                    estimatedDirectCostTotal: price.estimatedDirectItemsTotal,
                    estimatedIndirectCostTotal: price.estimatedIndirectItemsTotal,
                    indirectCostTotal: price.indirectItemsTotal
                });
            }
        }
    }, [marginData, price, price?.directItemsTotal, price?.estimatedDirectItemsTotal, price?.estimatedIndirectItemsTotal, price?.indirectItemsTotal])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        formik.setFieldValue('enablePriceOverride', event.target.checked);
    };

    const save = useCallback(async (price: Price) => {
        try {
            if (price && user) {
                await updatePrice({
                    companyId: user.companyId,
                    estimateId: estimateId,
                    orgId: user.organizationId,
                    body: {
                        estimatedQuantityMarginOverride: price.estimatedQuantityMarginOverride ?? null,
                        estimatedQuantityEstimatedPriceOverride: price.estimatedQuantityEstimatedPriceOverride ?? null,
                        estimatedQuantityMarkupOverride: price.estimatedQuantityMarkupOverride ?? null,
                        estimatedQuantityPerAreaOverride: price.estimatedQuantityPerAreaOverride ?? null,
                        quantityMarginOverride: price.quantityMarginOverride ?? null,
                        quantityMarkupOverride: price.quantityMarkupOverride ?? null,
                        quantityPerAreaOverride: price.quantityPerAreaOverride ?? null,
                        quantityPriceOverride: price.quantityPriceOverride ?? null,
                        directItemsTotal: price.directItemsTotal ?? null,
                        indirectItemsTotal: price.indirectItemsTotal ?? null,
                        estimatedDirectItemsTotal: price.estimatedDirectItemsTotal ?? null,
                        estimatedIndirectItemsTotal: price.estimatedIndirectItemsTotal ?? null,
                        enablePriceOverride: price.enablePriceOverride,
                        id: price.id ?? uuidv4()
                    }
                });
            }
        } catch (error) {
            setPageError(Errors.generic);
        }

    }, [estimateId, updatePrice, user])

    const openMarkupView = useCallback((calculationOption: CalculationOption) => {
        setCalculationOption(calculationOption);
        switch (calculationOption) {
            case "quantity":
                setTotalPrice(formik.values.quantityPrice);
                setIndirectMarginSum((formik.values.indirectItemsTotal ?? 0) + (formik.values.quantityMargin ?? 0));
                setPriceLabel("Target Price");
                setDirectCostLabel("Net Direct Cost");
                setCurrentPriceLabel("Current Price");
                break;
            case "override-quantity":
                setTotalPrice(formik.values.quantityPriceOverride);
                setIndirectMarginSum((formik.values.indirectItemsTotal ?? 0) + (formik.values.quantityMarginOverride ?? 0));
                setPriceLabel("Target Price");
                setDirectCostLabel("Net Direct Cost");
                setCurrentPriceLabel("Current Price");
                break;
            case "estimated-quantity":
                setTotalPrice(formik.values.estimatedQuantityEstimatedPrice);
                setIndirectMarginSum((formik.values.estimatedIndirectItemsTotal ?? 0) + (formik.values.quantityEstimatedMargin ?? 0));
                setPriceLabel("Target Estimated Price");
                setDirectCostLabel("Net Estimated Direct Cost");
                setCurrentPriceLabel("Current Estimated Price");
                break;
            case "override-estimated-quantity":
                setTotalPrice(formik.values.estimatedQuantityEstimatedPriceOverride);
                setIndirectMarginSum((formik.values.estimatedIndirectItemsTotal ?? 0) + (formik.values.estimatedQuantityMarginOverride ?? 0))
                setPriceLabel("Target Estimated Price");
                setDirectCostLabel("Net Estimated Direct Cost");
                setCurrentPriceLabel("Current Estimated Price");
                break;
            default:
                break;
        }

        setOpenMarkup(true);

    }, [formik.values.quantityPrice, formik.values.indirectItemsTotal, formik.values.quantityMargin, formik.values.quantityPriceOverride, formik.values.quantityMarginOverride, formik.values.estimatedQuantityEstimatedPrice, formik.values.estimatedIndirectItemsTotal, formik.values.quantityEstimatedMargin, formik.values.estimatedQuantityEstimatedPriceOverride, formik.values.estimatedQuantityMarginOverride])

    const closeMarkupView = useCallback(() => {
        setOpenMarkup(false);
    }, [])

    const openMargins = useCallback((calculationOption: CalculationOption) => {
        setCalculationOption(calculationOption);
        setOptions((calculationOption === CalculationOption.quantity || calculationOption === CalculationOption.overrideQuantity) ? directOptions : indirectOptions);
        if (price && (calculationOption === CalculationOption.overrideQuantity || calculationOption === CalculationOption.overrideEstimatedQuantity)) {
            setMarginOverrides({
                quantityMarginOverride: price.quantityMarginOverride,
                quantityPriceOverride: price.quantityPriceOverride,
                estimatedQuantityMarginOverride: price.estimatedQuantityMarginOverride,
                estimatedQuantityEstimatedPriceOverride: price.estimatedQuantityEstimatedPriceOverride
            })
        } else {
            setMarginOverrides({});
        }

        setOpenMargin(true);

    }, [price])

    const handleFieldChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const priceFieldText = "Save to recalculate";
        let value: number | null = parseFloat(event.target.value);
        if (isNaN(value)) {
            value = null;
        }
        switch (event.target.name) {
            case "quantityMarginOverride":
                const price = (formik.values.directItemsTotal ?? 0) + (formik.values.indirectItemsTotal ?? 0) + (value ?? 0);
                formik.setFieldValue("quantityPriceOverride", price);
                formik.setFieldValue("quantityPerAreaOverride", price / ((estimate?.area && estimate.area > 0) ? estimate.area : 1));
                formik.setFieldValue("quantityMarkupOverride", (((formik.values.indirectItemsTotal ?? 0) + (value ?? 0)) / (formik.values.directItemsTotal ?? 1)) * 100);
                formik.setFieldValue("quantityEstimatedPriceOverride", priceFieldText);
                break;
            case "quantityPriceOverride":
                const margin = (value ?? 0) - ((formik.values.directItemsTotal ?? 0) + (formik.values.indirectItemsTotal ?? 0));
                formik.setFieldValue("quantityMarginOverride", margin);
                formik.setFieldValue("quantityPerAreaOverride", (value ?? 0) / ((estimate?.area && estimate.area > 0) ? estimate.area : 1));
                formik.setFieldValue("quantityMarkupOverride", (((formik.values.indirectItemsTotal ?? 0) + margin) / (formik.values.directItemsTotal ?? 1)) * 100);
                formik.setFieldValue("quantityEstimatedPriceOverride", priceFieldText);
                break;
            case "quantityPerAreaOverride":
                let priceCal = (value ?? 0) * ((estimate?.area && estimate.area > 0) ? estimate.area : 1);
                let marginCal = priceCal - ((formik.values.directItemsTotal ?? 0) + (formik.values.indirectItemsTotal ?? 0));
                formik.setFieldValue("quantityPriceOverride", priceCal);
                formik.setFieldValue("quantityMarginOverride", marginCal);
                formik.setFieldValue("quantityPerAreaOverride", priceCal / ((estimate?.area && estimate.area > 0) ? estimate.area : 1));
                formik.setFieldValue("quantityMarkupOverride", (((formik.values.indirectItemsTotal ?? 0) + marginCal) / (formik.values.directItemsTotal ?? 1)) * 100);
                formik.setFieldValue("quantityEstimatedPriceOverride", priceFieldText);
                break;
            case "quantityMarkupOverride":
                let marginMarkup = (((value ?? 0) / 100) * (formik.values.directItemsTotal ?? 0)) - (formik.values.indirectItemsTotal ?? 0);
                let priceMarkup = (formik.values.directItemsTotal ?? 0) + (formik.values.indirectItemsTotal ?? 0) + marginMarkup;
                formik.setFieldValue("quantityMarginOverride", marginMarkup);
                formik.setFieldValue("quantityPriceOverride", priceMarkup);
                formik.setFieldValue("quantityPerAreaOverride", priceMarkup / ((estimate?.area && estimate.area > 0) ? estimate.area : 1));
                formik.setFieldValue("quantityEstimatedPriceOverride", priceFieldText);
                break;
            case "estimatedQuantityMarginOverride":
                const estPrice = (formik.values.estimatedDirectItemsTotal ?? 0) + (formik.values.estimatedIndirectItemsTotal ?? 0) + (value ?? 0);
                formik.setFieldValue("estimatedQuantityEstimatedPriceOverride", estPrice);
                formik.setFieldValue("estimatedQuantityPerAreaOverride", estPrice / ((estimate?.area && estimate.area > 0) ? estimate.area : 1));
                formik.setFieldValue("estimatedQuantityMarkupOverride", (((formik.values.estimatedIndirectItemsTotal ?? 0) + (value ?? 0)) / (formik.values.estimatedDirectItemsTotal ?? 1)) * 100);
                formik.setFieldValue("estimatedQuantityPriceOverride", priceFieldText);
                break;
            case "estimatedQuantityEstimatedPriceOverride":
                const estMargin = (value ?? 0) - ((formik.values.estimatedDirectItemsTotal ?? 0) + (formik.values.estimatedIndirectItemsTotal ?? 0));
                formik.setFieldValue("estimatedQuantityMarginOverride", estMargin);
                formik.setFieldValue("estimatedQuantityPerAreaOverride", (value ?? 0) / ((estimate?.area && estimate.area > 0) ? estimate.area : 1));
                formik.setFieldValue("estimatedQuantityMarkupOverride", (((formik.values.estimatedIndirectItemsTotal ?? 0) + estMargin) / (formik.values.estimatedDirectItemsTotal ?? 1)) * 100);
                formik.setFieldValue("estimatedQuantityPriceOverride", priceFieldText);
                break;
            case "estimatedQuantityPerAreaOverride":
                let estimatedPriceCal = (value ?? 0) * ((estimate?.area && estimate.area > 0) ? estimate.area : 1);
                const estMarginCal = estimatedPriceCal - ((formik.values.estimatedDirectItemsTotal ?? 0) + (formik.values.estimatedIndirectItemsTotal ?? 0));
                formik.setFieldValue("estimatedQuantityEstimatedPriceOverride", estimatedPriceCal);
                formik.setFieldValue("estimatedQuantityMarginOverride", estMarginCal);
                formik.setFieldValue("estimatedQuantityPerAreaOverride", estimatedPriceCal / ((estimate?.area && estimate.area > 0) ? estimate.area : 1));
                formik.setFieldValue("estimatedQuantityMarkupOverride", (((formik.values.estimatedIndirectItemsTotal ?? 0) + estMarginCal) / (formik.values.estimatedDirectItemsTotal ?? 1)) * 100);
                formik.setFieldValue("estimatedQuantityPriceOverride", priceFieldText);
                break;
            case "estimatedQuantityMarkupOverride":
                const estMarginMarkup = (((value ?? 0) / 100) * (formik.values.estimatedDirectItemsTotal ?? 0)) - (formik.values.estimatedIndirectItemsTotal ?? 0);
                const estPriceMarkup = (formik.values.estimatedDirectItemsTotal ?? 0) + (formik.values.estimatedIndirectItemsTotal ?? 0) + estMarginMarkup;
                formik.setFieldValue("estimatedQuantityMarginOverride", estMarginMarkup);
                formik.setFieldValue("estimatedQuantityEstimatedPriceOverride", estPriceMarkup);
                formik.setFieldValue("estimatedQuantityPerAreaOverride", estPriceMarkup / ((estimate?.area && estimate.area > 0) ? estimate.area : 1));
                formik.setFieldValue("estimatedQuantityMarkupOverride", (((formik.values.estimatedIndirectItemsTotal ?? 0) + estMarginMarkup) / (formik.values.estimatedDirectItemsTotal ?? 1)) * 100);
                formik.setFieldValue("estimatedQuantityPriceOverride", priceFieldText);
                break;
            default:
                break;
        }
        formik.setFieldValue(event.target.name, value);
    }, [estimate?.area, formik])

    const onMarginClose = useCallback((calculationOption: CalculationOption) => {
        const marginTotal = calculateMargins();
        switch (calculationOption) {
            case 'quantity':
            case 'override-quantity':
                setOptions(directOptions);
                formik.setFieldValue('margin', marginTotal.quantityMarginTotalFinal);
                break;
            case 'estimated-quantity':
            case 'override-estimated-quantity':
                setOptions(indirectOptions);
                formik.setFieldValue('estimatedMargin', marginTotal.estimatedMarginTotalFinal);
                break;
            default:
                break;
        }
        setOpenMargin(false);
    }, [calculateMargins, formik])

    if (!isReady) {
        return <></>
    }

    if (!hasViewAccess) {
        return <Box m="10px">Unauthorized access.</Box>
    }

    return <>
        {!openMarkup && <form noValidate onSubmit={formik.handleSubmit} style={{ height: pageError ? "calc(100% - 188px)" : "calc(100% - 130px)" }}>
            {pageError && <Box margin="10px 20px 10px 20px"><Alert severity="error">{pageError}</Alert></Box>}
            <Box height="100%" sx={{ borderLeft: `1px solid ${colors.gray[800]}`, boxShadow: `${colors.gray[800]} 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`, }}>
                <Box margin="20px">
                    <Box display="flex" flex="1" flexDirection="column" marginBottom="10px">
                        <Box sx={{ fontWeight: 600, fontSize: "16px", color: `${colors.gray[100]} !important` }} flex="0.3">
                            Current Price
                        </Box>
                        <Box display="flex" flex="1" flexDirection="row" marginTop="10px" alignContent="center" alignItems="center">
                            <Box width="50%" marginRight="10px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    disabled
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    label="Based on quantities"
                                    value={rounder(formik.values.currentPrice ?? 0, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                                    id="currentPrice"
                                    name="currentPrice" />
                            </Box>
                            <Box width="50%" marginLeft="10px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    disabled
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    label="Based on estimated quantities"
                                    value={rounder(formik.values.currentEstimatedPrice ?? 0, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                                    id="currentEstimatedPrice"
                                    name="currentEstimatedPrice" />
                            </Box>
                        </Box>
                    </Box>

                    <Box marginBottom="20px" display="flex" justifyContent="space-between">
                        <Typography component="div" sx={{ display: "flex", alignItems: "center" }}>
                            <Box sx={{ fontWeight: 600, fontSize: "16px", color: `${colors.gray[100]} !important` }}>Target Price Scenarios</Box>
                        </Typography>
                        <Box>
                            <FormControl component="fieldset">
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        value="end"
                                        control={<Switch
                                            color="primary"
                                            checked={formik.values.enablePriceOverride}
                                            onChange={handleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />}
                                        label="Enable price override"
                                        labelPlacement="end"
                                    />
                                </FormGroup>
                            </FormControl>
                            <Button type="submit" variant="contained" disabled={editDisabled}>Save</Button>
                        </Box>
                    </Box>
                    <Box display="flex" flex="1" flexDirection="column" marginBottom="10px">
                        <Box display="flex" flex="1" flexDirection="row" alignContent="center" alignItems="center">
                            <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    disabled
                                    label="Direct"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    value={rounder(formik.values.directItemsTotal ?? 0, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                                    onChange={handleFieldChange}
                                    onBlur={formik.handleBlur}
                                    id="directItemsTotal"
                                    name="directItemsTotal" />

                            </Box>
                            {formik.values.enablePriceOverride && <Box flex="1" marginRight="20px"></Box>}
                            <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    disabled
                                    label="Direct (Est. Quantity)"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    value={rounder(formik.values.estimatedDirectItemsTotal ?? 0, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                                    onChange={handleFieldChange}
                                    onBlur={formik.handleBlur}
                                    id="estimatedDirectItemsTotal"
                                    name="estimatedDirectItemsTotal" />
                            </Box>
                            {formik.values.enablePriceOverride && <Box flex="1" marginRight="20px"></Box>}
                        </Box>
                    </Box>
                    <Box display="flex" flex="1" flexDirection="column" marginBottom="10px">
                        <Box display="flex" flex="1" flexDirection="row" alignContent="center" alignItems="center">
                            <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    disabled
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    label="Indirect"
                                    value={rounder(formik.values.indirectItemsTotal ?? 0, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                                    onChange={handleFieldChange}
                                    onBlur={formik.handleBlur}
                                    id="indirectItemsTotal"
                                    name="indirectItemsTotal" />

                            </Box>
                            {formik.values.enablePriceOverride && <Box flex="1" marginRight="20px"></Box>}
                            <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    disabled
                                    label="Indirect (Est. Quantity)"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    onChange={handleFieldChange}
                                    onBlur={formik.handleBlur}
                                    value={rounder(formik.values.estimatedIndirectItemsTotal ?? 0, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                                    id="estimatedIndirectItemsTotal"
                                    name="estimatedIndirectItemsTotal" />
                            </Box>
                            {formik.values.enablePriceOverride && <Box flex="1" marginRight="20px"></Box>}
                        </Box>
                    </Box>
                    <Box display="flex" flex="1" flexDirection="column" marginBottom="10px">
                        <Box display="flex" flex="1" flexDirection="row" alignContent="center" alignItems="center">
                            <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    disabled
                                    value={rounder(formik.values.quantityMargin ?? 0, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                                    onChange={handleFieldChange}
                                    id="QuantityMargin"
                                    label="Margin"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={() => { openMargins(CalculationOption.quantity) }} color="primary" sx={{ padding: "0px", paddingRight: "5px" }}>
                                                <CalculateIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                    name="quantityMargin" />
                            </Box>
                            {formik.values.enablePriceOverride && <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    value={formik.values.quantityMarginOverride || ''}
                                    disabled={editDisabled}
                                    onChange={handleFieldChange}
                                    fullWidth
                                    label="Override Margin"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={() => { openMargins(CalculationOption.overrideQuantity) }} color="primary" sx={{ padding: "0px", paddingRight: "5px" }}>
                                                <CalculateIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                    id="quantityMarginOverride"
                                    name="quantityMarginOverride"
                                    inputProps={{ type: 'number' }} />
                            </Box>}
                            <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    disabled
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    label="Margin"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={() => { openMargins(CalculationOption.estimatedQuantity) }} color="primary" sx={{ padding: "0px", paddingRight: "5px" }}>
                                                <CalculateIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                    value={(rounder(formik.values.quantityEstimatedMargin ?? 0, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)) || 0}
                                    onChange={handleFieldChange}
                                    id="quantityEstimatedMargin"
                                    name="quantityEstimatedMargin" />
                            </Box>
                            {formik.values.enablePriceOverride && <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={() => { openMargins(CalculationOption.overrideEstimatedQuantity) }} color="primary" sx={{ padding: "0px", paddingRight: "5px" }}>
                                                <CalculateIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                    disabled={editDisabled}
                                    label="Override Margin"
                                    value={formik.values.estimatedQuantityMarginOverride || ''}
                                    onChange={handleFieldChange}
                                    id="estimatedQuantityMarginOverride"
                                    name="estimatedQuantityMarginOverride"
                                    inputProps={{ type: 'number' }} />
                            </Box>}
                        </Box>
                    </Box>
                    <Box display="flex" flex="1" flexDirection="column" marginBottom="10px">
                        <Box display="flex" flex="1" flexDirection="row" alignContent="center" alignItems="center">
                            <Box flex="1" marginRight="20px">
                                <TextField size="small"
                                    value={rounder(formik.values.quantityPrice ?? 0, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                                    fullWidth
                                    disabled
                                    label="Price (Calculated)"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    id="quantityPrice"
                                    name="quantityPrice" />
                                <Box height="20px"></Box>
                            </Box>
                            {formik.values.enablePriceOverride && <Box flex="1" marginRight="20px">
                                <TextField size="small"
                                    value={formik.values.quantityPriceOverride || ''}
                                    disabled={editDisabled}
                                    onChange={handleFieldChange}
                                    onBlur={formik.handleBlur}
                                    label="Override Price"
                                    fullWidth
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    id="quantityPriceOverride"
                                    name="quantityPriceOverride"
                                    inputProps={{ type: 'number' }} />
                                <Box marginLeft="5px" height="20px" color={formik.values.quantityOverridePriceMessage?.isAbove ? colors.redAccent[500] : colors.greenAccent[400]}>{formik.values.quantityOverridePriceMessage?.message}</Box>
                            </Box>}
                            <Box flex="1" marginRight="20px">
                                <TextField size="small"
                                    fullWidth
                                    disabled
                                    label="Price"
                                    value={rounder(formik.values.estimatedQuantityPrice ?? 0, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    id="estimatedQuantityPrice"
                                    name="estimatedQuantityPrice" />
                                <Box marginLeft="5px" height="20px" color={formik.values.estQuantityPriceMessage?.isAbove ? colors.redAccent[500] : colors.greenAccent[400]}>{formik.values.estQuantityPriceMessage?.message}</Box>
                            </Box>
                            {formik.values.enablePriceOverride && <Box flex="1" marginRight="20px">
                                <TextField size="small"
                                    fullWidth
                                    disabled={true}
                                    value={formik.values.estimatedQuantityPriceOverride || ''}
                                    onChange={handleFieldChange}
                                    onBlur={formik.handleBlur}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    label="Price"
                                    id="estimatedQuantityPriceOverride"
                                    name="estimatedQuantityPriceOverride" />
                                <Box marginLeft="5px" height="20px" color={formik.values.estQuantityOverridePriceMessage?.isAbove ? colors.redAccent[500] : colors.greenAccent[400]}>{formik.values.estQuantityOverridePriceMessage?.message}</Box>
                            </Box>}
                        </Box>
                    </Box>
                    <Box display="flex" flex="1" flexDirection="column" marginBottom="10px">
                        <Box display="flex" flex="1" flexDirection="row" alignContent="center" alignItems="center">
                            <Box flex="1" marginRight="20px">
                                <TextField size="small"
                                    value={rounder(formik.values.quantityEstimatedPrice ?? 0, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                                    fullWidth
                                    label="Estimated Price"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    id="quantityEstimatedPrice"
                                    name="quantityEstimatedPrice"
                                    disabled />
                                <Box marginLeft="5px" height="20px" color={formik.values.quantityEstPriceMessage?.isAbove ? colors.greenAccent[400] : colors.redAccent[500]}>{formik.values.quantityEstPriceMessage?.message}</Box>
                            </Box>
                            {formik.values.enablePriceOverride && <Box flex="1" marginRight="20px">
                                <TextField size="small"
                                    fullWidth
                                    value={formik.values.quantityEstimatedPriceOverride}
                                    disabled={true}
                                    label="Estimated Price"
                                    onChange={handleFieldChange}
                                    onBlur={formik.handleBlur}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    id="quantityEstimatedPriceOverride"
                                    name="quantityEstimatedPriceOverride" />
                                <Box marginLeft="5px" height="20px" color={formik.values.quantityOverrideEstPriceMessage?.isAbove ? colors.greenAccent[400] : colors.redAccent[500]}>{formik.values.quantityOverrideEstPriceMessage?.message}</Box>
                            </Box>}
                            <Box flex="1" marginRight="20px">
                                <TextField size="small"
                                    fullWidth
                                    value={rounder(formik.values.estimatedQuantityEstimatedPrice ?? 0, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                                    disabled
                                    label="Estimated Price (Calculated)"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    id="estimatedQuantityEstimatedPrice"
                                    name="estimatedQuantityEstimatedPrice" />
                                <Box height="20px"></Box>
                            </Box>
                            {formik.values.enablePriceOverride && <Box flex="1" marginRight="20px">
                                <TextField size="small"
                                    fullWidth
                                    value={formik.values.estimatedQuantityEstimatedPriceOverride || ''}
                                    disabled={editDisabled}
                                    onChange={handleFieldChange}
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    label="Override Estimated Price"
                                    id="estimatedQuantityEstimatedPriceOverride"
                                    name="estimatedQuantityEstimatedPriceOverride"
                                    onBlur={formik.handleBlur}
                                    inputProps={{ type: 'number' }} />
                                <Box marginLeft="5px" height="20px" color={formik.values.estQuantityOverrideEstPriceMessage?.isAbove ? colors.greenAccent[400] : colors.redAccent[500]}>{formik.values.estQuantityOverrideEstPriceMessage?.message}</Box>
                            </Box>}
                        </Box>
                    </Box>
                    <Box display="flex" flex="1" flexDirection="column" marginBottom="10px">
                        <Box display="flex" flex="1" flexDirection="row" alignContent="center" alignItems="center">
                            <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    value={rounder(formik.values.quantityPerArea ?? 0, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                                    id="quantityPerArea"
                                    name="quantityPerArea"
                                    label="Per Area"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    fullWidth
                                    disabled />
                            </Box>
                            {formik.values.enablePriceOverride && <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    id="quantityPerAreaOverride"
                                    name="quantityPerAreaOverride"
                                    label="Override Per Area"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    value={formik.values.quantityPerAreaOverride || ''}
                                    disabled={editDisabled}
                                    onChange={handleFieldChange}
                                    onBlur={formik.handleBlur}
                                    inputProps={{ type: 'number' }} />

                            </Box>}
                            <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    id="estimatedQuantityPerArea"
                                    name="estimatedQuantityPerArea"
                                    label="Per Area (Est. Price)"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    value={rounder(formik.values.estimatedQuantityPerArea ?? 0, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                                    disabled />
                            </Box>
                            {formik.values.enablePriceOverride && <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    id="estimatedQuantityPerAreaOverride"
                                    name="estimatedQuantityPerAreaOverride"
                                    label="Override Per Area (Est. Price)"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    value={formik.values.estimatedQuantityPerAreaOverride || ''}
                                    disabled={editDisabled}
                                    onChange={handleFieldChange}
                                    onBlur={formik.handleBlur}
                                    inputProps={{ type: 'number' }} />
                            </Box>}
                        </Box>
                    </Box>
                    <Box display="flex" flex="1" flexDirection="column" marginBottom="10px">
                        <Box display="flex" flex="1" flexDirection="row" alignContent="center" alignItems="center">
                            <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    disabled
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    label="Markup"
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={() => { openMarkupView(CalculationOption.quantity) }} color="primary" sx={{ padding: "0px", paddingRight: "5px" }}>
                                                <PriceCheckOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                    id="quantityMarkup"
                                    name="quantityMarkup"
                                    value={`${rounder((formik.values.quantityMarkup) ? (formik.values.quantityMarkup * 100) : 0, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3)}%`} />
                            </Box>
                            {formik.values.enablePriceOverride && <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    value={formik.values.quantityMarkupOverride || ''}
                                    id="quantityMarkupOverride"
                                    name="quantityMarkupOverride"
                                    label="Override Markup"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={() => { openMarkupView(CalculationOption.overrideQuantity) }} color="primary" sx={{ padding: "0px", paddingRight: "5px" }}>
                                                <PriceCheckOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                    disabled={editDisabled}
                                    onChange={handleFieldChange}
                                    onBlur={formik.handleBlur}
                                    inputProps={{ type: 'number' }} />
                            </Box>}
                            <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    id="estimatedQuantityMarkup"
                                    name="estimatedQuantityMarkup"
                                    label="Markup"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={() => { openMarkupView(CalculationOption.estimatedQuantity) }} color="primary" sx={{ padding: "0px", paddingRight: "5px" }}>
                                                <PriceCheckOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                    value={`${rounder((formik.values.estimatedQuantityMarkup) ? (formik.values.estimatedQuantityMarkup * 100) : 0, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3)}%`}
                                    disabled />
                            </Box>
                            {formik.values.enablePriceOverride && <Box flex="1" marginRight="20px">
                                <TextField style={{ marginBottom: '10px' }} size="small"
                                    fullWidth
                                    id="estimatedQuantityMarkupOverride"
                                    name="estimatedQuantityMarkupOverride"
                                    label="Override Markup"
                                    sx={{
                                        "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: `${colors.gray[200]}`,
                                        },
                                    }}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={() => { openMarkupView(CalculationOption.overrideEstimatedQuantity) }} color="primary" sx={{ padding: "0px", paddingRight: "5px" }}>
                                                <PriceCheckOutlinedIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }}
                                    value={formik.values.estimatedQuantityMarkupOverride || ''}
                                    disabled={editDisabled}
                                    onChange={handleFieldChange}
                                    onBlur={formik.handleBlur}
                                    inputProps={{ type: 'number' }} />
                            </Box>}
                        </Box>
                    </Box>

                </Box>
            </Box>
            <Backdrop
                sx={{ color: colors?.primary[1000], zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isUpdatingPrice}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </form>
        }
        <Drawer
            anchor="right"
            open={openMargin}
            onClose={() => onMarginClose(calculationOption)}
        >
            {marginViews && options && <MarginInfo disabled={editDisabled} marginOverrides={marginOverrides} calculatedOption={calculationOption} totals={totals} margins={marginViews} estimateId={estimateId} options={options} />}
        </Drawer>
        {estimateId && openMarkup && <Markup currentPriceLabel={currentPriceLabel} directCostLabel={directCostLabel} priceLabel={priceLabel} selectedOption={calculationOption} indirectMarginSum={indirectMarginSum} targetPrice={totalPrice} onBackToPricing={closeMarkupView} estimateId={estimateId}></Markup>}
    </>
}