import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

// Define a type for the slice state
interface AppState {
  isAppValid: boolean;
}

// Define the initial state using that type
const initialState: AppState = {
    isAppValid: false
}

export const appStateSlice = createSlice({
  name: 'app',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setAppValidState: (state, action: PayloadAction<boolean>) =>{
        state.isAppValid  = action.payload;
    }
  },
})

export const { setAppValidState } = appStateSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectAppState = (state: RootState) => state.appState.isAppValid;

export default appStateSlice.reducer