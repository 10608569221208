import {
    forwardRef,
    memo,
    MutableRefObject,
    useCallback,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Autocomplete, Box } from '@mui/material';
import { IndirectItemCategory } from 'Models/estimate';
import { useGetUserDetailsQuery } from 'State/Services/user';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { GridTextField } from 'Components/GridTextField';
import { IndirectItemView } from 'Models/indirect-item';
import { useGetIndirectItemCategoriesQuery } from 'State/Services/indirect-item-category';

export interface IndirectItemCategoryEditCellRendererProps extends ICellEditorParams<IndirectItemView> {
    estimateId: string;
    placeholder: string;
    level: number;
    setRef: (ref: MutableRefObject<any>) => void;
}

export default memo(
    forwardRef((props: IndirectItemCategoryEditCellRendererProps, ref) => {
        const { data: user } = useGetUserDetailsQuery();
        const refInput = useRef<HTMLInputElement>();
        const { data: storedCategories } = useGetIndirectItemCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId });
        const [categories, setCategories] = useState<IndirectItemCategory[]>();
        const [open, setOpen] = useState(false);

        useEffect(() => {
            if (storedCategories && storedCategories.length > 0) {
                const cats = storedCategories.filter((c) => (c.level === props.level));
                setCategories(cats);
            }
        }, [props.level, storedCategories])

        const getInitialValue = useCallback((params: ICellEditorParams<IndirectItemView, IndirectItemCategory>) => {
            let value: IndirectItemCategory | null = null;
            const cat = storedCategories?.find((cat) => (cat.displayId === props.value && cat.level === props?.level));
            if (cat) {
                value = cat;
            }
            return value;
        }, [props?.level, props.value, storedCategories]);

        const [value, setValue] = useState<IndirectItemCategory | null>(getInitialValue(props));

        /* Component Editor Lifecycle methods */
        useImperativeHandle(ref, () => {
            return {
                // the final value to send to the grid, on completion of editing
                getValue() {
                    return value?.displayId;
                },
                focusIn() {
                    refInput.current?.focus();
                    handleOpen();
                },
                setFocusOnAdd() {
                    if (refInput.current) {
                        refInput.current.focus();
                        refInput.current.select();
                        setOpen(true);
                    }
                },
            };
        });

        const handleCategoryChanged = useCallback((event: React.SyntheticEvent<Element, Event>, value: IndirectItemCategory | null) => {
            setValue(value);
        }, [])

        useEffect(() => {
            if (refInput) {
                props.setRef(refInput);
            }
        }, [props])

        const handleClose = () => {
            setOpen(false);
        };

        const handleOpen = () => {
            setOpen(true);
        };

        return (
            <Box sx={{ width: "100%", height: "100% !important" }}>
                <Autocomplete
                    className="ag-input-field-input ag-text-field-input"
                    sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                    value={value ?? null}
                    isOptionEqualToValue={(option, value) => {
                        return option.id === value.id;
                    }}
                    fullWidth
                    size="small"
                    onOpen={handleOpen}
                    onClose={handleClose}
                    open={open}
                    componentsProps={{ popper: { style: { width: 'fit-content' } } }}
                    getOptionLabel={(option) => `${option.displayId} - ${option.category}` ?? ""}
                    options={categories ?? []}
                    onChange={handleCategoryChanged}
                    renderInput={(params) => <GridTextField sx={{ height: "100%" }} inputRef={refInput} placeholder={`Select ${props.placeholder}`} {...params} />}
                    renderOption={(props, option, { inputValue }) => {
                        const matches = match(`${option.displayId} - ${option.category}`, inputValue, {
                            insideWords: true,
                        });
                        const parts = parse(`${option.displayId} - ${option.category}`, matches);

                        return (
                            <li {...props}>
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                />
            </Box>
        );
    })
);
