import { Box, Link } from '@mui/material';
import { hasEstimatePermission } from 'Helpers/estimate-permissions';
import { Entity } from 'Models/estimate';
import { useGetEstimateQuery } from 'State/Services/estimate';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { CustomHeaderProps } from 'ag-grid-react';
import { useEffect, useState } from 'react';

export interface MarkupEstimatedRateHeaderProps extends CustomHeaderProps {
    clear: () => void;
    estimateId: string;
}

export default function MarkupEstimatedRateHeader(props: MarkupEstimatedRateHeaderProps) {
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [editDisabled, setEditDisabled] = useState(false);

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                setEditDisabled(true);
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Pricing,
                    requiredPermissions: [502]
                });
                setEditDisabled(!hasEditAccess);
            }
        } else {
            setEditDisabled(true);
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    return <Box display="flex" flexDirection="column" justifyItems="space-between">
        <Box>{props.displayName}</Box>
        <Link component="button" disabled={editDisabled} onClick={props.clear} sx={{ cursor: "pointer", width: "40px" }}>Clear</Link>
    </Box>
}