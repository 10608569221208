import { Box, FormControl, OutlinedInput, InputAdornment, List, ListItem, useTheme } from "@mui/material";
import { ICellEditorParams } from "ag-grid-enterprise";
import { HtmlTooltip } from "Components/HtmlToolltip";
import { memo, forwardRef, useState, useMemo, useRef, useCallback, useImperativeHandle } from "react";
import { tokens } from "theme";
import WarningIcon from '@mui/icons-material/Warning';
import { StandardItemCategoryView } from "Models/standard-item-category";

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';

export interface StandardItemCategoryDescriptionEditCellRendererParams extends ICellEditorParams<StandardItemCategoryView> {
    estimateId: string;
}

export default memo(
    forwardRef((props: StandardItemCategoryDescriptionEditCellRendererParams, ref) => {
        const [colors, setColors] = useState<any>();
        const theme = useTheme();
        useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
        const [error, setError] = useState<string | undefined>(undefined);

        const createInitialState = () => {
            let startValue;
            let highlightAllOnFocus = true;
            const eventKey = props.eventKey;

            if (eventKey === KEY_BACKSPACE) {
                // if backspace or delete pressed, we clear the cell
                startValue = '';
            } else if (eventKey && eventKey.length === 1) {
                // if a letter was pressed, we start with the letter
                startValue = props.eventKey;
                highlightAllOnFocus = false;
            } else {
                // otherwise we start with the current value
                startValue = props.value;
                if (eventKey === KEY_F2) {
                    highlightAllOnFocus = false;
                }
            }

            if (props.node.isRowPinned()){
                startValue = '';
            }
            
            return {
                value: startValue,
                highlightAllOnFocus,
            };
        };

        const initialState = createInitialState();
        const [value, setValue] = useState(initialState.value);
        const refInput = useRef<any>(null);

        const onChange = useCallback((value: string) => {
            setValue(value);
        }, [])

        /* Component Editor Lifecycle methods */
        useImperativeHandle(ref, () => {
            return {
                // the final value to send to the grid, on completion of editing
                getValue() {
                    return value?.toString();
                },
                setFocusOnAdd() {
                    if (refInput.current) {
                        refInput.current.focus();
                        refInput.current.select();
                    }
                },
                setError(message: string) {
                    if (message) {
                        setError(message);
                    }
                },
                setInputValue(value: string) {
                    setValue(value);
                }
            };
        });

        return (
            <Box sx={{ width: "100%", height: "100% !important" }}>
                <FormControl sx={{ height: 'inherit', width: "100%" }}>
                    <OutlinedInput
                        sx={{ height: "inherit", backgroundColor: "white" }}
                        className="ag-input-field-input ag-text-field-input"
                        placeholder="Description"
                        onChange={(event) => onChange(event.target.value)}
                        endAdornment={error &&
                            <InputAdornment position="end">
                                <HtmlTooltip color={colors?.redAccent[500]}
                                    title={
                                        <List sx={{ listStyleType: 'disc', pl: 1 }}>
                                            <ListItem
                                                sx={{
                                                    padding: 0,
                                                    textAlign: "center",
                                                    listStyleType: "disc",
                                                    display: "list-item",
                                                }}
                                            >{error}</ListItem>
                                        </List>
                                    }>
                                    <WarningIcon sx={{ color: `${colors?.redAccent[500]}`, cursor: "pointer" }} />
                                </HtmlTooltip>
                            </InputAdornment>
                        }
                        value={value}
                        inputRef={refInput}
                    />
                </FormControl>
            </Box>
        );
    })
);
