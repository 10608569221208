import { forwardRef, useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { Tag, VendorView } from 'Models/vendor';
import { Chip } from '@mui/material';
import { take } from 'lodash';

export default forwardRef((props: ICellRendererParams<VendorView, Tag[]>, ref) => {
    const [displayed, setDisplayed] = useState<Tag[]>();
    const [count, setCount] = useState<number | undefined>()

    useEffect(() =>{
        if (props.value) {
            const displayed = take(props.value, 2);
            setDisplayed(displayed);
            if ((props.value.length - displayed.length) > 0) {
                setCount(props.value.length - displayed.length);
            }else{
                setCount(undefined);
            }
        }
    }, [props.value])

    return <>{displayed?.map((vendor, index) => {
        return <Chip label={vendor.description} sx={{ marginRight: "5px" }} key={index}/>
    })}{count && `+${count}`}</>;
});