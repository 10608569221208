import { PackageInfoView } from "Models/package";
import { useDeleteSubcontractorPackageMutation, useGetPackagesQuery } from "State/Services/subcontractor";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ColDef, ICellRendererParams, IRowNode } from "ag-grid-enterprise";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import SubcontractorListSaveCancelCellRenderer from "./SubcontractorListSaveCancelCellRenderer";
import { Box, Button, Link } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import AddIcon from '@mui/icons-material/Add';
import SubcontractorPackage from "./SubcontractorPackage";
import { rounder } from "Helpers/rounder";
import { useGetEstimateQuery } from "State/Services/estimate";
import { selectSubcontractorState } from "State/subcontractorSlice";
import { useAppSelector } from "State/hooks";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";

export interface SubcontractorListProps {
    estimateId?: string;
}

export default function SubcontractorList(props: SubcontractorListProps) {
    const subcontractorState = useAppSelector(selectSubcontractorState);
    const { data: user } = useGetUserDetailsQuery();
    const { data: packages } = useGetPackagesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId })
    const [rowData, setRowData] = useState<PackageInfoView[]>();
    const [showAdd, setShowAdd] = useState<boolean>(false);
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [packageId, setPackageId] = useState<string>();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [deleteSubcontractorPackage] = useDeleteSubcontractorPackageMutation();
    const hasAddEditAccessRef = useRef<boolean>();
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                hasAddEditAccessRef.current = false;
                setHasViewAccess(true);
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.SubcontractorQuotes,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.SubcontractorQuotes,
                    requiredPermissions: [502]
                });
                hasAddEditAccessRef.current = hasEditAccess;
            }
        } else {
            hasAddEditAccessRef.current = false;
        }
        setIsReady(true);
    }, [estimate, estimate?.EstimateUserRole, user])
    
    useEffect(() => {
        if (packages && packages.length > 0) {
            setRowData(packages.map((pckg) => ({ ...pckg, actions: '' })));
        } else {
            setRowData([]);
        }
    }, [packages])

    useEffect(() =>{
        if (subcontractorState && subcontractorState?.option){
            if (subcontractorState?.option==="edit"){
                setShowEdit(true);
            }else if (subcontractorState?.option==="create"){
                setShowAdd(true);
            }
        }
    }, [subcontractorState])

    const deletePackage = useCallback(async (node: IRowNode<PackageInfoView>) => {
        if (user && node.data){
            await deleteSubcontractorPackage({
                companyId: user.companyId,
                estimateId: props.estimateId,
                orgId: user.organizationId,
                packageId: node.data.id
            });
        }
    }, [deleteSubcontractorPackage, props.estimateId, user])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const deletePackageRef = useRef<any>();
    deletePackageRef.current = deletePackage;

    const showPackage = useCallback((packageId?: string) => {
        setPackageId(packageId);
        setShowEdit(true);
    }, [])

    const showAddPackage = useCallback(() => {
        setPackageId(undefined);
        setShowEdit(true);
    }, [])

    const [columnDefs] = useState<ColDef<PackageInfoView>[]>([
        { field: 'id', hide: true },
        { field: 'name', headerName: 'Name', flex: 1, cellRenderer: (params: ICellRendererParams<PackageInfoView>) => (<>{params.data && <Link component="button" onClick={() => showPackage(params.data?.id)} variant="body2">{params.data.name}</Link>}</>) },
        { field: 'noOfItems', headerName: 'No of Items', width: 200 },
        { field: 'noOfSubcontractors', headerName: 'No of Subcontractors', width: 200 },
        { field: 'totalAmount', headerName: 'Amount', width: 400, valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2), },
        {
            field: 'actions',
            width: 80,
            headerName: 'Actions',
            menuTabs: [],
            cellStyle: { textAlign: "left", padding: "0px" } as any,
            cellRenderer: SubcontractorListSaveCancelCellRenderer,
            cellRendererParams: {
                delete: (node: IRowNode<PackageInfoView>) => deletePackageRef.current(node),
                disabled: () => !hasAddEditAccessRef.current
            }
        },
    ]);

    const backToList = useCallback(() =>{
        setShowAdd(false);
        setShowEdit(false);
    }, [])

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);
    
    if (!isReady){
        return <></>
    }

    if (!hasViewAccess){
        return <Box m="10px">Unauthorized access.</Box>
    }

    return <>
        {!(showAdd || showEdit) && <Box height="100%" width="100%">
            <Button disabled={!hasAddEditAccessRef.current} startIcon={<AddIcon />} variant="contained" sx={{marginBottom: "10px"}} onClick={showAddPackage}>New Quote Package</Button>
            <Box height="calc(100% - 32px)" width="100%" className="ag-theme-alpine ag-theme-bidbow">
                <AgGridReact<PackageInfoView>
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection={'single'}
                    animateRows={true}
                />
            </Box>
        </Box>}
        {showAdd && <SubcontractorPackage backToList={backToList} estimateId={props.estimateId} />}
        {showEdit && <SubcontractorPackage backToList={backToList} estimateId={props.estimateId} packageId={packageId} />}
    </>
}