const pastelColors = [
    'rgba(246, 167, 157, 1)', // Muted Coral
    'rgba(163, 228, 215, 1)', // Pastel Mint
    'rgba(209, 167, 184, 1)', // Dusty Rose
    'rgba(192, 169, 217, 1)', // Soft Lavender
    'rgba(243, 230, 160, 1)', // Pale Lemon
    'rgba(161, 198, 234, 1)', // Muted Sky Blue
    'rgba(178, 216, 216, 1)', // Faded Aqua
    'rgba(215, 197, 163, 1)', // Warm Sand
    'rgba(167, 199, 183, 1)', // Light Moss Green
    'rgba(241, 193, 193, 1)', // Pale Rose
    'rgba(178, 199, 217, 1)', // Powder Blue
    'rgba(211, 183, 214, 1)', // Pale Lilac
    'rgba(167, 185, 179, 1)', // Faded Teal
    'rgba(246, 224, 164, 1)', // Soft Butter Yellow
    'rgba(166, 183, 215, 1)', // Soft Periwinkle
    'rgba(192, 225, 245, 1)', // Soft Celeste
    'rgba(247, 199, 156, 1)', // Candlelight Orange
    'rgba(209, 168, 193, 1)', // Muted Lavender Pink
    'rgba(167, 209, 183, 1)', // Dusty Mint
    'rgba(184, 169, 201, 1)', // Faded Lavender
    'rgba(211, 198, 182, 1)', // Light Taupe
    'rgba(197, 216, 164, 1)', // Muted Pistachio
    'rgba(240, 229, 216, 1)', // Ivory Beige
    'rgba(208, 185, 143, 1)', // Soft Khaki
    'rgba(232, 232, 232, 1)', // Cloud White
    'rgba(181, 181, 181, 1)', // Smoky Grey
    'rgba(166, 184, 159, 1)', // Pale Sage Green
    'rgba(209, 166, 183, 1)', // Mauve Pink
    'rgba(241, 197, 216, 1)', // Powder Pink
    'rgba(250, 217, 194, 1)', // Pale Peach
];

// Set up chart data dynamically
export const getChartData = (label: string, chartInfo: Array<{ label: string, data: number }>): any => {
    const backgroundColors: string[] = [];
    for (let i = 0; i < chartInfo.length; i++) {
        backgroundColors.push(pastelColors[i % pastelColors.length]); // Cycle through the colors list
    }
    return {
        labels: chartInfo.map((label) => (label.label)),
        datasets: [
            {
                label: label,
                data: chartInfo.map((chartData) => (chartData.data)),
                backgroundColor: backgroundColors,
                borderColor: backgroundColors,
                borderWidth: 1,
            }
        ],
    };
};