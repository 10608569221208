import { Box, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from "react";
import { EstimateUserRoleView } from "Models/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";

export interface IDeleteRoleCellRendererProps extends ICellRendererParams<EstimateUserRoleView> {
    delete: (node: IRowNode<EstimateUserRoleView>) => Promise<void>;
}

export default function DeleteRoleCellRenderer(props: IDeleteRoleCellRendererProps) {
    const { node } = props;
    const [isDeleting, setIsDeleting] = useState(false);
    const { data: user } = useGetUserDetailsQuery();

    const deleteRec = async () => {
        setIsDeleting(true);
        await props.delete(node);
        setIsDeleting(false);
    }

    return (<>
        <Box display="flex" alignItems="center" justifyContent="center">
            {!isDeleting &&
                <span>
                    <IconButton disabled={node.data?.userId===user?.userId} aria-label="delete" onClick={deleteRec}>
                        <CloseIcon />
                    </IconButton>
                </span>
            }
            {isDeleting &&
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress size="1rem" />
                </Box>
            }
        </Box>
    </>
    );
};
