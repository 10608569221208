import { LoadingButton } from "@mui/lab";
import { Autocomplete, Box, Button, Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, TextField, useTheme } from "@mui/material";
import { BulkVendorDto, Tag, VendorView } from "Models/vendor";
import { useGetTagsQuery } from "State/Services/tag";
import { useGetUserDetailsQuery } from "State/Services/user";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useCallback, useMemo, useState } from "react";
import { tokens } from "theme";
import SaveIcon from '@mui/icons-material/Save';
import { useBulkUpdateVendorMutation } from "State/Services/vendor";

export interface VendorEditProps {
    estimateId: string;
    vendors: VendorView[];
    closeModal: () => void;
}

export default function VendorEdit(props: VendorEditProps) {
    const theme = useTheme();
    const [colors, setColors] = useState<any>();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const { data: user } = useGetUserDetailsQuery();
    const { data: existingTags } = useGetTagsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [propertySelected, setPropertySelected] = useState('');
    const [tags, setTags] = useState<Tag[]>();
    const [isSaving, setIsSaving] = useState(false);
    const [bulkUpdate] = useBulkUpdateVendorMutation();
    const [selectedType, setSelectedType] = useState<string[]>([]);

    const handleChange = useCallback((event: SelectChangeEvent) => {
        setTags([]);
        setSelectedType([]);
        setPropertySelected(event.target.value as string);
    }, [])

    const handleOnTagChanged = useCallback((event: React.SyntheticEvent, newTags: Tag[] | null) => {
        if (newTags && newTags.length > 0) {
            setTags(newTags);
        } else {
            setTags([]);
        }
    }, [])

    const onTypeChange = (event: SelectChangeEvent<typeof selectedType>) => {
        const {
            target: { value },
        } = event;
        setSelectedType(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const onSubmit = useCallback(async () => {
        if (user) {
            setIsSaving(true);
            const bulkUpdateDto: BulkVendorDto = {
                ids: []
            };
            props.vendors.forEach((vendor) => {
                if (vendor.id) {
                    bulkUpdateDto.ids.push(vendor.id);
                }
            });
            if (propertySelected === 'Type') {
                bulkUpdateDto.type = selectedType.join(",");
            } else if (propertySelected === 'Tags') {
                bulkUpdateDto.tags = tags;
            }
            await bulkUpdate({
                body: bulkUpdateDto,
                companyId: user.companyId,
                estimateId: props.estimateId,
                orgId: user.organizationId
            });
            setIsSaving(false);
            props.closeModal();
        }
    }, [bulkUpdate, propertySelected, props, selectedType, tags, user]);

    return <>{colors && <Box margin="40px">
        <Box marginBottom="20px">
            <FormControl fullWidth>
                <InputLabel id="prop-label">Select Property</InputLabel>
                <Select
                    labelId="prop-label"
                    value={propertySelected}
                    label="Select Property"
                    onChange={handleChange}
                >
                    <MenuItem value='Type'>Type</MenuItem>
                    <MenuItem value='Tags'>Tags</MenuItem>
                </Select>
            </FormControl>
        </Box>
        {propertySelected === "Type" && <Box marginBottom="20px">
            <FormControl variant="standard" sx={{ height: 'inherit', width: "100%" }}>
                <Select
                    size="small"
                    multiple
                    labelId="type-select-label"
                    id="type-simple-select"
                    label="Type"
                    value={selectedType}
                    onChange={onTypeChange}
                    renderValue={(selected) => selected.join(', ')}
                    input={<OutlinedInput sx={{ height: "inherit", backgroundColor: "white" }} />}
                >
                    {["Subcontractor", "Supplier"].map((name) => (
                        <MenuItem key={name} value={name}>
                            <Checkbox checked={selectedType.indexOf(name) > -1} />
                            <ListItemText primary={name} sx={{ marginLeft: "2px" }} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>}
        {propertySelected === "Tags" && <Box marginBottom="20px">
            <Autocomplete
                fullWidth
                size="small"
                className="ag-input-field-input ag-text-field-input"
                renderTags={(value: readonly Tag[], getTagProps) =>
                    value.map((option: Tag, index: number) => (
                        <Chip variant="outlined" label={option.description} {...getTagProps({ index })} />
                    ))
                }
                multiple
                limitTags={2}
                onChange={handleOnTagChanged}
                options={existingTags ?? []}
                isOptionEqualToValue={(tag, tg) => (tag.id === tg.id)}
                renderInput={(params) => <TextField placeholder='Select/Enter Tag' {...params} />}
                renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.description, inputValue, {
                        insideWords: true,
                    });
                    const parts = parse(option.description, matches);

                    return (
                        <li {...props}>
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        </li>
                    );
                }}
            />
        </Box>}
        <Box display="flex" justifyContent="center">
            <LoadingButton
                variant="contained"
                sx={{ marginRight: "10px", backgroundColor: `${colors?.primary[400]}` }}
                disabled={!((selectedType && selectedType.length>0) || (tags && tags.length > 0))}
                type="submit"
                endIcon={<SaveIcon />}
                loading={isSaving}
                loadingPosition="end"
                onClick={onSubmit}>
                Update
            </LoadingButton>

            <Button variant="contained" color="secondary"
                sx={{ backgroundColor: `${colors?.gray[1000]}` }} onClick={props.closeModal}>Cancel</Button>
        </Box>
    </Box>}</>
}