import 'react-pro-sidebar/dist/css/styles.css';
import { tokens } from '../../theme';
import { Box, Typography, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ProSidebar, Menu, MenuItem, SidebarFooter } from 'react-pro-sidebar';
import { Link, matchRoutes, useLocation } from 'react-router-dom';
import { UserDetails } from 'Models/user-details';
import React from 'react';
import { ReactComponent as Bwc } from './Black and White Collection 2.svg';
import MuiMenu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth0 } from '@auth0/auth0-react';
import mainLogo from './logo-minimal.jpg';
import BookIcon from '@mui/icons-material/BookOutlined';
import RequestQuoteIcon from '@mui/icons-material/RequestQuoteOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import { selectAppState } from 'State/appStateSlice';
import { useAppSelector } from 'State/hooks';
import { useLogOffMutation } from 'State/Services/user';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

export type SidebarProps = {
    state: 'expand' | 'collapse';
    userDetails: UserDetails | undefined;
}

export type ItemOptions = {
    title: string;
    to: string;
    icon: React.ReactNode;
    selected: string;
    setSelected: (title: string) => void;
}

const Item = ({ title, to, icon, selected, setSelected }: ItemOptions) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (<MenuItem
        active={selected === title}
        style={{ color: colors.gray[100] }}
        onClick={() => setSelected(title)}
        icon={icon}>
        <Typography>{title}</Typography>
        <Link to={to} />
    </MenuItem>);
}

const routeEnum = {
    MasterLibrary: "organization/:organizationId/company/:companyId/master/*",
    Estimates: "organization/:organizationId/company/:companyId/estimate/*",
    Settings: "organization/:organizationId/company/:companyId/settings/*"
}

const routes = [
    {
        key: 'Estimates',
        path: routeEnum.Estimates
    },
    {
        key: 'Template Library',
        path: routeEnum.MasterLibrary
    },
    {
        key: 'Settings',
        path: routeEnum.Settings
    }
];
const Sidebar = ({ state, userDetails }: SidebarProps) => {
    const location = useLocation();
    const [user, setUser] = useState<UserDetails | undefined>(userDetails);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [selected, setSelected] = useState("Estimates");
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { logout } = useAuth0();
    const isAppValid = useAppSelector(selectAppState);
    const open = Boolean(anchorEl);
    const [logOff] = useLogOffMutation();

    useEffect(() => {
        const matches = matchRoutes(routes, location.pathname);

        if (matches) {
            const matchedRoute = matches.find(route => route.route.key);
            setSelected(matchedRoute ? matchedRoute.route.key : '');
        } else {
            setSelected('');
        }
    }, [location.pathname]);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const appLogout = useCallback(async () => {
        if (user) {
            await logOff({
                companyId: user?.companyId ?? '',
                orgId: user?.organizationId ?? ''
            });
            setAnchorEl(null);
            logout({ logoutParams: { returnTo: window.location.origin } });
        }
        handleClose();
    }, [logOff, logout, user])

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (userDetails) {
            setUser(userDetails);
        }
    }, [userDetails])

    return <>{isAppValid && <Box sx={{
        boxShadow: `${colors.gray[800]} 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`,
        borderTop: `1px solid ${colors.gray[800]}`,
        " & .pro-sidebar-inner": {
            background: `${colors?.primary[1000]} !important`
        },
        "& .pro-sidebar-layout": {
            justifyContent: "space-between"
        },
        "& .pro-inner-item": {
            padding: "5px 35px 5px 20px !important",
        },
        "& .pro-menu-item": {
            color: `${colors.gray[400]} !important`
        },
        "& .pro-inner-item:hover": {
            color: `${colors.primary[400]} !important`
        },
        "& .pro-menu-item.active": {
            color: `${colors?.primary[1000]} !important`,
            "& .pro-icon-wrapper": {
                backgroundColor: `${colors.primary[400]} !important`,
                borderRadius: "9px"
            },
        }
    }}>

        <Box height="56px" display="flex" justifyContent="center" alignItems="center" sx={{ backgroundColor: "white" }}>
            <img src={mainLogo} style={{ width: "45px", height: "45px" }} alt="Bidbow" />
        </Box>
        <ProSidebar collapsed={true} style={{ height: 'calc(100% - 56px)' }}>
            <Menu>
                <Box>
                    <Item title="Template Library" to={`organization/${user?.organizationId}/company/${user?.companyId}/master/dashboard`} icon={<BookIcon />} selected={selected} setSelected={setSelected} />
                    <Item title="Estimates" to={`organization/${user?.organizationId}/company/${user?.companyId}/estimate/dashboard`} icon={<RequestQuoteIcon />} selected={selected} setSelected={setSelected} />
                    <Item title="Settings" to={`organization/${user?.organizationId}/company/${user?.companyId}/settings`} icon={<SettingsIcon />} selected={selected} setSelected={setSelected} />
                    <MenuItem
                        style={{ color: colors.gray[100] }}
                        onClick={() => {window.open('https://bidbow.outseta.com/support/kb/', '_blank')}}
                        icon={<HelpOutlineOutlinedIcon />}>
                    </MenuItem>
                </Box>
            </Menu>
            <SidebarFooter>
                <Menu>
                    <Box sx={{
                        "& .pro-icon": {
                            display: "flex",
                            width: "36px",
                            height: "36px",
                            padding: "7px 7.662px 7px 8.507px",
                            justifyContent: "center",
                            alignItems: "center",
                            background: `${colors.purpleAccent[500]}`,
                            borderRadius: "9px"
                        },
                    }}>
                        <MenuItem
                            style={{ color: colors.gray[100] }}
                            onClick={handleClick}
                            icon={<Bwc />}>
                        </MenuItem>
                        <MuiMenu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'center',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <MuiMenuItem sx={{ fontWeight: 'bold' }}>{user?.email}</MuiMenuItem>
                            <MuiMenuItem onClick={appLogout}>
                                <ListItemIcon>
                                    <LogoutIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>Log out</ListItemText>
                            </MuiMenuItem>
                        </MuiMenu>
                    </Box>
                </Menu>
            </SidebarFooter>
        </ProSidebar>
    </Box>}</>
}

export default React.memo(Sidebar);