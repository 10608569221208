import { Box, Tab, Tabs, Typography, useTheme } from "@mui/material";
import { tokens } from "theme";
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import { useRouteMatch } from "Helpers/route";
import DesignServicesOutlinedIcon from '@mui/icons-material/DesignServicesOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import {
    Outlet,
    Link as RouterLink,
} from 'react-router-dom';
import BookIcon from '@mui/icons-material/BookOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import { useGetUserDetailsQuery } from "State/Services/user";
import { hasSystemRolePermission } from "Helpers/role-permissions";
import { useEffect, useRef, useState } from "react";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { getOutseta } from "Helpers/outseta";

export default function Settings() {
    const outsetaRef = useRef(getOutseta());
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const basePath = `organization/:organizationId/company/:companyId/settings`;
    const colors = tokens(theme.palette.mode);
    const routeMatch = useRouteMatch([`${basePath}/units`, `${basePath}/currencies`, `${basePath}/master`, `${basePath}/user-roles`, `${basePath}/account`]);
    const currentTab = routeMatch?.pattern?.path;
    const [hasAddEditAccess, setHasAddEditAccess] = useState(false);
    const [isPrimaryUser, setIsPrimaryUser] = useState<boolean>(false);

    useEffect(() => {
        const outseta = outsetaRef.current as { getJwtPayload: Function };
        const payload = outseta.getJwtPayload();
        if (payload) {
            setIsPrimaryUser(payload["outseta:isPrimary"]==="1");
        }
    }, [])

    useEffect(() => {
        if (user) {
            const hasAccess = hasSystemRolePermission(user, [302]);
            setHasAddEditAccess(hasAccess);
        }
    }, [user])

    return <>
        <Box height={"inherit"} sx={{ borderLeft: `1px solid ${colors.gray[800]}`, boxShadow: `${colors.gray[800]} 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`, }}>
            <Box paddingTop="16px" paddingBottom="16px" paddingLeft="24px" paddingRight="24px" borderBottom={`1px solid ${colors.gray[800]}`}>
                <Typography component="div" sx={{ display: "flex" }}>
                    <SettingsIcon sx={{ width: "20px", height: "20px", color: `${colors.gray[400]} !important`, marginRight: "4px" }} />
                    <Box sx={{ fontWeight: 600, fontSize: "16px", color: `${colors.gray[100]} !important` }}>Settings</Box>
                </Typography>
            </Box>
            <Box display="flex" width="100%">
                    <Tabs
                        sx={{
                            height: "50px",
                            minHeight: '50px',
                            padding: '14px',
                            width: "100%",
                            alignItems: 'center',
                            " & .Mui-selected": {
                                borderRadius: '8px',
                                background: `#EDF2FD !important`,
                                color: `${colors.primary[100]} !important`
                            },
                            " & .MuiTab-root": {
                                padding: '6px 10px',
                                minHeight: '30px',
                                height: '30px',
                                marginRight: '8px',
                                textTransform: 'none',
                                color: `${colors.gray[500]}`
                            },
                            borderBottom: `1px solid ${colors.gray[800]}`
                        }}
                        TabIndicatorProps={{
                            style: {
                                display: 'none'
                            }
                        }}
                        value={currentTab}
                        aria-label="Estimate"
                    >
                        <Tab icon={<DesignServicesOutlinedIcon />} iconPosition="start" label="Units" value={`${basePath}/units`} to="units" component={RouterLink} />
                        <Tab icon={<CurrencyExchangeOutlinedIcon />} iconPosition="start" label="Currencies" value={`${basePath}/currencies`} to="currencies" component={RouterLink} />
                        {hasAddEditAccess && <Tab icon={<BookIcon />} iconPosition="start" label="Template" value={`${basePath}/master`} to="master" component={RouterLink} />}
                        {hasAddEditAccess && <Tab icon={<PeopleOutlinedIcon />} iconPosition="start" label="User Roles" value={`${basePath}/user-roles`} to="user-roles" component={RouterLink} />}
                        {isPrimaryUser && <Tab icon={<AccountCircleIcon />} iconPosition="start" label="Account" value={`${basePath}/account`} to="account" component={RouterLink} />}
                    </Tabs>
                </Box>
                <Outlet />
        </Box>
    </>
}