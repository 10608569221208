import { SupplierPackage, SupplierPackageInfo, SupplierPackageResource, SupplierPackageResourceDetails } from 'Models/package';
import { api } from './api'
import { SupplierPackageVendor, SupplierPackageVendorFile } from 'Models/supplier';


export const supplierApi = api.injectEndpoints({
    endpoints: (build) => ({
        getSupplierPackageDetails: build.query<SupplierPackage<SupplierPackageResourceDetails>, { organizationId: string, companyId: string, estimateId: string, packageId: string }>({
            query: ({ organizationId, companyId, estimateId, packageId }: { organizationId: string, companyId: string, estimateId: string, packageId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/supplier/package/${packageId}` }),
            providesTags: ['SupplierPackages']
        }),
        getSupplierPackages: build.query<Array<SupplierPackageInfo>, { organizationId: string, companyId: string, estimateId: string }>({
            query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/supplier/packages` }),
            providesTags: ['SupplierPackages']
        }),
        updateSupplierPackage: build.mutation<SupplierPackage<SupplierPackageResource>, Partial<{ body: SupplierPackage<SupplierPackageResource>, orgId: string, companyId: string, estimateId: string, packageId: string }>>({
            query({ body, orgId, companyId, packageId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/supplier/package/${packageId}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: ['SupplierPackages', 'Pricing', 'ItemDetails', 'SupplierPackageVendors', 'SubcontractorResourcesActivities', 'Pricing', 'Resources', 'Resources', 'Activities', 'CompositeResources', 'ActivityResources', 'IndirectItemDetails', 'ItemDetails', 'FilteredResources', "StandardItems", "StandardItemActivityResources", 'CalculatedResource'],
        }),
        isPackageNameDuplicate: build.query<boolean, { organizationId: string, companyId: string, estimateId: string, name: string, packageId?: string }>({
            query: ({ organizationId, companyId, estimateId, name, packageId }: { organizationId: string, companyId: string, estimateId: string, name: string, packageId?: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/supplier/package/name/${name}?packageId=${packageId}` })
        }),
        createSupplierPackage: build.mutation<SupplierPackage<SupplierPackageResource>, Partial<{ body: SupplierPackage<SupplierPackageResource>, orgId: string, companyId: string, estimateId: string }>>({
            query({ body, orgId, companyId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/supplier/package`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['SupplierPackages'],
        }),
        uploadSupplierPackageFile: build.mutation<SupplierPackageVendorFile, { organizationId: string, companyId: string, estimateId: string, supplierPackageVendorId: string, description: string, fileData: FormData }>({
            query({ organizationId, companyId, estimateId, fileData, supplierPackageVendorId, description }: { organizationId: string, companyId: string, estimateId: string, supplierPackageVendorId: string, description: string, fileData: FormData }) {
                return {
                    url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/supplier/package-vendor/${supplierPackageVendorId}/upload?description=${description}`,
                    method: 'POST',
                    body: fileData,
                };
            },
            invalidatesTags: ['SupplierPackageVendorFiles'],
        }),
        getSupplierPackageFiles: build.query<Array<SupplierPackageVendorFile>, { organizationId: string, companyId: string, estimateId: string, supplierPackageVendorId: string }>({
            query: ({ organizationId, companyId, estimateId, supplierPackageVendorId }: { organizationId: string, companyId: string, estimateId: string, supplierPackageVendorId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/supplier/package-vendor/${supplierPackageVendorId}/files` }),
            providesTags: ['SupplierPackageVendorFiles']
        }),
        getSupplierPackageVendors: build.query<Array<SupplierPackageVendor>, { organizationId: string, companyId: string, estimateId: string, packageId: string }>({
            query: ({ organizationId, companyId, estimateId, packageId }: { organizationId: string, companyId: string, estimateId: string, packageId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/supplier/package/${packageId}/vendors` }),
            providesTags: ['SupplierPackageVendors']
        }),
        updateSupplierPackageVendor: build.mutation<SupplierPackageVendor, Partial<{ body: SupplierPackageVendor, orgId: string, companyId: string, estimateId: string, supplierPackageVendorId: string }>>({
            query({ body, orgId, companyId, supplierPackageVendorId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/supplier/package-vendor/${supplierPackageVendorId}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: ['SupplierPackageVendors', 'SupplierPackages'],
        }),
        updateSupplierResource: build.mutation<SupplierPackageResource, Partial<{ body: SupplierPackageResource, orgId: string, companyId: string, estimateId: string, supplierPackageVendorId: string, resourceId: string, packageId: string }>>({
            query({ body, orgId, companyId, supplierPackageVendorId, estimateId, resourceId, packageId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/supplier/package/${packageId}/package-vendor/${supplierPackageVendorId}/resource/${resourceId}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: ['SupplierPackages', 'Pricing', 'ItemDetails', 'SupplierPackageVendors', 'SubcontractorResourcesActivities', 'Pricing', 'Resources', 'Resources', 'Activities', 'CompositeResources', 'ActivityResources', 'IndirectItemDetails', 'ItemDetails', 'FilteredResources', "StandardItems", "StandardItemActivityResources", 'CalculatedResource'],
        }),
        downloadSupplierPackageVendorFile: build.mutation<any, Partial<{ organizationId: string, companyId: string, estimateId: string, supplierPackageVendorId: string, packageVendorFileId: string }>>({
            query({ organizationId, companyId, estimateId, supplierPackageVendorId, packageVendorFileId }: { organizationId: string, companyId: string, estimateId: string, fileId: string, supplierPackageVendorId: string, packageVendorFileId: string }) {
                return {
                    url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/supplier/package-vendor/${supplierPackageVendorId}/file/${packageVendorFileId}/download`,
                    method: "POST",
                    responseHandler: async (response: any) => window.open(window.URL.createObjectURL(await response.blob()), '_blank'),
                    cache: "no-cache",
                };
            },
        }),
        deleteSupplierPackageVendorFile: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, supplierPackageVendorId: string, packageVendorFileId: string }>>({
            query({ orgId, companyId, estimateId, supplierPackageVendorId, packageVendorFileId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/supplier/package-vendor/${supplierPackageVendorId}/file/${packageVendorFileId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['SupplierPackageVendorFiles']
        }),
        deleteSupplierPackageVendor: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, packageId: string, supplierPackageVendorId: string }>>({
            query({ orgId, companyId, estimateId, packageId, supplierPackageVendorId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/supplier/package/${packageId}/package-vendor/${supplierPackageVendorId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['SupplierPackageVendorFiles', 'SupplierPackages', 'SupplierPackageVendors']
        }),
        deleteSupplierResource: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, packageId: string, resourceId: string }>>({
            query({ orgId, companyId, estimateId, packageId, resourceId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/supplier/package/${packageId}/resource/${resourceId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['SupplierPackages']
        }),
        deleteSupplierPackage: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, packageId: string }>>({
            query({ orgId, companyId, estimateId, packageId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/supplier/package/${packageId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: ['SupplierPackages']
        }),
        createSupplierPackageVendor: build.mutation<void, Partial<{
            body: {
                packageVendors: Array<{ id: string }>,
                packageResources: Array<SupplierPackageResource>
            }, 
            orgId: string, 
            companyId: string, 
            estimateId: string, 
            packageId: string
        }>>({
            query({ body, orgId, companyId, estimateId, packageId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/supplier/package/${packageId}/vendor`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['SupplierPackages', 'SupplierPackageVendors'],
        }),
    })
});

export const {
    useLazyIsPackageNameDuplicateQuery,
    useCreateSupplierPackageVendorMutation,
    useDeleteSupplierPackageMutation,
    useDeleteSupplierResourceMutation,
    useDeleteSupplierPackageVendorMutation,
    useUpdateSupplierResourceMutation,
    useDeleteSupplierPackageVendorFileMutation,
    useDownloadSupplierPackageVendorFileMutation,
    useUpdateSupplierPackageVendorMutation,
    useGetSupplierPackageVendorsQuery,
    useGetSupplierPackageFilesQuery,
    useUploadSupplierPackageFileMutation,
    useGetSupplierPackagesQuery,
    useCreateSupplierPackageMutation,
    useGetSupplierPackageDetailsQuery,
    useLazyGetSupplierPackageDetailsQuery,
    useUpdateSupplierPackageMutation,
} = supplierApi