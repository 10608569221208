import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'handsontable/dist/handsontable.full.min.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate';
import { Provider } from 'react-redux';
import { store } from 'State/store';
import { Config, configManager } from 'Helpers/config-manager';
import { LicenseManager } from "ag-grid-enterprise";
import { registerAllModules } from 'handsontable/registry';
import { loadOutseta } from 'outseta';

registerAllModules();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

fetch('/config.json').then((res) => res.json()).then(async (config: Config) => {
  configManager.setConfig(config);
  LicenseManager.setLicenseKey(config.agGridLicenseKey);
  await loadOutseta(config.outseta.domain);
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <Auth0ProviderWithNavigate>
            <App />
          </Auth0ProviderWithNavigate>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
});

