import { Autocomplete, Box, Button, TextField, useTheme } from "@mui/material";
import { CompanyCurrency } from "Models/company-currency";
import { CalculatedResource, CalculatedResourceView } from "Models/resource";
import { useGetCompanyCurrenciesQuery } from "State/Services/company-currency";
import { useGetUserDetailsQuery } from "State/Services/user";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useFormik } from "formik";
import { useCallback, useEffect, useMemo, useState } from "react";
import { tokens } from "theme";
import * as yup from 'yup';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { rounder } from "Helpers/rounder";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useCreateCalculatedResourceMutation, useGetCalculatedResourceByResourceIdQuery, useUpdateCalculatedResourceMutation } from "State/Services/resource";
import { v4 as uuidv4 } from 'uuid';

export interface CalculatedResourceModalProps {
    estimateId: string;
    close: (result?: { total?: number, calculatedResourceId: string }) => void;
    resourceId: string;
    unit?: string;
    disabled: boolean;
}

const calculatedResourceValidationSchema = yup.object<CalculatedResource>({
    id: yup
        .string()
        .optional(),
    amortizationPeriodDays: yup
        .number()
        .nullable()
        .optional(),
    rate: yup
        .number()
        .required('Rate is required')
});

export default function CalculatedResourceModal(props: CalculatedResourceModalProps) {
    const theme = useTheme();
    const [colors, setColors] = useState<any>();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const { data: user } = useGetUserDetailsQuery();
    const { data: storedCalculatedResource } = useGetCalculatedResourceByResourceIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '', resourceId: (props?.resourceId) ? props.resourceId : '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId || !props.resourceId });
    const { data: companyCurrencies } = useGetCompanyCurrenciesQuery({ companyId: (user && user.companyId) ? user.companyId : '', orgId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [selectedCurrency, setSelectedCurrency] = useState<CompanyCurrency & { currencyOption: string }>();
    const [companyCurrenciesExtended, setCompanyCurrenciesExtended] = useState<Array<CompanyCurrency & { currencyOption: string }>>([]);
    const [total, setTotal] = useState<number>();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [createCalculatedResource] = useCreateCalculatedResourceMutation();
    const [updateCalculatedResource] = useUpdateCalculatedResourceMutation();
    const [exchangeRate, setExchangeRate] = useState<number>(1);

    useEffect(() => {
        if (companyCurrencies) {
            setCompanyCurrenciesExtended(companyCurrencies.map((currency) => ({ ...currency, currencyOption: `${currency.alphabeticalCode} - ${currency.currency}` })))
            if (storedCalculatedResource) {
                const currency = companyCurrencies.find((curr) => (curr.id === storedCalculatedResource.companyCurrencyId));
                if (currency) {
                    setSelectedCurrency({ ...currency, currencyOption: `${currency.alphabeticalCode} - ${currency.currency}` });
                }
            } else {
                if (estimate?.CompanyCurrency?.Currency) {
                    const primaryCurr = { ...estimate?.CompanyCurrency, currencyOption: `${estimate?.CompanyCurrency?.Currency.alphabeticalCode} - ${estimate?.CompanyCurrency?.Currency.currency}` };
                    setSelectedCurrency(primaryCurr);
                }
            }
        }
    }, [companyCurrencies, estimate?.CompanyCurrency, storedCalculatedResource])

    useEffect(() => {
        return () => {
            setTotal(undefined);
        }
    }, [])

    const clearCloseModal = () => {
        props.close();
    }

    const formik = useFormik<CalculatedResourceView>({
        enableReinitialize: true,
        validateOnMount: true,
        initialValues: (storedCalculatedResource) ? {
            id: storedCalculatedResource.id ?? '',
            amortizationPeriodDays: storedCalculatedResource.amortizationPeriodDays,
            rate: storedCalculatedResource.rate,
            resourceId: props.resourceId,
            companyCurrency: selectedCurrency
        } : {
            id: '',
            amortizationPeriodDays: null,
            rate: null,
            resourceId: props.resourceId,
            companyCurrency: selectedCurrency,
        },
        validationSchema: calculatedResourceValidationSchema,
        onSubmit: async (calculatedResource) => {
            if (user && calculatedResource.rate && calculatedResource.companyCurrency) {
                const amortPeriod = (calculatedResource.amortizationPeriodDays) ? parseFloat(calculatedResource.amortizationPeriodDays.toString()) : 1;
                let createdUpdatedResource;
                if (calculatedResource.id) {
                    createdUpdatedResource = await updateCalculatedResource({
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        resourceId: props.resourceId,
                        orgId: user.organizationId,
                        calculatedResourceId: calculatedResource.id,
                        body: {
                            companyCurrencyId: calculatedResource.companyCurrency.id ?? '',
                            id: calculatedResource.id,
                            resourceId: props.resourceId,
                            amortizationPeriodDays: amortPeriod === 0 ? 1 : amortPeriod,
                            rate: parseFloat(calculatedResource.rate.toString())
                        }
                    }).unwrap();
                } else {
                    createdUpdatedResource = await createCalculatedResource({
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        resourceId: props.resourceId,
                        orgId: user.organizationId,
                        body: {
                            companyCurrencyId: calculatedResource.companyCurrency.id ?? '',
                            id: uuidv4(),
                            resourceId: props.resourceId,
                            amortizationPeriodDays: amortPeriod === 0 ? 1 : amortPeriod,
                            rate: parseFloat(calculatedResource.rate.toString())
                        }
                    }).unwrap();
                }
                const resourceTotal = (calculatedResource.rate * (exchangeRate ?? 1)) / amortPeriod;
                props.close({ total: resourceTotal, calculatedResourceId: createdUpdatedResource.id });
            }
        },
    });

    useEffect(() => {
        if (formik.values.rate && formik.values.amortizationPeriodDays && parseFloat(formik.values.amortizationPeriodDays.toString()) !== 0) {
            let numerator = estimate?.CompanyCurrency?.primaryToSecondaryRate ?? 1;
            let denominator = formik.values.companyCurrency?.primaryToSecondaryRate ?? 1;
            setExchangeRate(numerator / denominator);
            const resourceTotal = (formik.values.rate * ((numerator / denominator) ?? 1)) / formik.values.amortizationPeriodDays;
            setTotal(parseFloat(resourceTotal.toString()));
        } else {
            setTotal(0);
        }
    }, [estimate?.CompanyCurrency?.primaryToSecondaryRate, exchangeRate, formik.values.amortizationPeriodDays, formik.values.companyCurrency?.primaryToSecondaryRate, formik.values.rate])

    const currencyChanged = useCallback((event: any, value: CompanyCurrency & { currencyOption: string } | null) => {
        if (value) {
            let numerator = estimate?.CompanyCurrency?.primaryToSecondaryRate ?? 1;
            let denominator = value.primaryToSecondaryRate ?? 1;
            setExchangeRate(numerator / denominator);
            formik.setFieldValue('companyCurrency', value);
        }
    }, [estimate?.CompanyCurrency?.primaryToSecondaryRate, formik])

    return <>{colors &&
        <form noValidate onSubmit={formik.handleSubmit} style={{ overflow: "auto", height: "100%" }}>
            <Box gap="15px" display="flex" flexDirection="column" width="100%" height="100%" padding="10px 30px 0px 30px">
                <Box display="flex" alignItems="center" flex="1" paddingBottom="10px">
                    <Box display="flex" flexDirection="column" flex="1">
                        <Box display="flex" flexDirection="column" >
                            <Box display="flex" gap="15px" flex="1" alignItems="center" borderBottom={`1px solid ${colors.gray[600]}`} paddingBottom="10px">
                                <Box flex="0.5">
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id="rate"
                                        name="rate"
                                        autoComplete="off"
                                        placeholder="Rate"
                                        disabled={props.disabled}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.rate}
                                        error={formik.touched.rate && Boolean(formik.errors.rate)}
                                        helperText={formik.touched.rate && formik.errors.rate} />
                                </Box>
                                <Box flex="1">
                                    {companyCurrenciesExtended && companyCurrenciesExtended.length > 0 && <Autocomplete
                                        fullWidth
                                        size="small"
                                        className="ag-input-field-input ag-text-field-input"
                                        sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                                        disabled={props.disabled}
                                        onChange={currencyChanged}
                                        options={companyCurrenciesExtended}
                                        getOptionLabel={(option) => option.currencyOption ?? ''}
                                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                                        value={formik.values.companyCurrency ?? null}
                                        renderInput={(params) => <TextField name="Seccurrency" sx={{ height: "100%" }} placeholder='Select Currency' {...params} />}
                                        renderOption={(props, option, { inputValue }) => {
                                            const matches = match(option.currencyOption ?? '', inputValue, {
                                                insideWords: true,
                                            });
                                            const parts = parse(option.currencyOption ?? '', matches);

                                            return (
                                                <li {...props}>
                                                    <div>
                                                        {parts.map((part, index) => (
                                                            <span
                                                                key={index}
                                                                style={{
                                                                    fontWeight: part.highlight ? 700 : 400,
                                                                }}
                                                            >
                                                                {part.text}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </li>
                                            );
                                        }}
                                    />
                                    }
                                </Box>
                                <Box flex="0.2">
                                    X {rounder(exchangeRate, estimate?.CompanyCurrency?.Currency.minorUnit ?? 2)}
                                </Box>
                            </Box>
                            <Box display="flex" gap="15px" flex="1" alignItems="center" paddingTop="10px">
                                <Box flex="0.5">
                                    <TextField
                                        fullWidth
                                        size="small"
                                        id="amortizationPeriodDays"
                                        name="amortizationPeriodDays"
                                        autoComplete="off"
                                        disabled={props.disabled}
                                        placeholder="Amortization Period"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.amortizationPeriodDays}
                                        error={formik.touched.amortizationPeriodDays && Boolean(formik.errors.amortizationPeriodDays)}
                                        helperText={formik.touched.amortizationPeriodDays && formik.errors.amortizationPeriodDays} />
                                </Box>
                                <Box flex="1">{props.unit}</Box>
                                <Box flex="0.2">&nbsp;</Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" flex="0.2" paddingLeft="10px">
                        =&nbsp;&nbsp;&nbsp; {rounder(total, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2)}
                    </Box>
                </Box>
                <Box display="flex" flexDirection="column" width="100%" height="100%" padding="10px">
                    <Box display="flex" justifyContent="space-evenly">
                        <Button color="primary" variant="contained" type="submit" startIcon={<SaveIcon />} disabled={!formik.isValid || props.disabled}>
                            Save
                        </Button>
                        <Button variant="outlined" onClick={clearCloseModal} startIcon={<CloseIcon />}>
                            Cancel
                        </Button>
                    </Box>
                </Box>
            </Box>

        </form >
    }</>
}