import React, {
    MutableRefObject,
    forwardRef,
    useEffect,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Autocomplete, Chip } from '@mui/material';
import { useGetUserDetailsQuery } from 'State/Services/user';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { GridTextField } from 'Components/GridTextField';
import { useGetTagsQuery } from 'State/Services/tag';
import { Tag, VendorView } from 'Models/vendor';

export interface IVendorTagCellEditorParams extends ICellEditorParams<VendorView> {
    setRef: (ref: MutableRefObject<any>) => void;
    estimateId: string;
}

export default forwardRef((props: IVendorTagCellEditorParams, ref) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: tags } = useGetTagsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const inputRef = useRef<any>();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<Tag[]>(props.value);

    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                return value;
            },
            setFocusOnAdd() {
                if (inputRef.current) {
                    inputRef.current.focus();
                    setOpen(true);
                }
            },
            focusIn() {
                inputRef.current.focus();
                handleOpen();
            }
        };
    });

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (inputRef) {
            props.setRef(inputRef);
        }
    }, [props])

    return (
        <Autocomplete
            fullWidth
            freeSolo
            onClose={handleClose}
            onOpen={handleOpen}
            open={open}
            className="ag-input-field-input ag-text-field-input"
            sx={{ border: 'none', background: 'white', minHeight: "40px", height: (value.length > 2) ? "auto" : "40px", width: "200px", "& .MuiInputBase-root": { minHeight: "40px" }, "& .MuiTextField-root": { height: "100%", minHeight: "40px" } }}
            renderTags={(value: readonly string[], getTagProps) =>
                value.map((option: string, index: number) => (
                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                ))
            }
            size="small"
            multiple
            limitTags={2}
            value={value.map((val) => (val.description)) ?? ''}
            defaultValue={value.map((val) => (val.description)) ?? ''}
            onClick={handleOpen}
            onChange={(event: React.SyntheticEvent, newTags: string[] | null) => {
                const tagsToSet = new Array<Tag>();
                if (newTags && newTags.length > 0) {
                    newTags?.forEach((newTag) => {
                        const tag = tags?.find((tg) => (tg.description === newTag));
                        if (tag) {
                            tagsToSet.push(tag);
                        } else {
                            tagsToSet.push({ description: newTag });
                        }
                    });
                }
                setValue(tagsToSet);
            }}
            options={tags?.map((tag) => tag.description) ?? []}
            renderInput={(params) => <GridTextField sx={{ minHeight: "40px", height: (value.length > 2) ? "100%" : "40px" }} inputRef={inputRef} placeholder='Select/Enter Tag' {...params} />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option, inputValue, {
                    insideWords: true,
                });
                const parts = parse(option, matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
    );
});
