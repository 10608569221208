import { Box, useTheme } from "@mui/material";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "theme";
import { AgGridReact } from "ag-grid-react";
import { ColDef, IRowNode, SuppressKeyboardEventParams } from "ag-grid-enterprise";
import SaveCancelCategoryCellRenderer from "./SaveCancelCategoryCellRenderer";
import { CellClickedEvent, CellEditingStartedEvent, CellEditingStoppedEvent, CellKeyDownEvent, GetRowIdParams, ICellRendererParams } from "ag-grid-community";
import { v4 as uuidv4 } from 'uuid';
import { ActivityCategoryView } from "Models/activity-category";
import { useCreateActivityCategoryMutation, useDeleteActivityCategoryMutation, useGetActivityCategoriesQuery, useUpdateActivityCategoryMutation } from "State/Services/activity-category";
import CategoryActivityIdEditCellRenderer from "./CategoryActivityIdEditCellRenderer";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { useGetEstimateQuery } from "State/Services/estimate";
import { ServerError } from "Models/error-info";
import CategoryActivityDescriptionEditCellRenderer from "./CategoryActivityDescriptionEditCellRenderer";

export interface ActivityCategoryProps {
    estimateId: string | undefined;
}

export default function AddEditActivityCategory(props: ActivityCategoryProps) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [currentCategoryEditing, setCurrentCategoryEditing] = useState<{ node: IRowNode<ActivityCategoryView> | undefined, column?: string }>();
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [createCategory] = useCreateActivityCategoryMutation();
    const [updateCategory] = useUpdateActivityCategoryMutation();
    const [deleteCategory] = useDeleteActivityCategoryMutation();
    const { data: storedActivityCategories } = useGetActivityCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId })
    const [activityCategories, setActivityCategories] = useState<Array<ActivityCategoryView>>([]);
    const activityCategoryGridRef = useRef<AgGridReact<ActivityCategoryView>>(null);
    const editDisabledRef = useRef<boolean>();
    const deleteDisabledRef = useRef<boolean>();
    const [categoryErrors, setCategoryErrors] = useState<Array<{ field: string, error: string }>>([]);
    const [isCategoryCancelClicked, setIsCategoryCancelClicked] = useState(false);

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
                deleteDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Activities,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Activities,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            editDisabledRef.current = true;
            deleteDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    useEffect(() => {
        if (currentCategoryEditing && typeof currentCategoryEditing.node?.rowIndex === 'number') {
            if (currentCategoryEditing.node.isRowPinned()) {
                activityCategoryGridRef.current!.api.startEditingCell({
                    rowIndex: currentCategoryEditing.node.rowIndex,
                    colKey: currentCategoryEditing.column ?? 'displayId',
                    rowPinned: 'top'
                });
            } else {
                activityCategoryGridRef.current!.api.startEditingCell({
                    rowIndex: currentCategoryEditing.node.rowIndex,
                    colKey: currentCategoryEditing.column ?? 'displayId',
                });
            }
        }
    }, [currentCategoryEditing])

    useEffect(() => {
        if (categoryErrors.length > 0) {
            categoryErrors.forEach((errorDetails) => {
                switch (errorDetails.field) {
                    case 'description':
                        const descriptionInstances = activityCategoryGridRef.current!.api.getCellEditorInstances({
                            columns: ['description']
                        });
                        if (descriptionInstances && descriptionInstances.length > 0 && descriptionInstances[0] && typeof (descriptionInstances[0] as any).setError === 'function') {
                            (descriptionInstances[0] as any).setError(errorDetails.error);
                        }
                        break;
                    case 'displayId':
                        const displayIdInstances = activityCategoryGridRef.current!.api.getCellEditorInstances({
                            columns: ['displayId']
                        });
                        if (displayIdInstances && displayIdInstances.length > 0 && displayIdInstances[0] && typeof (displayIdInstances[0] as any).setError === 'function') {
                            (displayIdInstances[0] as any).setError(errorDetails.error);
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    }, [categoryErrors])

    useEffect(() => {
        const categories = new Array<ActivityCategoryView>();
        if (storedActivityCategories && storedActivityCategories.length > 0) {
            for (let i = 0; i < storedActivityCategories.length; i++) {
                const category = storedActivityCategories[i];
                categories.push({
                    displayId: category.displayId,
                    description: category.description,
                    actions: '',
                    masterReferenceId: category.masterReferenceId,
                    canDelete: category.canDelete,
                    id: category.id,
                    errors: [],
                    isNew: false
                });
            }
            setActivityCategories(categories);
        } else {
            setActivityCategories([]);
        }
    }, [storedActivityCategories])

    const saveCategoryRow = useCallback((nodeToSave: IRowNode<ActivityCategoryView>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<ActivityCategoryView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (activityCategoryGridRef && activityCategoryGridRef.current) {
                    activityCategoryGridRef.current!.api.stopEditing();
                    if (!nodeToSave.data?.displayId) {
                        const error: ServerError = { data: { displayId: 'ID is required' } };
                        throw error;
                    }
                    if (!nodeToSave.data?.description) {
                        const error: ServerError = { data: { description: 'Description is required' } };
                        throw error;
                    }
                    if (nodeToSave.data) {
                        if (!nodeToSave.data.isNew) {
                            await updateCategory({
                                activityCategoryId: nodeToSave.data.id,
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                body: {
                                    id: nodeToSave.data.id,
                                    displayId: nodeToSave.data.displayId,
                                    description: nodeToSave.data.description
                                }
                            }).unwrap();
                        }
                        else {
                            await createCategory({
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                body: {
                                    id: uuidv4(),
                                    displayId: nodeToSave.data.displayId,
                                    description: nodeToSave.data.description
                                }
                            }).unwrap();
                        }

                        if (nodeToSave.rowPinned) {
                            activityCategoryGridRef.current!.api.applyTransaction({ add: [nodeToSave.data] });
                            nodeToSave.updateData({
                                displayId: '',
                                description: 'Enter Category Name',
                                id: undefined,
                                errors: [],
                                isNew: true
                            });
                        }
                    }
                }
                resolve();
                if (toEditAfterSave) {
                    setCurrentCategoryEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                } else {
                    setCurrentCategoryEditing(undefined);
                }
            } catch (error: any) {
                let errorMessage = '';
                let column = 'displayId';
                if (error) {
                    if (error.data.displayId) {
                        errorMessage = error.data.displayId;
                        column = 'displayId';
                    } else if (error.data.description) {
                        errorMessage = error.data.description;
                        column = 'description';
                    }
                }

                if (errorMessage && typeof nodeToSave.rowIndex === 'number') {
                    if (nodeToSave.isRowPinned()) {
                        activityCategoryGridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: column,
                            rowPinned: 'top'
                        });
                    } else {
                        activityCategoryGridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: column,
                        });
                    }
                    setCategoryErrors([{ field: column, error: errorMessage }]);
                }
                reject(error);
            }
        });
    }, [createCategory, props.estimateId, updateCategory, user?.companyId, user?.organizationId])

    const saveActivityCategoryOnEnter = useCallback((params: SuppressKeyboardEventParams<ActivityCategoryView>) => {
        if (params.event.key === 'Enter' && params.node) {
            params.event.stopPropagation();
            saveCategoryRow(params.node);
            return true;
        }
        return false;
    }, [saveCategoryRow])

    const deleteCategoryRow = useCallback((node: IRowNode<ActivityCategoryView>) => {
        return new Promise<void>(async (resolve) => {
            if (node.data) {
                await deleteCategory({
                    companyId: user?.companyId,
                    estimateId: props.estimateId,
                    orgId: user?.organizationId,
                    activityCategoryId: node.data?.id,
                });
            }
            resolve();
        });
    }, [deleteCategory, props.estimateId, user?.companyId, user?.organizationId])

    const cancelCategoryEditing = useCallback((node: IRowNode<ActivityCategoryView>) => {
        activityCategoryGridRef.current!.api.stopEditing(true);
        if (node.rowPinned) {
            node.updateData({
                displayId: '',
                description: 'Enter Category Name',
                id: undefined,
                isNew: true
            });
        } else {
            setIsCategoryCancelClicked(true);
            const category = storedActivityCategories?.find((v) => (v.id === node.data?.id));
            if (category) {
                activityCategoryGridRef.current!.api.applyTransaction({ update: [{ ...category }] });
            }
            activityCategoryGridRef.current!.api.stopEditing(true);
        }
        setCurrentCategoryEditing(undefined);
    }, [storedActivityCategories])

    const onCategoryCellClicked = useCallback(async (event: CellClickedEvent) => {
        try {
            if (currentCategoryEditing?.node === event.node) {
                return;
            }
            if (!currentCategoryEditing?.node) {
                if (event.node.data?.masterReferenceId || editDisabledRef.current) return;

                if (isCategoryCancelClicked) {
                    setIsCategoryCancelClicked(false);
                    return;
                };

                if (event.column.getColId() === 'actions') {
                    return;
                }
                setCurrentCategoryEditing({ node: event.node, column: event.column.getColId() });
            } else {
                await saveCategoryRow(currentCategoryEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
            }
        } catch (error) { }

    }, [currentCategoryEditing?.node, isCategoryCancelClicked, saveCategoryRow])

    const getCategoryColumnDefs = (): ColDef<ActivityCategoryView>[] => {
        return [
            { field: 'id', hide: true, cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } },
            {
                field: 'displayId',
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                cellEditor: CategoryActivityIdEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId
                },
                headerName: 'ID',
                cellDataType: 'string',
                width: 85,
                sortable: true,
                suppressKeyboardEvent: (params) => saveActivityCategoryOnEnter(params)
            },
            {
                field: 'description',
                cellEditor: CategoryActivityDescriptionEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId,
                },
                cellRenderer: (params: ICellRendererParams<ActivityCategoryView>) =>{
                    if (params.node.isRowPinned()){
                        return <i>{params.node.data?.description}</i>
                    }
                    return params.node.data?.description;
                },
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                flex: 1,
                suppressKeyboardEvent: (params) => saveActivityCategoryOnEnter(params),
            },
            {
                field: 'actions',
                suppressKeyboardEvent: (params) => saveActivityCategoryOnEnter(params),
                resizable: true,
                width: 60,
                editable: false,
                headerName: '',
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRendererSelector: () => {
                    return {
                        component: SaveCancelCategoryCellRenderer,
                        params: {
                            delete: (node: IRowNode<ActivityCategoryView>) => deleteCategoryRow(node),
                            save: (node: IRowNode<ActivityCategoryView>) => saveCategoryRow(node),
                            cancel: (node: IRowNode<ActivityCategoryView>) => cancelCategoryEditing(node),
                            disabled: () => deleteDisabledRef.current
                        },
                    };
                },
            },
        ]
    }

    const [activityCategoryDefs] = useState<ColDef<ActivityCategoryView>[]>(getCategoryColumnDefs());
    const defaultCategoryColDef = useMemo<ColDef>(() => {
        return {
            editable: true
        };
    }, []);

    const createActivityCategoryPinnedData = useMemo<ActivityCategoryView[]>(() => {
        if (!editDisabledRef.current) {
            return [{ isNew: true, description: 'Enter Category Name', displayId: '' }];
        }

        return [];
    }, [])

    const onCategoryRowEditingStarted = (event: CellEditingStartedEvent<ActivityCategoryView>) => {
        setIsCategoryCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
        setTimeout(() => {
            const displayInstances = activityCategoryGridRef.current!.api.getCellEditorInstances({
                columns: [currentCategoryEditing?.column ?? 'displayId']
            });
            if (displayInstances && displayInstances.length > 0 && displayInstances[0] && typeof (displayInstances[0] as any).setFocusOnAdd === 'function') {
                (displayInstances[0] as any).setFocusOnAdd();
            }
        }, 100);
    }

    const onCategoryRowEditingStopped = (event: CellEditingStoppedEvent<ActivityCategoryView>) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }

    const getCategoryRowId = useCallback(function (params: GetRowIdParams<ActivityCategoryView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    const onCategoryCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelCategoryEditing(e.node);
            setIsCategoryCancelClicked(false);
        }
    }, [cancelCategoryEditing]);

    return <>{colors && <Box display="flex" height="100%">
        <Box className="ag-theme-alpine ag-theme-bidbow" height="100%" width="100%" padding="10px 20px 40px 20px">
            <AgGridReact<ActivityCategoryView>
                ref={activityCategoryGridRef}
                editType={'fullRow'}
                rowData={activityCategories}
                suppressRowClickSelection={true}
                columnDefs={activityCategoryDefs}
                defaultColDef={defaultCategoryColDef}
                pinnedTopRowData={createActivityCategoryPinnedData}
                onRowEditingStarted={onCategoryRowEditingStarted}
                onRowEditingStopped={onCategoryRowEditingStopped}
                getRowId={getCategoryRowId}
                onCellClicked={onCategoryCellClicked}
                onCellKeyDown={onCategoryCellKeyDown}
            />
        </Box>
    </Box>}
    </>;
}