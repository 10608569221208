import { Autocomplete, Box, Button, TextField, Typography, useTheme } from "@mui/material";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useCallback, useEffect, useMemo, useState } from "react";
import { tokens } from "theme";
import { StandardItemCategory } from "Models/standard-item-category";
import { useGetStandardItemCategoriesQuery } from "State/Services/standard-item-category";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';

export interface SelectedStandardItemCategories {
    category1?: StandardItemCategory;
    category2?: StandardItemCategory;
}

export interface StandardItemCategoryEditProps {
    estimateId: string | undefined;
    category1Id: string | undefined;
    category2Id: string | undefined;
    close: () => void;
    save: (selectedCategories: SelectedStandardItemCategories) => void;
}

export default function StdItemCategory(props: StandardItemCategoryEditProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const [colors, setColors] = useState<any>();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const [isSaving, setIsSaving] = useState(false);
    const { data: standardItemCategories } = useGetStandardItemCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [level1Categories, setLevel1Categories] = useState<Array<StandardItemCategory> | undefined>();
    const [level2Categories, setLevel2Categories] = useState<Array<StandardItemCategory> | undefined>();
    const [selectedCategory1, setSelectedCategory1] = useState<StandardItemCategory | null | undefined>();
    const [selectedCategory2, setSelectedCategory2] = useState<StandardItemCategory | null | undefined>();

    useEffect(() => {
        const level1Cats = new Array<StandardItemCategory>();
        const level2Cats = new Array<StandardItemCategory>();
        if (standardItemCategories && standardItemCategories.length > 0) {
            standardItemCategories.forEach((standardItem) => {
                if (standardItem.level === 1) {
                    level1Cats.push({
                        description: standardItem.description,
                        displayId: standardItem.displayId,
                        level: standardItem.level,
                        canDelete: standardItem.canDelete,
                        id: standardItem.id
                    });
                } else if (standardItem.level === 2) {
                    level2Cats.push({
                        description: standardItem.description,
                        displayId: standardItem.displayId,
                        level: standardItem.level,
                        canDelete: standardItem.canDelete,
                        id: standardItem.id
                    });
                }
            });
            setLevel1Categories(level1Cats);
            setLevel2Categories(level2Cats);
        } else {
            setLevel1Categories([]);
            setLevel2Categories([]);
        }
        const cat1 = level1Cats.find((cat) => (cat.id===props.category1Id));
        if (cat1){
            setSelectedCategory1(cat1);
        }
        const cat2 = level2Cats.find((cat) => (cat.id===props.category2Id));
        if (cat2){
            setSelectedCategory2(cat2);
        }
    }, [props.category1Id, props.category2Id, standardItemCategories])

    const save = useCallback(() => {
        try {
            setIsSaving(true);
            let selectedCategories: SelectedStandardItemCategories = {};
            if (selectedCategory1) {
                selectedCategories.category1 = selectedCategory1;
            }
            if (selectedCategory2) {
                selectedCategories.category2 = selectedCategory2;
            }

            props.save(selectedCategories);
            props.close();
        } catch (error) {
            console.log(error);
        } finally {
            setIsSaving(false);
        }
    }, [props, selectedCategory1, selectedCategory2])

    return <Box padding="0px 16px 16px 16px" width="100%" height="100%">
        <Box padding="12px 16px" display="flex" alignItems="center">
            <Typography fontSize="13px" fontWeight="600" lineHeight="140%" fontStyle="normal">Subitem Categories</Typography>
        </Box>
        <Box display="flex" flexDirection="column" padding="0px 16px 16px 16px" gap="12px">
            {level1Categories && <Box>
                <label htmlFor="category1" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Category 1</label>
                <Autocomplete
                    fullWidth
                    freeSolo
                    value={selectedCategory1?.description || null}
                    size="small"
                    onChange={(event: React.SyntheticEvent, newValue: string | null) => {
                        if (newValue) {
                            const cat = level1Categories.find((cat) => (cat.description === newValue));
                            if (cat) {
                                setSelectedCategory1(cat);
                            } else {
                                setSelectedCategory1({ description: newValue, level: 1 });
                            }

                        } else {
                            setSelectedCategory1(undefined);
                        }
                    }}
                    onInputChange={(event: React.SyntheticEvent, newValue: string | null) => {
                        if (newValue) {
                            const cat = level1Categories.find((cat) => (cat.description === newValue));
                            if (cat) {
                                setSelectedCategory1(cat);
                            } else {
                                setSelectedCategory1({ description: newValue, level: 1 });
                            }
                        } else {
                            setSelectedCategory1(undefined);
                        }
                    }}
                    options={level1Categories.map((option) => option.description)}
                    renderInput={(params) => <TextField variant="filled" label='Select/Enter category' {...params} />}
                    renderOption={(props, option, { inputValue }) => {
                        const matches = match(option, inputValue, {
                            insideWords: true,
                        });
                        const parts = parse(option, matches);

                        return (
                            <li {...props}>
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                />
            </Box>
            }
            {level2Categories && <Box>
                <label htmlFor="category2" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Category 2</label>
                <Autocomplete
                    fullWidth
                    freeSolo
                    value={selectedCategory2?.description || null}
                    size="small"
                    onChange={(event: React.SyntheticEvent, newValue: string | null) => {
                        if (newValue) {
                            const cat = level2Categories.find((cat) => (cat.description === newValue));
                            if (cat) {
                                setSelectedCategory2(cat);
                            } else {
                                setSelectedCategory2({ description: newValue, level: 1 });
                            }

                        } else {
                            setSelectedCategory2(undefined);
                        }
                    }}
                    onInputChange={(event: React.SyntheticEvent, newValue: string | null) => {
                        if (newValue) {
                            const cat = level2Categories.find((cat) => (cat.description === newValue));
                            if (cat) {
                                setSelectedCategory2(cat);
                            } else {
                                setSelectedCategory2({ description: newValue, level: 1 });
                            }
                        } else {
                            setSelectedCategory1(undefined);
                        }
                    }}
                    options={level2Categories.map((option) => option.description)}
                    renderInput={(params) => <TextField variant="filled" label='Select/Enter category' {...params} />}
                    renderOption={(props, option, { inputValue }) => {
                        const matches = match(option, inputValue, {
                            insideWords: true,
                        });
                        const parts = parse(option, matches);

                        return (
                            <li {...props}>
                                <div>
                                    {parts.map((part, index) => (
                                        <span
                                            key={index}
                                            style={{
                                                fontWeight: part.highlight ? 700 : 400,
                                            }}
                                        >
                                            {part.text}
                                        </span>
                                    ))}
                                </div>
                            </li>
                        );
                    }}
                />
            </Box>
            }
        </Box>
        <Box paddingLeft="50px" paddingRight="50px" display="flex" justifyContent="space-between">
                <Button
                    color="primary"
                    size="small"
                    variant="contained"
                    disabled={isSaving}
                    startIcon={!isSaving ? <SaveIcon /> : <CircularProgress sx={{ color: "white" }} size="1rem" />}
                    onClick={save}>Save</Button>
                <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    onClick={props.close}
                    sx={{ backgroundColor: `${colors?.gray[1000]}` }}
                >Cancel</Button>
            </Box>
    </Box>
}