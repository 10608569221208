const OUTSETA_ID = "BidbowOutseta";
let wndw = window as any;
let OPTIONS = {
    id: OUTSETA_ID,
    /* Vital setting for a single page application */
    monitorDom: true,
    /* Do not load the nocode module */
    load: "profile,support,chat",
    translations: {
        en: {
            Labels: {
                Team: 'Seats'
            }
        }
    },
    profile: {
        tab: "account",
        mode: "embed",
        selector: 'div[data-embed="outseta"]'
    }

} as any;

let outsetaPromise: Promise<void> | null = null;

const createScript = (domain: string) => {
    OPTIONS.domain = domain;
    const optionsKey = `${OUTSETA_ID}Options`;

    // Set the options on window
    wndw[optionsKey] = OPTIONS;

    // Create external script element
    const script = document.createElement("script");
    script.src = "https://cdn.outseta.com/outseta.min.js";
    // Set name of options key on window
    script.dataset.options = optionsKey;

    return script;
};

export const loadOutseta = async (domain: string) => {
    // In case loadOutseta is called several times,
    // lets make sure we only make one promise.
    if (outsetaPromise) return outsetaPromise;

    outsetaPromise = new Promise((resolve, reject) => {
        if (wndw[OUTSETA_ID]) {
            // If Outseta is initialized
            // lets resolve right away
            resolve(wndw[OUTSETA_ID]);
        } else {
            const script = createScript(domain);

            script.onload = () => {
                if (wndw[OUTSETA_ID]) {
                    resolve(wndw[OUTSETA_ID]);
                } else {
                    reject(new Error("Outseta.js not available"));
                }
            };

            script.onerror = () => {
                reject(new Error("Failed to load Outseta.js"));
            };

            document.head.appendChild(script);
        }
    });

    return outsetaPromise;
};
