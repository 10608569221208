import { IndirectItemForm } from "./indirect-item-form";
import * as yup from 'yup';
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUnitsQuery } from "State/Services/unit";
import { useGetIndirectItemDetailsForIndirectItemQuery, useGetIndirectItemIdQuery, useGetIndirectItemNavQuery, useLazyGetFilteredIndirectItemNavsQuery, useSaveIndirectItemAsStandardItemMutation, useUpdateIndirectItemMutation } from "State/Services/indirect-item";
import { BaseIndirectItem } from "Models/indirect-item";
import CalculateIcon from '@mui/icons-material/Calculate';
import { Typography, Link, Box, Breadcrumbs, useTheme, Button, Autocomplete, TextField, IconButton, OutlinedInput, InputAdornment, Popover, CircularProgress, Modal } from "@mui/material";
import { tokens } from "theme";
import {
    Link as RouterLink,
} from 'react-router-dom';
import IndirectItemCategoryEdit, { SelectedCategories } from "./IndirectItemCategoryEdit";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import React, { KeyboardEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { GridTextField } from "Components/GridTextField";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SaveIcon from '@mui/icons-material/Save';
import IndirectItemDetails from "./IndirectItemDetail";
import { rounder } from "Helpers/rounder";
import { useConfirm } from "material-ui-confirm";
import SplitButton, { MenuOption } from "Components/SplitButton";
import { ProjectPrice } from "Components/Items/ProjectPrice";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import CloseIcon from '@mui/icons-material/Close';
import QuantityCalculator from "Components/Shared/QuantityCalculator";
import { NavItem } from "Models/nav";

const baseIndirectItemValidationSchema = yup.object<IndirectItemForm>({
    itemBoqId: yup
        .string()
        .nullable()
        .optional(),
    page: yup
        .string()
        .nullable()
        .optional(),
    category1: yup
        .string()
        .nullable()
        .optional(),
    category2: yup
        .string()
        .nullable()
        .optional(),
    category3: yup
        .string()
        .nullable()
        .optional(),
    category4: yup
        .string()
        .nullable()
        .optional(),
    category1Id: yup
        .number()
        .nullable()
        .optional(),
    category2Id: yup
        .number()
        .nullable()
        .optional(),
    category3Id: yup
        .number()
        .nullable()
        .optional(),
    category4Id: yup
        .number()
        .nullable()
        .optional(),
    description: yup
        .string()
        .nullable()
        .optional(),
    unitId: yup
        .string()
        .nullable()
        .optional(),
    customUnit: yup
        .string()
        .nullable()
        .optional(),
    quantity: yup
        .number()
        .nullable()
        .optional(),
    estimatedQuantity: yup
        .number()
        .nullable()
        .optional(),
    remarks: yup
        .string()
        .nullable()
        .optional(),
});


export default function IndirectItem() {
    const confirm = useConfirm();
    const { estimateId, indirectItemId } = useParams();
    const { data: user } = useGetUserDetailsQuery();
    const { data: nav } = useGetIndirectItemNavQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '', indirectItemId: indirectItemId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId || !indirectItemId })
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });
    const { data: indirectItem } = useGetIndirectItemIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '', indirectItemId: indirectItemId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId || !indirectItemId });
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [updateIndirectItem] = useUpdateIndirectItemMutation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const [indirectItemDisabled, setIndirectItemDisabled] = useState(true);
    const indirectItemFieldRef = useRef<HTMLInputElement>();
    const [indirectItemFocus, setIndirectItemFocus] = useState(false);
    const [pageDisabled, setPageDisabled] = useState(true);
    const pageFieldRef = useRef<HTMLInputElement>();
    const [pageFocus, setPageFocus] = useState(false);
    const unitInputRef = useRef<any>();
    const [isSaving, setIsSaving] = useState(false);
    const categoryRef = useRef<HTMLDivElement>();
    const [openCategoryPopover, setOpenCategoryPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [showForm, setShowForm] = useState(true);
    const [unitCost, setUnitCost] = useState<number>(0);
    const [estCost, setEstCost] = useState<number>(0);
    const [cost, setCost] = useState<number>(0);
    const { data: storedIndirectItemDetails } = useGetIndirectItemDetailsForIndirectItemQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '', indirectItemId: indirectItem?.id ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId || !indirectItem?.id });
    const [saveMenuItems, setSaveMenuItems] = useState<Array<MenuOption>>([{ option: "Save to library items", disabled: false }]);
    const [saveIndirectItemAsStandardItem] = useSaveIndirectItemAsStandardItemMutation();
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const [editDisabled, setEditDisabled] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [getFilteredNavIndirectItems] = useLazyGetFilteredIndirectItemNavsQuery();
    const [options, setOptions] = useState<Array<NavItem>>([]);
    const [open, setOpen] = useState<boolean>(false);

    const [openCalculateModal, setOpenCalculateModal] = useState(false);
    const handleOpenCalculateModal = () => {
        setOpenCalculateModal(true);
    };
    const handleCloseCalculateModal = () => {
        setOpenCalculateModal(false);
    };

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                setEditDisabled(true);
                setHasViewAccess(true);
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Indirect,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Indirect,
                    requiredPermissions: [502]
                });
                setEditDisabled(!hasEditAccess);
            }
        } else {
            setEditDisabled(true);
            setHasViewAccess(true);
        }
        setIsReady(true);
    }, [estimate, estimate?.EstimateUserRole, user])

    const handleOpenCategoryPopover = () => {
        setOpenCategoryPopover(true);
        if (categoryRef.current) {
            setAnchorEl(categoryRef.current);
        }
    }
    const handleCloseCategoryPopover = () => {
        setOpenCategoryPopover(false);
        setAnchorEl(null);
    };

    const formik = useFormik<IndirectItemForm>({
        enableReinitialize: true,
        initialValues: (indirectItem) ? {
            itemBoqId: indirectItem.itemBoqId ?? '',
            description: indirectItem.description ?? '',
            page: indirectItem.page ?? '',
            customUnit: indirectItem.customUnit ?? '',
            unitId: indirectItem.unitId ?? '',
            quantity: indirectItem.quantity?.toString() ?? '',
            estimatedQuantity: indirectItem.estimatedQuantity?.toString() ?? '',
            remarks: indirectItem.remarks ?? '',
            category1Id: indirectItem.category1Id?.toString() ?? '',
            category2Id: indirectItem.category2Id?.toString() ?? '',
            category3Id: indirectItem.category3Id?.toString() ?? '',
            category4Id: indirectItem.category4Id?.toString() ?? '',
            category1: indirectItem.category1 ?? '',
            category2: indirectItem.category2 ?? '',
            category3: indirectItem.category3 ?? '',
            category4: indirectItem.category4 ?? '',
            id: indirectItem.id
        } : {
            id: '',
            itemBoqId: '',
            description: '',
            page: '',
            customUnit: '',
            unitId: '',
            quantity: '',
            estimatedQuantity: '',
            remarks: '',
            category1Id: '',
            category2Id: '',
            category3Id: '',
            category4Id: '',
            category1: '',
            category2: '',
            category3: '',
            category4: '',
        },
        validationSchema: baseIndirectItemValidationSchema,
        onSubmit: async (values) => {
            if (values) {
                try {
                    if (values && indirectItem) {
                        setIsSaving(true);
                        await updateIndirectItem({
                            companyId: user?.companyId,
                            estimateId: estimateId,
                            indirectItemId: indirectItem?.id,
                            orgId: user?.organizationId,
                            body: {
                                description: values.description,
                                displayId: indirectItem.displayId,
                                id: indirectItem.id,
                                itemBoqId: values.itemBoqId,
                                page: values.page,
                                quantity: parseFloat(values.quantity),
                                category1: values.category1,
                                category1Id: parseInt(values.category1Id),
                                category2: values.category2,
                                category2Id: parseInt(values.category2Id),
                                category3: values.category3,
                                category3Id: parseInt(values.category3Id),
                                category4: values.category4,
                                category4Id: parseInt(values.category4Id),
                                unitId: values.unitId,
                                customUnit: (!values.unitId) ? values.customUnit : undefined,
                                remarks: values.remarks,
                                estimatedQuantity: parseFloat(values.estimatedQuantity)
                            }
                        });
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    setIsSaving(false);
                }
            }
        },
    });

    const onAmountUpdate = useCallback((estAmountSum: number, amountSum: number) => {
        const decs = (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2;
        const quantity = (!isNaN(parseFloat(formik.values.quantity))) ? parseFloat(formik.values.quantity) : 0;
        setUnitCost(rounder(amountSum, decs));
        setEstCost(rounder(estAmountSum, decs));
        setCost(rounder(amountSum * quantity, decs));
    }, [estimate?.CompanyCurrency?.Currency?.minorUnit, formik.values.quantity])

    const breadcrumbs = useMemo(() => {
        if (estimate?.isMaster) {
            return [
                <Link
                    component={RouterLink}
                    to={`/organization/${user?.organizationId}/company/${user?.companyId}/master/dashboard`}
                    underline="hover"
                    key="1"
                    color="inherit"
                >
                    Template
                </Link>,
                <Link
                    component={RouterLink}
                    to={`/organization/${user?.organizationId}/company/${user?.companyId}/master/${estimateId}/indirect-items`}
                    underline="hover"
                    key="1"
                    color="inherit"
                >
                    {estimate?.name}
                </Link>,
                <Typography key="2" color="text.primary">
                    Item: {indirectItem?.displayId}
                </Typography>
            ]
        } else {
            return [
                <Link
                    component={RouterLink}
                    to={`/organization/${user?.organizationId}/company/${user?.companyId}/estimate/dashboard`}
                    underline="hover"
                    key="1"
                    color="inherit"
                >
                    Estimates
                </Link>,
                <Link
                    component={RouterLink}
                    to={`/organization/${user?.organizationId}/company/${user?.companyId}/estimate/${estimateId}/indirect-items`}
                    underline="hover"
                    key="1"
                    color="inherit"
                >
                    {estimate?.name}
                </Link>,
                <Typography key="2" color="text.primary">
                    Item: {indirectItem?.displayId}
                </Typography>
            ]
        }

    }, [estimate?.isMaster, estimate?.name, estimateId, indirectItem?.displayId, user?.companyId, user?.organizationId]);

    useEffect(() => {
        if (storedIndirectItemDetails && storedIndirectItemDetails.some((indirectItemDetail) => (indirectItemDetail.type === "item"))) {
            setSaveMenuItems([{ option: "Save to library items", disabled: true }]);
        } else {
            setSaveMenuItems([{ option: "Save to library items", disabled: false }]);
        }
    }, [breadcrumbs, indirectItemId, storedIndirectItemDetails])

    const toItem = useCallback((value: NavItem | null) => {
        if (value) {
            navigate(`../indirect-item/${value.itemId}`);
        }
    }, [navigate])

    const onInputChange = useCallback(async (event: SyntheticEvent, newValue: string | null) => {
        try {
            if (user) {
                const items = await getFilteredNavIndirectItems({
                    companyId: user.companyId ?? '',
                    estimateId: estimateId ?? '',
                    filterText: newValue ?? '',
                    organizationId: user.organizationId
                }).unwrap();
                setOptions(items);
            }
        } catch (error) {
            console.log(error);
        }
    }, [estimateId, getFilteredNavIndirectItems, user])

    const onChange = useCallback(async (event: SyntheticEvent, newValue: NavItem | null) => {
        if (newValue) {
            toItem(newValue);
        }
        setOpen(false);
    }, [toItem]);

    const next = useCallback(() => {
        if (nav && indirectItem) {
            toItem(nav.next);
        }
    }, [nav, indirectItem, toItem])

    const previous = useCallback(() => {
        if (nav && indirectItem) {
            toItem(nav.previous)
        }
    }, [nav, indirectItem, toItem])

    const saveCategories = useCallback((selectedCategories: SelectedCategories) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (selectedCategories.category1) {
                    formik.setFieldValue('category1Id', selectedCategories.category1.id);
                    formik.setFieldValue('category1', selectedCategories.category1.category);
                } else {
                    formik.setFieldValue('category1Id', undefined);
                    formik.setFieldValue('category1', undefined);
                }
                if (selectedCategories.category2) {
                    formik.setFieldValue('category2Id', selectedCategories.category2.id);
                    formik.setFieldValue('category2', selectedCategories.category2.category);
                } else {
                    formik.setFieldValue('category2Id', undefined);
                    formik.setFieldValue('category2', undefined);
                }
                if (selectedCategories.category3) {
                    formik.setFieldValue('category3Id', selectedCategories.category3.id);
                    formik.setFieldValue('category3', selectedCategories.category3.category);
                } else {
                    formik.setFieldValue('category3Id', undefined);
                    formik.setFieldValue('category3', undefined);
                }
                if (selectedCategories.category4) {
                    formik.setFieldValue('category4Id', selectedCategories.category4.id);
                    formik.setFieldValue('category4', selectedCategories.category4.category);
                } else {
                    formik.setFieldValue('category4Id', undefined);
                    formik.setFieldValue('category4', undefined);
                }
                await formik.submitForm();
                resolve();
            } catch (error) {
                reject();
            }
        });
    }, [formik]);

    const renderSwitch = useCallback((item: BaseIndirectItem) => {
        let category = '';
        switch (estimate?.indirectItemNoOfLevels) {
            default:
            case 1:
                category = item.category1 ?? '';
                break
            case 2:
                category = `${item.category1} / ${item.category2}`;
                break;
            case 3:
                category = `${item.category1} / ${item.category2} / ${item.category3}`;
                break;
            case 4:
                category = `${item.category1} / ${item.category2} / ${item.category3} / ${item.category4}`;
                break;
        }
        return <OutlinedInput
            inputRef={categoryRef}
            size="small"
            disabled={true}
            endAdornment={
                <InputAdornment position="end">
                    <Box display="flex" width="16px" height="16px" color={`${colors.gray[500]}`}>
                        <IconButton disabled={editDisabled} aria-label="edit item" size="small" color="inherit" onClick={handleOpenCategoryPopover}>
                            <ModeEditIcon fontSize="inherit" />
                        </IconButton>
                    </Box>
                </InputAdornment>
            }
            sx={{
                '& .MuiInputBase-input': {
                    color: "black",
                    fontColor: "black",
                    WebkitTextFillColor: "black"
                },
                width: "320px",
                height: "28px",
                borderRadius: "7px",
                fontWeight: "600",
                border: `1px solid ${colors.gray[800]}`,

            }}
            value={category} />
    }, [colors.gray, editDisabled, estimate?.indirectItemNoOfLevels])

    const indirectItemFieldEdit = useCallback(() => {
        setIndirectItemDisabled(false);
        setIndirectItemFocus(true);
    }, [])

    const indirectItemBlur = useCallback((event: React.FocusEvent) => {
        setIndirectItemDisabled(true);
        setIndirectItemFocus(false);
        formik.handleBlur(event);
        formik.submitForm();
    }, [formik])

    const pageFieldEdit = useCallback(() => {
        setPageDisabled(false);
        setPageFocus(true);
    }, [])

    const pageBlur = useCallback((event: React.FocusEvent) => {
        setPageDisabled(true);
        setPageFocus(false);
        formik.handleBlur(event);
        formik.submitForm();
    }, [formik])

    useEffect(() => {
        if (indirectItemFocus && indirectItemFieldRef.current) {
            indirectItemFieldRef.current.focus();
        }
    }, [indirectItemFocus])

    useEffect(() => {
        if (pageFocus && pageFieldRef.current) {
            pageFieldRef.current.focus();
        }
    }, [pageFocus])

    const routeToIndirectItemOnEnter = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            const item = options?.find((i) => (i.displayId.toString() === (event.target as HTMLInputElement).value));
            toItem(item ?? null);
        }
    }

    const saveOnEnter = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            (event.target as HTMLInputElement).blur();
        }
    }, [])

    const showHideForm = useCallback(() => {
        setShowForm(!showForm);
    }, [showForm])


    const saveAsStandard = useCallback(async (overwrite: boolean) => {
        try {

            if (user) {
                await saveIndirectItemAsStandardItem({
                    orgId: user.organizationId,
                    companyId: user.companyId,
                    estimateId: estimateId,
                    indirectItemId: indirectItem?.id,
                    overwrite: overwrite
                }).unwrap();
            }
        } catch (error: any) {
            if (('status' in error) &&
                error.status === 400 &&
                ('data' in error) &&
                ('description' in error.data)) {
                try {
                    await confirm({ cancellationText: "No, cancel", confirmationText: "Yes, continue", description: `A library item with the same description already exists. Do you want to overwrite it?`, title: 'Overwrite confirmation' });
                    await saveAsStandard(true);
                } catch (error) {
                    // User has cancelled. Do nothing
                }
            }
        }
    }, [confirm, estimateId, indirectItem?.id, saveIndirectItemAsStandardItem, user])

    const handleClose = useCallback(async (option?: string) => {
        try {
            if (option === "Save to library items") {
                await confirm({ cancellationText: "No, cancel", confirmationText: "Yes, continue", description: `Do you want to save this item to the estimate library?`, title: 'Save confirmation' });
                await saveAsStandard(false);
            } else {
                formik.handleSubmit();
            }
        } catch (error: any) {
            // User has cancelled. Do nothing
        }

    }, [confirm, formik, saveAsStandard])

    if (!isReady) {
        return <></>
    }

    if (!hasViewAccess) {
        return <Box m="10px">Unauthorized access.</Box>
    }

    return <>{indirectItem && nav &&
        <>
            <form noValidate onSubmit={formik.handleSubmit}>
                <Box display="flex" alignItems='center' justifyContent="space-between" paddingLeft="24px" paddingRight="0px" borderBottom={`1px solid ${colors.gray[800]}`} height="57px">
                    <Breadcrumbs
                        sx={{
                            " & .MuiBreadcrumbs-li": {
                                color: `${colors.gray[500]}`
                            },
                            " & .MuiBreadcrumbs-separator": {
                                color: `${colors.gray[700]}`
                            }
                        }}
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                    <Box display="flex">
                        <Button
                            variant="outlined"
                            disabled={!nav.previous}
                            onClick={previous}
                            sx={{
                                marginRight: "4px",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                minWidth: "15px",
                                padding: "7px",
                                ' & .MuiButton-startIcon': {
                                    marginRight: "0px",
                                    marginLeft: "0px"
                                },
                            }}
                            startIcon={<KeyboardArrowLeftIcon sx={{ marginRight: "0px" }} />} >
                        </Button>
                        <Autocomplete
                            size="small"
                            autoHighlight
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            onKeyDown={routeToIndirectItemOnEnter}
                            value={nav.current ?? undefined}
                            isOptionEqualToValue={(option, value) => (option.itemId === value.itemId)}
                            getOptionLabel={(i) => (i.displayId.toString())}
                            onChange={onChange}
                            onInputChange={onInputChange}
                            options={options}
                            filterOptions={(x) => x}
                            sx={{ width: 100 }}
                            renderInput={(params) => <TextField label="Item ID" {...params} />}
                        />
                        <Button
                            variant="outlined"
                            disabled={!nav.next}
                            onClick={next}
                            sx={{
                                marginLeft: "4px",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                minWidth: "15px",
                                padding: "7px",
                                ' & .MuiButton-startIcon': {
                                    marginRight: "0px",
                                    marginLeft: "0px"
                                },
                            }}
                            startIcon={<KeyboardArrowRightIcon sx={{ marginRight: "0px" }} />} >
                        </Button>
                    </Box>
                    <ProjectPrice estimateId={estimateId} />
                </Box>
                <Box display="flex" width="100%" justifyContent="space-between" alignContent="center" alignItems="center" padding="20px 24px" borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box display="flex">
                        <Box
                            display="flex"
                            fontSize="16px"
                            fontWeight="600"
                            marginRight="12px"
                            alignItems="center">
                            Item ID: {indirectItem?.displayId}
                        </Box>

                        <OutlinedInput
                            size="small"
                            inputRef={indirectItemFieldRef}
                            disabled={indirectItemDisabled || editDisabled}
                            onBlur={indirectItemBlur}
                            autoComplete="off"
                            id="itemBoqId"
                            name="itemBoqId"
                            onKeyDown={saveOnEnter}
                            onChange={formik.handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Box display="flex" width="16px" height="16px" color={`${colors.gray[500]}`}>
                                        <IconButton disabled={editDisabled} aria-label="edit item" size="small" color="inherit" onClick={indirectItemFieldEdit}>
                                            <ModeEditIcon fontSize="inherit" />
                                        </IconButton>
                                    </Box>
                                </InputAdornment>
                            }
                            sx={{
                                '& .MuiInputBase-input': {
                                    color: "black",
                                    fontColor: "black",
                                    WebkitTextFillColor: "black"
                                },
                                marginRight: "12px",
                                width: "120px",
                                height: "28px",
                                borderRadius: "7px",
                                fontWeight: "600",
                                border: `1px solid ${colors.gray[800]}`,

                            }}
                            value={(indirectItemDisabled) ? `Item: ${formik.values.itemBoqId}` : formik.values.itemBoqId} />
                        <OutlinedInput
                            size="small"
                            id="page"
                            name="page"
                            autoComplete="off"
                            onKeyDown={saveOnEnter}
                            onChange={formik.handleChange}
                            inputRef={pageFieldRef}
                            disabled={pageDisabled || editDisabled}
                            onBlur={pageBlur}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Box display="flex" width="16px" height="16px" color={`${colors.gray[500]}`}>
                                        <IconButton disabled={editDisabled} aria-label="edit page" size="small" color="inherit" onClick={pageFieldEdit}>
                                            <ModeEditIcon fontSize="inherit" />
                                        </IconButton>
                                    </Box>
                                </InputAdornment>
                            }
                            sx={{
                                '& .MuiInputBase-input': {
                                    color: "black",
                                    fontColor: "black",
                                    WebkitTextFillColor: "black"
                                },
                                marginRight: "12px",
                                width: "120px",
                                height: "28px",
                                borderRadius: "7px",
                                fontWeight: "600",
                                border: `1px solid ${colors.gray[800]}`
                            }}
                            value={(pageDisabled) ? `Page: ${formik.values.page}` : formik.values.page} />
                        {estimate && (estimate?.indirectItemNoOfLevels > 0) &&
                            <Box justifyContent="space-between"
                                ref={categoryRef}
                                display="flex"
                                alignItems="center"
                                width="auto"
                                height="28px">
                                {renderSwitch(indirectItem)}
                            </Box>
                        }
                    </Box>
                    <Box>
                        <SplitButton buttonIcon={!isSaving ? <SaveIcon /> : <CircularProgress sx={{ color: "white" }} size="1rem" />} disabled={isSaving || editDisabled} buttonHeight="35px" options={saveMenuItems} buttonText="Save" onButtonClick={handleClose} onMenuItemClick={handleClose} />
                    </Box>
                </Box>
                <Box flexDirection="column" sx={{ display: (showForm) ? 'flex' : 'none' }}>
                    <Box display="flex" width="100%" gap="24px" padding="6px 24px 25px 24px" >
                        <Box flex="0.7">
                            <Box display="flex" flexDirection="column" marginBottom="15px" height="100%">
                                <label htmlFor="description" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Description</label>
                                <OutlinedInput
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            border: "0",
                                            height: "100% !important"
                                        },
                                        border: `1px solid ${colors.gray[800]}`,
                                        borderRadius: "10px",
                                        padding: "3.5px 5px"
                                    }}
                                    size="small"
                                    id="description"
                                    name="description"
                                    disabled={editDisabled}
                                    placeholder="Description"
                                    autoComplete="off"
                                    style={{ height: "100%" }}
                                    rows="5"
                                    multiline
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description} />
                            </Box>
                        </Box>
                        <Box flex="0.4">
                            <Box display="flex" flexDirection="column" height="100%">
                                <label htmlFor="remarks" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Remarks</label>
                                <OutlinedInput
                                    size="small"
                                    id="remarks"
                                    name="remarks"
                                    disabled={editDisabled}
                                    rows="5"
                                    sx={{
                                        height: "100%",
                                        border: `1px solid ${colors.gray[800]}`,
                                        padding: "3.5px 5px",
                                        '& .MuiInputBase-input': {
                                            height: "100% !important"
                                        },
                                    }}
                                    multiline
                                    autoComplete="off"
                                    placeholder="Enter Remarks"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.remarks} />
                            </Box>
                        </Box>
                        <Box flex="1" display="flex" flexDirection="column">
                            <Box display="flex" flexDirection="row" gap="12px">
                                <Box display="flex" flexDirection="column" flex="1">
                                    <label htmlFor="unit" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Unit</label>
                                    {units && units.length > 0 && <Autocomplete
                                        fullWidth
                                        freeSolo
                                        disabled={editDisabled}
                                        sx={{ borderRadius: "10px", height: "40px" }}
                                        value={((formik.values.customUnit) ? formik.values.customUnit : ((units.some((unt) => (unt.id === formik.values.unitId))) ? units.find((unt) => (unt.id === formik.values.unitId))?.description : '')) || null}
                                        size="small"
                                        onChange={(event: React.SyntheticEvent, newValue: string | null) => {
                                            if (newValue) {
                                                if (units) {
                                                    const unit = units.find((unt) => (unt.description === newValue));
                                                    if (unit) {
                                                        formik.setFieldValue('unitId', unit.id);
                                                        formik.setFieldValue('customUnit', '');
                                                    } else {
                                                        formik.setFieldValue('customUnit', newValue);
                                                        formik.setFieldValue('unitId', '');
                                                    }
                                                }
                                            } else {
                                                formik.setFieldValue('unitId', '');
                                                formik.setFieldValue('customUnit', '');
                                            }
                                        }}
                                        onInputChange={(event: React.SyntheticEvent, newValue: string | null) => {
                                            if (newValue) {
                                                if (units) {
                                                    const unit = units.find((unt) => (unt.description === newValue));
                                                    if (unit) {
                                                        formik.setFieldValue('customUnit', '');
                                                        formik.setFieldValue('unitId', unit.id);
                                                    } else {
                                                        formik.setFieldValue('customUnit', newValue);
                                                        formik.setFieldValue('unitId', '');
                                                    }
                                                }
                                            } else {
                                                formik.setFieldValue('unitId', '');
                                                formik.setFieldValue('customUnit', '');
                                            }
                                        }}
                                        options={units.map((unit) => unit.description)}
                                        renderInput={(params) => <GridTextField name="unit" sx={{ height: "100%" }} inputRef={unitInputRef} placeholder='Select/Enter Unit' {...params} />}
                                        renderOption={(props, option, { inputValue }) => {
                                            const matches = match(option, inputValue, {
                                                insideWords: true,
                                            });
                                            const parts = parse(option, matches);

                                            return (
                                                <li {...props}>
                                                    <div>
                                                        {parts.map((part, index) => (
                                                            <span
                                                                key={index}
                                                                style={{
                                                                    fontWeight: part.highlight ? 700 : 400,
                                                                }}
                                                            >
                                                                {part.text}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </li>
                                            );
                                        }}
                                    />
                                    }
                                </Box>
                                <Box display="flex" flexDirection="column" flex="1">
                                    <label htmlFor="quantity" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Quantity</label>
                                    <OutlinedInput
                                        sx={{ height: "40px" }}
                                        size="small"
                                        id="quantity"
                                        type="number"
                                        disabled={editDisabled}
                                        autoComplete="off"
                                        name="quantity"
                                        placeholder="Quantity"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.quantity} />
                                </Box>
                                <Box display="flex" flexDirection="column" marginBottom="16px" flex="1">
                                    <label htmlFor="estimatedQuantity" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Estimated Quantity</label>
                                    <OutlinedInput
                                        sx={{ height: "40px", paddingRight: "0px" }}
                                        size="small"
                                        id="estimatedQuantity"
                                        type="number"
                                        autoComplete="off"
                                        disabled={editDisabled}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleOpenCalculateModal} color="primary" sx={{ padding: "0px", paddingRight: "5px" }}>
                                                    <CalculateIcon />
                                                </IconButton>
                                            </InputAdornment>}
                                        name="estimatedQuantity"
                                        placeholder="Estimated Quantity"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.estimatedQuantity} />
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" gap="12px">
                                <Box flex="1" display="flex" padding="3px 6px" border={`1px solid ${colors.primary[300]}`} borderRadius="12px" gap="24px">
                                    <Box padding="6px 0px" flex="1">
                                        <Box color={colors.gray[100]} fontSize="11px" fontStyle="normal" lineHeight="160%" fontWeight="600">Unit Cost:</Box>
                                        <Box display="flex" justifyContent="flex-start">{unitCost}</Box>
                                        <hr />
                                    </Box>
                                    <Box padding="6px 0px" flex="1">
                                        <Box color={colors.gray[100]} fontSize="11px" fontStyle="normal" lineHeight="160%" fontWeight="600">Cost:</Box>
                                        <Box display="flex" justifyContent="flex-start">{cost}</Box>
                                        <hr />
                                    </Box>
                                    <Box padding="6px 0px" flex="1">
                                        <Box color={colors.gray[100]} fontSize="11px" fontStyle="normal" lineHeight="160%" fontWeight="600">Est Cost:</Box>
                                        <Box display="flex" justifyContent="flex-start">{estCost}</Box>
                                        <hr />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
                <Box borderBottom={`1px solid ${colors.gray[800]}`} position="relative">
                    <Box
                        sx={{ backgroundColor: 'white', cursor: 'pointer' }}
                        alignItems="center"
                        display="flex"
                        position="absolute"
                        left="50%"
                        bottom="-12px"
                        height="24px"
                        border={`1px solid ${colors.gray[800]}`}
                        padding="4px 8px"
                        fontSize="11px"
                        fontWeight="600"
                        fontStyle="normal"
                        lineHeight="145%"
                        onClick={showHideForm}
                        color={`${colors.gray[500]}`}
                        borderRadius="7px">{(showForm) ? <>Hide Info <ExpandLessIcon /></> : <>Show Info <ExpandMoreIcon /></>} </Box>
                </Box>
            </form>
            <Box height={(showForm) ? `calc(100vh - 327px)` : `calc(100vh - 150px)`}>
                <IndirectItemDetails previousIndirectItem={nav.previous} estimateId={estimateId} indirectItem={indirectItem} currentForm={formik.values} onAmountUpdate={onAmountUpdate} />
            </Box>
            <Popover
                anchorEl={anchorEl}
                open={openCategoryPopover}
                onClose={handleCloseCategoryPopover}
                sx={{
                    zIndex: 900,
                    background: "transparent",
                    '& .MuiPaper-root': {
                        background: "transparent",
                        boxShadow: "none",
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box width="320px" height="auto" boxShadow={`0px 4px 12px 0px rgba(6, 22, 55, 0.12), 0px 2px 8px 0px rgba(6, 22, 55, 0.08)`} borderRadius="14px" border={`1px solid ${colors.primary[300]}`} sx={{ backgroundColor: "white" }}>
                    <IndirectItemCategoryEdit
                        save={saveCategories}
                        close={handleCloseCategoryPopover}
                        category1Id={indirectItem.category1Id}
                        category2Id={indirectItem.category2Id}
                        category3Id={indirectItem.category3Id}
                        category4Id={indirectItem.category4Id}
                        estimateId={estimateId}
                        noOfLevels={estimate?.indirectItemNoOfLevels} />
                </Box>
            </Popover>
        </>
    }
        <Modal
            open={openCalculateModal}
            onClose={handleCloseCalculateModal}
            aria-labelledby="edit-items-title"
            aria-describedby="edit-items-description"
        >
            <Box className="calculator-modal">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    marginBottom="10px"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" color="primary">Calculate Quantity</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="edit" color="primary" onClick={handleCloseCalculateModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                {indirectItem && <QuantityCalculator type="indirect-item" estimateId={estimateId} directIndirectItemId={indirectItemId} close={handleCloseCalculateModal} />}
            </Box>
        </Modal>
    </>
}