import { createContext, useState, useMemo } from 'react';
import { ThemeOptions, createTheme } from '@mui/material/styles';

export const tokens = (mode: 'dark' | 'light') => ({
    ...((mode === 'dark') ? {
        gray: {
            100: "#F7F7F8",
            200: "#F4F4F6",
            300: "#E9EAED",
            400: "#E3E4E8",
            500: "#DDDFE3",
            600: "#C7CAD1",
            700: "#A5AAB5",
            800: "#838A9A",
            900: "#89909F",
            1000: "#6E7587",
            1100: "#494E5A",
            1200: "#31343C"
        },
        primary: {
            100: "#F1F5FD",
            200: "#EDF2FD",
            300: "#E4EBFC",
            400: "#DAE4FB",
            500: "#D1DDFA",
            600: "#7FA1F0",
            700: "#4879EA",
            800: "#245EE5",
            900: "#1A54DB",
            1000: "#184DC9"
        },
        greenAccent: {
            100: "#def1eb",
            200: "#bce3d8",
            300: "#9bd5c4",
            400: "#79c7b1",
            500: "#58b99d",
            600: "#46947e",
            700: "#356f5e",
            800: "#234a3f",
            900: "#12251f"
        },
        redAccent: {
            100: "#f8dcdb",
            200: "#f1b9b7",
            300: "#e99592",
            400: "#e2726e",
            500: "#db4f4a",
            600: "#D32F2F",
            700: "#832f2c",
            800: "#58201e",
            900: "#2c100f"
        },
        purpleAccent: {
            100: "#ecdcf9",
            200: "#d9b9f3",
            300: "#c795ec",
            400: "#b472e6",
            500: "#a14fe0",
            600: "#813fb3",
            700: "#612f86",
            800: "#40205a",
            900: "#20102d"
        },
        blueAccent: {
            100: "#dbecfc",
            200: "#b8daf9",
            300: "#94c7f5",
            400: "#71b5f2",
            500: "#4da2ef",
            600: "#3e82bf",
            700: "#2e618f",
            800: "#1f4160",
            900: "#0f2030"
        },
    } : {
        gray: {
            100: "#31343C",
            200: "#494E5A",
            300: "#6E7587",
            400: "#89909F",
            500: "#838A9A",
            600: "#A5AAB5",
            700: "#C7CAD1",
            800: "#DDDFE3",
            900: "#E3E4E8",
            1000: "#E9EAED",
            1100: "#F4F4F6",
            1200: "#F7F7F8"
        },
        primary: {
            100: "#184DC9",
            200: "#1A54DB",
            300: "#245EE5",
            400: "#4879EA",
            500: "#7FA1F0",
            600: "#D1DDFA",
            700: "#DAE4FB",
            800: "#E4EBFC",
            900: "#EDF2FD",
            1000: "#FFFFFF"
        },
        greenAccent: {
            100: "#12251f",
            200: "#234a3f",
            300: "#356f5e",
            400: "#46947e",
            500: "#58b99d",
            600: "#79c7b1",
            700: "#9bd5c4",
            800: "#bce3d8",
            900: "#def1eb",
        },
        redAccent: {
            100: "#2c100f",
            200: "#58201e",
            300: "#832f2c",
            400: "#D32F2F",
            500: "#db4f4a",
            600: "#e2726e",
            700: "#e99592",
            800: "#f1b9b7",
            900: "#f8dcdb",
        },
        purpleAccent: {
            100: "#20102d",
            200: "#40205a",
            300: "#612f86",
            400: "#813fb3",
            500: "#a14fe0",
            600: "#b472e6",
            700: "#c795ec",
            800: "#d9b9f3",
            900: "#ecdcf9"
        },
        blueAccent: {
            100: "#0f2030",
            200: "#1f4160",
            300: "#2e618f",
            400: "#3e82bf",
            500: "#4da2ef",
            600: "#71b5f2",
            700: "#94c7f5",
            800: "#b8daf9",
            900: "#dbecfc",
        },
    })
});



// Material UI theme settings

export const themeSettings = (mode: 'dark' | 'light'): ThemeOptions => {
    const colors = tokens(mode);

    return {
        palette: {
            mode: mode,
            ...((mode === 'dark') ? {
                primary: {
                    main: colors.primary[500]
                },
                secondary: {
                    main: colors.greenAccent[500],
                },
                neutral: {
                    dark: colors.gray[700],
                    main: colors.gray[500],
                    light: colors.gray[100]
                },
                background: {
                    default: colors.primary[500],
                }
            } : {
                primary: {
                    main: colors.primary[300]
                },
                secondary: {
                    main: colors.greenAccent[500],
                },
                neutral: {
                    dark: colors.gray[700],
                    main: colors.gray[500],
                    light: colors.gray[100]
                },
                background: {
                    default: '#fcfcfc',
                }
            })
        },
        components: {
            MuiLink: {
                styleOverrides: {
                    root: {
                        '&[disabled]': {
                            color: 'Gray',
                            pointerEvents: 'none',
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        textTransform: "none",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "150%"
                    },
                },
            },
            MuiToggleButton: {
                styleOverrides: {
                    // Name of the slot
                    root: {
                        textTransform: "none",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "150%"
                    },
                },
            }
        },
        typography: {
            fontFamily: ['Source Sans 3', "sans-serif"].join(","),
            fontSize: 12,
            h1: {
                fontFamily: ['Source Sans 3', "sans-serif"].join(","),
                fontSize: 40
            },
            h2: {
                fontFamily: ['Source Sans 3', "sans-serif"].join(","),
                fontSize: 32
            },
            h3: {
                fontFamily: ['Source Sans 3', "sans-serif"].join(","),
                fontSize: 24
            },
            h4: {
                fontFamily: ['Source Sans 3', "sans-serif"].join(","),
                fontSize: 20
            },
            h5: {
                fontFamily: ['Source Sans 3', "sans-serif"].join(","),
                fontSize: 16
            },
            h6: {
                fontFamily: ['Source Sans 3', "sans-serif"].join(","),
                fontSize: 14
            },
        }
    };
}

// Context for color mode
export const ColorModeContext = createContext({
    toggleColorMode: () => { }
});

export const useMode = () => {
    const [mode, setMode] = useState<'dark' | 'light'>('light');

    const colorMode = useMemo(() => ({
        toggleColorMode: () =>
            setMode((prev: 'dark' | 'light') => (prev === 'light' ? 'dark' : 'light')),
    }), [])

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return { theme, colorMode };
}