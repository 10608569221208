import { Box, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from "react";
import { useAppSelector } from "State/hooks";
import { IndirectItemView } from "Models/indirect-item";
import { selectIndirectItemState } from "State/indirectItemSlice";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
export interface ICancelCellRendererProps extends ICellRendererParams<IndirectItemView> {
    delete: (node: IRowNode<IndirectItemView>) => Promise<void>;
    save: (node: IRowNode<IndirectItemView>) => Promise<void>;
    cancel: (node: IRowNode<IndirectItemView>) => void;
    disabled: boolean;
}

export default function SaveCancelItemCellRenderer(props: ICancelCellRendererProps) {
    const { node } = props;
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const indirectItemState = useAppSelector(selectIndirectItemState);
    let editingCells = props.api.getEditingCells();
    let isCurrentRowEditing = editingCells.some((cell) => {
        return cell.rowIndex === node.rowIndex;
    });

    const deleteRec = async () => {
        try {
            if (isCurrentRowEditing) {
                props.cancel(node);
            } else {
                setIsDeleting(true);
                await props.delete(node);
                setIsDeleting(false);
            }
        } catch (error) {
            setIsDeleting(false);
            console.log(error);
        }
    }

    const saveRec = async () => {
        try {
            setIsSaving(true);
            await props.save(node);
            setIsSaving(false);
        } catch (error) {
            setIsSaving(false);
        }
    }

    return (<>
        {!node.group && node.data &&
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                {isCurrentRowEditing && !isSaving &&
                    <IconButton aria-label="save" color="primary" onClick={saveRec} disabled={!indirectItemState.isRowValid}>
                        <SaveIcon />
                    </IconButton>
                }
                {isSaving &&
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress size="1rem" />
                    </Box>
                }
                {!isDeleting &&
                    <IconButton aria-label="delete" onClick={deleteRec} disabled={props.disabled}>
                        {isCurrentRowEditing && <CloseIcon />}
                        {!isCurrentRowEditing && <DeleteOutlineIcon />}
                    </IconButton>
                }
                {isDeleting &&
                    <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <CircularProgress size="1rem" />
                    </Box>
                }
            </Box>}
    </>
    );
};
