import { Alert, Autocomplete, Box, Button, CircularProgress, IconButton, MenuItem, TextField, Typography, useTheme } from "@mui/material";
import { CompanyCurrency } from "Models/company-currency";
import { BaseMaster, Master } from "Models/master";
import { useGetCompanyCurrenciesQuery } from "State/Services/company-currency";
import { useGetUserDetailsQuery } from "State/Services/user";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { tokens } from "theme";
import * as yup from 'yup';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import { Errors } from "Models/errors";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    p: 2,
};

const masterValidationSchema = yup.object<BaseMaster>({
    name: yup
        .string()
        .required('Name is required'),
    description: yup
        .string()
        .optional(),
    client: yup
        .string()
        .optional(),
    noOfLevels: yup
        .number()
        .min(0, 'No of categories cannot be negative')
        .max(4, 'A maximum of 4 categories is supported')
        .required('Categories are required'),
    indirectItemNoOfLevels: yup
        .number()
        .min(0, 'No of categories cannot be negative')
        .max(4, 'A maximum of 4 categories is supported')
        .required('Categories are required'),
});

export interface CreateEditCopyMasterProps {
    save: (master: Master, state: "newOrEdit" | "copy") => Promise<void>;
    close: () => void;
    master?: Master;
    state: "newOrEdit" | "copy";
}

export default function CreateEditCopyMaster(props: CreateEditCopyMasterProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const { data: user } = useGetUserDetailsQuery();
    const { data: companyCurrencies } = useGetCompanyCurrenciesQuery({ companyId: (user && user.companyId) ? user.companyId : '', orgId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [companyCurrenciesExtended, setCompanyCurrenciesExtended] = useState<Array<CompanyCurrency & { currencyOption: string }>>([]);
    const [isSaving, setIsSaving] = useState(false);
    const [pageError, setPageError] = useState<string | undefined>();

    const { setValues, setFieldValue, resetForm, setFieldError, ...formik } = useFormik<Master>({
        initialValues: {
            id: props?.master?.id ?? '',
            name: props?.master?.name ?? '',
            noOfLevels: props?.master?.noOfLevels ?? 0,
            indirectItemNoOfLevels: props?.master?.indirectItemNoOfLevels ?? 0,
            companyCurrencyId: props?.master?.companyCurrencyId ?? '',
            companyCurrencyOption: props.master?.companyCurrencyOption,
            currencyExchangeRate: props?.master?.currencyExchangeRate,
            description: props?.master?.description ?? ''
        },
        enableReinitialize: true,
        validationSchema: masterValidationSchema,
        onSubmit: async (values) => {
            try {
                setIsSaving(true);
                await props.save(values, props.state);
                setIsSaving(false);
            } catch (error) {
                try {
                    setIsSaving(true);
                    await props.save(values, props.state);
                    setIsSaving(false);
                } catch (error: any) {
                    setIsSaving(false);
                    if (error && error?.data && 'name' in error.data) {
                        setFieldError('name', error.data.name);
                    } else {
                        setPageError(Errors.generic);
                    }
                }
            }
        },
    });

    useEffect(() => {
        if (companyCurrencies) {
            setCompanyCurrenciesExtended(companyCurrencies.map((currency) => ({ ...currency, currencyOption: `${currency.alphabeticalCode} - ${currency.currency}` })))
        }
    }, [companyCurrencies])

    const currencyChanged = useCallback((event: any, value: CompanyCurrency & { currencyOption: string } | null) => {
        if (value) {
            setFieldValue('companyCurrency', value);
            setFieldValue('companyCurrencyId', value.id);
        }
    }, [setFieldValue])

    const clearCloseModal = useCallback(() => {
        resetForm();
        props.close();
    }, [props, resetForm])

    return <Box sx={style}>
        <Box
            display="flex"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            marginBottom="10px"
            borderBottom={`1px solid ${colors?.gray[800]}`}>
            <Box padding="10px">
                {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>{props.state === "copy" ? 'Duplicate as template ' : formik.values.id ? 'Edit template' : 'Create new template'}</Typography>}
            </Box>
            <Box>
                <IconButton aria-label="delete" onClick={props.close}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>
        <Box>
            {pageError && <Box marginTop="10px" marginBottom="15px"><Alert severity="error">{pageError}</Alert></Box>}
            <form noValidate onSubmit={formik.handleSubmit}>
                <TextField
                    style={{ marginBottom: '10px' }}
                    fullWidth
                    id="name"
                    name="name"
                    label="Template Name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                />
                <TextField
                    style={{ marginBottom: '10px' }}
                    fullWidth
                    id="description"
                    name="description"
                    label="Description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                />
                <Box display="flex">
                    {companyCurrenciesExtended && companyCurrenciesExtended.length > 0 && <Box flex="1"><Autocomplete
                        fullWidth
                        disabled={!!props?.master?.id || props.state === "copy"}
                        className="ag-input-field-input ag-text-field-input"
                        sx={{
                            height: "inherit", "& .MuiTextField-root:": { height: "inherit" },
                            marginBottom: '15px'
                        }}
                        onChange={currencyChanged}
                        options={companyCurrenciesExtended}
                        getOptionLabel={(option) => option.currencyOption ?? ''}
                        isOptionEqualToValue={(option, value) => (option.id === value.id)}
                        value={formik.values.companyCurrencyOption ?? null}
                        renderInput={(params) => <TextField name="Seccurrency" sx={{ height: "100%" }} placeholder='Select Currency' {...params} />}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.currencyOption ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.currencyOption ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    /></Box>
                    }
                </Box>

                <TextField style={{ marginBottom: '10px' }}
                    select
                    fullWidth
                    disabled={!!formik.values.id || props.state === "copy"}
                    id="noOfLevels"
                    name="noOfLevels"
                    label="Direct Item Category Levels"
                    value={formik.values.noOfLevels}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}>
                    <MenuItem value={0}>None</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                </TextField>
                <TextField style={{ marginBottom: '10px' }}
                    select
                    fullWidth
                    id="indirectItemNoOfLevels"
                    disabled={!!formik.values.id || props.state === "copy"}
                    name="indirectItemNoOfLevels"
                    label="Indirect Item Category Levels"
                    value={formik.values.indirectItemNoOfLevels}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.indirectItemNoOfLevels && Boolean(formik.errors.indirectItemNoOfLevels)}
                    helperText={formik.touched.indirectItemNoOfLevels && formik.errors.indirectItemNoOfLevels}
                    inputProps={{ type: 'number' }}>
                    <MenuItem value={0}>None</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                </TextField>
                <Box display="flex" justifyContent="space-evenly">
                    <Button color="primary" variant="contained" type="submit" disabled={isSaving}
                        startIcon={!isSaving ? <SaveIcon /> : <CircularProgress sx={{ color: "white" }} size="1rem" />}>
                        Submit
                    </Button>
                    <Button variant="outlined" onClick={clearCloseModal}>
                        Cancel
                    </Button>
                </Box>
            </form>
        </Box>
    </Box>
}