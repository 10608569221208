import { Alert, Box, Button, useTheme } from "@mui/material";
import { UnitCategory, UnitExtended, UnitType } from "Models/unit";
import { CellClickedEvent, CellKeyDownEvent, IRowNode, RowDragEndEvent, RowEditingStartedEvent, RowEditingStoppedEvent, SuppressKeyboardEventParams, ValueFormatterParams } from "ag-grid-community";
import { ColDef, GetRowIdParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "theme";
import { useConfirm } from "material-ui-confirm";
import { flushSync } from "react-dom";
import { v4 as uuidv4 } from 'uuid';
import { useCreateUnitMutation, useDeleteUnitMutation, useGetUnitsQuery, useLazyGetUnitsQuery, useReorderUnitsMutation, useUpdateUnitMutation } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import { UnitEditCellRenderer } from "./UnitEditCellRenderer";
import SaveCancelUnitsCellRenderer from "./SaveCancelUnitsCellRenderer";
import AddIcon from '@mui/icons-material/Add';
import { hasSystemRolePermission } from "Helpers/role-permissions";
import { ServerError } from "Models/error-info";
import { Errors } from "Models/errors";

export default function Units() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data: user } = useGetUserDetailsQuery();
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [getUnits] = useLazyGetUnitsQuery();
    const [userUnits, setUserUnits] = useState<UnitExtended[]>([]);
    const [systemUnits, setSystemUnits] = useState<UnitExtended[]>([]);
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<UnitExtended> | undefined, column?: string }>();
    const confirm = useConfirm();
    const gridRef = useRef<AgGridReact<UnitExtended>>(null);
    const [saveUnit] = useCreateUnitMutation();
    const [updateUnit] = useUpdateUnitMutation();
    const [deleteUnit] = useDeleteUnitMutation();
    const [reorderUnits] = useReorderUnitsMutation();
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const [errors, setErrors] = useState<Array<{ field: string, error: string }>>([]);
    const [disableAdd, setDisableAdd] = useState(false);
    const [hasAddEditAccess, setHasAddEditAccess] = useState(false);
    const hasAddEditAccessRef = useRef<boolean>();
    const [pageError, setPageError] = useState<string | undefined>();

    const defaultUnitColDef = useMemo<ColDef>(() => {
        return {
            editable: true
        };
    }, []);

    useEffect(() => {
        if (user) {
            const hasAccess = hasSystemRolePermission(user, [300]);
            setHasAddEditAccess(hasAccess);
            hasAddEditAccessRef.current = hasAccess;
        }
    }, [user])

    useEffect(() => {
        const userUnits = new Array<UnitExtended>();
        const systemUnits = new Array<UnitExtended>();
        getUnits({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, true).unwrap().then((units) => {
            units?.forEach((unit) => {
                if (unit.type === UnitType.User) {
                    userUnits.push({
                        id: unit.id,
                        description: unit.description,
                        isNew: false,
                        category: unit.category,
                        companyId: user?.companyId,
                        fullDescription: unit.fullDescription,
                        type: UnitType.User,
                        actions: '',
                        canDelete: unit.canDelete,
                        order: unit.order
                    });
                } else {
                    systemUnits.push({
                        id: unit.id,
                        description: unit.description,
                        isNew: false,
                        category: unit.category,
                        companyId: user?.companyId,
                        fullDescription: unit.fullDescription,
                        type: UnitType.System,
                        actions: '',
                        canDelete: unit.canDelete,
                        order: unit.order
                    });
                }
            });
            setUserUnits(userUnits);
            setSystemUnits(systemUnits);
        });
    }, [getUnits, units, user, user?.companyId])

    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((errorDetails) => {
                switch (errorDetails.field) {
                    case 'description':
                        const descriptionInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['description']
                        });
                        if (descriptionInstances && descriptionInstances.length > 0 && descriptionInstances[0] && typeof (descriptionInstances[0] as any).setError === 'function') {
                            (descriptionInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    }, [errors])

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            setDisableAdd(true);
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node.rowIndex,
                colKey: currentEditing.column ?? 'description',
            });
        } else {
            setDisableAdd(false);
        }
    }, [currentEditing])



    const getUnitRowId = useCallback(function (params: GetRowIdParams<UnitExtended>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    const saveUpdateUnit = useCallback(async (nodeToSave: IRowNode<UnitExtended>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<UnitExtended>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (user && nodeToSave.data) {
                    gridRef.current!.api.stopEditing();
                    if (!nodeToSave.data.description) {
                        const error: ServerError = { data: { description: 'Unit is required' } };
                        throw error;
                    }
                    if (!nodeToSave.data.fullDescription) {
                        const error: ServerError = { data: { fullDescription: 'Description is required' } };
                        throw error;
                    }
                    if (nodeToSave.data.id) {
                        if (nodeToSave.data.isNew) {
                            await saveUnit({
                                companyId: user?.companyId,
                                orgId: user.organizationId,
                                body: {
                                    description: nodeToSave.data.description,
                                    fullDescription: nodeToSave.data.fullDescription
                                }
                            }).unwrap();
                        } else {
                            await updateUnit({
                                companyId: user?.companyId,
                                orgId: user.organizationId,
                                unitId: nodeToSave.data.id,
                                body: {
                                    description: nodeToSave.data.description,
                                    fullDescription: nodeToSave.data.fullDescription,
                                    order: nodeToSave.data.order
                                }
                            }).unwrap();
                        }
                    }

                    resolve();
                    if (toEditAfterSave) {
                        setCurrentEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                    } else {
                        setCurrentEditing(undefined);
                    }
                }
            } catch (error: any) {
                if (error.status === 500) {
                    setPageError(Errors.generic);
                    return;
                }
                if (error && error.data) {
                    if (typeof nodeToSave.rowIndex === 'number') {
                        gridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: 'description',
                        });
                    }
                    if (error.data.description) {
                        setErrors([{ field: 'description', error: error.data.description }]);
                    }
                    else if (error?.data?.message && error.data.message[0]) {
                        setErrors([{ field: 'description', error: error.data.message[0] }]);
                    } else if (error.data.page) {
                        setPageError(error.data.page);
                    }
                }
                reject(error);
            }
        });
    }, [saveUnit, updateUnit, user])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const saveUpdateUnitRef = useRef<any>();
    saveUpdateUnitRef.current = saveUpdateUnit;

    const onCellClicked = useCallback(async (event: CellClickedEvent) => {
        if (!hasAddEditAccess) return;

        if (isCancelClicked) {
            setIsCancelClicked(false);
            return;
        };

        if (currentEditing?.node === event.node) {
            return;
        }

        if (event.column.getColId() === 'actions') {
            return;
        }

        if (!currentEditing?.node) {
            setCurrentEditing({ node: event.node, column: event.column.getColId() });
        } else {
            await saveUpdateUnit(currentEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
        }
    }, [currentEditing?.node, hasAddEditAccess, isCancelClicked, saveUpdateUnit])

    const onUnitRowEditingStarted = useCallback((event: RowEditingStartedEvent<UnitExtended>) => {
        setIsCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
        setTimeout(() => {
            const displayInstances = gridRef.current!.api.getCellEditorInstances({
                columns: [currentEditing?.column ?? 'description']
            });
            if (displayInstances && displayInstances.length > 0 && displayInstances[0] && typeof (displayInstances[0] as any).setFocusOnAdd === 'function') {
                (displayInstances[0] as any).setFocusOnAdd();
            }
        }, 100);
    }, [currentEditing?.column])

    const onUnitRowEditingStopped = useCallback(async (event: RowEditingStoppedEvent<UnitExtended>) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const addUnit = useCallback(async () => {
        if (currentEditing) {
            try {
                await confirm({ description: "You have unsaved changes. If you proceed, your current edits will be lost. Do you want to continue?" });
                gridRef.current!.api.stopEditing(true);
                if (currentEditing && currentEditing.node?.data) {
                    if (currentEditing.node?.data.id) {
                        const compositeResourceBeforeEditing = userUnits?.find((unit) => (unit.id === currentEditing?.node?.data?.id));
                        if (compositeResourceBeforeEditing) {
                            gridRef.current!.api.applyTransaction({ update: [{ ...compositeResourceBeforeEditing }] });
                        } else {
                            gridRef.current!.api.applyTransaction({ remove: [currentEditing.node?.data] });
                        }
                    } else {
                        gridRef.current!.api.applyTransaction({ remove: [currentEditing.node?.data] });
                    }
                }
            } catch (error) {
                return;
            }
        }
        let addedCompositeResourceRow = gridRef.current!.api.applyTransaction({
            add: [{
                id: uuidv4(),
                isNew: true,
                description: '',
                actions: '',
                canDelete: true
            }],
            addIndex: 0
        });
        if (addedCompositeResourceRow && addedCompositeResourceRow.add && addedCompositeResourceRow.add.length > 0 && addedCompositeResourceRow.add[0] && typeof addedCompositeResourceRow.add[0].rowIndex === 'number' && addedCompositeResourceRow.add[0].id) {
            if (addedCompositeResourceRow.add[0].id) {
                queueMicrotask(() => flushSync(() => {
                    if (addedCompositeResourceRow && addedCompositeResourceRow.add[0].id) {
                        const node = gridRef.current!.api.getRowNode(addedCompositeResourceRow.add[0].id);
                        if (node) {
                            gridRef.current!.api.ensureNodeVisible(node, "middle");
                            setCurrentEditing({ node: node, column: 'description' });
                        }
                    }
                }));
            }
        }
    }, [confirm, currentEditing, userUnits])

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<UnitExtended>) => {
        if (params.event.key === 'Enter' && params.node) {
            params.event.stopPropagation();

            const save = async () => {
                await saveUpdateUnit(params.node);
            }
            save();
        }
        return true;
    }, [saveUpdateUnit])

    const deleteUnitsRow = useCallback((node: IRowNode<UnitExtended>) => {
        return new Promise<void>(async (resolve) => {
            if (node.data) {
                await deleteUnit({
                    companyId: user?.companyId,
                    unitId: node.data.id,
                    orgId: user?.organizationId
                });
            }
            resolve();
        });
    }, [deleteUnit, user?.companyId, user?.organizationId])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const deleteUnitsEditingRef = useRef<any>();
    deleteUnitsEditingRef.current = deleteUnitsRow;

    const cancelUnitsEditing = useCallback((node: IRowNode<UnitExtended>) => {
        if (node && node.data) {
            if (!node.data.isNew) {
                setIsCancelClicked(true);
                const unit = units?.find(u => u.id === node.data?.id);
                if (unit) {
                    gridRef.current!.api.applyTransaction({ update: [{ ...unit, isNew: false, actions: '' }] });
                }
                gridRef.current!.api.stopEditing(true);
            } else {
                gridRef.current!.api.applyTransaction({ remove: [node.data] });
            }
            setCurrentEditing(undefined);
        }
    }, [units])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const cancelUnitsEditingRef = useRef<any>();
    cancelUnitsEditingRef.current = cancelUnitsEditing;

    const [userUnitColumnDefs] = useState<ColDef<UnitExtended>[]>([
        {
            field: "id",
            hide: true,
            suppressKeyboardEvent: saveOnEnter,
        },
        {
            field: "order",
            hide: true,
            suppressKeyboardEvent: saveOnEnter,
        },
        {
            rowDrag: true,
            field: "description",
            suppressKeyboardEvent: saveOnEnter,
            cellEditor: UnitEditCellRenderer,
            editable: () => !!hasAddEditAccessRef.current,
            headerName: "Unit",
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: "fullDescription",
            suppressKeyboardEvent: saveOnEnter,
            headerName: "Description",
            editable: () => !!hasAddEditAccessRef.current,
            flex: 1,
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
        },
        {
            field: 'actions',
            suppressKeyboardEvent: (params) => saveOnEnter(params),
            resizable: true,
            maxWidth: 60,
            editable: false,
            headerName: '',
            menuTabs: [],
            cellStyle: { textAlign: "left", padding: "0px" } as any,
            cellRendererSelector: () => {
                return {
                    component: SaveCancelUnitsCellRenderer,
                    params: {
                        delete: (node: IRowNode<UnitExtended>) => deleteUnitsEditingRef.current(node),
                        save: (node: IRowNode<UnitExtended>) => saveUpdateUnitRef.current(node),
                        cancel: (node: IRowNode<UnitExtended>) => cancelUnitsEditingRef.current(node),
                        disabled: () => !hasAddEditAccessRef.current
                    },
                };
            },
        },
    ]);

    const [systemUnitColumnDefs] = useState<ColDef<UnitExtended>[]>([
        {
            field: "id",
            hide: true,
        },
        {
            field: "order",
            hide: true,
        },
        {
            field: "category",
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
            hide: true,
            rowGroup: true,
            valueFormatter: (params: ValueFormatterParams<UnitExtended>) => {
                switch (params.value) {
                    case "LengthDistance":
                        return UnitCategory.LengthDistance;
                    case "DensityComplex":
                        return UnitCategory.DensityComplex;
                    default:
                        return params.value;
                }
            }
        },
        {
            field: "description",
            headerName: "Unit",
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: "fullDescription",
            headerName: "Description",
            flex: 1,
            cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
        }
    ]);

    const onRowDragEnd = useCallback(async (event: RowDragEndEvent) => {
        const movedUnit = userUnits.find((unit) => (unit.id === event?.node?.data?.id));
        const overUnit = userUnits.find((unit) => (unit.id === event.overNode?.data?.id));
        if (overUnit && movedUnit) {
            const overNodeOrder = overUnit.order;
            overUnit.order = movedUnit.order;
            movedUnit.order = overNodeOrder;
        }
        await reorderUnits({
            body: userUnits.map((unit) => ({
                order: unit.order ?? 0,
                unitId: unit.id ?? ''
            })),
            companyId: user?.companyId,
            orgId: user?.organizationId
        });
    }, [reorderUnits, user?.companyId, user?.organizationId, userUnits])

    const groupDefaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            minWidth: 100,
        };
    }, []);

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelUnitsEditing(e.node);
            setIsCancelClicked(false);
        }
    }, [cancelUnitsEditing]);

    return <>
        {colors && <Box padding="10px" display="flex" flexDirection="column" height="calc(100% - 160px)" width="100%">
            {pageError && <Box marginBottom="10px"><Alert severity="error">{pageError}</Alert></Box>}
            <Box display="flex" height="100%">
                <Box className="ag-theme-alpine ag-theme-bidbow" flex="1" height="100%" padding="5px">
                    <Button startIcon={<AddIcon />} disabled={disableAdd || !hasAddEditAccess} size="small" variant="contained" sx={{ marginBottom: "10px" }} onClick={addUnit}>New</Button>
                    <AgGridReact<UnitExtended>
                        ref={gridRef}
                        editType={'fullRow'}
                        rowData={userUnits}
                        suppressRowClickSelection={true}
                        columnDefs={userUnitColumnDefs}
                        defaultColDef={defaultUnitColDef}
                        onRowEditingStarted={onUnitRowEditingStarted}
                        onRowEditingStopped={onUnitRowEditingStopped}
                        onCellClicked={onCellClicked}
                        getRowId={getUnitRowId}
                        onRowDragEnd={onRowDragEnd}
                        onCellKeyDown={onCellKeyDown}
                    />
                </Box>
                <Box className="ag-theme-alpine ag-theme-bidbow" flex="1" height="100%" marginTop="37.5px" padding="5px">
                    <AgGridReact<UnitExtended>
                        rowData={systemUnits}
                        groupDisplayType={'groupRows'}
                        groupDefaultExpanded={-1}
                        defaultColDef={groupDefaultColDef}
                        columnDefs={systemUnitColumnDefs}
                    />
                </Box>
            </Box>

        </Box>}
    </>
}