import { Box, Button, Card, CardActions, CardContent, Link, Typography } from "@mui/material";
import LockClockOutlinedIcon from '@mui/icons-material/LockClockOutlined';
import { LockedStatus } from "Models/company";
import { getOutseta } from "Helpers/outseta";
import { useCallback, useEffect, useRef, useState } from "react";
import fullLogo from '../Images/logo.png';
import { configManager } from "Helpers/config-manager";
import { useAuth0 } from "@auth0/auth0-react";

export type unAuthorizedProps = {
    lockedStatus?: LockedStatus;
}

export default function CompanyLockedRedirect({ lockedStatus }: unAuthorizedProps) {
    const outsetaRef = useRef(getOutseta());
    const [accountUid, setAccountUid] = useState<string>('');
    const [header, setHeader] = useState<string>();
    const [message, setMessage] = useState<string>();
    const [buttonText, setButtonText] = useState<string>();
    const { logout } = useAuth0();
    
    useEffect(() => {
        const outseta = outsetaRef.current as { getJwtPayload: Function };
        const payload = outseta.getJwtPayload();
        if (payload) {
            setAccountUid(payload["outseta:accountUid"]);
        }
        switch (lockedStatus) {
            case LockedStatus.TrialExpired:
                setHeader('Your free trial has expired');
                setMessage('Your trial has expired, but all your estimates are still safe. You can get access by signing up with the same email you initially registered with here:');
                setButtonText('Sign Up Here');
                break;
            case LockedStatus.Expired:
                setHeader('Your subscription has expired');
                setMessage('Your subscription has expired, but all your estimates are still safe. You can get access by signing up with the same email you initially registered with here:');
                setButtonText('Sign Up Here');
                break;
            case LockedStatus.PastDue:
                setHeader('Your subscription has expired due to failed payments');
                setMessage('Your subscription has expired, but all your estimates are still safe. You can get access by updating your payment details here:');
                setButtonText('Update Payment Details');
                break;
            default:
                break;
        }
    }, [lockedStatus])

    const onRedirectClick = useCallback(() => {
        switch (lockedStatus) {
            case LockedStatus.Expired:
            case LockedStatus.TrialExpired:
                window.location.href = "https://bidbow.com/pricing";
                break;
            case LockedStatus.PastDue:
                window.location.href = `https://${configManager.getConfig()?.outseta.domain}/accounts/${accountUid}/payment-information`;
                break;
            default:
                break;
        }
    }, [accountUid, lockedStatus])

    const userLogout = useCallback(() =>{
        logout({ logoutParams: { returnTo: window.location.origin } });
    }, [logout])

    return <Box display="flex" alignItems="center" justifyContent="center" alignContent="center" width="100%" height="100%" justifyItems="center" sx={{ backgroundColor: "gray" }}>
        <Card sx={{ maxWidth: 500, height: 400, padding: "50px 100px 100px 100px" }}>
            <CardContent>
                <Box width="100%" display="flex" justifyContent="center" marginBottom="30px">
                    <img src={fullLogo} alt="Bidbow" />
                </Box>
                <Box width="100%" display="flex" justifyContent="center" marginBottom="20px">
                    <LockClockOutlinedIcon sx={{ fontSize: "150px" }} />
                </Box>
                <Typography gutterBottom variant="h4" component="div" sx={{ display: "flex", alignContent: "center", justifyContent: "center", textAlign: "Center" }}>
                    {header}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', textAlign: "center" }}>
                    {message}
                </Typography>
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "center", flexDirection: "column", gap: "10px" }}>
                <Button variant="contained" onClick={onRedirectClick}>{buttonText}</Button>
                <Link variant="body2" component="button" onClick={userLogout}>Logout</Link>
            </CardActions>
        </Card>
    </Box>
}