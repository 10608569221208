import { Box, IconButton, Tooltip } from "@mui/material";
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import { useCallback } from "react";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { SupplierPackageInfoView } from "Models/package";

export interface ISupplierActionsCellRendererProps extends ICellRendererParams<SupplierPackageInfoView> {
    delete: (node: IRowNode<SupplierPackageInfoView>) => Promise<void>;
    disabled: () => boolean;
}

export default function SupplierActionsCellRenderer(props: ISupplierActionsCellRendererProps) {
    const { node } = props;

    const deleteRow = useCallback(() => {
        props.delete(props.node);
        props.delete(props.node);
    }, [props])

    return (<>
        {!node.group && node.data &&
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                <Tooltip title={!node.data?.canDelete ? 'Supplier quote is currently being used and cannot be deleted.' : ''}>
                    <span>
                        <IconButton aria-label="delete" color="primary" onClick={deleteRow} disabled={!node.data?.canDelete || props.disabled()}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </span>
                </Tooltip>
            </Box>}
    </>
    );
};
