import { Box, IconButton, Tooltip } from "@mui/material";
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import { PackageInfoView } from "Models/package";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useCallback } from "react";

export interface ICancelCellRendererProps extends ICellRendererParams<PackageInfoView> {
    delete: (node: IRowNode<PackageInfoView>) => Promise<void>;
    disabled: () => boolean;
}

export default function SaveCancelEstimateCellRenderer(props: ICancelCellRendererProps) {
    const { node } = props;

    const deletePackageInfo = useCallback(() => {
        props.delete(node);
    }, [node, props])

    return (<>
        {!node.group && node.data &&
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                <Tooltip title={!node.data?.canDelete ? 'Subcontractor quote is currently being used and cannot be deleted.' : ''}>
                    <span>
                    <IconButton aria-label="delete" color="primary" onClick={deletePackageInfo} disabled={!node.data?.canDelete || props.disabled()}>
                    <DeleteOutlineIcon />
                </IconButton>
                    </span>
                </Tooltip>
            </Box>}
    </>
    );
};
