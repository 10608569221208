import { Autocomplete, Avatar, FormControl, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, TextField, useTheme } from "@mui/material";
import { useGetUserDetailsQuery } from "State/Services/user";
import { SyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { tokens } from "theme";
import { useAppendItemDetailMutation, useGetItemsByFilterQuery, useLazyGetItemsPagedQuery } from "State/Services/item";
import { FilterField } from "Models/resource";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useGetResourcesByFilterQuery, useLazyGetResourcesPagedQuery } from "State/Services/resource";
import { useGetActivitiesByFilterQuery, useLazyGetActivitiesPagedQuery } from "State/Services/activity";
import { useGetStandardItemByFilterQuery, useLazyGetStandardItemPagedQuery } from "State/Services/standard-item";
import SplitButton, { MenuOption } from "Components/SplitButton";
import { AddOptions } from "Models/button-options";
import { useAppendIndirectItemDetailMutation, useGetIndirectItemsByFilterQuery, useLazyGetIndirectItemsPagedQuery } from "State/Services/indirect-item";
import { BaseItem } from "Models/item";
import { BaseIndirectItem } from "Models/indirect-item";
import { NavItem } from "Models/nav";

export interface FilteredEntity {
    id?: string;
    description: string;
    displayId?: string;
    type?: "resource" | "activity" | "item" | "directitem" | "previousitem";
    disableSubElements?: boolean;
}

export interface AppendProps {
    estimateId: string | undefined;
    item: BaseItem | BaseIndirectItem;
    parent: "direct" | "indirect"
    previousItem: NavItem | null;
    disabled: boolean;
}

export function Append(props: AppendProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const [selectedOption, setSelectedOption] = useState<"resource" | "activity" | "item" | "directitem" | "previousitem">("resource");
    const [searchValue, setSearchValue] = useState<FilteredEntity | null>(null);
    const [options, setOptions] = useState<Array<FilteredEntity>>([]);
    const inputRef = useRef<any>();
    const [addMenuItems, setAddMenuItems] = useState<Array<MenuOption>>([{ option: AddOptions.AddElement, disabled: false }, { option: AddOptions.AddSubElements, disabled: false }]);
    const [open, setOpen] = useState<boolean>(false);
    const [appendItemDetail] = useAppendItemDetailMutation();
    const [appendIndirectItemDetail] = useAppendIndirectItemDetailMutation();
    const { data: filteredResources } = useGetResourcesByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', field: FilterField.both, estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !(searchValue && searchValue.description && searchValue.type === "resource" && searchValue.description.length > 1) });
    const { data: filteredActivities } = useGetActivitiesByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !(searchValue && searchValue.description && searchValue.type === "activity" && searchValue.description.length > 1) });
    const { data: filteredStandardItems } = useGetStandardItemByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', field: FilterField.both, estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !(searchValue && searchValue.description && searchValue.type === "item" && searchValue.description.length > 1) });
    const { data: filteredItems } = useGetItemsByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', field: FilterField.both, estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !(searchValue && searchValue.description && searchValue.type === "directitem" && searchValue.description.length > 1 && props.parent === "direct") });
    const { data: filteredIndirectItems } = useGetIndirectItemsByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: (searchValue && searchValue.description) ? searchValue.description : '', field: FilterField.both, estimateId: (props && props.estimateId) ? props.estimateId : '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || !(searchValue && searchValue.description && searchValue.type === "directitem" && searchValue.description.length > 1 && props.parent === "indirect") });
    const [getResourcesPaged] = useLazyGetResourcesPagedQuery();
    const [getActivitiesPaged] = useLazyGetActivitiesPagedQuery();
    const [getStandardItemsPaged] = useLazyGetStandardItemPagedQuery();
    const [getItemsPaged] = useLazyGetItemsPagedQuery();
    const [getIndirectItemsPaged] = useLazyGetIndirectItemsPagedQuery();
    const [appendError, setAppendError] = useState<string>();

    useEffect(() => {
        if (selectedOption === 'previousitem') {
            setSearchValue({
                id: props.previousItem?.itemId,
                description: props.previousItem?.description ?? '',
                displayId: props.previousItem?.displayId?.toString(),
                type: "directitem",
            });
        }
    }, [props.previousItem, selectedOption])

    useEffect(() => {
        if (filteredResources && filteredResources.length > 0) {
            setOptions(filteredResources.map((res) => ({
                description: `${res.displayId} - ${res.description}`,
                id: res.resourceId,
                displayId: res.displayId,
                type: "resource",
                disableSubElements: !res.isComposite
            })));
        }
    }, [filteredResources])

    useEffect(() => {
        if (filteredActivities && filteredActivities.length > 0) {
            setOptions(filteredActivities.map((act) => ({
                description: `${act.displayId} - ${act.description}`,
                id: act.activityId,
                displayId: act.displayId,
                type: "activity",
                disableSubElements: !act.hasResources
            })));
        }
    }, [filteredActivities])

    useEffect(() => {
        if (filteredItems && filteredItems.length > 0) {
            const filtered = filteredItems.filter((item) => (item.itemId !== props.item?.id));
            setOptions(filtered.map((item) => ({
                description: `${item.displayId} - ${item.description}`,
                id: item.itemId,
                displayId: item.displayId,
                type: "directitem",
                disableSubElements: !item.hasResourcesActivities
            })));
        }
    }, [filteredItems, props.item?.id])

    useEffect(() => {
        if (filteredIndirectItems && filteredIndirectItems.length > 0) {
            const filtered = filteredIndirectItems.filter((item) => (item.itemId !== props.item?.id));
            setOptions(filtered.map((item) => ({
                description: `${item.displayId} - ${item.description}`,
                id: item.itemId,
                displayId: item.displayId,
                type: "directitem",
                disableSubElements: !item.hasResourcesActivities
            })));
        }
    }, [filteredIndirectItems, props.item?.id])

    useEffect(() => {
        if (filteredStandardItems && filteredStandardItems.length > 0) {
            setOptions(filteredStandardItems.map((item) => ({
                description: `${item.displayId} - ${item.description}`,
                id: item.itemId,
                displayId: item.displayId,
                type: "item",
                disableSubElements: !item.hasResourcesActivities
            })));
        }
    }, [filteredStandardItems])

    const setDefaultResources = useCallback(async () => {
        const results = await getResourcesPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : ''
        }, true).unwrap();
        if (results && results.length > 0) {
            setOptions(results.map((res) => ({
                description: `${res.displayId} - ${res.description}`,
                id: res.resourceId,
                displayId: res.displayId,
                type: "resource",
                disableSubElements: !res.isComposite
            })));
        }
    }, [getResourcesPaged, props, user])

    const setDefaultActivities = useCallback(async () => {
        const results = await getActivitiesPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : ''
        }, true).unwrap();
        if (results && results.length > 0) {
            setOptions(results.map((act) => ({
                description: `${act.displayId} - ${act.description}`,
                id: act.activityId,
                displayId: act.displayId,
                type: "activity",
                disableSubElements: !act.hasResources
            })));
        }
    }, [getActivitiesPaged, props, user])

    const setDefaultStandardItems = useCallback(async () => {
        const results = await getStandardItemsPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : ''
        }, true).unwrap();
        if (results && results.length > 0) {
            setOptions(results.map((item) => ({
                description: `${item.displayId} - ${item.description}`,
                id: item.itemId,
                displayId: item.displayId,
                type: "item",
                disableSubElements: !item.hasResourcesActivities
            })));
        }

    }, [getStandardItemsPaged, props, user])

    const setDefaultItems = useCallback(async () => {
        const results = await getItemsPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : ''
        }, true).unwrap();
        if (results && results.length > 0) {
            const filtered = results.filter((item) => (item.id !== props.item?.id));
            setOptions(filtered.map((item) => ({
                description: `${item.displayId} - ${item.description}`,
                id: item.id,
                displayId: item.displayId?.toString(),
                type: "directitem",
                disableSubElements: !item.hasResourcesActivities
            })));
        }
    }, [getItemsPaged, props, user])

    const setDefaultIndirectItems = useCallback(async () => {
        const results = await getIndirectItemsPaged({
            companyId: (user && user.companyId) ? user.companyId : '',
            organizationId: (user && user.organizationId) ? user.organizationId : '',
            skip: 0,
            take: 100,
            estimateId: (props && props.estimateId) ? props.estimateId : ''
        }, true).unwrap();
        if (results && results.length > 0) {
            const filtered = results.filter((item) => (item.itemId !== props.item?.id));
            setOptions(filtered.map((item) => ({
                description: `${item.displayId} - ${item.description}`,
                id: item.itemId,
                displayId: item.displayId,
                type: "directitem",
                disableSubElements: !item.hasResourcesActivities
            })));
        }
    }, [getIndirectItemsPaged, props, user])

    const populateDefaultValues = useCallback(async () => {
        switch (selectedOption) {
            case "resource":
                await setDefaultResources();
                break;
            case "activity":
                await setDefaultActivities();
                break;
            case "directitem":
                if (props.parent === "direct") {
                    await setDefaultItems();
                } else if (props.parent === "indirect") {
                    await setDefaultIndirectItems();
                }
                break;
            case "item":
                await setDefaultStandardItems();
                break;
            default:
                break;
        }
    }, [props.parent, selectedOption, setDefaultActivities, setDefaultIndirectItems, setDefaultItems, setDefaultResources, setDefaultStandardItems])

    useEffect(() => {
        populateDefaultValues();
    }, [populateDefaultValues])


    const handleOptionChange = useCallback(async (event: any) => {
        setAppendError(undefined);
        setSelectedOption(event.target.value as "resource" | "activity" | "item" | "directitem" | "previousitem");
        if (event.target.value === "previousitem") {
            setSearchValue({
                id: props.previousItem?.itemId,
                description: props.previousItem?.description ?? '',
                displayId: props.previousItem?.displayId?.toString(),
                type: "directitem",
            });
        } else {
            setSearchValue(null);
        }
        if (event.target.value === "previousitem" || event.target.value === "directitem") {
            const menuOption = addMenuItems.find((menuItem) => (menuItem.option === AddOptions.AddElement));
            if (menuOption) {
                menuOption.disabled = true;
                setAddMenuItems([...addMenuItems]);
            }
        }
        setOptions([]);
    }, [addMenuItems, props.previousItem?.description, props.previousItem?.displayId, props.previousItem?.itemId])

    const onInputChange = useCallback(async (event: SyntheticEvent, newValue: string | null) => {
        setAppendError(undefined);
        if (selectedOption === "previousitem") return;
        if (newValue) {
            setSearchValue({ description: newValue, type: selectedOption });
        } else {
            setSearchValue(null);
            setOpen(false);
        }
    }, [selectedOption])

    const onChange = useCallback(async (event: SyntheticEvent, newValue: FilteredEntity | null) => {
        if (newValue) {
            setSearchValue(newValue);
            const menuOption = addMenuItems.find((menuItem) => (menuItem.option === AddOptions.AddSubElements));
            if (menuOption) {
                menuOption.disabled = !!newValue.disableSubElements;
                setAddMenuItems([...addMenuItems]);
            }
        } else {
            setSearchValue(null);
        }
        setOpen(false);
    }, [addMenuItems]);

    const handleClose = useCallback(async (option?: string) => {
        try {
            setAppendError(undefined);
            if (searchValue?.type && props.item.id) {
                if (props.parent === "direct") {
                    await appendItemDetail({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        itemId: props.item.id,
                        body: {
                            addOption: option === "Add sub-elements" ? 'sub-element' : 'element',
                            id: searchValue.id,
                            type: searchValue.type
                        }
                    }).unwrap();
                } else if (props.parent === "indirect") {
                    await appendIndirectItemDetail({
                        companyId: user?.companyId,
                        estimateId: props.estimateId,
                        orgId: user?.organizationId,
                        indirectItemId: props.item.id,
                        body: {
                            addOption: option === "Add sub-elements" ? 'sub-element' : 'element',
                            id: searchValue.id,
                            type: searchValue.type
                        }
                    }).unwrap();
                }
                setSearchValue(null);
            }
        } catch (error: any) {
            if (error && ('data' in error)) {
                if (error.data.page) {
                    setAppendError(error.data.page);
                }
            }
        }

    }, [appendIndirectItemDetail, appendItemDetail, props.estimateId, props.item.id, props.parent, searchValue, user?.companyId, user?.organizationId]);

    return <>
        <FormControl sx={{
            marginRight: "10px", width: 180, height: 45, '& .MuiInputBase-root': {
                height: 45
            },
        }} size="small">
            <InputLabel id="option-multiple-icon-label">Select option</InputLabel>
            <Select
                size="small"
                disabled={props.disabled}
                sx={{
                    '& .MuiSelect-select': {
                        height: 45,
                        display: "flex",
                        alignItems: "center"
                    },
                    '& .MuiSelect-select .MuiListItemIcon-root': {
                        minWidth: "35px",
                        alignItems: "center",
                    }
                }}
                labelId="option-multiple-icon-label"
                value={selectedOption}
                onChange={handleOptionChange}
                input={<OutlinedInput label="Select option" />}
            >
                <MenuItem key="resource" value="resource">
                    <ListItemIcon>
                        <Avatar sx={{ bgcolor: colors.blueAccent[500], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">R</Avatar>
                    </ListItemIcon>
                    <ListItemText>Resource</ListItemText>
                </MenuItem>
                <MenuItem key="activity" value="activity">
                    <ListItemIcon>
                        <Avatar sx={{ bgcolor: colors.primary[300], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">A</Avatar>
                    </ListItemIcon>
                    <ListItemText>Activity</ListItemText>
                </MenuItem>
                <MenuItem key="item" value="item">
                    <ListItemIcon>
                        <Avatar sx={{ bgcolor: colors.purpleAccent[500], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">I</Avatar>
                    </ListItemIcon>
                    <ListItemText>Item</ListItemText>
                </MenuItem>
                <MenuItem key="directitem" value="directitem">
                    <ListItemText>{(props.parent === "direct") ? 'Direct Item' : 'Indirect Item'}</ListItemText>
                </MenuItem>
                <MenuItem key="previousitem" value="previousitem" disabled={!props.previousItem}>
                    <ListItemText>Previous Item</ListItemText>
                </MenuItem>
            </Select>
        </FormControl>
        <FormControl sx={{ width: 250, height: 45, marginRight: "10px" }} size="small">
            <Autocomplete
                fullWidth
                sx={{ height: "100%" }}
                open={open}
                disabled={props.disabled}
                onOpen={() => {
                    setOpen(true);
                }}
                onClose={() => {
                    setOpen(false);
                }}
                value={searchValue}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.description}
                filterOptions={(x) => x}
                size="small"
                onChange={onChange}
                disableClearable={!!searchValue}
                onInputChange={onInputChange}
                options={options ?? []}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        error={Boolean(appendError)}
                        helperText={appendError}
                        sx={{ '& .MuiInputBase-root': { height: 45 } }}
                        inputRef={inputRef}
                        placeholder='Search...' />}
                renderOption={(props, option, { inputValue }) => {
                    const matches = match(option.description, inputValue, { insideWords: true });
                    const parts = parse(option.description, matches);

                    return (
                        <li {...props}>
                            <div>
                                {parts.map((part, index) => (
                                    <span
                                        key={index}
                                        style={{
                                            fontWeight: part.highlight ? 700 : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        </li>
                    );
                }}
            />
        </FormControl>
        <FormControl>
            <SplitButton disabled={!searchValue?.id || props.disabled} buttonHeight="35px" options={addMenuItems} buttonText="Add" onButtonClick={handleClose} onMenuItemClick={handleClose} />
        </FormControl></>;
}