import { Alert, Autocomplete, Box, Button, CircularProgress, IconButton, TextField, Typography, useTheme } from "@mui/material";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useCallback, useEffect, useState } from "react";
import { tokens } from "theme";
import CloseIcon from '@mui/icons-material/Close';
import { CompanyCurrency } from "Models/company-currency";
import { useGetCompanyCurrenciesQuery } from "State/Services/company-currency";
import { useGetUnitsQuery } from "State/Services/unit";
import { useFormik } from "formik";
import { ChangeCurrencyView, Estimate } from "Models/estimate";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import UnitsModal from "./UnitsModal";
import SaveIcon from '@mui/icons-material/Save';
import { useChangeCurrencyMutation } from "State/Services/estimate";
import SubcontractorPackageModal from "./SubcontractorPackageModal";
import { useGetPackagesQuery } from "State/Services/subcontractor";
import { Errors } from "Models/errors";

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    p: 2,
};

export interface ChangeCurrencyProps {
    close: () => void;
    estimate: Estimate | undefined;
}

export default function ChangeCurrency(props: ChangeCurrencyProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: packages } = useGetPackagesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimate?.id ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimate?.id })
    const { data: companyCurrencies } = useGetCompanyCurrenciesQuery({ companyId: (user && user.companyId) ? user.companyId : '', orgId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [companyCurrenciesExtended, setCompanyCurrenciesExtended] = useState<Array<CompanyCurrency & { currencyOption: string }>>([]);
    const [showAdditionalFields, setShowAdditionalFields] = useState<boolean>(false);
    const [isSaving, setIsSaving] = useState(false);
    const [changeCurrency] = useChangeCurrencyMutation();
    const [pageError, setPageError] = useState<string | undefined>();

    const getUnits = useCallback(() => {
        let unitIds = new Array<string>()
        const filteredUnits = units?.filter(unit => unit.description !== 'PCT');
        if (filteredUnits) {
            filteredUnits.forEach((unit) => {
                if (unit?.id) {
                    unitIds.push(unit.id);
                }
            })
        }
        return unitIds;
    }, [units])

    const getPackages = useCallback(() => {
        let packageIds = new Array<string>();
        if (packages) {
            packages.forEach((pckg) => {
                if (pckg?.id) {
                    packageIds.push(pckg.id);
                }
            })
        }
        return packageIds;
    }, [packages])

    useEffect(() => {
        if (companyCurrencies) {
            setCompanyCurrenciesExtended(companyCurrencies.map((currency) => ({ ...currency, currencyOption: `${currency.alphabeticalCode} - ${currency.currency}` })))
        }
    }, [companyCurrencies])

    const handleClose = useCallback(() => {
        props.close();
    }, [props])

    const { setFieldValue, handleSubmit, resetForm, setValues, setFieldError, ...formik } = useFormik<ChangeCurrencyView>({
        initialValues: {
            companyCurrencyId: props.estimate?.companyCurrencyId,
            companyCurrencyOption: props.estimate?.CompanyCurrency ? { ...props.estimate?.CompanyCurrency, currencyOption: `${props.estimate?.CompanyCurrency.Currency.alphabeticalCode} - ${props.estimate?.CompanyCurrency.Currency.currency}` } : undefined,
            currencyExchangeRate: undefined,
            unitIds: [],
            packageIds: []
        },
        enableReinitialize: true,
        onSubmit: async (values) => {
            try {
                setIsSaving(true);
                if (user && props.estimate?.id) {
                    await changeCurrency({
                        companyId: user.companyId,
                        orgId: user.organizationId,
                        estimateId: props.estimate.id,
                        body: {
                            companyCurrencyId: values.companyCurrencyId,
                            unitIds: values.unitIds,
                            packageIds: values.packageIds
                        }
                    }).unwrap();
                }
                setIsSaving(false);
                props.close();
            } catch (error: any) {
                setPageError(Errors.generic);
            }
        },
    });

    useEffect(() => {
        setValues({
            companyCurrencyId: props.estimate?.CompanyCurrency?.id ?? '',
            companyCurrencyOption: props.estimate?.CompanyCurrency ? { ...props.estimate?.CompanyCurrency, currencyOption: `${props.estimate?.CompanyCurrency?.Currency.alphabeticalCode} - ${props.estimate?.CompanyCurrency?.Currency.currency}` } : undefined,
            unitIds: getUnits(),
            packageIds: getPackages()
        });
    }, [getPackages, getUnits, packages, props.estimate, setValues, units])

    const currencyChanged = useCallback((event: any, value: CompanyCurrency & { currencyOption: string } | null) => {
        if (value) {
            if (value.id !== props?.estimate?.CompanyCurrency?.id) {
                setShowAdditionalFields(true);
                let denominator = props?.estimate?.CompanyCurrency?.primaryToSecondaryRate ?? 1;
                let numerator = value.primaryToSecondaryRate ?? 1;
                setFieldValue('currencyExchangeRate', numerator / denominator);
            } else {
                setShowAdditionalFields(false);
            }
            setFieldValue('companyCurrencyOption', value);
            setFieldValue('companyCurrencyId', value.id);
        }
    }, [props?.estimate?.CompanyCurrency?.id, props?.estimate?.CompanyCurrency?.primaryToSecondaryRate, setFieldValue])

    const onUnitsSelected = useCallback((unitIds?: Array<string>) => {
        if (unitIds) {
            setFieldValue('unitIds', unitIds);
        }
    }, [setFieldValue])

    const onPackagesSelected = useCallback((packageIds?: Array<string>) => {
        if (packageIds) {
            setFieldValue('packageIds', packageIds);
        }
    }, [setFieldValue])

    return <Box sx={style}>
        <Box
            display="flex"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            marginBottom="10px"
            borderBottom={`1px solid ${colors?.gray[800]}`}>
            <Box padding="10px">
                {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Change currency</Typography>}
            </Box>
            <Box>
                <IconButton aria-label="close" onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>
        {pageError && <Box marginTop="10px" marginBottom="15px"><Alert severity="error">{pageError}</Alert></Box>}
        <form noValidate onSubmit={handleSubmit}>
            <Box>
                <Box display="flex" gap={showAdditionalFields ? '10px' : '0px'}>
                    {companyCurrenciesExtended && companyCurrenciesExtended.length > 0 && <Box flex="1">
                        <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Currency</label>
                        <Autocomplete
                            fullWidth
                            className="ag-input-field-input ag-text-field-input"
                            sx={{
                                height: "inherit", "& .MuiTextField-root:": { height: "inherit" },
                                marginBottom: '15px'
                            }}
                            onChange={currencyChanged}
                            options={companyCurrenciesExtended}
                            getOptionLabel={(option) => option.currencyOption ?? ''}
                            isOptionEqualToValue={(option, value) => (option.id === value.id)}
                            value={formik.values.companyCurrencyOption ?? null}
                            renderInput={(params) => <TextField name="Seccurrency" sx={{ height: "100%" }} placeholder='Select Currency' {...params} />}
                            renderOption={(props, option, { inputValue }) => {
                                const matches = match(option.currencyOption ?? '', inputValue, {
                                    insideWords: true,
                                });
                                const parts = parse(option.currencyOption ?? '', matches);

                                return (
                                    <li {...props}>
                                        <div>
                                            {parts.map((part, index) => (
                                                <span
                                                    key={index}
                                                    style={{
                                                        fontWeight: part.highlight ? 700 : 400,
                                                    }}
                                                >
                                                    {part.text}
                                                </span>
                                            ))}
                                        </div>
                                    </li>
                                );
                            }}
                        /></Box>
                    }
                    {showAdditionalFields && <Box display="flex" flex="1" alignItems="center">
                        <Box display="flex" flexDirection="column">
                            <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Rate</label>
                            <TextField style={{ marginBottom: '15px', marginRight: '10px' }}
                                fullWidth
                                disabled={true}
                                id="currencyExchangeRate"
                                name="currencyExchangeRate"
                                value={formik.values.currencyExchangeRate}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                inputProps={{ type: 'number' }} />
                        </Box>
                        <Box display="flex" flexDirection="column">
                            <UnitsModal select={onUnitsSelected} unitIds={formik.values.unitIds} />
                            <SubcontractorPackageModal estimateId={props.estimate?.id} select={onPackagesSelected} packageIds={formik.values.packageIds} />
                        </Box>
                    </Box>
                    }
                </Box>
                <Box display="flex" justifyContent="space-evenly">
                    <Button disabled={isSaving} color="primary" variant="contained" type="submit" startIcon={!isSaving ? <SaveIcon /> : <CircularProgress sx={{ color: "white" }} size="1rem" />}>
                        Save
                    </Button>
                    <Button variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                </Box>
            </Box>
        </form>
    </Box>
}