import { AnalysisResult, BaseFileMetadata, FileDetails, FileMetadata } from 'Models/file';
import { api } from './api';

export const uploadFileAPI = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadFile: builder.mutation<FileDetails, { organizationId: string, companyId: string, estimateId: string, fileData: FormData }>({
      query({ organizationId, companyId, estimateId, fileData }: { organizationId: string, companyId: string, estimateId: string, fileData: FormData }) {
        return {
          url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/file/upload`,
          method: 'POST',
          body: fileData,
        };
      },
      invalidatesTags: ['Files'],
    }),
    getFiles: builder.query<Array<FileDetails>, Partial<{ organizationId: string, companyId: string, estimateId: string }>>({
      query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => 
      ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/file/all` }),
      providesTags: ['Files'],
    }),
    getFileMetadata: builder.query<FileMetadata, Partial<{ organizationId: string, companyId: string, estimateId: string, fileId: string }>>({
      query: ({ organizationId, companyId, estimateId, fileId }: { organizationId: string, companyId: string, estimateId: string, fileId: string }) => 
      ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/file/${fileId}/fileMetadata` }),
      providesTags: ['FileMetadata'],
    }),
    createFileMetadata: builder.mutation<FileMetadata, Partial<{ organizationId: string, companyId: string, estimateId: string, fileId: string, fileMetadata: BaseFileMetadata }>>({
      query({organizationId, companyId, estimateId, fileId, fileMetadata}) {
        return {
          url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/file/${fileId}/fileMetadata`,
          method: 'POST',
          body: fileMetadata,
        }
      },
      invalidatesTags: ['FileMetadata'],
    }),
    updateFileMetadata: builder.mutation<FileMetadata, Partial<{ organizationId: string, companyId: string, estimateId: string, fileId: string, fileMetadata: BaseFileMetadata, id: string }>>({
      query({organizationId, companyId, estimateId, fileId, fileMetadata, id}) {
        return {
          url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/file/${fileId}/fileMetadata/${id}`,
          method: 'PUT',
          body: fileMetadata,
        }
      },
      invalidatesTags: ['FileMetadata', 'Analysis'],
    }),
    analyze: builder.query<AnalysisResult, Partial<{ organizationId: string, companyId: string, estimateId: string, fileId: string }>>({
      query: ({ organizationId, companyId, estimateId, fileId }: { organizationId: string, companyId: string, estimateId: string, fileId: string }) => 
      ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/file/${fileId}/analyze` }),
      providesTags: ['Analysis']
    }),
    downloadFile: builder.mutation<any, Partial<{ organizationId: string, companyId: string, estimateId: string, fileId: string }>>({
      query({ organizationId, companyId, estimateId, fileId }: { organizationId: string, companyId: string, estimateId: string, fileId: string }) {
        return {
          url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/file/${fileId}/download`,
          method: "POST",
          responseHandler: async (response: any) => window.open(window.URL.createObjectURL(await response.blob()), '_blank'),
          cache: "no-cache",
        };
      },
    }),
    exportToOriginal: builder.mutation<any, Partial<{ organizationId: string, companyId: string, estimateId: string, fileId: string, rateOnly: boolean }>>({
      query({ organizationId, companyId, estimateId, fileId, rateOnly }: { organizationId: string, companyId: string, estimateId: string, fileId: string, rateOnly: boolean }) {
        return {
          url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/file/${fileId}/export?rateOnly=${rateOnly}`,
          method: "POST",
          responseHandler: async (response: any) => window.open(window.URL.createObjectURL(await response.blob()), '_blank'),
          cache: "no-cache",
        };
      },
    }),
  }),
});

export const { useExportToOriginalMutation, useUploadFileMutation, useGetFilesQuery, useDownloadFileMutation, useCreateFileMetadataMutation, useGetFileMetadataQuery, useUpdateFileMetadataMutation, useAnalyzeQuery } = uploadFileAPI;

