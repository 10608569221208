import { Box, IconButton } from "@mui/material";
import { MarkupItem } from "Models/item";
import { ICellRendererParams, IRowNode } from "ag-grid-enterprise";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useCallback, useEffect, useState } from "react";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";

export interface MarkupLockedCellRendererProps extends ICellRendererParams<MarkupItem> {
    lockUnlockItem: (lock: boolean, item: MarkupItem) => void;
    estimateId: string;
}

export default function MarkupLockedCellRenderer(props: MarkupLockedCellRendererProps) {
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [editDisabled, setEditDisabled] = useState(false);

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                setEditDisabled(true);
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Pricing,
                    requiredPermissions: [502]
                });
                setEditDisabled(!hasEditAccess);
            }
        } else {
            setEditDisabled(true);
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    const onLockUnlock = useCallback(async (lock: boolean, node: IRowNode<MarkupItem>) =>{
        if (node.data){
            await props.lockUnlockItem(lock, node.data);
        }
    }, [props])

    return <>
        <Box>
            {props.node.data?.locked && <IconButton disabled={editDisabled} onClick={() => onLockUnlock(false, props.node)} aria-label="lock">
                <LockIcon />
            </IconButton>}
            {!props.node.data?.locked && <IconButton disabled={editDisabled} onClick={() => onLockUnlock(true, props.node)} aria-label="unlock">
                <LockOpenIcon />
            </IconButton>}
        </Box>
    </>;
}