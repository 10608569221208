import { Autocomplete, Box } from "@mui/material";
import { ICellEditorParams } from "ag-grid-enterprise";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { GridTextField } from "Components/GridTextField";
import { ResourceView } from "Models/resource";
import { ResourceCategory } from "Models/resource-category";
import { forwardRef, MutableRefObject, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useGetResourceCategoriesQuery } from "State/Services/resource-category";
import { useGetUserDetailsQuery } from "State/Services/user";

export interface ResourceListCategoryEditCellRendererParams extends ICellEditorParams<ResourceView, ResourceCategory> {
    estimateId: string;
    setRef: (ref: MutableRefObject<any>) => void;
}

export default forwardRef((props: ResourceListCategoryEditCellRendererParams, ref) => {
    const { data: user } = useGetUserDetailsQuery();
    const { data: storedResourceCategories } = useGetResourceCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId });
    const [open, setOpen] = useState(false);
    const inputRef = useRef<any>();

    useImperativeHandle(ref, () => {
        return {
            // the final value to send to the grid, on completion of editing
            getValue() {
                return value?.description ?? null;
            },
            focusIn() {
                inputRef.current.focus();
                handleOpen();
            },
            setFocusOnAdd() {
                if (inputRef.current) {
                    inputRef.current.focus();
                    setOpen(true);
                }
            },
        };
    });

    const getInitialValue = useCallback((props: ICellEditorParams<ResourceView, ResourceCategory>) => {
        let value: ResourceCategory | null = null;
        const resCategory = storedResourceCategories?.find((resCategory) => (resCategory.id === props.data?.categoryId));
        if (resCategory) {
            value = resCategory;
        }
        return value;
    }, [storedResourceCategories]);

    const [value, setValue] = useState<ResourceCategory | null>(getInitialValue(props));

    const onChange = useCallback((event: React.SyntheticEvent, newValue: ResourceCategory | null) => {
        const subCategoryInstances = props.api.getCellEditorInstances({
            columns: ['subCategoryDescription']
        });
        if (subCategoryInstances.length > 0 
            && subCategoryInstances[0] 
            && typeof (subCategoryInstances[0] as any).loadSubcategories === 'function') {
                if (newValue && storedResourceCategories) {
                    const resCategory = storedResourceCategories.find((category) => (category.id === newValue.id));
                    if (resCategory) {
                        setValue(resCategory);
                        (subCategoryInstances[0] as any).loadSubcategories(resCategory.id);                      
                    } else {
                        setValue(null);
                        (subCategoryInstances[0] as any).loadSubcategories();
                    }
                } else {
                    setValue(null);
                    (subCategoryInstances[0] as any).loadSubcategories();
                }
        }
    }, [props.api, storedResourceCategories])

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    useEffect(() => {
        if (inputRef) {
            props.setRef(inputRef);
        }
    }, [props])

    return <Box height="100%">
        <Autocomplete
            fullWidth
            className="ag-input-field-input ag-text-field-input"
            sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
            value={value || null}
            size="small"
            onClose={handleClose}
            isOptionEqualToValue={(option, value) => (option.id === value.id)}
            onOpen={handleOpen}
            open={open}
            onChange={onChange}
            options={storedResourceCategories ?? []}
            getOptionLabel={(resCategory) => {
                if (typeof resCategory === 'string') {
                    return resCategory;
                }
                return resCategory.description ?? '';
            }}
            renderInput={(params) => <GridTextField sx={{ height: "100%" }} inputRef={inputRef} placeholder='Select Category' {...params} />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.description ?? '', inputValue, {
                    insideWords: true,
                });
                const parts = parse(option.description ?? '', matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
    </Box>
});