import { api } from './api'
import { Price, ProjectPrice } from 'Models/price';

export const pricingApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPricing: build.query<Price, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/pricing` }),
      providesTags: ['Pricing']
    }),
    getProjectPricing: build.query<ProjectPrice, {organizationId: string, companyId: string, estimateId: string}>({
      query: ({organizationId, companyId, estimateId}: {organizationId: string, companyId: string, estimateId: string}) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/pricing/project` }),
      providesTags: ['ProjectPricing']
    }),
    upsertPricing: build.mutation<Price, Partial<{ body: Price, orgId: string, companyId: string, estimateId: string}>>({
        query({ body, orgId, companyId, estimateId }) {
          return {
            url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/pricing`,
            method: 'POST',
            body,
          }
        },
        invalidatesTags: ['Pricing'],
      }),
  })
});

export const {
    useGetPricingQuery,
    useUpsertPricingMutation,
    useLazyGetProjectPricingQuery
} = pricingApi