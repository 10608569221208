import { Box, Button, Step, StepLabel, Stepper } from "@mui/material";
import { FileDetails, FileMetadata } from "Models/file";
import { useCallback, useEffect, useRef, useState } from "react";
import FileUpload from "./FileUpload";
import { ColumnMapping } from "./ColumnMapping";
import { Categories } from "./Categories";
import { Analyze } from "./Analyze";

export interface ImportProps {
    estimateId: string | undefined;
    noOfLevels: number | undefined;
    onFileSelected: (fileSelected: string) => void;
    onClose: () => void;
}

export function Import(props: ImportProps) {
    const [steps, setSteps] = useState<Array<string>>([]);
    const [activeStep, setActiveStep] = useState(0);
    const [file, setFile] = useState<FileDetails>();
    const columnMappingRef = useRef<any>();
    const [isColumnMappingValid, setIsColumnMappingValid] = useState(false);

    useEffect(() => {
        if (props.noOfLevels === 0) {
            setSteps(['Upload file', 'Column mapping', 'Analyze'])
        } else {
            setSteps(['Upload file', 'Column mapping', 'Categories',  'Analyze'])
        }
    }, [props.noOfLevels])

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleNext = async () => {
        if (steps[activeStep] === 'Column mapping' && columnMappingRef.current) {
            columnMappingRef.current.saveMappings();
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const finish = useCallback(() => {
        props.onClose();
    }, [props])

    const fileUploaded = (fileDetails: FileDetails) => {
        setFile(fileDetails);
        props.onFileSelected(fileDetails.originalName);
        handleNext();
    }

    const setColMappings = (fileMetadata: FileMetadata) => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }

    return (
        <Box overflow="auto" display="flex" flexDirection="column" flex="1" justifyContent="space-between" margin="10px" overflow-x="hidden">
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            <Box display="flex" flex="1">
                {steps[activeStep] === 'Upload file' && props.estimateId && <>
                    <FileUpload fileUploaded={fileUploaded} estimateId={props.estimateId} />
                </>}
                {steps[activeStep] === 'Categories' && props.estimateId && <>
                    <Categories estimateId={props.estimateId} noOfLevels={props.noOfLevels} isImport={true}/>
                </>}
                {steps[activeStep] === 'Column mapping' && props.estimateId && file && <>
                    <ColumnMapping fileDetails={file} estimateId={props.estimateId} onColumnMapping={setColMappings} ref={columnMappingRef} onValidate={setIsColumnMappingValid}/>
                </>}
                {steps[activeStep] === 'Analyze' && props.estimateId && file && <>
                    <Analyze fileDetails={file} estimateId={props.estimateId} onAnalysisDone={finish} />
                </>}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    disabled={steps[activeStep] === 'Upload file'}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                {
                    (steps[activeStep] === 'Column mapping') && <Button onClick={handleNext} disabled={!isColumnMappingValid}>Next</Button>                  
                }
                {
                    (steps[activeStep] === 'Categories') && <Button onClick={handleNext}>Next</Button>
                }
                {
                    steps[activeStep] === 'Upload file' && <Button onClick={handleNext} disabled={!file}>Next</Button>
                }
                {
                    steps[activeStep] === 'Analyze' && <Button onClick={finish}>Finish</Button>
                }
            </Box>
        </Box>
    )
}