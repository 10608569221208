import UnAuthorizedError from "Components/UnAuthorizedError";
import { useState, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { setAppValidState } from "State/appStateSlice";
import { useAppDispatch } from "State/hooks";
import { useGetUserDetailsQuery } from "State/Services/user";

export default function Core() {
    const { organizationId, companyId } = useParams();
    const { data: user } = useGetUserDetailsQuery();
    const [isValid, setIsValid] = useState(false);
    const [ready, setReady] = useState(false);
    const dispatch = useAppDispatch();
    
    useEffect(() => {
        if (user?.organizationId !== organizationId || user?.companyId !== companyId) {
            setIsValid(false);
            dispatch(setAppValidState(false));
        } else {
            setIsValid(true);
            dispatch(setAppValidState(true));
        }
        setReady(true);
    }, [companyId, dispatch, organizationId, user])

    if (ready && !isValid) {
        return <UnAuthorizedError />
    }

    return <>
        <Outlet />
    </>
}