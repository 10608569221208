import { rounder } from "Helpers/rounder";
import { SupplierPackageInfoView } from "Models/package";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useDeleteSupplierPackageMutation, useGetSupplierPackagesQuery } from "State/Services/supplier";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ColDef, ICellRendererParams, IRowNode } from "ag-grid-enterprise";
import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import SupplierActionsCellRenderer from "./SupplierActionsCellRenderer";
import { Box, Button, Link } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import AddIcon from '@mui/icons-material/Add';
import SupplierPackage from "./SupplierPackage";
import { selectSupplierState } from "State/supplierSlice";
import { useAppSelector } from "State/hooks";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";

export interface SupplierListProps {
    estimateId?: string;
}

export default function SupplierList(props: SupplierListProps){
    const supplierState = useAppSelector(selectSupplierState);
    const { data: user } = useGetUserDetailsQuery();
    const { data: packages } = useGetSupplierPackagesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId })
    const [rowData, setRowData] = useState<SupplierPackageInfoView[]>();
    const [showAdd, setShowAdd] = useState<boolean>(false);
    const [showEdit, setShowEdit] = useState<boolean>(false);
    const [packageId, setPackageId] = useState<string>();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [deleteSupplierPackage] = useDeleteSupplierPackageMutation();
    const hasAddEditAccessRef = useRef<boolean>();

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                hasAddEditAccessRef.current = false;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.SupplierQuotes,
                    requiredPermissions: [502]
                });
                hasAddEditAccessRef.current = hasEditAccess;
            }
        } else {
            hasAddEditAccessRef.current = false;
        }
    }, [estimate, estimate?.EstimateUserRole, user])
    
    useEffect(() => {
        if (packages && packages.length > 0) {
            setRowData(packages.map((pckg) => ({ ...pckg, actions: '' })));
        } else {
            setRowData([]);
        }
    }, [packages])

    useEffect(() =>{
        if (supplierState && supplierState?.option){
            if (supplierState?.option==="edit"){
                setShowEdit(true);
            }else if (supplierState?.option==="create"){
                setShowAdd(true);
            }
        }
    }, [supplierState])
    
    const deletePackage = useCallback(async (node: IRowNode<SupplierPackageInfoView>) => {
        if (user && node.data){
            await deleteSupplierPackage({
                companyId: user.companyId,
                estimateId: props.estimateId,
                orgId: user.organizationId,
                packageId: node.data.id
            });
        }
    }, [deleteSupplierPackage, props.estimateId, user])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const deletePackageRef = useRef<any>();
    deletePackageRef.current = deletePackage;

    const showPackage = useCallback((packageId?: string) => {
        setPackageId(packageId);
        setShowEdit(true);
    }, [])

    const showAddPackage = useCallback(() => {
        setPackageId(undefined);
        setShowEdit(true);
    }, [])

    const [columnDefs] = useState<ColDef<SupplierPackageInfoView>[]>([
        { field: 'id', hide: true },
        { field: 'name', headerName: 'Name', flex: 1, cellRenderer: (params: ICellRendererParams<SupplierPackageInfoView>) => (<>{params.data && <Link component="button" onClick={() => showPackage(params.data?.id)} variant="body2">{params.data.name}</Link>}</>) },
        { field: 'noOfResources', headerName: 'No of Resources', width: 200 },
        { field: 'noOfSuppliers', headerName: 'No of Suppliers', width: 200 },
        { field: 'totalAmount', headerName: 'Amount', width: 400, valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2), },
        {
            field: 'actions',
            width: 80,
            headerName: 'Actions',
            menuTabs: [],
            cellStyle: { textAlign: "left", padding: "0px" } as any,
            cellRenderer: SupplierActionsCellRenderer,
            cellRendererParams: {
                delete: (node: IRowNode<SupplierPackageInfoView>) => deletePackageRef.current(node),
                disabled: () => !hasAddEditAccessRef.current
            }
        },
    ]);

    const backToList = useCallback(() =>{
        setShowAdd(false);
        setShowEdit(false);
    }, [])

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    return <>
        {!(showAdd || showEdit) && <Box height="100%" width="100%">
            <Button disabled={!hasAddEditAccessRef.current} startIcon={<AddIcon />} variant="contained" sx={{marginBottom: "10px"}} onClick={showAddPackage}>New Quote Package</Button>
            <Box height="calc(100% - 32px)" width="100%" className="ag-theme-alpine ag-theme-bidbow">
                <AgGridReact<SupplierPackageInfoView>
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    rowSelection={'single'}
                    animateRows={true}
                />
            </Box>
        </Box>}
        {showAdd && <SupplierPackage backToList={backToList} estimateId={props.estimateId} />}
        {showEdit && <SupplierPackage backToList={backToList} estimateId={props.estimateId} packageId={packageId} />}
    </>
}