import { Box } from "@mui/material";
import StandardItemList from "Components/StandardItems/StandardItemList";
import { PanelState } from "Models/panel";
import { useState } from "react";
import { useParams } from "react-router-dom";

export default function SubItemLanding(){
    const { estimateId } = useParams();
    const [panelState] = useState<PanelState>({position: {state: 'full'}});
    
    return <Box height="calc(100% - 130px)" margin="10px"><StandardItemList estimateId={estimateId} panelState={panelState}/></Box>
}