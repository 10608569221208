import {
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Box, FormControl, InputAdornment, List, ListItem, OutlinedInput, useTheme } from '@mui/material';
import { tokens } from 'theme';
import { HtmlTooltip } from 'Components/HtmlToolltip';
import WarningIcon from '@mui/icons-material/Warning';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { useGetMaxVendorIdQuery } from 'State/Services/vendor';
import { VendorView } from 'Models/vendor';
import { useGetEstimateQuery } from 'State/Services/estimate';
import { useGetSettingsQuery } from 'State/Services/settings';

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';

export interface VendorIdEditCellRendererParams extends ICellEditorParams<VendorView> {
    estimateId: string;
}

export default memo(
    forwardRef((props: VendorIdEditCellRendererParams, ref) => {
        const [colors, setColors] = useState<any>();
        const theme = useTheme();
        const { data: user } = useGetUserDetailsQuery();
        const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
        const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
        const { data: maxVendorId } = useGetMaxVendorIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
        useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
        const [errorMessages, setErrorMessages] = useState<Array<string>>([]);

        const createInitialState = () => {
            let startValue;
            let highlightAllOnFocus = true;
            const eventKey = props.eventKey;

            if (eventKey === KEY_BACKSPACE) {
                // if backspace or delete pressed, we clear the cell
                startValue = '';
            } else if (eventKey && eventKey.length === 1) {
                // if a letter was pressed, we start with the letter
                startValue = props.eventKey;
                highlightAllOnFocus = false;
            } else {
                // otherwise we start with the current value
                startValue = props.value;
                if (eventKey === KEY_F2) {
                    highlightAllOnFocus = false;
                }
            }

            return {
                value: startValue,
                highlightAllOnFocus,
            };
        };

        const initialState = createInitialState();
        const [value, setValue] = useState(initialState.value);
        const refInput = useRef<any>(null);

        const onChange = useCallback((value: string) => {
            setValue(value);
            if (value) {
                setErrorMessages([]);
            }else{
                setErrorMessages(['ID is required']);
            }
        }, [])

        useEffect(() =>{
            let prefix = estimate?.isMaster ? `${settings?.masterIdPrefix}-` : '';
            if (!props.value){
                setValue((maxVendorId?.maxId) ? `${prefix}${(maxVendorId?.maxId + 1).toString()}` : `${prefix}1`);
            }
        }, [estimate?.isMaster, maxVendorId?.maxId, props.value, settings?.masterIdPrefix])

        /* Component Editor Lifecycle methods */
        useImperativeHandle(ref, () => {
            return {
                // the final value to send to the grid, on completion of editing
                getValue() {
                    return value === '' || value == null ? null : value;
                },
                setFocusOnAdd(){
                    if (refInput.current){
                        refInput.current.focus();
                        refInput.current.select();
                    }
                },
                setInputValue(value: string) {
                    setValue(value);
                },
                setErrors(message: string) {
                    if (message && errorMessages) {
                        setErrorMessages([...errorMessages, message]);
                    }
                },
            };
        });

        return (
            <Box sx={{ width: "100%", height: "100% !important" }}>
                <FormControl sx={{ height: 'inherit', width: "100%" }}>
                    <OutlinedInput
                        sx={{ height: "inherit", backgroundColor: "white" }}
                        className="ag-input-field-input ag-text-field-input"
                        placeholder="ID"
                        onChange={(event) => onChange(event.target.value)}
                        endAdornment={errorMessages && errorMessages.length > 0 &&
                            <InputAdornment position="end">
                                <HtmlTooltip color={colors?.redAccent[500]}
                                    title={
                                        <List sx={{ listStyleType: 'disc', pl: 1 }}>
                                            {errorMessages.map((errorMessage: string) => {
                                                return <ListItem
                                                    sx={{
                                                        padding: 0,
                                                        textAlign: "center",
                                                        listStyleType: "disc",
                                                        display: "list-item",
                                                    }}
                                                >{errorMessage}</ListItem>
                                            })}
                                        </List>
                                    }>
                                    <WarningIcon sx={{ color: `${colors?.redAccent[500]}`, cursor: "pointer" }} />
                                </HtmlTooltip>
                            </InputAdornment>
                        }
                        value={value}
                        inputRef={refInput}
                    />
                </FormControl>
            </Box>
        );
    })
);
