import { Box, Radio } from "@mui/material";
import { SupplierPackageResourceDetailsView } from "Models/package";
import { Column, ICellRendererParams, IRowNode } from "ag-grid-enterprise";
import { forwardRef, useCallback, useEffect, useState } from "react";

export interface ISupplierPackageCheckedCellRendererParams extends ICellRendererParams<SupplierPackageResourceDetailsView> {
    package: () => SupplierPackageResourceDetailsView;
    onSelect: (node: IRowNode<SupplierPackageResourceDetailsView>, column: Column<SupplierPackageResourceDetailsView>) => void;
    disabled: () => boolean;
}

export default forwardRef((props: ISupplierPackageCheckedCellRendererParams, ref) => {
    const [selectedValue, setSelectedValue] = useState<boolean>(props.value);

    const onSelect = useCallback(() => {
        if (props.column) {
            props.onSelect(props.node, props.column);
        }
    }, [props])

    useEffect(() => {
        setSelectedValue(props.value ?? false);
    }, [props])

    return <><Box display="flex" justifyContent="center">
        <Radio disabled={props.disabled()} onChange={onSelect} value={selectedValue} checked={selectedValue} />
    </Box></>;
});