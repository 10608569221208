import { Box } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import { ICellRendererParams } from "ag-grid-community";
import { UserInfoView } from "Models/user-details";

export default function UserLockedCellRenderer(props: ICellRendererParams<UserInfoView>) {
    return <Box display="flex" alignItems="center" justifyContent="center" height="100%">
        {props.value && <LockIcon />}
    </Box>
}
