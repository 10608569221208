import { Backdrop, Box, Button, CircularProgress, IconButton, Modal, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import { Entity } from "Models/estimate";
import { IndirectItemView } from "Models/indirect-item";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useDeleteIndirectItemsMutation } from "State/Services/indirect-item";
import { useGetUserDetailsQuery } from "State/Services/user";
import { AgGridReact } from "ag-grid-react";
import { useConfirm } from "material-ui-confirm";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { tokens } from "theme";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { EditIndirectItems } from "./EditIndirectItems";
import { IndirectFilterSettings, Filters } from "./IndirectItemCategoryFilters";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import IndirectCategories from "./IndirectCategories";
import AddIcon from '@mui/icons-material/Add';
import IndirectItemsFlat from "./IndirectItemsFlat";
import IndirectItemsGrouped from "./IndirectItemsGrouped";

const editModalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "450px",
    height: "320px",
    bgcolor: 'background.paper',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    borderRadius: "5px",
    display: 'flex',
    flexDirection: "column"
};

export default function InDirectItems() {
    const { estimateId } = useParams();
    const [colors, setColors] = useState<any>();
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });
    const gridRef = useRef<AgGridReact<IndirectItemView>>(null);
    const containerStyle = useMemo(() => ({ width: '100%', height: 'calc(100% - 120px)' }), []);
    const [deleteIndirectItems] = useDeleteIndirectItemsMutation();
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const theme = useTheme();
    const [selectedRows, setSelectedRows] = useState<Array<IndirectItemView>>([]);
    const [filterValues] = useState<IndirectFilterSettings | undefined>();
    const [grouped, setGrouped] = useState(false);
    const confirm = useConfirm();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const [autoRowHeightEnabled, setAutoRowHeightEnabled] = useState(false);
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [openCategoriesModal, setOpenCategoriesModal] = useState(false);
    const indirectItemGridRef = useRef<any>();
    const [isEditing, setIsEditing] = useState(false);
    const [editDisabled, setEditDisabled] = useState<boolean>();
    const [deleteDisabled, setDeleteDisabled] = useState<boolean>();

    const handleOpenCategoriesModal = () => {
        setOpenCategoriesModal(true);
    };
    const handleCloseCategoriesModal = async () => {
        setOpenCategoriesModal(false);
        indirectItemGridRef.current.reloadAndClearFilters();
    };

    useEffect(() => {
        if (filterValues) {
            gridRef.current!.api.onFilterChanged();
        }
    }, [filterValues])

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                setHasViewAccess(true);
                setEditDisabled(true);
                setDeleteDisabled(true);
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Indirect,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Indirect,
                    requiredPermissions: [502]
                });
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Indirect,
                    requiredPermissions: [503]
                });
                setEditDisabled(!hasEditAccess);
                setDeleteDisabled(!hasDeleteAccess);
            }
        } else {
            setHasViewAccess(true);
            setEditDisabled(true);
            setDeleteDisabled(true);
        }
        setIsReady(true);
    }, [estimate, estimate?.EstimateUserRole, user])
    const editDisabledRef = useRef<boolean>();
    editDisabledRef.current = editDisabled;
    const deleteDisabledRef = useRef<boolean>();
    deleteDisabledRef.current = deleteDisabled;

    const [openEditModal, setOpenEditModal] = useState(false);
    const handleOpenEditModal = () => {
        setOpenEditModal(true);
    };
    const handleCloseEditModal = () => {
        setOpenEditModal(false);
        indirectItemGridRef.current.reloadAndClearFilters();
    };

    const deleteSelected = async () => {
        try {
            if (selectedRows && selectedRows.length > 0) {
                await confirm({ description: `Are you sure you want to delete ${selectedRows.length} records?`, title: 'Delete confirmation' });
                setOpenBackdrop(true);
                await deleteIndirectItems({
                    body: selectedRows.map((item) => {
                        if (item.id) {
                            return item.id
                        }
                        return ''
                    }),
                    companyId: user?.companyId,
                    estimateId: estimateId,
                    orgId: user?.organizationId
                });
                setOpenBackdrop(false);
                indirectItemGridRef.current.reloadAndClearFilters();
            }
        } catch (error) {

        }
    }

    const onFilterChanged = useCallback((settings: IndirectFilterSettings) => {
        indirectItemGridRef.current.setFilterChanged(settings);
    }, [])

    const editSelected = () => {
        handleOpenEditModal();
    }
    const enableDisableRowAutoHeight = useCallback(() => {
        setAutoRowHeightEnabled(!autoRowHeightEnabled);
        indirectItemGridRef.current.setAutoheight(!autoRowHeightEnabled);

    }, [autoRowHeightEnabled])


    const addItem = useCallback(() => {
        indirectItemGridRef.current.addNewRow();
    }, [])

    const onSelection = useCallback((rows: Array<IndirectItemView>) => {
        setSelectedRows(rows);
    }, [])

    const switchGrid = useCallback(() => {
        if (grouped) {
            setGrouped(false);
        } else {
            setGrouped(true);
        }
    }, [grouped])

    if (!isReady) {
        return <></>
    }

    if (!hasViewAccess) {
        return <Box m="10px">Unauthorized access.</Box>
    }

    return <>
        <Box style={containerStyle}>
            <Box height="100%" margin="10px" display="flex" justifyContent="space-between" flexDirection="column" gap="15px">
                {colors && estimateId &&
                    <>
                        <Box display="flex" flexDirection="row" width="100%">
                            <Box display="flex" flexDirection="column" gap="20px" justifyContent="space-between">
                                {estimate && estimate.indirectItemNoOfLevels > 0 && <Box marginRight="10px" marginTop="2px">
                                    <Button
                                        variant="outlined"
                                        disabled={!!editDisabledRef.current}
                                        startIcon={<CategoryOutlinedIcon />}
                                        onClick={handleOpenCategoriesModal}>Categories</Button>
                                </Box>}
                                <Box marginRight="10px" marginBottom="2px">
                                    <Button
                                        fullWidth
                                        startIcon={<AddIcon />}
                                        disabled={!!editDisabledRef.current}
                                        variant="contained"
                                        onClick={addItem}>New</Button>
                                </Box>
                            </Box>
                            <Box flex="1">
                                {estimateId && <Filters estimateId={estimateId} onFilterChanged={onFilterChanged} />}
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Box display="flex">
                                    <Box marginRight="10px">
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={grouped}
                                            size="small"
                                            disabled={!!isEditing}
                                            exclusive
                                            onChange={switchGrid}
                                            aria-label="Tree"
                                        >
                                            <ToggleButton value={false}>List View</ToggleButton>
                                            <ToggleButton value={true}>Tree View</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                    <Box>
                                        <ToggleButtonGroup
                                            color="primary"
                                            value={autoRowHeightEnabled}
                                            size="small"
                                            disabled={!!isEditing}
                                            exclusive
                                            onChange={enableDisableRowAutoHeight}
                                            aria-label="Autoheight"
                                        >
                                            <ToggleButton value={false}>Condensed</ToggleButton>
                                            <ToggleButton value={true}>AutoFit</ToggleButton>
                                        </ToggleButtonGroup>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                        {!grouped && <Box display="flex" flexDirection="column">
                            <Box display="flex">
                                {selectedRows?.length > 0 && !isEditing &&
                                    <>
                                        <Box marginRight="10px">
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disabled={selectedRows?.length > 500 || editDisabledRef.current}
                                                startIcon={<EditIcon />}
                                                onClick={editSelected}>Edit</Button>
                                        </Box>
                                        <Box marginRight="10px">
                                            <Button
                                                variant="contained"
                                                disabled={selectedRows?.length > 500 || editDisabledRef.current}
                                                sx={{ backgroundColor: `${colors?.redAccent[500]}` }}
                                                startIcon={<DeleteIcon />}
                                                onClick={deleteSelected}>Delete</Button>
                                        </Box>
                                    </>
                                }
                            </Box>
                            {selectedRows?.length > 0 && !isEditing &&
                                <Box marginTop="10px">
                                    <Typography variant="body1" color={selectedRows?.length > 500 ? `${colors.redAccent[500]}` : `${colors.greenAccent[300]}`}>
                                        {selectedRows?.length} records selected. Select max 500 records.
                                    </Typography>
                                </Box>}
                        </Box>
                        }
                    </>
                }
                {!grouped && <IndirectItemsFlat estimateId={estimateId} onSelected={onSelection} ref={indirectItemGridRef} onEdit={setIsEditing} />}
                {grouped && <IndirectItemsGrouped estimateId={estimateId} onSelected={onSelection} ref={indirectItemGridRef} />}
            </Box>
        </Box>
        <Modal
            open={openEditModal}
            onClose={handleCloseEditModal}
            aria-labelledby="edit-items-title"
            aria-describedby="edit-items-description"
        >
            <Box sx={{ ...editModalStyle }}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    marginBottom="10px"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" color="primary">Bulk edit {selectedRows?.length} records</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="edit" color="primary" onClick={handleCloseEditModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                {estimateId && estimate && <EditIndirectItems estimateId={estimateId} noOfLevels={estimate.indirectItemNoOfLevels} closeModal={handleCloseEditModal} items={selectedRows} />}
            </Box>
        </Modal>
        <Modal
            open={openCategoriesModal}
            onClose={handleCloseCategoriesModal}
            aria-labelledby="categories-modal"
            aria-describedby="categories-modal-description"
        >
            <Box className="large-modal">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Categories</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="delete" onClick={handleCloseCategoriesModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <IndirectCategories estimateId={estimateId} noOfLevels={estimate?.indirectItemNoOfLevels} />
            </Box>
        </Modal>
        <Backdrop
            sx={{ color: colors?.primary[1000], zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={openBackdrop}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
}