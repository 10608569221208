export interface UnitDto {
    displayId: string;
    description: string;
}

export interface Unit {
    id?: string;
    companyId?: string;
    description: string;
    fullDescription?: string;
    category?: UnitCategory;
    type?: UnitType;
    createDate?: Date;
    lastUpdated?: Date;
    order?: number;
    canDelete?: boolean;
}
export interface UnitExtended extends Unit{
    isNew: boolean;
    actions: string;
}
export interface UnitView {
    unitId?: string;
    unitDescription?: string;
}

export enum UnitCategory {
    Time = 'Time',
    Unit = 'Unit',
    LengthDistance = 'Length / Distance',
    Area = 'Area',
    Volume = 'Volume',
    Mass = 'Mass',
    Speed = 'Speed',
    DensityComplex = 'Density / Complex',
    Percentage = 'Percentage',
    Other = 'Other',
  }
  
  export enum UnitType {
    System = 'System',
    User = 'User',
  }
  