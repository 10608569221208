import { useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { ItemView } from 'Models/item';
import { Box, Tooltip } from '@mui/material';
import { useGetCategoriesQuery } from 'State/Services/category';

export interface ItemCategoryCellRendererProps extends ICellRendererParams<ItemView> {
    estimateId: string;
    level: number;
}

export default function ItemCategoryCellRenderer(props: ItemCategoryCellRendererProps) {
    const { data: user } = useGetUserDetailsQuery();
    const { data: storedCategories } = useGetCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId });
    const [title, setTitle] = useState('');

    useEffect(() =>{
     if (storedCategories && storedCategories.length>0){
        const cat = storedCategories.find((c) => (c.displayId===props.value && c.level===props.level));
        if (cat){
            setTitle(cat.category);
        }
     }
    }, [props.level, props.value, storedCategories])

    return (
        <Tooltip title={title}>
            <Box>{props.value}</Box>
        </Tooltip>
    );
};