import { api } from './api'
import { BaseVendor, BulkVendorDto, Vendor } from 'Models/vendor';


export const vendorApi = api.injectEndpoints({
    endpoints: (build) => ({
        getVendors: build.query<Array<Vendor>, { organizationId: string, companyId: string, estimateId: string }>({
            query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/vendor` }),
            providesTags: ['Vendors']
        }),
        getMaxVendorId: build.query<{ maxId: number }, { organizationId: string, companyId: string, estimateId: string }>({
            query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/vendor/max-id` }),
            providesTags: ['MaxVendorId']
        }),
        updateVendor: build.mutation<BaseVendor, Partial<{ body: BaseVendor, orgId: string, companyId: string, estimateId: string, vendorId: string }>>({
            query({ body, orgId, companyId, vendorId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/vendor/${vendorId}`,
                    method: 'PUT',
                    body,
                }
            },
            invalidatesTags: (result) => (result ? ['Vendors', 'MaxVendorId', 'Tags'] : [])
        }),
        createVendor: build.mutation<BaseVendor, Partial<{ body: BaseVendor, orgId: string, companyId: string, estimateId: string }>>({
            query({ body, orgId, companyId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/vendor`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: (result) => (result ? ['Vendors', 'MaxVendorId', 'Tags'] : [])
        }),
        bulkUpdateVendor: build.mutation<null, Partial<{ body: BulkVendorDto, orgId: string, companyId: string, estimateId: string }>>({
            query({ body, orgId, companyId, estimateId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/vendor/bulk`,
                    method: 'POST',
                    body,
                }
            },
            invalidatesTags: ['Vendors', 'MaxVendorId'],
        }),
        deleteVendor: build.mutation<void, Partial<{ orgId: string, companyId: string, estimateId: string, vendorId: string }>>({
            query({ orgId, companyId, estimateId, vendorId }) {
                return {
                    url: `organization/${orgId}/company/${companyId}/estimate/${estimateId}/vendor/${vendorId}`,
                    method: 'DELETE',
                }
            },
            invalidatesTags: (result) => (result ? ['Vendors', 'MaxVendorId'] : []),
        }),
    })
});

export const {
    useCreateVendorMutation,
    useDeleteVendorMutation,
    useBulkUpdateVendorMutation,
    useGetMaxVendorIdQuery,
    useGetVendorsQuery,
    useUpdateVendorMutation
} = vendorApi