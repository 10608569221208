import {
    forwardRef,
    memo,
    useCallback,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
} from 'react';
import { ICellEditorParams, IRowNode } from 'ag-grid-community';
import { Box, FormControl, MenuItem, OutlinedInput, Select, SelectChangeEvent, useTheme } from '@mui/material';
import { tokens } from 'theme';
import { EstimateUserRoleView } from 'Models/estimate';
import { EstimateRole } from 'Models/estimate-permission';

export interface RoleCellRendererParams extends ICellEditorParams<EstimateUserRoleView> {
    onChange: (node: IRowNode<EstimateUserRoleView>, role: EstimateRole) => Promise<void>;
}

export default memo(
    forwardRef((props: RoleCellRendererParams, ref) => {
        const [colors, setColors] = useState<any>();
        const theme = useTheme();
        useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
        const refInput = useRef<any>(null);

        const onChange = useCallback(async (event: SelectChangeEvent) => {
            await props.onChange(props.node, event.target.value as EstimateRole);
        }, [props])

        /* Component Editor Lifecycle methods */
        useImperativeHandle(ref, () => {
            return {
                // the final value to send to the grid, on completion of editing
                setFocusOnAdd() {
                    if (refInput.current) {
                        refInput.current.focus();
                    }
                }
            };
        });

        return (
            <>{colors && <Box sx={{ width: "100%", height: "100% !important" }}>
                <FormControl variant="standard" sx={{ height: 'inherit', width: "100%" }}>
                    <Select
                        value={props.value}
                        onChange={onChange}
                        input={<OutlinedInput sx={{ height: "inherit", backgroundColor: "white" }} />}
                    >
                        <MenuItem value={'View'}>View</MenuItem>
                        <MenuItem value={'Manage Data'}>Manage Data</MenuItem>
                        <MenuItem value={'Estimate Items'}>Estimate Items</MenuItem>
                        <MenuItem value={'Full'}>Full</MenuItem>
                    </Select>
                </FormControl>
            </Box>}
            </>
        );
    })
);
