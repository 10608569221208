import { Box, useTheme } from "@mui/material";
import { rounder } from "Helpers/rounder";
import { CategorySubcategoryTotal } from "Models/report";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";
import { ColDef, SideBarDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useCallback, useMemo, useState } from "react";
import { tokens } from "theme";

export interface ProjectResourceLevel2Props {
    categoryTotals: Array<CategorySubcategoryTotal>;
    estimateId: string | undefined;
}

export default function ProjectResourceLevel2(props: ProjectResourceLevel2Props) {
    const [rowData, setRowData] = useState<Array<CategorySubcategoryTotal>>([]);
    const groupDefaultExpanded = -1;
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [totalAmount] = useState(props.categoryTotals.reduce((accumulator, currentValue) => (accumulator + (currentValue?.amount ?? 0)), 0));
    const [totalEstimatedAmount] = useState(props.categoryTotals.reduce((accumulator, currentValue) => (accumulator + (currentValue?.estimatedAmount ?? 0)), 0));
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

    const [dataTypeDefinitions] = useState<any>({
        amountPercentage: {
            extendsDataType: 'number',
            baseDataType: 'number',
            valueFormatter: (params: any) => {
                if (params.node.group && props.categoryTotals){
                    let category = props.categoryTotals?.filter((cat) => (cat.categoryDescription===params.node.key));
                     const categoryTotal = category.reduce((accumulator, currentValue) => (accumulator + (currentValue?.amount??0)), 0);
                    if (categoryTotal){
                        return `${((categoryTotal/totalAmount) * 100).toFixed(2)}%` 
                    }
                }
                if (params.node.level===-1){
                    return '';
                }
                return `${((params.node.data?.amount/totalAmount) * 100).toFixed(2)}%` 
            }
        },
        estAmountPercentage: {
            extendsDataType: 'number',
            baseDataType: 'number',
            valueFormatter: (params: any) => {
                if (params.node.group && props.categoryTotals){
                    let category = props.categoryTotals?.filter((cat) => (cat.categoryDescription===params.node.key));
                     const categoryTotal = category.reduce((accumulator, currentValue) => (accumulator + (currentValue?.estimatedAmount??0)), 0);
                    if (categoryTotal){
                        return `${((categoryTotal/totalEstimatedAmount) * 100).toFixed(2)}%` 
                    }
                }
                if (params.node.level===-1){
                    return '';
                }
                return `${((params.node.data?.estimatedAmount/totalEstimatedAmount) * 100).toFixed(2)}%` 
            }
        },
        amount: {
            extendsDataType: 'number',
            baseDataType: 'number',
            valueFormatter: (params: any) => {
                return rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2);
            }
        }
    })

    const onGridReady = useCallback(() => {
        setRowData([...props.categoryTotals])
    }, [props.categoryTotals])

    const getRowStyle = (params: any) => {
        if (params.node.group) {
            return { fontWeight: 'bold' };
        }
    };

    const [columnDefs] = useState<Array<ColDef<CategorySubcategoryTotal>>>([
        {
            field: 'subCategoryDisplayId',
            resizable: true,
            headerName: 'ID',
            hide: false,
            cellRenderer: 'agGroupCellRenderer',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            width: 250,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            showRowGroup: true,
        },
        {
            field: 'description',
            flex: 1,
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            resizable: true,
            cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'quantity',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            resizable: true,
            aggFunc: 'sum',
            width: 175,
            cellDataType: "amountPercentage",
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'estimatedQuantity',
            width: 175,
            aggFunc: 'sum',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            resizable: true,
            cellDataType: "estAmountPercentage",
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'amount',
            width: 175,
            aggFunc: 'sum',
            enableValue: true,
            cellDataType: 'amount',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            resizable: true,
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'estimatedAmount',
            width: 175,
            aggFunc: 'sum',
            cellDataType: 'amount',
            menuTabs: ["filterMenuTab", "generalMenuTab"],
            resizable: true,
            cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
        },
        {
            field: 'categoryDescription',
            editable: true,
            rowGroup: true,
            hide: true
        },
    ]);

    const autoGroupColumnDef = useMemo<ColDef>(() => {
        return {
            colSpan: () => {
                return 2;
            }
        };
    }, []);

    const defaultColDef = useMemo<ColDef>(() => {
        return {
           resizable: true
        };
    }, []);

    const columnToolPanelParams = useMemo<SideBarDef>(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
        };
    }, []);

    return <>
        <Box style={gridStyle} className="ag-theme-alpine ag-theme-bidbow">
            <AgGridReact<CategorySubcategoryTotal>
                dataTypeDefinitions={dataTypeDefinitions}
                rowData={rowData}
                onGridReady={onGridReady}
                columnDefs={columnDefs}
                autoGroupColumnDef={autoGroupColumnDef}
                defaultColDef={defaultColDef}
                sideBar={columnToolPanelParams}
                groupDisplayType={'custom'}
                suppressAggFuncInHeader={true}
                groupDefaultExpanded={groupDefaultExpanded}
                suppressClickEdit={true}
                getRowStyle={getRowStyle}
                groupIncludeTotalFooter={true}
            />
        </Box>
    </>;
}