import { Box, Button, Modal, Typography, useTheme } from "@mui/material";
import { useGetUserDetailsQuery } from "State/Services/user";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "theme";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { useCreateMasterMutation, useGetAllMastersForCompanyQuery, useUpdateMasterMutation } from "State/Services/master";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Master, MasterView } from "Models/master";
import { ColDef, ICellRendererParams, IRowNode } from "ag-grid-enterprise";
import { useGetCompanyCurrenciesQuery } from "State/Services/company-currency";
import { format } from "date-fns";
import SaveCancelMasterCellRenderer, { MasterMenuOptions } from "./SaveCancelMasterCellRenderer";
import { AgGridReact } from "ag-grid-react";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import CreateEditCopyMaster from "Components/Master/CreateEditCopyMaster";
import { hasSystemRolePermission } from "Helpers/role-permissions";
import SharingPermissions from "Components/Shared/SharingPermissions";
import ChangeCurrency from "../EstimateDashboard/ChangeCurrency";
import { useConfirm } from "material-ui-confirm";
import { Estimate } from "Models/estimate";
import { useCopyEstimateMutation } from "State/Services/estimate";
import DeleteConfirmationModal from "Components/DeleteConfirmationModal";

export default function MasterDashboard() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const { data: user } = useGetUserDetailsQuery();
    const { data: masters } = useGetAllMastersForCompanyQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [rowData, setRowData] = useState<MasterView[]>();
    const [createMaster] = useCreateMasterMutation();
    const [updateMaster] = useUpdateMasterMutation();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const { data: companyCurrencies } = useGetCompanyCurrenciesQuery({ companyId: (user && user.companyId) ? user.companyId : '', orgId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const gridRef = useRef<AgGridReact<MasterView>>(null);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [selectedMaster, setSelectedMaster] = useState<Master>();
    const [selectedEstimate, setSelectedEstimate] = useState<Estimate>();
    const [createDisabled, setCreateDisabled] = useState(false);
    const [selectedOption, setSelectedOption] = useState<MasterMenuOptions>();
    const confirm = useConfirm();
    const [copyEstimate] = useCopyEstimateMutation();

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [])

    useEffect(() => {
        if (masters && masters.length > 0) {
            setRowData(masters.map((master) => ({ ...master, description: master.MasterEstimate?.description ?? '', actions: '', createdBy: `${master?.Owner?.firstName} ${master?.Owner?.lastName}  (${master?.Owner?.email})`, canDelete: master.MasterEstimate?.Estimate.length === 1 })));
        } else {
            setRowData([]);
        }
    }, [masters])

    useEffect(() => {
        if (user) {
            const hasCreateAccess = hasSystemRolePermission(user, [200]);
            setCreateDisabled(!hasCreateAccess);
        }
    }, [user])

    const saveMaster = useCallback(async (values: Master, state: "newOrEdit" | "copy") => {
        try {
            if (user) {
                switch (state) {
                    case "newOrEdit":
                        if (values.id) {
                            const master = masters?.find((master) => (master.id === values.id));
                            if (master) {
                                await updateMaster({
                                    companyId: user.companyId,
                                    orgId: user.organizationId,
                                    masterId: master.MasterEstimate?.id ?? '',
                                    body: {
                                        name: values.name,
                                        noOfLevels: values.noOfLevels,
                                        indirectItemNoOfLevels: values.indirectItemNoOfLevels,
                                        companyCurrencyId: values.companyCurrencyId,
                                        currencyExchangeRate: values.currencyExchangeRate,
                                        description: values.description,
                                        estimateId: values.id
                                    }
                                }).unwrap();
                                handleClose();
                            }
                        } else {
                            const estimate = await createMaster({
                                companyId: user.companyId,
                                orgId: user.organizationId,
                                body: {
                                    name: values.name,
                                    noOfLevels: values.noOfLevels,
                                    indirectItemNoOfLevels: values.indirectItemNoOfLevels,
                                    companyCurrencyId: values.companyCurrencyId,
                                    currencyExchangeRate: values.currencyExchangeRate,
                                    description: values.description,
                                }
                            }).unwrap();
                            navigate(`../${estimate.id}`);
                        }
                        break;
                    case "copy":
                        const newEstimate = await copyEstimate({
                            companyId: user.companyId,
                            orgId: user.organizationId,
                            fromEstimateId: values.id,
                            body: {
                                activities: true,
                                direct: true,
                                excludeDirectItemCostQuantities: false,
                                excludeDirectItemQuantities: false,
                                excludeIndirectItemCostQuantities: false,
                                excludeIndirectItemQuantities: false,
                                excludeResourceFactor: false,
                                indirect: true,
                                items: true,
                                margins: true,
                                vendorQuotations: true,
                                vendors: true,
                                resources: true,
                                pricing: true,
                                name: values.name,
                                noOfLevels: values.noOfLevels,
                                indirectItemNoOfLevels: values.indirectItemNoOfLevels,
                                projectName: '',
                                companyCurrencyId: values.companyCurrencyId,
                                currencyExchangeRate: values.currencyExchangeRate,
                                unitIds: [],
                                toMaster: true,
                                fromMaster: false,
                                isMasterDuplicate: true,
                                description: values.description
                            }
                        }).unwrap();
                        if (newEstimate) {
                            navigate(`../${newEstimate.id}`);
                            return;
                        }
                        break;
                    default:
                        break;
                }
            }


        } catch (error) {
            throw error;
        }

    }, [copyEstimate, createMaster, handleClose, masters, navigate, updateMaster, user])

    const action = useCallback(async (node: IRowNode<MasterView>, option: MasterMenuOptions) => {
        const estimate = masters?.find((master) => (master.id === node.data?.id));
        switch (option) {
            case MasterMenuOptions.newEdit:
                if (estimate?.CompanyCurrency?.Currency) {
                    setSelectedMaster({
                        id: estimate.id,
                        name: estimate.name,
                        noOfLevels: estimate.noOfLevels,
                        indirectItemNoOfLevels: estimate.indirectItemNoOfLevels,
                        companyCurrencyOption: estimate?.CompanyCurrency ? { ...estimate?.CompanyCurrency, currencyOption: `${estimate?.CompanyCurrency?.Currency.alphabeticalCode} - ${estimate?.CompanyCurrency?.Currency.currency}` } : undefined,
                        companyCurrencyId: estimate?.CompanyCurrency?.id ?? '',
                        currencyExchangeRate: estimate.currencyExchangeRate,
                        description: estimate.MasterEstimate?.description ?? ''
                    });
                }
                break;
            case MasterMenuOptions.duplicate:
                if (estimate?.CompanyCurrency?.Currency) {
                    setSelectedMaster({
                        id: estimate.id,
                        name: `${estimate.name} - Copy`,
                        noOfLevels: estimate.noOfLevels,
                        indirectItemNoOfLevels: estimate.indirectItemNoOfLevels,
                        companyCurrencyOption: estimate?.CompanyCurrency ? { ...estimate?.CompanyCurrency, currencyOption: `${estimate?.CompanyCurrency?.Currency.alphabeticalCode} - ${estimate?.CompanyCurrency?.Currency.currency}` } : undefined,
                        companyCurrencyId: estimate?.CompanyCurrency?.id ?? '',
                        currencyExchangeRate: estimate.currencyExchangeRate,
                        description: estimate.MasterEstimate?.description ?? ''
                    });
                }
                break;
            case MasterMenuOptions.sharingPermissions:
            case MasterMenuOptions.delete:
                setSelectedMaster(estimate);
                break;
            case MasterMenuOptions.changeCurrency:
                await confirm({
                    title: 'Confirmation',
                    cancellationText: "I’d like to duplicate my template.", confirmationText: "No thanks, I already did it.",
                    description: 'Before taking this action, we recommend that you duplicate your template. This will allow you to revert back to your original template.',
                });
                if (estimate?.CompanyCurrency?.Currency) {
                    setSelectedEstimate({
                        id: estimate.id,
                        name: estimate.name,
                        noOfLevels: estimate.noOfLevels,
                        indirectItemNoOfLevels: estimate.indirectItemNoOfLevels,
                        CompanyCurrency: estimate?.CompanyCurrency,
                        projectName: '',
                        companyCurrencyId: estimate?.CompanyCurrency?.id ?? '',
                        currencyExchangeRate: estimate.currencyExchangeRate,
                        unitIds: [],
                        updateResourceBasicRates: false
                    });
                }
                break;
            default:
                break;
        }
        setSelectedOption(option);
        handleOpen();
    }, [confirm, masters])
    const actionRef = useRef<any>();
    actionRef.current = action;

    const [columnDefs] = useState<ColDef<MasterView>[]>([
        { field: 'id', hide: true },
        { field: 'estimateId', hide: true },
        { field: 'name', headerName: 'Name', flex: 1, cellRenderer: (params: ICellRendererParams<MasterView>) => (<>{params.data && <Link to={`../${params.data.id}`}>{params.data.name}</Link>}</>) },
        { field: 'description', headerName: 'Description', width: 300 },
        { field: 'createdBy', headerName: 'Owner', width:300 },
        {
            field: 'createDate', sort: 'desc', valueGetter: (params) => {
                if (params.data && params.data.createDate) {
                    return format(new Date(params.data.createDate).getTime(), 'MM/dd/yyyy');
                }
                return ''
            },
        },
        {
            field: 'lastUpdated', valueGetter: (params) => {
                if (params.data && params.data.createDate) {
                    return format(new Date(params.data.createDate).getTime(), 'MM/dd/yyyy hh:mm aaa');
                }
                return ''
            },
        },
        {
            field: 'actions',
            resizable: true,
            width: 80,
            headerName: 'Actions',
            menuTabs: [],
            cellStyle: { textAlign: "left", padding: "0px" } as any,
            cellRenderer: SaveCancelMasterCellRenderer,
            cellRendererParams: {
                onAction: (node: IRowNode<MasterView>, option: MasterMenuOptions) => actionRef.current(node, option),
            }
        },
    ]);

    const addNewMaster = useCallback(() => {
        if (companyCurrencies) {
            const primary = companyCurrencies.find((curr) => (curr.isPrimary));
            if (primary) {
                const primaryCurr = { ...primary, currencyOption: `${primary.alphabeticalCode} - ${primary.currency}` };
                setSelectedMaster({
                    id: '',
                    name: '',
                    noOfLevels: 0,
                    indirectItemNoOfLevels: 0,
                    companyCurrencyOption: primaryCurr,
                    currencyExchangeRate: undefined,
                    companyCurrencyId: primaryCurr?.id ?? '',
                    description: ''
                });
                setSelectedOption(MasterMenuOptions.newEdit);
                handleOpen();
            }
        }
    }, [companyCurrencies])

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    return <Box height="100%" sx={{ borderLeft: `1px solid ${colors.gray[800]}`, boxShadow: `${colors.gray[800]} 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`, }}>
        <Box paddingTop="16px" paddingBottom="16px" paddingLeft="24px" paddingRight="24px" borderBottom={`1px solid ${colors.gray[800]}`}>
            <Typography component="div" sx={{ display: "flex" }}>
                <InsertDriveFileIcon sx={{ width: "20px", height: "20px", color: `${colors.gray[400]} !important`, marginRight: "4px" }} />
                <Box sx={{ fontWeight: 600, fontSize: "16px", color: `${colors.gray[100]} !important` }}>Templates</Box>
            </Typography>
        </Box>
        <Box height='calc(100% - 57px)' width='100%' display='flex' flexDirection='column' paddingLeft="10px" paddingRight="10px">
            <Box style={{ marginBottom: '5px', minHeight: '30px', marginTop: '5px' }}>
                <Button disabled={createDisabled} variant="contained" color="primary" onClick={addNewMaster} startIcon={<AddOutlinedIcon />}>New Template</Button>
            </Box>
            <div style={{ flex: '1 1 0px' }}>
                <div style={gridStyle} className="ag-theme-alpine ag-theme-bidbow">
                    <AgGridReact<MasterView>
                        ref={gridRef}
                        rowData={rowData}
                        defaultColDef={defaultColDef}
                        columnDefs={columnDefs}
                        rowSelection={'single'}
                        animateRows={true}
                    />
                </div>
            </div>
        </Box>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                {selectedOption === MasterMenuOptions.delete && <DeleteConfirmationModal close={handleClose} estimateMasterId={selectedMaster?.MasterEstimate?.id} isMaster={true} name={selectedMaster?.name} />}
                {selectedOption === MasterMenuOptions.newEdit && <CreateEditCopyMaster state="newOrEdit" save={saveMaster} close={handleClose} master={selectedMaster} />}
                {selectedOption === MasterMenuOptions.duplicate && <CreateEditCopyMaster state="copy" save={saveMaster} close={handleClose} master={selectedMaster} />}
                {selectedOption === MasterMenuOptions.changeCurrency && <ChangeCurrency close={handleClose} estimate={selectedEstimate} />}
                {selectedOption === MasterMenuOptions.sharingPermissions && <SharingPermissions isMaster={true} masters={masters} close={handleClose} estimateId={selectedMaster?.id} />}
            </>
        </Modal>
    </Box>
}