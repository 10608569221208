import {
    forwardRef,
    memo,
    useCallback,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { ICellEditorParams } from 'ag-grid-community';
import { Box, FormControl, OutlinedInput } from '@mui/material';
import { CompanyCurrencyView } from 'Models/company-currency';

// backspace starts the editor on Windows
const KEY_BACKSPACE = 'Backspace';
const KEY_F2 = 'F2';
const KEY_ARROW_LEFT = 'ArrowLeft';
const KEY_ARROW_RIGHT = 'ArrowRight';
const KEY_DELETE = "Delete";

export default memo(
    forwardRef((props: ICellEditorParams<CompanyCurrencyView>, ref) => {
        const createInitialState = () => {
            let startValue;
            let highlightAllOnFocus = true;
            const eventKey = props.eventKey;

            if (eventKey === KEY_BACKSPACE) {
                // if backspace or delete pressed, we clear the cell
                startValue = '';
            } else if (eventKey && eventKey.length === 1) {
                // if a letter was pressed, we start with the letter
                startValue = props.eventKey;
                highlightAllOnFocus = false;
            } else {
                // otherwise we start with the current value
                startValue = props.value;
                if (eventKey === KEY_F2) {
                    highlightAllOnFocus = false;
                }
            }
            if (!props.value) {
                startValue = '';
            }

            return {
                value: startValue,
                highlightAllOnFocus,
            };
        };

        const initialState = createInitialState();
        const [value, setValue] = useState(initialState.value);
        const refInput = useRef<HTMLInputElement>(null);

        const onChange = useCallback((value: string) => {
            setValue(value);
            const secondaryToPrimaryInstances = props.api.getCellEditorInstances({
                columns: ['secondaryToPrimaryRate']
            });
            if (secondaryToPrimaryInstances.length > 0 && secondaryToPrimaryInstances[0] && typeof (secondaryToPrimaryInstances[0] as any).changeValue === 'function') {
                if (!isNaN(parseFloat(value))) {
                    (secondaryToPrimaryInstances[0] as any).changeValue(1 / parseFloat(value));
                } else {
                    (secondaryToPrimaryInstances[0] as any).changeValue('');
                }
            }
        }, [props])

        /* Utility Methods */
        const isCharacter = props.eventKey && props.eventKey.length === 1;
        const cancelBeforeStart =
            isCharacter && '1234567890'.indexOf(props.eventKey!) < 0;

        const isCharNumeric = (charStr: string) => {
            return !!/^[0-9.]$/.test(charStr);
        };

        const isNumericKey = (event: any) => {
            const charStr = event.key;
            return isCharNumeric(charStr);
        };

        const isLeftOrRight = (event: any) => {
            return [KEY_ARROW_LEFT, KEY_ARROW_RIGHT].indexOf(event.key) > -1;
        };

        const isBackspaceOrDelete = (event: any) => {
            return event.key === KEY_BACKSPACE || event.key === KEY_DELETE;
        };

        const onKeyDown = (event: any) => {
            if (isLeftOrRight(event) || isBackspaceOrDelete(event)) {
                return;
            }

            if (!isNumericKey(event)) {
                if (event.preventDefault) event.preventDefault();
            }
        };

        /* Component Editor Lifecycle methods */
        useImperativeHandle(ref, () => {
            return {
                // the final value to send to the grid, on completion of editing
                getValue() {
                    return value === '' || value == null ? null : parseFloat(value);
                },
                setFocusOnAdd(){
                    if (refInput.current){
                        refInput.current.focus();
                        refInput.current.select();
                    }
                },
                // Gets called once before editing starts, to give editor a chance to
                // cancel the editing before it even starts.
                isCancelBeforeStart() {
                    return cancelBeforeStart;
                },
                changeValue(value: number) {
                    setValue(value);
                },
                focusIn() {
                    refInput.current!.focus();
                }
            };
        });

        return (
            <Box sx={{ width: "100%", height: "100% !important" }}>
                <FormControl sx={{ height: 'inherit', width: "100%" }}>
                    <OutlinedInput
                        sx={{ height: "inherit", backgroundColor: "white" }}
                        className="ag-input-field-input ag-text-field-input"
                        placeholder="Rate"
                        onChange={(event) => onChange(event.target.value)}
                        value={value}
                        inputRef={refInput}
                        onKeyDown={(event) => onKeyDown(event)}
                    />
                </FormControl>
            </Box>
        );
    })
);
