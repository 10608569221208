import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Box, Button, useTheme, Autocomplete, TextField } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { tokens } from "theme";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useGetPackagesQuery } from "State/Services/subcontractor";
import { PackageInfo } from "Models/package";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useNavigate } from "react-router-dom";
import { ItemView } from "Models/item";
import { useAppDispatch } from "State/hooks";
import { setSubcontractorState } from "State/subcontractorSlice";

export interface SubcontractorQuotesProps {
    estimateId: string;
    closeModal: () => void;
    items: ItemView[];
}

export default function SubcontractorQuotes(props: SubcontractorQuotesProps) {
    const theme = useTheme();
    const [colors, setColors] = useState<any>();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const { data: user } = useGetUserDetailsQuery();
    const { data: packages } = useGetPackagesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId })
    const [selectedPackage, setSelectedPackage] = useState<PackageInfo | undefined>();
    const [value, setValue] = useState('create');
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    
    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedPackage(undefined);
        setValue((event.target as HTMLInputElement).value);
    }, []);

    const openPackage = useCallback(() => {
        dispatch(setSubcontractorState({option: value as "edit" | "create" | "none", packageId: value === "edit" ? selectedPackage?.id : null, items: props.items}));
        navigate(`/organization/${user?.organizationId}/company/${user?.companyId}/estimate/${props.estimateId}/vendor`, {
            state: {
                tab: "Subcontractor Quotes"
            }
        });
    }, [dispatch, navigate, props.estimateId, props.items, selectedPackage?.id, user?.companyId, user?.organizationId, value])

    return <>
        <Box display="flex" alignItems="center" marginTop="10px" marginLeft="20px" marginRight="20px" marginBottom="30px">
            <FormControl fullWidth>
                <FormLabel id="options-radio-buttons-group-label">Options</FormLabel>
                <RadioGroup
                    value={value}
                    onChange={handleChange}
                    aria-labelledby="options-radio-buttons-group-label"
                    defaultValue="create"
                    name="radio-buttons-group"
                >
                    <FormControlLabel value="create" control={<Radio />} label="Create new quote package" />
                    <FormControlLabel value="edit" control={<Radio />} label="Add items to existing package" />
                    <Autocomplete
                        disabled={value !== "edit"}
                        fullWidth
                        className="ag-input-field-input ag-text-field-input"
                        sx={{ marginLeft: "25px", width: "245px", height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                        size="small"
                        getOptionLabel={(option) => option.name ?? ''}
                        onChange={(event, value) => {
                            if (value) {
                                setSelectedPackage(value);
                            } else {
                                setSelectedPackage(undefined);
                            }
                        }}
                        options={packages ?? []}
                        isOptionEqualToValue={(option, tag) => option.id === tag.id}
                        value={selectedPackage ?? null}
                        renderInput={(params) => <TextField sx={{ height: "100%" }} placeholder='Select Package' {...params} />}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.name ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.name ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />
                </RadioGroup>
            </FormControl>
        </Box>
        <Box display="flex" justifyContent="space-evenly">
            <Button
                variant="contained"
                onClick={openPackage}
            >
                Submit
            </Button>
            <Button variant="contained" color="secondary" sx={{ backgroundColor: `${colors?.gray[1000]}` }} onClick={props.closeModal}>Cancel</Button>
        </Box>
    </>
}