import { Box, Typography, useTheme } from "@mui/material";
import { useCreateResourceCategoryMutation, useCreateResourceSubCategoryMutation, useDeleteResourceCategoryMutation, useDeleteResourceSubCategoryMutation, useGetResourceCategoriesQuery, useUpdateResourceCategoryMutation, useUpdateResourceSubCategoryMutation } from "State/Services/resource-category";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "theme";
import { ResourceCategoryView, ResourceSubCategoryView } from "Models/resource-category";
import { AgGridReact } from "ag-grid-react";
import { ColDef, IRowNode, SuppressKeyboardEventParams } from "ag-grid-enterprise";
import SaveCancelCategoryCellRenderer from "./SaveCancelCategoryCellRenderer";
import CategoryResourceIdEditCellRenderer from "./CategoryResourceIdEditCellRenderer";
import { CellClickedEvent, CellEditingStartedEvent, CellEditingStoppedEvent, CellKeyDownEvent, GetRowIdParams, ICellRendererParams } from "ag-grid-community";
import { v4 as uuidv4 } from 'uuid';
import SaveCancelSubCategoryCellRenderer from "./SaveCancelSubCategoryCellRenderer";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { useGetEstimateQuery } from "State/Services/estimate";
import CategoryResourceDescriptionEditCellRenderer from "./CategoryResourceDescriptionEditCellRenderer";
import { ServerError } from "Models/error-info";

export interface ResourceCategoryProps {
    estimateId: string | undefined;
}

export default function AddEditResourceCategory(props: ResourceCategoryProps) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [currentCategoryEditing, setCurrentCategoryEditing] = useState<{ node: IRowNode<ResourceCategoryView> | undefined, column?: string }>();
    const [currentSubCategoryEditing, setCurrentSubCategoryEditing] = useState<{ node: IRowNode<ResourceSubCategoryView> | undefined, column?: string }>();
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: storedResourceCategories } = useGetResourceCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId });
    const [createResourceCategory] = useCreateResourceCategoryMutation();
    const [createResourceSubCategory] = useCreateResourceSubCategoryMutation();
    const [updateResourceCategory] = useUpdateResourceCategoryMutation();
    const [updateResourceSubCategory] = useUpdateResourceSubCategoryMutation();
    const [isCategoryCancelClicked, setIsCategoryCancelClicked] = useState(false);
    const [isSubCategoryCancelClicked, setIsSubCategoryCancelClicked] = useState(false);
    const [resourceCategories, setResourceCategories] = useState<Array<ResourceCategoryView>>([]);
    const [resourceSubCategories, setResourceSubCategories] = useState<Array<ResourceSubCategoryView>>([]);
    const resourceCategoryGridRef = useRef<AgGridReact<ResourceCategoryView>>(null);
    const resourceSubCategoryGridRef = useRef<AgGridReact<ResourceSubCategoryView>>(null);
    const [selectedCategory, setSelectedCategory] = useState<ResourceCategoryView | undefined>();
    const [deleteCategory] = useDeleteResourceCategoryMutation();
    const [deleteSubCategory] = useDeleteResourceSubCategoryMutation();
    const editDisabledRef = useRef<boolean>();
    const deleteDisabledRef = useRef<boolean>();
    const [categoryErrors, setCategoryErrors] = useState<Array<{ field: string, error: string }>>([]);
    const [subCategoryErrors, setSubCategoryErrors] = useState<Array<{ field: string, error: string }>>([]);

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
                deleteDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Resources,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Resources,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            editDisabledRef.current = true;
            deleteDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    useEffect(() => {
        const categories = new Array<ResourceCategoryView>();
        if (storedResourceCategories && storedResourceCategories.length > 0) {
            for (let i = 0; i < storedResourceCategories.length; i++) {
                const category = storedResourceCategories[i];
                categories.push({
                    displayId: category.displayId,
                    description: category.description,
                    actions: '',
                    masterReferenceId: category.masterReferenceId,
                    canDelete: category.canDelete,
                    id: category.id,
                    errors: [],
                    isNew: false
                });
            }
            setResourceCategories(categories);
        } else {
            setResourceCategories([]);
        }
    }, [storedResourceCategories])

    useEffect(() => {
        if (categoryErrors.length > 0) {
            categoryErrors.forEach((errorDetails) => {
                switch (errorDetails.field) {
                    case 'description':
                        const descriptionInstances = resourceCategoryGridRef.current!.api.getCellEditorInstances({
                            columns: ['description']
                        });
                        if (descriptionInstances && descriptionInstances.length > 0 && descriptionInstances[0] && typeof (descriptionInstances[0] as any).setError === 'function') {
                            (descriptionInstances[0] as any).setError(errorDetails.error);
                        }
                        break;
                    case 'displayId':
                        const displayIdInstances = resourceCategoryGridRef.current!.api.getCellEditorInstances({
                            columns: ['displayId']
                        });
                        if (displayIdInstances && displayIdInstances.length > 0 && displayIdInstances[0] && typeof (displayIdInstances[0] as any).setError === 'function') {
                            (displayIdInstances[0] as any).setError(errorDetails.error);
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    }, [categoryErrors])

    useEffect(() => {
        if (subCategoryErrors.length > 0) {
            subCategoryErrors.forEach((errorDetails) => {
                switch (errorDetails.field) {
                    case 'description':
                        const descriptionInstances = resourceSubCategoryGridRef.current!.api.getCellEditorInstances({
                            columns: ['description']
                        });
                        if (descriptionInstances && descriptionInstances.length > 0 && descriptionInstances[0] && typeof (descriptionInstances[0] as any).setError === 'function') {
                            (descriptionInstances[0] as any).setError(errorDetails.error);
                        }
                        break;
                    case 'displayId':
                        const displayIdInstances = resourceSubCategoryGridRef.current!.api.getCellEditorInstances({
                            columns: ['displayId']
                        });
                        if (displayIdInstances && displayIdInstances.length > 0 && displayIdInstances[0] && typeof (displayIdInstances[0] as any).setError === 'function') {
                            (displayIdInstances[0] as any).setError(errorDetails.error);
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    }, [subCategoryErrors])

    useEffect(() => {
        if (currentCategoryEditing && typeof currentCategoryEditing.node?.rowIndex === 'number') {
            if (currentCategoryEditing.node.isRowPinned()) {
                resourceCategoryGridRef.current!.api.startEditingCell({
                    rowIndex: currentCategoryEditing.node.rowIndex,
                    colKey: currentCategoryEditing.column ?? 'displayId',
                    rowPinned: 'top'
                });
            } else {
                resourceCategoryGridRef.current!.api.startEditingCell({
                    rowIndex: currentCategoryEditing.node.rowIndex,
                    colKey: currentCategoryEditing.column ?? 'displayId',
                });
            }
        }
    }, [currentCategoryEditing])

    useEffect(() => {
        if (currentSubCategoryEditing && typeof currentSubCategoryEditing.node?.rowIndex === 'number') {
            if (currentSubCategoryEditing.node.isRowPinned()) {
                resourceSubCategoryGridRef.current!.api.startEditingCell({
                    rowIndex: currentSubCategoryEditing.node.rowIndex,
                    colKey: currentSubCategoryEditing.column ?? 'displayId',
                    rowPinned: 'top'
                });
            } else {
                resourceSubCategoryGridRef.current!.api.startEditingCell({
                    rowIndex: currentSubCategoryEditing.node.rowIndex,
                    colKey: currentSubCategoryEditing.column ?? 'displayId',
                });
            }
        }
    }, [currentSubCategoryEditing])

    const saveCategoryRow = useCallback((nodeToSave: IRowNode<ResourceCategoryView>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<ResourceCategoryView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (resourceCategoryGridRef && resourceCategoryGridRef.current) {
                    if (nodeToSave.data) {
                        resourceCategoryGridRef.current!.api.stopEditing();
                        if (!nodeToSave.data.displayId) {
                            const error: ServerError = {data: { displayId: 'ID is required'}};
                            throw error;
                        }
                        if (!nodeToSave.data.description) {
                            const error: ServerError = {data: {description: 'Description is required'}};
                            throw error;
                        }
                        if (!nodeToSave.data.isNew) {
                            await updateResourceCategory({
                                resourceCategoryId: nodeToSave.data.id,
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                body: {
                                    id: nodeToSave.data.id,
                                    displayId: nodeToSave.data.displayId,
                                    description: nodeToSave.data.description
                                }
                            }).unwrap();
                        }
                        else {
                            await createResourceCategory({
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                body: {
                                    id: uuidv4(),
                                    displayId: nodeToSave.data.displayId,
                                    description: nodeToSave.data.description
                                }
                            }).unwrap();
                        }

                        if (nodeToSave.rowPinned) {
                            resourceCategoryGridRef.current!.api.applyTransaction({ add: [nodeToSave.data] });
                            nodeToSave.updateData({
                                displayId: '',
                                description: 'Enter Category Name',
                                id: undefined,
                                errors: [],
                                isNew: true
                            });
                        }
                    }
                }
                resolve();
                if (toEditAfterSave) {
                    setCurrentCategoryEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                } else {
                    setCurrentCategoryEditing(undefined);
                }
            } catch (error: any) {
                let errorMessage = '';
                let column = 'displayId';
                if (error) {
                    if (error.data.displayId) {
                        errorMessage = error.data.displayId;
                        column = 'displayId';
                    } else if (error.data.description) {
                        errorMessage = error.data.description;
                        column = 'description';
                    }
                }

                if (errorMessage && typeof nodeToSave.rowIndex === 'number') {
                    if (nodeToSave.isRowPinned()) {
                        resourceCategoryGridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: column,
                            rowPinned: 'top'
                        });
                    } else {
                        resourceCategoryGridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: column,
                        });
                    }
                    setCategoryErrors([{ field: column, error: errorMessage }]);
                }
                reject(error);
            }
        });
    }, [createResourceCategory, props.estimateId, updateResourceCategory, user?.companyId, user?.organizationId])

    const onCategoryCellClicked = useCallback(async (event: CellClickedEvent) => {
        try {
            if (currentCategoryEditing?.node === event.node) {
                return;
            }
            if (!currentCategoryEditing?.node) {
                if (event.node.data?.masterReferenceId || editDisabledRef.current) return;

                if (isCategoryCancelClicked) {
                    setIsCategoryCancelClicked(false);
                    return;
                };

                if (event.column.getColId() === 'actions') {
                    return;
                }
                setCurrentCategoryEditing({ node: event.node, column: event.column.getColId() });
            } else {
                await saveCategoryRow(currentCategoryEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
            }
        } catch (error) {}

    }, [currentCategoryEditing?.node, isCategoryCancelClicked, saveCategoryRow])

    const saveSubCategoryRow = useCallback((nodeToSave: IRowNode<ResourceSubCategoryView>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<ResourceSubCategoryView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (resourceSubCategoryGridRef && resourceSubCategoryGridRef.current) {
                    resourceSubCategoryGridRef.current!.api.stopEditing();
                    if (!nodeToSave.data?.displayId) {
                        const error: ServerError = {data: { displayId: 'ID is required'}};
                        throw error;
                    }
                    if (!nodeToSave.data?.description) {
                        const error: ServerError = {data: {description: 'Description is required'}};
                        throw error;
                    }
                    if (nodeToSave.data) {
                        if (!nodeToSave.data.isNew) {
                            await updateResourceSubCategory({
                                resourceSubCategoryId: nodeToSave.data.id,
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                resourceCategoryId: nodeToSave.data.resourceCategoryId,
                                body: {
                                    id: nodeToSave.data.id,
                                    displayId: nodeToSave.data.displayId,
                                    description: nodeToSave.data.description,
                                    resourceCategoryId: nodeToSave.data.resourceCategoryId
                                }
                            }).unwrap();
                        }
                        else {
                            await createResourceSubCategory({
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                resourceCategoryId: nodeToSave.data.resourceCategoryId,
                                body: {
                                    id: uuidv4(),
                                    displayId: nodeToSave.data.displayId,
                                    description: nodeToSave.data.description,
                                    resourceCategoryId: nodeToSave.data.resourceCategoryId
                                }
                            }).unwrap();
                        }

                        if (nodeToSave.rowPinned) {
                            resourceSubCategoryGridRef.current!.api.applyTransaction({ add: [nodeToSave.data] });
                            nodeToSave.updateData({
                                displayId: '',
                                description: 'Enter Category Name',
                                resourceCategoryId: nodeToSave.data.resourceCategoryId,
                                id: undefined,
                                errors: [],
                                isNew: true
                            });
                        }
                    }
                }
                resolve();
                if (toEditAfterSave) {
                    setCurrentSubCategoryEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                } else {
                    setCurrentSubCategoryEditing(undefined);
                }
            } catch (error: any) {
                let errorMessage = '';
                let column = 'displayId';
                if (error) {
                    if (error.data.displayId) {
                        errorMessage = error.data.displayId;
                        column = 'displayId';
                    } else if (error.data.description) {
                        errorMessage = error.data.description;
                        column = 'description';
                    }
                }

                if (errorMessage && typeof nodeToSave.rowIndex === 'number') {
                    if (nodeToSave.isRowPinned()) {
                        resourceSubCategoryGridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: column,
                            rowPinned: 'top'
                        });
                    } else {
                        resourceSubCategoryGridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: column,
                        });
                    }
                    setSubCategoryErrors([{ field: column, error: errorMessage }]);
                }
                reject(error);
            }
        });
    }, [createResourceSubCategory, props.estimateId, updateResourceSubCategory, user?.companyId, user?.organizationId])

    const onSubCategoryCellClicked = useCallback(async (event: CellClickedEvent) => {
        try {
            if (currentSubCategoryEditing?.node === event.node) {
                return;
            }
            if (!currentSubCategoryEditing?.node) {
                if (event.node.data?.masterReferenceId || editDisabledRef.current) return;

                if (isSubCategoryCancelClicked) {
                    setIsSubCategoryCancelClicked(false);
                    return;
                };

                if (event.column.getColId() === 'actions') {
                    return;
                }
                setCurrentSubCategoryEditing({ node: event.node, column: event.column.getColId() });
            } else {
                await saveSubCategoryRow(currentSubCategoryEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
            }
        } catch (error) {}

    }, [currentSubCategoryEditing?.node, isSubCategoryCancelClicked, saveSubCategoryRow])

    const saveResourceCategoryOnEnter = useCallback((params: SuppressKeyboardEventParams<ResourceCategoryView>) => {
        try {
            if (params.event.key === 'Enter' && params.node) {
                params.event.stopPropagation();
                saveCategoryRow(params.node);
                return true;
            }
        } catch (error) { }
        return false;
    }, [saveCategoryRow])

    const saveResourceSubCategoryOnEnter = useCallback((params: SuppressKeyboardEventParams<ResourceSubCategoryView>) => {
        if (params.event.key === 'Enter' && params.node) {
            params.event.stopPropagation();
            saveSubCategoryRow(params.node);
            return true;
        }
        return false;
    }, [saveSubCategoryRow])

    const deleteCategoryRow = useCallback((node: IRowNode<ResourceCategoryView>) => {
        return new Promise<void>(async (resolve) => {
            if (node.data) {
                await deleteCategory({
                    companyId: user?.companyId,
                    estimateId: props.estimateId,
                    orgId: user?.organizationId,
                    resourceCategoryId: node.data?.id,
                });
            }
            resolve();
        });
    }, [deleteCategory, props.estimateId, user?.companyId, user?.organizationId])

    const cancelCategoryEditing = useCallback((node: IRowNode<ResourceCategoryView>) => {
        resourceCategoryGridRef.current!.api.stopEditing(true);
        if (node.rowPinned) {
            node.updateData({
                displayId: '',
                description: 'Enter Category Name',
                id: undefined,
                isNew: true
            });
        } else {
            setIsCategoryCancelClicked(true);
            const category = storedResourceCategories?.find((v) => (v.id === node.data?.id));
            if (category){
                resourceCategoryGridRef.current!.api.applyTransaction({ update: [{ ...category }] });
            }              
            resourceCategoryGridRef.current!.api.stopEditing(true);
        }
        setCurrentCategoryEditing(undefined);
    }, [storedResourceCategories])

    const deleteSubCategoryRow = useCallback((node: IRowNode<ResourceSubCategoryView>) => {
        return new Promise<void>(async (resolve) => {
            if (node.data) {
                await deleteSubCategory({
                    companyId: user?.companyId,
                    estimateId: props.estimateId,
                    orgId: user?.organizationId,
                    resourceCategoryId: node.data?.resourceCategoryId,
                    resourceSubCategoryId: node.data.id
                });
            }
            resolve();
        });
    }, [deleteSubCategory, props.estimateId, user?.companyId, user?.organizationId])

    const cancelSubCategoryEditing = useCallback((node: IRowNode<ResourceSubCategoryView>) => {
        resourceSubCategoryGridRef.current!.api.stopEditing(true);
        if (node.rowPinned) {
            node.updateData({
                displayId: '',
                description: 'Enter Category Name',
                resourceCategoryId: node.data?.resourceCategoryId,
                id: undefined,
                isNew: true
            });
        } else {
            setIsCategoryCancelClicked(true);
            const category = storedResourceCategories?.find((v) => (v.id === node.data?.resourceCategoryId));
            const subCategory = category?.resourceSubCategories?.find(c => c.id===node.data?.id);
            if (subCategory){
                resourceSubCategoryGridRef.current!.api.applyTransaction({ update: [{ ...subCategory }] });
            }              
            resourceSubCategoryGridRef.current!.api.stopEditing(true);
        }
        setCurrentSubCategoryEditing(undefined);
    }, [storedResourceCategories])

    const getCategoryColumnDefs = (): ColDef<ResourceCategoryView>[] => {
        return [
            { field: 'id', hide: true, cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } },
            {
                field: 'displayId',
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                cellEditor: CategoryResourceIdEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId,
                    type: 'category'
                },
                headerName: 'ID',
                cellDataType: 'string',
                width: 85,
                sortable: true,
                suppressKeyboardEvent: (params) => saveResourceCategoryOnEnter(params)
            },
            {
                field: 'description',
                cellEditor: CategoryResourceDescriptionEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId,
                    type: 'subcategory'
                },
                cellRenderer: (params: ICellRendererParams<ResourceCategoryView>) =>{
                    if (params.node.isRowPinned()){
                        return <i>{params.node.data?.description}</i>
                    }
                    return params.node.data?.description;
                },
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                flex: 1,
                suppressKeyboardEvent: (params) => saveResourceCategoryOnEnter(params),
            },
            {
                field: 'actions',
                suppressKeyboardEvent: (params) => saveResourceCategoryOnEnter(params),
                resizable: true,
                width: 100,
                editable: false,
                headerName: '',
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRendererSelector: () => {
                    return {
                        component: SaveCancelCategoryCellRenderer,
                        params: {
                            delete: (node: IRowNode<ResourceCategoryView>) => deleteCategoryRow(node),
                            save: (node: IRowNode<ResourceCategoryView>) => saveCategoryRow(node),
                            cancel: (node: IRowNode<ResourceCategoryView>) => cancelCategoryEditing(node),
                            disabled: () => deleteDisabledRef.current,
                            select: (node: IRowNode<ResourceCategoryView>) => onCategorySelectionChangedRef.current(node)
                        },
                    };
                },
            },
        ]
    }
    const getSubCategoryColumnDefs = (): ColDef<ResourceSubCategoryView>[] => {
        return [
            { field: 'id', hide: true, cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } },
            { field: 'resourceCategoryId', hide: true, cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` } },
            {
                field: 'displayId',
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                cellEditor: CategoryResourceIdEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId,
                    type: 'subcategory'
                },
                headerName: 'ID',
                cellDataType: 'string',
                width: 85,
                sortable: true,
                suppressKeyboardEvent: (params) => saveResourceSubCategoryOnEnter(params),
            },
            {
                field: 'description',
                cellRenderer: (params: ICellRendererParams<ResourceSubCategoryView>) =>{
                    if (params.node.isRowPinned()){
                        return <i>{params.node.data?.description}</i>
                    }
                    return params.node.data?.description;
                },
                cellEditor: CategoryResourceDescriptionEditCellRenderer,
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                cellStyle: { borderRight: `1px solid ${colors?.gray[800]}` },
                flex: 1,
                suppressKeyboardEvent: (params) => saveResourceSubCategoryOnEnter(params),
            },
            {
                field: 'actions',
                suppressKeyboardEvent: (params) => saveResourceSubCategoryOnEnter(params),
                resizable: true,
                width: 60,
                editable: false,
                headerName: '',
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRendererSelector: () => {
                    return {
                        component: SaveCancelSubCategoryCellRenderer,
                        params: {
                            delete: (node: IRowNode<ResourceSubCategoryView>) => deleteSubCategoryRow(node),
                            save: (node: IRowNode<ResourceSubCategoryView>) => saveSubCategoryRow(node),
                            cancel: (node: IRowNode<ResourceSubCategoryView>) => cancelSubCategoryEditing(node),
                            disabled: () => deleteDisabledRef.current
                        },
                    };
                },
            },
        ]
    }
    const [resourceCategoryDefs] = useState<ColDef<ResourceCategoryView>[]>(getCategoryColumnDefs());
    const [resourceSubCategoryDefs] = useState<ColDef<ResourceSubCategoryView>[]>(getSubCategoryColumnDefs());

    const defaultCategoryColDef = useMemo<ColDef>(() => {
        return {
            editable: true
        };
    }, []);

    const defaultSubCategoryColDef = useMemo<ColDef>(() => {
        return {
            editable: true
        };
    }, []);

    const createResourceCategoryPinnedData = useMemo<ResourceCategoryView[]>(() => {
        if (!editDisabledRef.current) {
            return [{ isNew: true, description: 'Enter Category Name', displayId: '' }];
        }
        return [];
    }, [])

    const onCategoryRowEditingStarted = (event: CellEditingStartedEvent<ResourceCategoryView>) => {
        setIsCategoryCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
        setTimeout(() => {
            const displayInstances = resourceCategoryGridRef.current!.api.getCellEditorInstances({
                columns: [currentCategoryEditing?.column ?? 'displayId']
            });
            if (displayInstances && displayInstances.length > 0 && displayInstances[0] && typeof (displayInstances[0] as any).setFocusOnAdd === 'function') {
                (displayInstances[0] as any).setFocusOnAdd();
            }
        }, 100);
    }

    const onCategoryRowEditingStopped = (event: CellEditingStoppedEvent<ResourceCategoryView>) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }

    const onSubCategoryRowEditingStarted = (event: CellEditingStartedEvent<ResourceSubCategoryView>) => {
        setIsSubCategoryCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
        setTimeout(() => {
            const displayInstances = resourceSubCategoryGridRef.current!.api.getCellEditorInstances({
                columns: [currentSubCategoryEditing?.column ?? 'displayId']
            });
            if (displayInstances && displayInstances.length > 0 && displayInstances[0] && typeof (displayInstances[0] as any).setFocusOnAdd === 'function') {
                (displayInstances[0] as any).setFocusOnAdd();
            }
        }, 100);
    }

    const onSubCategoryRowEditingStopped = (event: CellEditingStoppedEvent<ResourceSubCategoryView>) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }

    const getCategoryRowId = useCallback(function (params: GetRowIdParams<ResourceCategoryView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    const getSubCategoryRowId = useCallback(function (params: GetRowIdParams<ResourceSubCategoryView>) {
        if (params.data.id) {
            return params.data.id.toString();
        }
        return '';
    }, []);

    const onSubCategoryGridReady = useCallback(() => {
        if (selectedCategory && resourceSubCategoryGridRef.current!.api) {
            const resourceSubCategories = new Array<ResourceSubCategoryView>()
            if (storedResourceCategories && storedResourceCategories.length > 0 && selectedCategory) {
                const storedCategory = storedResourceCategories.find((resourceCategory) => (resourceCategory.id === selectedCategory.id));
                if (storedCategory?.resourceSubCategories) {
                    for (let i = 0; i < storedCategory?.resourceSubCategories.length; i++) {
                        const subCategory = storedCategory?.resourceSubCategories[i];
                        resourceSubCategories.push({ ...subCategory, actions: '', isNew: false, errors: [] });
                    }
                }
            }
            if (selectedCategory) {
                if (!selectedCategory.masterReferenceId && !editDisabledRef.current) {
                    resourceSubCategoryGridRef.current!.api.setGridOption('pinnedTopRowData', [{ isNew: true, description: 'Enter Category Name', resourceCategoryId: selectedCategory.id, displayId: '' }]);
                } else {
                    resourceSubCategoryGridRef.current!.api.setGridOption('pinnedTopRowData', []);
                }

            }
            setResourceSubCategories(resourceSubCategories);
        }
    }, [selectedCategory, storedResourceCategories])

    useEffect(() => {
        onSubCategoryGridReady();
    }, [onSubCategoryGridReady, selectedCategory])

    const onCategorySelectionChanged = useCallback((node: IRowNode<ResourceCategoryView>) => {
        setSelectedCategory(node.data);
    }, [])
    const onCategorySelectionChangedRef = useRef<(node: IRowNode<ResourceCategoryView>) => void>(onCategorySelectionChanged);

    const onCategoryCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelCategoryEditing(e.node);
            setIsCategoryCancelClicked(false);
        }
    }, [cancelCategoryEditing]);
    
    const onSubCategoryCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelSubCategoryEditing(e.node);
            setIsSubCategoryCancelClicked(false);
        }
    }, [cancelSubCategoryEditing]);

    return <>{colors && <Box display="flex" height="100%">
        <Box className="ag-theme-alpine ag-theme-bidbow" height="100%" width="100%" padding="10px 20px 40px 20px">
            <Typography marginBottom="5px" variant="h5">Level 1 Categories</Typography>
            <AgGridReact<ResourceCategoryView>
                ref={resourceCategoryGridRef}
                editType={'fullRow'}
                rowData={resourceCategories}
                suppressRowClickSelection={true}
                onCellClicked={onCategoryCellClicked}
                columnDefs={resourceCategoryDefs}
                defaultColDef={defaultCategoryColDef}
                pinnedTopRowData={createResourceCategoryPinnedData}
                onRowEditingStarted={onCategoryRowEditingStarted}
                onRowEditingStopped={onCategoryRowEditingStopped}
                getRowId={getCategoryRowId}
                onCellKeyDown={onCategoryCellKeyDown}
            />
        </Box>
        {selectedCategory && <Box className="ag-theme-alpine ag-theme-bidbow" height="100%" width="100%" padding="10px 20px 40px 20px">
            <Typography marginBottom="5px" variant="h5">Level 2 Categories</Typography>
            <AgGridReact<ResourceSubCategoryView>
                ref={resourceSubCategoryGridRef}
                editType={'fullRow'}
                rowData={resourceSubCategories}
                suppressRowClickSelection={true}
                columnDefs={resourceSubCategoryDefs}
                defaultColDef={defaultSubCategoryColDef}
                onCellClicked={onSubCategoryCellClicked}
                onGridReady={onSubCategoryGridReady}
                onRowEditingStarted={onSubCategoryRowEditingStarted}
                onRowEditingStopped={onSubCategoryRowEditingStopped}
                getRowId={getSubCategoryRowId}
                onCellKeyDown={onSubCategoryCellKeyDown}
            />
        </Box>}
        {!selectedCategory && <Box className="ag-theme-alpine ag-theme-bidbow" height="100%" width="100%" padding="10px 20px 40px 20px">
            <Typography marginBottom="5px" variant="h5">Level 2 Categories</Typography>
            <Box display="flex" border={`1px solid ${colors.gray[600]}`} width="100%" height="100%" alignItems="center" justifyContent="center">
                <Typography variant="h5">Select a category to load the sub categories</Typography>
            </Box>
        </Box>}
    </Box>}

    </>;
}