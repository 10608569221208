
import { StandardItemRateCalculated, StandardItemView } from "Models/standard-item";
import { useLazyGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import { AgGridReact } from "ag-grid-react";
import { useMemo, useState, useRef, useCallback, useEffect, MutableRefObject } from "react";
import { tokens } from "theme";
import { Box, Button, IconButton, Link, Modal, Typography, useTheme } from "@mui/material";
import { useCreateStandardItemMutation, useDeleteStandardItemMutation, useGetStandardItemRowsMutation, useLazyGetStandardItemByIdQuery, useUpdateStandardItemMutation } from "State/Services/standard-item";
import { ColDef, ICellRendererParams, IRowNode, IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-enterprise";
import StandardItemUnitCellRenderer from "./StandardItemUnitCellRenderer";
import StandardItemsActionsCellRenderer from "./StandardItemActionsCellRenderer";
import AddEditStandardItem from "./AddEditStandardItem";
import AddEditStandardItemCategory from "./AddEditStandardItemCategory";
import CloseIcon from '@mui/icons-material/Close';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import { useLazyGetStandardItemCategoriesQuery } from "State/Services/standard-item-category";
import StandardItemCategoryFilters, { FilterSettings } from "./StandardItemCategoryFilters";
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';
import { useConfirm } from "material-ui-confirm";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { rounder } from "Helpers/rounder";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { CellClickedEvent, CellEditingStartedEvent, CellEditingStoppedEvent, CellKeyDownEvent, FilterChangedEvent, GetRowIdParams, GridReadyEvent, PaginationChangedEvent, SortChangedEvent, SuppressKeyboardEventParams } from "ag-grid-community";
import { PanelState } from "Models/panel";
import { StandardItemCategory } from "Models/standard-item-category";
import SplitButton, { MenuOption } from "Components/SplitButton";
import { Unit } from "Models/unit";
import { ServerError } from "Models/error-info";
import StandardItemListIdEditRenderer from "./StandardItemListIdEditRenderer";
import StandardItemListDescriptionEditCellRenderer from "./StandardItemListDescriptionEditCellRenderer";
import StandardItemListCategoryEditCellRenderer from "./StandardItemListCategoryEditCellRenderer";
import StandardItemListUnitEditCellRenderer from "./StandardItemListUnitEditCellRenderer";
import StandardItemListNumberEditCellRenderer from "./StandardItemListNumberEditCellRenderer";
import { flushSync } from "react-dom";
export interface SubitemProps {
    standardItemId?: string;
    estimateId: string | undefined;
    onDrop?: (subitem: StandardItemView) => void;
    panelState: PanelState;
}

export default function StandardItemList(props: SubitemProps) {
    const theme = useTheme();
    const [deleteStandardItem] = useDeleteStandardItemMutation();
    const confirm = useConfirm();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const gridStyle = useMemo(() => ({ height: 'calc(100% - 48px)', width: '100%' }), []);
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [getUnits] = useLazyGetUnitsQuery();
    const [showAdd, setShowAdd] = useState(false);
    const [editStandardItem, setEditStandardItem] = useState<StandardItemView>();
    const gridRef = useRef<AgGridReact<StandardItemView>>(null);
    const [openCategoriesModal, setOpenCategoriesModal] = useState(false);
    const [getStandardItemCategories] = useLazyGetStandardItemCategoriesQuery();
    const [seed, setSeed] = useState<number>(1);
    const editDisabledRef = useRef<boolean>();
    const deleteDisabledRef = useRef<boolean>();
    const [getStandardItemRows] = useGetStandardItemRowsMutation();
    const [getStandardItemById] = useLazyGetStandardItemByIdQuery();
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<StandardItemView> | undefined, column?: string }>();
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const [currentStandardItemRows, setCurrentStandardItemRows] = useState<Array<StandardItemRateCalculated>>([]);
    const [standardItemCategories, setStandardItemCategories] = useState<Array<StandardItemCategory>>([]);
    const [saveStandardItem] = useCreateStandardItemMutation();
    const [updateStandardItem] = useUpdateStandardItemMutation();
    const [errors, setErrors] = useState<Array<{ field: string, error: string }>>([]);
    const [unitRef, setUnitRef] = useState<MutableRefObject<any> | undefined>();
    const [categoryRef, setCategoryRef] = useState<MutableRefObject<any> | undefined>();
    const [addMenuItems] = useState<Array<MenuOption>>([{ option: 'New inline', disabled: false }, { option: 'New in form', disabled: false }]);
    const [units, setUnits] = useState<Array<Unit>>();

    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((errorDetails) => {
                switch (errorDetails.field) {
                    case 'name':
                        const nameInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['name']
                        });
                        if (nameInstances && nameInstances.length > 0 && nameInstances[0] && typeof (nameInstances[0] as any).setError === 'function') {
                            (nameInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    case 'displayId':
                        const idInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['displayId']
                        });
                        if (idInstances && idInstances.length > 0 && idInstances[0] && typeof (idInstances[0] as any).setError === 'function') {
                            (idInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    case 'description':
                        const descriptionInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['description']
                        });
                        if (descriptionInstances && descriptionInstances.length > 0 && descriptionInstances[0] && typeof (descriptionInstances[0] as any).setError === 'function') {
                            (descriptionInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    case 'quantity':
                        const quantityInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['quantity']
                        });
                        if (quantityInstances && quantityInstances.length > 0 && quantityInstances[0] && typeof (quantityInstances[0] as any).setError === 'function') {
                            (quantityInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    case 'unit':
                        const unitInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['unit']
                        });
                        if (unitInstances && unitInstances.length > 0 && unitInstances[0] && typeof (unitInstances[0] as any).setError === 'function') {
                            (unitInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    }, [errors])

    const getStandardItems = useCallback(async (params: IServerSideGetRowsParams<StandardItemView>) => {
        if (user) {
            let storedStandardItemCategories = new Array<StandardItemCategory>();
            let storedUnits = new Array<Unit>();
            const response = await getStandardItemRows({
                companyId: user.companyId,
                orgId: user.organizationId,
                estimateId: props.estimateId,
                body: { ...params.request }
            }).unwrap();
            if (standardItemCategories && standardItemCategories.length > 0) {
                storedStandardItemCategories = standardItemCategories;
            } else {
                storedStandardItemCategories = await getStandardItemCategories({
                    companyId: user.companyId ?? '',
                    estimateId: props.estimateId ?? '',
                    organizationId: user.organizationId ?? ''
                }, true).unwrap();
                setStandardItemCategories(storedStandardItemCategories);
            }
            if (units && units.length > 0) {
                storedUnits = units
            } else {
                storedUnits = await getUnits({
                    companyId: user.companyId ?? '',
                    organizationId: user.organizationId ?? ''
                }, true).unwrap();
                setUnits(storedUnits);
            }
            if (params?.request && response.results) {
                const rows = new Array<StandardItemView>();
                setCurrentStandardItemRows(response.results);
                response.results.forEach((standardItem) => {
                    const unit = storedUnits?.find((unit) => (unit.id === standardItem.unitId));
                    let category1 = undefined;
                    let category2 = undefined;
                    if (storedStandardItemCategories) {
                        if (standardItem.category1) {
                            category1 = storedStandardItemCategories.find((cat) => (cat.id === standardItem.category1));
                        }
                        if (standardItem.category2) {
                            category2 = storedStandardItemCategories.find((cat) => (cat.id === standardItem.category2));
                        }
                    }
                    rows.push({
                        id: standardItem.id,
                        description: standardItem.description,
                        displayId: standardItem.displayId,
                        rate: standardItem.rate,
                        unitId: standardItem.unitId,
                        quantity: standardItem.quantity,
                        masterReferenceId: standardItem.masterReferenceId,
                        unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined,
                        type: standardItem.type,
                        category1: standardItem.category1,
                        category1Description: category1?.description,
                        category2: standardItem.category2,
                        category2Description: category2?.description,
                        canDelete: standardItem.canDelete,
                        isNew: false
                    });
                });
                params.success({
                    rowData: rows,
                    rowCount: response.totalCount
                });
            }
        }
    }, [getStandardItemCategories, getStandardItemRows, getUnits, props.estimateId, standardItemCategories, units, user]);
    const getStandardItemsRef = useRef<(params: IServerSideGetRowsParams<StandardItemView>) => void>(() => (null));
    getStandardItemsRef.current = getStandardItems;

    const createServerSideDatasource = useCallback((): IServerSideDatasource => {
        return {
            getRows: (params) => getStandardItemsRef.current(params),
        };
    }, []);

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
                deleteDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Items,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Items,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            editDisabledRef.current = true;
            deleteDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    const handleOpenCategoriesModal = () => {
        setOpenCategoriesModal(true);
    };

    const handleCloseCategoriesModal = async () => {
        setOpenCategoriesModal(false);
    };

    const showHideCols = useCallback(() => {
        if (gridRef.current?.api) {
            gridRef.current!.api.setColumnsVisible(["quantity", "category1Description", "category2Description"], props.panelState.position.state !== "half");
        }
    }, [props.panelState])

    useEffect(() => {
        showHideCols();
    }, [showHideCols])

    const switchToEdit = useCallback((standardItem: StandardItemView | undefined) => {
        if (standardItem) {
            setEditStandardItem(standardItem);
            setShowAdd(true);
        }
    }, [])

    const edit = useCallback((node: IRowNode<StandardItemView>) => {
        switchToEdit(node.data);
    }, [switchToEdit])

    const navigateToStandardItem = useCallback(() => {
        if (props.standardItemId && user) {
            getStandardItemById({
                companyId: user.companyId ?? '',
                estimateId: props.estimateId ?? '',
                organizationId: user.organizationId,
                standardItemId: props.standardItemId
            }).then((response) => {
                setSeed(Math.random());
                switchToEdit(response.data);
            })
        }
    }, [getStandardItemById, props.estimateId, props.standardItemId, switchToEdit, user])

    useEffect(() => {
        navigateToStandardItem();
    }, [navigateToStandardItem])

    const deleteRow = useCallback((node: IRowNode<StandardItemView>) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await confirm({ description: `Are you sure you want to delete the record?`, title: 'Delete confirmation' });
                if (user && node.data) {
                    await deleteStandardItem({
                        standardItemId: node.data.id,
                        companyId: user.companyId,
                        estimateId: props.estimateId,
                        orgId: user.organizationId
                    });
                    gridRef.current!.api.onFilterChanged();
                }
                resolve();
            } catch (error) {
                reject('Cancelled by user.');
            }
        });
    }, [confirm, deleteStandardItem, props.estimateId, user]);

    const cancelEditing = useCallback((node: IRowNode<StandardItemView>) => {
        if (node && node.data) {
            setIsCancelClicked(true);
            const standardItem = currentStandardItemRows?.find((c) => (c.id === node.data?.id));
            if (standardItem) {
                const unit = units?.find((unit) => (unit.id === standardItem.unitId));
                let category1 = undefined;
                let category2 = undefined;
                if (standardItemCategories) {
                    if (standardItem.category1) {
                        category1 = standardItemCategories.find((cat) => (cat.id === standardItem.category1 && cat.level === 1));
                    }
                    if (standardItem.category2) {
                        category2 = standardItemCategories.find((cat) => (cat.id === standardItem.category2 && cat.level === 2));
                    }
                }

                node.updateData({
                    ...standardItem,
                    unit: (unit) ? { unitId: unit.id, unitDescription: unit.description } : undefined,
                    category1Description: category1?.description,
                    category2Description: category2?.description
                });
            }
            gridRef.current!.api.stopEditing(true);
            if (node.data.isNew) {
                gridRef.current!.api.applyServerSideTransaction({ remove: [node.data] });
            }

            setCurrentEditing(undefined);
        }
    }, [currentStandardItemRows, standardItemCategories, units])
    const cancelEditingRef = useRef<any>();
    cancelEditingRef.current = cancelEditing;

    const saveRow = useCallback(async (nodeToSave: IRowNode<StandardItemView>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<StandardItemView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (user && props.estimateId && nodeToSave.data) {
                    gridRef.current!.api.stopEditing();
                    if (!nodeToSave.data.displayId) {
                        const error: ServerError = { data: { displayId: 'ID is required' } };
                        throw error;
                    }
                    if (!nodeToSave.data.description) {
                        const error: ServerError = { data: { description: 'Description is required' } };
                        throw error;
                    }
                    if (nodeToSave.data.id) {
                        let standardItemResponse: StandardItemRateCalculated;
                        if (nodeToSave.data.isNew) {
                            standardItemResponse = await saveStandardItem({
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                body: {
                                    id: nodeToSave.data.id,
                                    description: nodeToSave.data.description,
                                    unitId: nodeToSave.data.unit?.unitId,
                                    displayId: nodeToSave.data.displayId,
                                    quantity: (nodeToSave.data.quantity?.toString()) ? parseFloat(nodeToSave.data.quantity.toString()) : undefined,
                                    category1: nodeToSave.data.category1,
                                    category1Description: nodeToSave.data.category1Description,
                                    category2: nodeToSave.data.category2,
                                    category2Description: nodeToSave.data.category2Description
                                }
                            }).unwrap();
                        } else {
                            standardItemResponse = await updateStandardItem({
                                standardItemId: nodeToSave.data?.id,
                                companyId: user?.companyId,
                                estimateId: props.estimateId,
                                orgId: user?.organizationId,
                                body: {
                                    id: nodeToSave.data.id,
                                    description: nodeToSave.data.description,
                                    unitId: nodeToSave.data.unit?.unitId,
                                    displayId: nodeToSave.data.displayId,
                                    quantity: (nodeToSave.data.quantity?.toString()) ? parseFloat(nodeToSave.data.quantity.toString()) : undefined,
                                    category1: nodeToSave.data.category1,
                                    category1Description: nodeToSave.data.category1Description,
                                    category2: nodeToSave.data.category2,
                                    category2Description: nodeToSave.data.category2Description
                                }
                            }).unwrap();
                        }
                        nodeToSave.setDataValue('rate', standardItemResponse.rate);
                        nodeToSave.setDataValue('isNew', false);
                    }
                    resolve();
                    if (toEditAfterSave?.nodeToEditAfterSave) {
                        if (toEditAfterSave?.nodeToEditAfterSave?.data?.masterReferenceId) {
                            setCurrentEditing(undefined);
                            return;
                        }
                        setCurrentEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                    } else {
                        setCurrentEditing(undefined);
                    }
                }
            } catch (error: any) {
                if (error && error.data) {
                    if (typeof nodeToSave.rowIndex === 'number') {
                        gridRef.current!.api.startEditingCell({
                            rowIndex: nodeToSave.rowIndex,
                            colKey: 'displayId',
                        });
                    }
                    const errors = new Array<{ field: string, error: string }>();
                    if (error.data.displayId) {
                        errors.push({ field: 'displayId', error: error.data.displayId })
                    }
                    if (error.data.name) {
                        errors.push({ field: 'name', error: error.data.name })
                    }
                    if (error.data.description) {
                        errors.push({ field: 'description', error: error.data.description })
                    }
                    if (error.data.quantity) {
                        errors.push({ field: 'quantity', error: error.data.quantity })
                    }
                    if (error?.data?.unitId) {
                        errors.push({ field: 'unit', error: error.data.unitId })
                    }
                    setErrors(errors);
                }
                reject(error);
            }
        });
    }, [props.estimateId, saveStandardItem, updateStandardItem, user])
    const saveEditingRef = useRef<any>();
    saveEditingRef.current = saveRow;

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<StandardItemView>) => {
        if (params.event.key === 'Enter' && params.node) {
            if (params.event.target === unitRef?.current ||
                params.event.target === categoryRef?.current) {
                return true;
            }
            params.event.stopPropagation();
            const save = async () => {
                try {
                    await saveRow(params.node);
                } catch (error) {

                }
            }
            save();
        }
        return true;
    }, [categoryRef, saveRow, unitRef])
    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates so it can be used in the col defs
    // Passing in a direct ref to the function will result in a stale reference
    const saveOnEnterRef = useRef<any>();
    saveOnEnterRef.current = saveOnEnter;

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelEditing(e.node);
            setIsCancelClicked(false);
        }
    }, [cancelEditing]);

    const getPanelBaseDefs = useCallback((): Array<ColDef<StandardItemView>> => {
        return [
            {
                field: 'id',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'category1',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'category2',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'isNew',
                cellDataType: 'boolean',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                headerName: 'ID',
                hide: false,
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                cellEditor: StandardItemListIdEditRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId
                },
                editable: (params) => {
                    return !params.data?.masterReferenceId && !editDisabledRef.current;
                },
                sort: 'asc',
                dndSource: props.panelState.position.state === "half",
                filter: 'agTextColumnFilter',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                showRowGroup: true,
                width: 120,
                autoHeight: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            },
            {
                field: 'description',
                flex: 1,
                cellEditor: StandardItemListDescriptionEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId
                },
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                filter: 'agTextColumnFilter',
                cellRenderer: (params: ICellRendererParams<StandardItemView>) => (<>{params.data && <Link component="button" onClick={() => edit(params.node)} variant="body2">{params.data.description}</Link>}</>),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'category1Description',
                headerName: 'Category 1',
                cellEditor: StandardItemListCategoryEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId,
                    setRef: setCategoryRef,
                    level: 1
                },
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                width: 200,
                hide: true,
                filter: 'agTextColumnFilter',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'category2Description',
                headerName: 'Category 2',
                cellEditor: StandardItemListCategoryEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId,
                    setRef: setCategoryRef,
                    level: 2
                },
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                width: 200,
                hide: true,
                filter: 'agTextColumnFilter',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 75,
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                cellEditor: StandardItemListUnitEditCellRenderer,
                cellEditorParams: {
                    setRef: setUnitRef,
                },
                filter: 'agTextColumnFilter',
                cellRenderer: StandardItemUnitCellRenderer,
                headerName: 'Unit',
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantity',
                width: 175,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                hide: true,
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                editable: (params) => (!params.data?.masterReferenceId && !editDisabledRef.current),
                cellEditor: StandardItemListNumberEditCellRenderer,
                cellEditorParams: {
                    placeholder: 'Quantity'
                },
                filter: 'agTextColumnFilter',
                headerName: 'Quantity',
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                width: 175,
                sortable: false,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'actions',
                width: 80,
                sortable: false,
                headerName: 'Actions',
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRenderer: StandardItemsActionsCellRenderer,
                cellRendererParams: {
                    delete: deleteRow,
                    disabled: () => deleteDisabledRef.current,
                    save: (node: IRowNode<StandardItemView>) => saveEditingRef.current(node),
                    cancel: (node: IRowNode<StandardItemView>) => cancelEditingRef.current(node),
                }
            },
        ];
    }, [colors?.gray, deleteRow, edit, estimate?.CompanyCurrency?.Currency?.minorUnit, props.estimateId, props.panelState.position.state, settings?.quantityDecimals])

    const [panelColumnDefs] = useState<ColDef[]>(getPanelBaseDefs());

    const gridDragOver = useCallback((event: any) => {
        const dragSupported = event.dataTransfer.types.length;

        if (dragSupported) {
            event.dataTransfer.dropEffect = 'copy';
            event.preventDefault();
        }
    }, []);

    const addNewStandardItemInForm = useCallback(() => {
        setShowAdd(showAdd => !showAdd);
        setEditStandardItem(undefined);
    }, [])

    const onGridReady = useCallback((params: GridReadyEvent) => {
        const datasource = createServerSideDatasource();
        params.api!.setGridOption("serverSideDatasource", datasource);
        showHideCols();
    }, [createServerSideDatasource, showHideCols])

    const filterChanged = useCallback((filterSettings: FilterSettings) => {
        let filters = {};
        if (filterSettings.categoryDescription) {
            const catFilter = {
                category1Description: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.categoryDescription
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.searchText) {
            const descriptionFilter = {
                description: {
                    filterType: 'text',
                    type: 'contains',
                    filter: filterSettings.searchText
                }
            };
            filters = { ...filters, ...descriptionFilter };
        }
        console.log(filters);
        gridRef.current!.api.setFilterModel(filters);
        gridRef.current!.api.onFilterChanged();
    }, [])


    const resetAndAddNew = useCallback((category1: string | undefined, category2: string | undefined) => {
        setSeed(Math.random());
        setEditStandardItem({
            category1: category1,
            category2: category2
        });
    }, [])

    const getRowId = useCallback(function (params: GetRowIdParams<StandardItemView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const onRowEditingStarted = useCallback((event: CellEditingStartedEvent<StandardItemView>) => {
        setIsCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
        setTimeout(() => {
            if (event.node.data?.unit?.unitDescription === 'PCT') {
                const factorInstances = gridRef.current!.api.getCellEditorInstances({
                    columns: [currentEditing?.column ?? 'displayId']
                });
                if (factorInstances && factorInstances.length > 0 && factorInstances[0] && typeof (factorInstances[0] as any).setFocusOnAdd === 'function') {
                    (factorInstances[0] as any).setFocusOnAdd();
                }
            } else {
                const quantityInstances = gridRef.current!.api.getCellEditorInstances({
                    columns: [currentEditing?.column ?? 'displayId']
                });
                if (quantityInstances && quantityInstances.length > 0 && quantityInstances[0] && typeof (quantityInstances[0] as any).setFocusOnAdd === 'function') {
                    (quantityInstances[0] as any).setFocusOnAdd();
                }
            }
        }, 100);
    }, [currentEditing?.column])

    const onRowEditingStopped = useCallback(async (event: CellEditingStoppedEvent<StandardItemView>) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onCellClicked = useCallback(async (event: CellClickedEvent<StandardItemView>) => {
        try {
            if (isCancelClicked) {
                setIsCancelClicked(false);
                return;
            };

            if (editDisabledRef.current) {
                setCurrentEditing(undefined);
                return;
            };

            if (currentEditing?.node === event.node) {
                return;
            }
            if (event.column.getColId() === 'actions') {
                return;
            }
            if (!currentEditing?.node) {
                if (event.column.isCellEditable(event.node)) {
                    setCurrentEditing({ node: event.node, column: event.column.getColId() });
                } else {
                    setCurrentEditing({ node: event.node, column: undefined });
                }
            } else {
                await saveRow(currentEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
            }
        } catch (error) {

        }
    }, [currentEditing?.node, isCancelClicked, saveRow])

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node.rowIndex,
                colKey: currentEditing.column ?? 'displayId',
            });
        }
    }, [currentEditing, props])

    const addStandardItemInline = useCallback(async () => {
        if (currentEditing) {
            try {
                await confirm({ description: "You have unsaved changes. If you proceed, your current edits will be lost. Do you want to continue?" });
                gridRef.current!.api.stopEditing(true);
                if (currentEditing && currentEditing.node?.data) {
                    if (currentEditing.node.data.id) {
                        const standardItemBeforeEditing = currentStandardItemRows?.find((stdItem) => (stdItem.id === currentEditing?.node?.data?.id));
                        if (standardItemBeforeEditing) {
                            gridRef.current!.api.applyServerSideTransaction({ update: [{ ...standardItemBeforeEditing }] });
                        } else {
                            gridRef.current!.api.applyServerSideTransaction({ remove: [currentEditing.node.data] });
                        }
                    } else {
                        gridRef.current!.api.applyServerSideTransaction({ remove: [currentEditing.node.data] });
                    }
                }
            } catch (error) {
                return;
            }
        }
        const currentPage = gridRef.current!.api.paginationGetCurrentPage();
        const currentPageSize = gridRef.current!.api.paginationGetPageSize();
        let standardItemRow = gridRef.current!.api.applyServerSideTransaction({
            add: [{
                id: uuidv4(),
                description: '',
                displayId: undefined,
                rate: undefined,
                masterReferenceId: undefined,
                type: undefined,
                isNew: true,
                canDelete: true,
                category1: undefined,
                category1Description: undefined,
                category2: undefined,
                category2Description: undefined,
                unitId: undefined,
                unit: undefined,
                quantity: undefined,
            }],
            addIndex: currentPage * currentPageSize
        });
        if (standardItemRow && standardItemRow.add && standardItemRow.add.length > 0 && standardItemRow.add[0] && typeof standardItemRow.add[0].rowIndex === 'number' && standardItemRow.add[0].id) {
            if (standardItemRow.add[0].id) {
                queueMicrotask(() => flushSync(() => {
                    if (standardItemRow && standardItemRow.add && standardItemRow.add[0].id) {
                        const node = gridRef.current!.api.getRowNode(standardItemRow.add[0].id);
                        if (node) {
                            gridRef.current!.api.ensureNodeVisible(node, "middle");
                            setCurrentEditing({ node: node, column: 'displayId' });
                        }
                    }
                }))
            }
        }
    }, [confirm, currentEditing, currentStandardItemRows])

    const handleClose = useCallback((option?: string) => {
        switch (option) {
            case 'New in form':
                addNewStandardItemInForm();
                break;
            case 'New inline':
            default:
                addStandardItemInline();
                break;
        }
    }, [addNewStandardItemInForm, addStandardItemInline])

    const onPageChanged = useCallback((event: PaginationChangedEvent<StandardItemView>) => {
        if (currentEditing && currentEditing.node?.data && event.newPage) {
            cancelEditing(currentEditing.node);
        }
    }, [cancelEditing, currentEditing])

    const onPageFilterSortChanged = useCallback((event: SortChangedEvent<StandardItemView> | FilterChangedEvent<StandardItemView>) => {
        if (currentEditing && currentEditing.node?.data) {
            cancelEditing(currentEditing.node);
        }
    }, [cancelEditing, currentEditing])

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    return <><Box height="100%">
        {!showAdd && <><Box marginTop="10px" marginBottom="10px" display="flex" alignItems="center" justifyContent="space-between">
            <SplitButton
                disabled={editDisabledRef.current}
                buttonHeight="32px"
                options={addMenuItems}
                buttonText="New"
                buttonIcon={<AddIcon />}
                onButtonClick={handleClose}
                onMenuItemClick={handleClose} />
            <Box flex="1" marginLeft="20px">
                <StandardItemCategoryFilters estimateId={props.estimateId} onFilterChanged={filterChanged} />
            </Box>
            <Button
                variant="outlined"
                startIcon={<CategoryOutlinedIcon />}
                onClick={handleOpenCategoriesModal}>Categories</Button>
        </Box>
            <Box
                className="ag-theme-alpine ag-theme-bidbow inner-col"
                style={gridStyle}
                onDragOver={gridDragOver}>
                <AgGridReact<StandardItemView>
                    ref={gridRef}
                    animateRows={false}
                    suppressScrollOnNewData={true}
                    rowDragManaged={true}
                    columnDefs={panelColumnDefs}
                    onGridReady={onGridReady}
                    editType={'fullRow'}
                    getRowId={getRowId}
                    defaultColDef={defaultColDef}
                    rowModelType={"serverSide"}
                    pagination={true}
                    paginationPageSize={15}
                    cacheBlockSize={15}
                    paginationPageSizeSelector={[15, 25, 50, 100]}
                    onRowEditingStarted={onRowEditingStarted}
                    onRowEditingStopped={onRowEditingStopped}
                    onCellKeyDown={onCellKeyDown}
                    onCellClicked={onCellClicked}
                    onPaginationChanged={onPageChanged}
                    onSortChanged={onPageFilterSortChanged}
                    onFilterChanged={onPageFilterSortChanged}
                />
            </Box>
        </>}
        {showAdd && props.estimateId && <AddEditStandardItem key={seed} resetAndAddNew={resetAndAddNew} backToList={() => (setShowAdd(showAdd => !showAdd))} standardItem={editStandardItem} estimateId={props.estimateId} panelState={props.panelState} />}
    </Box>
        <Modal
            open={openCategoriesModal}
            onClose={handleCloseCategoriesModal}
            aria-labelledby="categories-modal"
            aria-describedby="categories-modal-description"
        >
            <Box className="large-modal">
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignContent="center"
                    alignItems="center"
                    borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box padding="10px">
                        {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Item Categories</Typography>}
                    </Box>
                    <Box>
                        <IconButton aria-label="delete" onClick={handleCloseCategoriesModal}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
                <AddEditStandardItemCategory estimateId={props.estimateId} />
            </Box>
        </Modal>
    </>
}