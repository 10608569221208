import { Box, Button, Modal, Typography, useTheme } from "@mui/material";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { tokens } from "theme";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ICellRendererParams, IRowNode } from "ag-grid-community";
import { useGetUserDetailsQuery } from "State/Services/user";
import { Estimate, EstimateView } from "Models/estimate";
import { useCopyEstimateMutation, useCreateEstimateMutation, useGetAllEstimatesForCompanyQuery, useUpdateEstimateMutation } from "State/Services/estimate";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useGetUnitsQuery } from "State/Services/unit";
import SaveCancelEstimateCellRenderer, { EstimateMenuOptions } from "./SaveCancelEstimateCellRenderer";
import { useGetCompanyCurrenciesQuery } from "State/Services/company-currency";
import CreateUpdateEstimateModal from "./CreateUpdateEstimateModal";
import CreateEstimateStepper from "./CreateEstimateStepper";
import { useGetAllMastersForCompanyQuery } from "State/Services/master";
import CreateEditCopyMaster from "Components/Master/CreateEditCopyMaster";
import { Master } from "Models/master";
import { Selections } from "./CreateEstimateSelection";
import ChangeCurrency from "./ChangeCurrency";
import { useConfirm } from "material-ui-confirm";
import SharingPermissions from "Components/Shared/SharingPermissions";
import { hasSystemRolePermission } from "Helpers/role-permissions";
import DeleteConfirmationModal from "Components/DeleteConfirmationModal";
import { EstimateItemProgressChartRenderer } from "./EstimateItemProgressChartRenderer";

export default function EstimateDashboard() {
    const navigate = useNavigate();
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimates } = useGetAllEstimatesForCompanyQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: masters } = useGetAllMastersForCompanyQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: companyCurrencies } = useGetCompanyCurrenciesQuery({ companyId: (user && user.companyId) ? user.companyId : '', orgId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const theme = useTheme();
    const [createEstimate] = useCreateEstimateMutation();
    const [updateEstimate] = useUpdateEstimateMutation();
    const colors = tokens(theme.palette.mode);
    const gridRef = useRef<AgGridReact<EstimateView>>(null);
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const [rowData, setRowData] = useState<EstimateView[]>();
    const [estimate, setEstimate] = useState<Estimate>();
    const [selectedOption, setSelectedOption] = useState<EstimateMenuOptions>();
    const [selectedMaster, setSelectedMaster] = useState<Master>();
    const [copyEstimate] = useCopyEstimateMutation();
    const confirm = useConfirm();
    const [createDisabled, setCreateDisabled] = useState(false);

    const handleClose = useCallback((event?: any, reason?: string) => {
        if (reason === 'backdropClick') return;
        setOpen(false);
        setSelectedOption(undefined);
    }, [])

    const saveEstimate = useCallback(async (values: Estimate, selections?: Selections) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (user) {
                    switch (selectedOption) {
                        case EstimateMenuOptions.edit:
                            if (values.id) {
                                await updateEstimate({
                                    companyId: user.companyId,
                                    orgId: user.organizationId,
                                    estimateId: values.id,
                                    updateResourceBasicRates: values.updateResourceBasicRates,
                                    body: {
                                        name: values.name,
                                        noOfLevels: values.noOfLevels,
                                        client: values.client,
                                        indirectItemNoOfLevels: values.indirectItemNoOfLevels,
                                        area: values.area,
                                        areaUnitId: values.areaUnit?.id,
                                        projectName: '',
                                        companyCurrencyId: values.companyCurrencyId,
                                        currencyExchangeRate: values.currencyExchangeRate,
                                        unitIds: values.unitIds,
                                    }
                                }).unwrap();
                                handleClose();
                            }
                            break;
                        case EstimateMenuOptions.new:
                            if (selections) {
                                if (selections.selectedOption === "CreateFromScratch") {
                                    const estimate = await createEstimate({
                                        companyId: user.companyId,
                                        orgId: user.organizationId,
                                        body: {
                                            name: values.name,
                                            noOfLevels: values.noOfLevels,
                                            client: values.client,
                                            indirectItemNoOfLevels: values.indirectItemNoOfLevels,
                                            area: values.area,
                                            areaUnitId: values.areaUnit?.id,
                                            projectName: '',
                                            companyCurrencyId: values.companyCurrencyId,
                                            currencyExchangeRate: values.currencyExchangeRate,
                                            unitIds: values.unitIds
                                        }
                                    }).unwrap();
                                    navigate(`../${estimate.id}`);
                                }
                                const isFromMaster = selections.selectedOption === "ImportFromMaster";
                                const createdEst = await copyEstimate({
                                    companyId: user.companyId,
                                    orgId: user.organizationId,
                                    fromEstimateId: (selections.selectedOption === "ImportFromEstimate") ? selections.selectedEstimate?.id : selections.selectedMaster?.id,
                                    body: {
                                        activities: selections.activities,
                                        direct: selections.direct,
                                        excludeDirectItemCostQuantities: selections.excludeDirectItemCostQuantities,
                                        excludeDirectItemQuantities: selections.excludeDirectItemQuantities,
                                        excludeIndirectItemCostQuantities: selections.excludeIndirectItemCostQuantities,
                                        excludeIndirectItemQuantities: selections.excludeIndirectItemQuantities,
                                        excludeResourceFactor: selections.excludeResourceFactor,
                                        indirect: selections.indirect,
                                        items: selections.items,
                                        margins: selections.margins,
                                        vendorQuotations: selections.vendorQuotations,
                                        pricing: false,
                                        vendors: selections.vendors,
                                        resources: selections.resources,
                                        name: values.name,
                                        noOfLevels: values.noOfLevels,
                                        client: values.client,
                                        indirectItemNoOfLevels: values.indirectItemNoOfLevels,
                                        area: values.area,
                                        areaUnitId: values.areaUnit?.id,
                                        projectName: '',
                                        companyCurrencyId: values.companyCurrencyId,
                                        currencyExchangeRate: values.currencyExchangeRate,
                                        unitIds: values.unitIds,
                                        toMaster: false,
                                        isMasterDuplicate: false,
                                        fromMaster: isFromMaster,
                                        ...(isFromMaster && {
                                            masterId: selections.selectedMaster?.MasterEstimate?.id
                                        })
                                    }
                                }).unwrap();
                                navigate(`../${createdEst.id}`);
                            }
                            handleClose();
                            break;
                        case EstimateMenuOptions.duplicate:
                            const newEstimate = await copyEstimate({
                                companyId: user.companyId,
                                orgId: user.organizationId,
                                fromEstimateId: values.id,
                                body: {
                                    activities: true,
                                    direct: true,
                                    excludeDirectItemCostQuantities: false,
                                    excludeDirectItemQuantities: false,
                                    excludeIndirectItemCostQuantities: false,
                                    excludeIndirectItemQuantities: false,
                                    excludeResourceFactor: false,
                                    indirect: true,
                                    items: true,
                                    margins: true,
                                    vendorQuotations: true,
                                    vendors: true,
                                    resources: true,
                                    pricing: true,
                                    name: values.name,
                                    noOfLevels: values.noOfLevels,
                                    client: values.client,
                                    indirectItemNoOfLevels: values.indirectItemNoOfLevels,
                                    area: values.area,
                                    areaUnitId: values.areaUnit?.id,
                                    projectName: '',
                                    companyCurrencyId: values.companyCurrencyId,
                                    currencyExchangeRate: values.currencyExchangeRate,
                                    unitIds: values.unitIds,
                                    toMaster: false,
                                    fromMaster: false,
                                    isMasterDuplicate: false
                                }
                            }).unwrap();
                            if (newEstimate) {
                                navigate(`../${newEstimate.id}`);
                                return;
                            }
                            break;
                        default:
                            break;
                    }
                }
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }, [copyEstimate, createEstimate, handleClose, navigate, selectedOption, updateEstimate, user])

    useEffect(() => {
        const gridData = new Array<EstimateView>();
        if (estimates && estimates.length > 0) {
            estimates.forEach((estimate) => {
                gridData.push({ ...estimate, actions: '', createdBy: `${estimate.Owner?.firstName} ${estimate.Owner?.lastName}  (${estimate.Owner?.email})` });
            });
            setRowData(gridData);
        } else {
            setRowData([]);
        }
    }, [estimates])

    useEffect(() => {
        if (user) {
            const hasCreateAccess = hasSystemRolePermission(user, [100]);
            setCreateDisabled(!hasCreateAccess);
        }
    }, [user])

    const action = useCallback(async (node: IRowNode<EstimateView>, option: EstimateMenuOptions) => {
        const estimate = estimates?.find((est) => (est.id === node.data?.id));
        switch (option) {
            case EstimateMenuOptions.master:
                if (companyCurrencies) {
                    const primary = companyCurrencies.find((curr) => (curr.isPrimary));
                    if (primary && estimate) {
                        const primaryCurr = { ...primary, currencyOption: `${primary.alphabeticalCode} - ${primary.currency}` };
                        let unitIds = new Array<string>()
                        const filteredUnits = units?.filter(unit => unit.description !== 'PCT');
                        if (filteredUnits) {
                            filteredUnits.forEach((unit) => {
                                if (unit?.id) {
                                    unitIds.push(unit.id);
                                }
                            })
                        }
                        setSelectedMaster({
                            id: '',
                            name: estimate.name,
                            noOfLevels: estimate.noOfLevels,
                            indirectItemNoOfLevels: estimate.indirectItemNoOfLevels,
                            companyCurrencyOption: primaryCurr,
                            currencyExchangeRate: estimate.currencyExchangeRate,
                            companyCurrencyId: primaryCurr?.id ?? '',
                            description: ''
                        });
                        setEstimate({
                            id: estimate.id,
                            name: estimate.name,
                            noOfLevels: estimate.noOfLevels,
                            indirectItemNoOfLevels: estimate.indirectItemNoOfLevels,
                            client: estimate.client,
                            area: estimate.area,
                            companyCurrencyOption: estimate?.CompanyCurrency ? { ...estimate?.CompanyCurrency, currencyOption: `${estimate?.CompanyCurrency?.Currency.alphabeticalCode} - ${estimate?.CompanyCurrency?.Currency.currency}` } : undefined,
                            areaUnit: units?.find((unit) => (unit.id === estimate.areaUnitId)),
                            projectName: '',
                            companyCurrencyId: estimate?.CompanyCurrency?.id ?? '',
                            currencyExchangeRate: estimate.currencyExchangeRate,
                            unitIds: unitIds,
                            updateResourceBasicRates: false
                        });
                    }
                }
                break;
            case EstimateMenuOptions.edit:
                if (estimate?.CompanyCurrency?.Currency) {
                    let unitIds = new Array<string>()
                    const filteredUnits = units?.filter(unit => unit.description !== 'PCT');
                    if (filteredUnits) {
                        filteredUnits.forEach((unit) => {
                            if (unit?.id) {
                                unitIds.push(unit.id);
                            }
                        })
                    }
                    setEstimate({
                        id: estimate.id,
                        name: estimate.name,
                        noOfLevels: estimate.noOfLevels,
                        indirectItemNoOfLevels: estimate.indirectItemNoOfLevels,
                        client: estimate.client,
                        area: estimate.area,
                        companyCurrencyOption: estimate?.CompanyCurrency ? { ...estimate?.CompanyCurrency, currencyOption: `${estimate?.CompanyCurrency?.Currency.alphabeticalCode} - ${estimate?.CompanyCurrency?.Currency.currency}` } : undefined,
                        areaUnit: units?.find((unit) => (unit.id === estimate.areaUnitId)),
                        projectName: '',
                        companyCurrencyId: estimate?.CompanyCurrency?.id ?? '',
                        currencyExchangeRate: estimate.currencyExchangeRate,
                        unitIds: unitIds,
                        updateResourceBasicRates: false
                    });
                }
                break;
            case EstimateMenuOptions.duplicate:
                if (estimate?.CompanyCurrency?.Currency) {
                    let unitIds = new Array<string>()
                    const filteredUnits = units?.filter(unit => unit.description !== 'PCT');
                    if (filteredUnits) {
                        filteredUnits.forEach((unit) => {
                            if (unit?.id) {
                                unitIds.push(unit.id);
                            }
                        })
                    }
                    setEstimate({
                        id: estimate.id,
                        name: estimate.name,
                        noOfLevels: estimate.noOfLevels,
                        indirectItemNoOfLevels: estimate.indirectItemNoOfLevels,
                        client: estimate.client,
                        area: estimate.area,
                        companyCurrencyOption: estimate?.CompanyCurrency ? { ...estimate?.CompanyCurrency, currencyOption: `${estimate?.CompanyCurrency?.Currency.alphabeticalCode} - ${estimate?.CompanyCurrency?.Currency.currency}` } : undefined,
                        areaUnit: units?.find((unit) => (unit.id === estimate.areaUnitId)),
                        projectName: '',
                        companyCurrencyId: estimate?.CompanyCurrency?.id ?? '',
                        currencyExchangeRate: estimate.currencyExchangeRate,
                        unitIds: unitIds,
                        updateResourceBasicRates: false
                    });
                }
                break;
            case EstimateMenuOptions.changeCurrency:
                try {
                    await confirm({
                        title: 'Confirmation',
                        cancellationText: "I’d like to duplicate my estimate.", confirmationText: "No thanks, I already did it.",
                        description: 'Before taking this action, we recommend that you duplicate your estimate. This will allow you to revert back to your original estimate.',
                    });
                    setEstimate(estimate);
                } catch (error) {
                    handleClose();
                    action(node, EstimateMenuOptions.duplicate);
                    return;
                }
                break;
            case EstimateMenuOptions.sharingPermissions:
            case EstimateMenuOptions.delete:
                setEstimate(estimate);
                break;
            default:
                break;
        }
        setSelectedOption(option);
        handleOpen();
    }, [companyCurrencies, confirm, estimates, handleClose, units])

    // https://github.com/ag-grid/ag-grid/issues/4858
    // Store a reference to it every time react updates (could also do a useEffect hook)
    const actionRef = useRef<any>();
    actionRef.current = action;

    const [columnDefs] = useState<ColDef<EstimateView>[]>([
        { field: 'id', hide: true },
        { field: 'name', headerName: 'Name', flex: 1, cellRenderer: (params: ICellRendererParams<Estimate>) => (<>{params.data && <Link to={`../${params.data.id}`}>{params.data.name}</Link>}</>) },
        { field: 'client', headerName: 'Client', width: 300 },
        { field: 'createdBy', headerName: 'Owner', width: 300 },
        {
            field: 'createDate', sort: 'desc', valueGetter: (params) => {
                if (params.data && params.data.createDate) {
                    return format(new Date(params.data.createDate).getTime(), 'MM/dd/yyyy');
                }
                return ''
            },
        },
        {
            field: 'lastUpdated', valueGetter: (params) => {
                if (params.data && params.data.createDate) {
                    return format(new Date(params.data.createDate).getTime(), 'MM/dd/yyyy hh:mm aaa');
                }
                return ''
            },
        },
        {
            field: 'itemStatusCounts', 
            cellRenderer: EstimateItemProgressChartRenderer, 
            width: 200, 
            headerName: 'Status',
        },
        {
            field: 'actions',
            width: 80,
            headerName: 'Actions',
            menuTabs: [],
            cellStyle: { textAlign: "left", padding: "0px" } as any,
            cellRenderer: SaveCancelEstimateCellRenderer,
            cellRendererParams: {
                onAction: (node: IRowNode<EstimateView>, option: EstimateMenuOptions) => actionRef.current(node, option),
            }
        },
    ]);

    const addNewEstimate = useCallback(() => {
        if (companyCurrencies) {
            const primary = companyCurrencies.find((curr) => (curr.isPrimary));
            if (primary) {
                const primaryCurr = { ...primary, currencyOption: `${primary.alphabeticalCode} - ${primary.currency}` };
                setEstimate({
                    id: '',
                    name: '',
                    noOfLevels: 0,
                    indirectItemNoOfLevels: 0,
                    client: '',
                    area: undefined,
                    areaUnit: undefined,
                    companyCurrencyOption: primaryCurr,
                    projectName: '',
                    currencyExchangeRate: undefined,
                    companyCurrencyId: primaryCurr?.id ?? '',
                    unitIds: [],
                    updateResourceBasicRates: false,
                    isMaster: true
                });
                setSelectedOption(EstimateMenuOptions.new);
                handleOpen();
            }
        }
    }, [companyCurrencies])

    const saveAsMaster = useCallback(async (master: Master) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (user && estimate) {
                    const createdMaster = await copyEstimate({
                        companyId: user.companyId,
                        orgId: user.organizationId,
                        fromEstimateId: estimate.id,
                        body: {
                            activities: true,
                            direct: true,
                            excludeDirectItemCostQuantities: false,
                            excludeDirectItemQuantities: false,
                            excludeIndirectItemCostQuantities: false,
                            excludeIndirectItemQuantities: false,
                            excludeResourceFactor: false,
                            indirect: true,
                            items: true,
                            margins: true,
                            vendorQuotations: true,
                            pricing: true,
                            vendors: true,
                            resources: true,
                            name: master.name,
                            noOfLevels: master.noOfLevels,
                            client: undefined,
                            indirectItemNoOfLevels: master.indirectItemNoOfLevels,
                            area: undefined,
                            areaUnitId: undefined,
                            projectName: '',
                            companyCurrencyId: master.companyCurrencyId,
                            currencyExchangeRate: master.currencyExchangeRate,
                            unitIds: [],
                            toMaster: true,
                            fromMaster: false,
                            isMasterDuplicate: false,
                            description: master.description
                        }
                    }).unwrap();
                    if (user) {
                        navigate(`/organization/${user.organizationId}/company/${user.companyId}/master/${createdMaster.id}`);
                    }
                }
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    }, [copyEstimate, estimate, navigate, user])

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);

    return (<Box height="100%" sx={{ borderLeft: `1px solid ${colors.gray[800]}`, boxShadow: `${colors.gray[800]} 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px`, }}>
        <Box paddingTop="16px" paddingBottom="16px" paddingLeft="24px" paddingRight="24px" borderBottom={`1px solid ${colors.gray[800]}`}>
            <Typography component="div" sx={{ display: "flex" }}>
                <InsertDriveFileIcon sx={{ width: "20px", height: "20px", color: `${colors.gray[400]} !important`, marginRight: "4px" }} />
                <Box sx={{ fontWeight: 600, fontSize: "16px", color: `${colors.gray[100]} !important` }}>Estimates</Box>
            </Typography>
        </Box>
        <Box height='calc(100% - 57px)' width='100%' display='flex' flexDirection='column' paddingLeft="10px" paddingRight="10px">
            <Box style={{ marginBottom: '5px', minHeight: '30px', marginTop: '5px' }}>
                <Button disabled={createDisabled} variant="contained" color="primary" onClick={addNewEstimate} startIcon={<AddOutlinedIcon />}>New Estimate</Button>
            </Box>
            <div style={{ flex: '1 1 0px' }}>
                <div style={gridStyle} className="ag-theme-alpine ag-theme-bidbow">
                    <AgGridReact<EstimateView>
                        ref={gridRef}
                        defaultColDef={defaultColDef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        rowSelection={'single'}
                        animateRows={true}
                    />
                </div>
            </div>
        </Box>
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <>
                {selectedOption === EstimateMenuOptions.new && <CreateEstimateStepper close={handleClose} estimates={estimates} masters={masters} save={saveEstimate} estimate={estimate} />}
                {(selectedOption === EstimateMenuOptions.edit || selectedOption === EstimateMenuOptions.duplicate) && <CreateUpdateEstimateModal close={handleClose} save={saveEstimate} estimate={estimate} selectedOption={selectedOption} />}
                {selectedOption === EstimateMenuOptions.master && <CreateEditCopyMaster state="copy" save={saveAsMaster} close={handleClose} master={selectedMaster} />}
                {selectedOption === EstimateMenuOptions.delete && <DeleteConfirmationModal close={handleClose} estimateMasterId={estimate?.id} isMaster={false} name={estimate?.name} />}
                {selectedOption === EstimateMenuOptions.changeCurrency && <ChangeCurrency close={handleClose} estimate={estimate} />}
                {selectedOption === EstimateMenuOptions.sharingPermissions && <SharingPermissions close={handleClose} isMaster={false} estimates={estimates} estimateId={estimate?.id} />}
            </>
        </Modal>
    </Box>
    )
}