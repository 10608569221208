import { Alert, Box, Button, IconButton, TextField, Tooltip, Typography, useTheme } from "@mui/material";
import { useCallback, useState } from "react";
import { tokens } from "theme";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import { useDeleteEstimateMutation } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';
import { useDeleteMasterMutation } from "State/Services/master";
import { Errors } from "Models/errors";

const deleteConfirmationStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    p: 2,
};

export interface DeleteConfirmationModalProps {
    name: string | undefined;
    estimateMasterId: string | undefined;
    isMaster: boolean;
    close: () => void;
}


export default function DeleteConfirmationModal(props: DeleteConfirmationModalProps) {
    const theme = useTheme();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const { data: user } = useGetUserDetailsQuery();
    const [name, setName] = useState('');
    const [showTooltip, setShowTooltip] = useState(false);
    const [isError, setIsError] = useState(false);
    const [deleteEstimate] = useDeleteEstimateMutation();
    const [deleteMaster] = useDeleteMasterMutation();
    const [loading, setLoading] = useState(false);
    const [pageError, setPageError] = useState<string | undefined>();

    const submit = useCallback(async () => {
        try {
            if (name === props.name) {
                setIsError(false);
                if (user) {
                    setLoading(true);
                    if (props.isMaster) {
                        await deleteMaster({
                            companyId: user.companyId,
                            masterId: props.estimateMasterId,
                            orgId: user.organizationId
                        }).unwrap();
                    } else {
                        await deleteEstimate({
                            companyId: user.companyId,
                            estimateId: props.estimateMasterId,
                            orgId: user.organizationId
                        }).unwrap();
                    }
                    setLoading(false);
                    props.close();
                }
            } else {
                setIsError(true);
            }
        } catch (error) {
            setPageError(Errors.generic);
        }
    }, [deleteEstimate, deleteMaster, name, props, user])

    const cancel = useCallback(() => {
        props.close();
    }, [props])

    const copyToClipboard = useCallback(async () => {
        if (props.name) {
            await navigator.clipboard.writeText(props.name);
            setShowTooltip(true);
        }
    }, [props.name])

    return <Box sx={deleteConfirmationStyle}>
        <Box
            display="flex"
            justifyContent="space-between"
            alignContent="center"
            alignItems="center"
            marginBottom="10px"
            borderBottom={`1px solid ${colors?.gray[800]}`}>
            <Box padding="10px">
                {colors && <Typography variant="h4" sx={{ color: `${colors?.primary[300]}` }}>Delete Confirmation</Typography>}
            </Box>
            <Box>
                <IconButton aria-label="delete" onClick={props.close}>
                    <CloseIcon />
                </IconButton>
            </Box>
        </Box>
        {pageError && <Box marginTop="10px" marginBottom="15px"><Alert severity="error">{pageError}</Alert></Box>}
        <Box display="flex" flexDirection="column" gap="10px">
            <Box>{props.isMaster ? 'Template' : 'Estimate'} to be deleted: <strong>{props.name}</strong>
                <Tooltip title={showTooltip ? 'Copied' : ''} arrow placement="top">
                    <IconButton aria-label="copy" color="primary" onClick={copyToClipboard}>
                        <ContentCopyIcon />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box>Please confirm you wish to delete the {props.isMaster ? 'template' : 'estimate'} by typing in the name:</Box>
            <Box>
                <TextField
                    fullWidth
                    error={isError}
                    helperText={isError ? `The entered text does not match the name of the ${props.isMaster ? 'template' : 'estimate'}` : ''}
                    label="Name"
                    size="small"
                    value={name}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setName(event.target.value);
                    }}
                />
            </Box>
            <Box display="flex" justifyContent="space-evenly">
                <LoadingButton
                    onClick={submit}
                    loading={loading}
                    aria-label="loading"
                    endIcon={<SaveIcon />}
                    color="primary"
                    loadingPosition="end"
                    variant="contained">
                    Submit
                </LoadingButton>
                <Button variant="contained" sx={{ color: "black", backgroundColor: `${colors?.gray[1000]}` }} onClick={cancel}>Cancel</Button>
            </Box>
        </Box>

    </Box>
}