import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { AuthenticationGuard } from "Guards/AuthGuard";
import { useGetUserDetailsQuery } from "State/Services/user";
import Core from "Pages/Core";
import EstimateDashboard from "Pages/Core/EstimateDashboard";
import Estimate from "Pages/Core/Estimate";
import DirectItems from "Pages/Core/DirectItems";
import InDirectItems from "Pages/Core/IndirectItems";
import Pricing from "Pages/Core/Pricing";
import Item from "Pages/Core/DirectItem";
import IndirectItem from "Pages/Core/IndirectItem";
import ReportMain from "Pages/Core/Reports/ReportMain";
import { Library } from "Pages/Core/Library";
import Settings from "Pages/Settings";
import Units from "Pages/Settings/Units";
import Currencies from "Pages/Settings/Currencies";
import MasterSettings from "Pages/Settings/Master";
import MasterDashboard from "Pages/Core/MasterDashboard";
import Vendor from "Pages/Core/Vendor";
import Master from "Pages/Core/Master";
import ActivityLanding from "Pages/Core/Master/activity-landing";
import ResourceLanding from "Pages/Core/Master/resource-landing";
import SubItemLanding from "Pages/Core/Master/subitem-landing";
import Users from "Pages/Settings/Users";
import Profile from "Pages/Settings/Profile";

export default function AppRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<AuthenticationGuard component={DefaultRouteTo} />} />
        <>
            <Route path="organization/:organizationId/company/:companyId/estimate" element={<Core />}>
              <Route path="dashboard" element={<AuthenticationGuard component={EstimateDashboard} />} />
              <Route path=":estimateId" element={<AuthenticationGuard component={Estimate} />}>
                <Route path="" element={<Navigate to="direct-items" />} />
                <Route path="indirect-items" element={<AuthenticationGuard component={InDirectItems} />} />
                <Route path="pricing" element={<AuthenticationGuard component={Pricing} />} />
                <Route path="library" element={<AuthenticationGuard component={Library} />} />
                <Route path="vendor" element={<AuthenticationGuard component={Vendor} />} />
                <Route path="reports" element={<AuthenticationGuard component={ReportMain} />} />
                <Route path="direct-items" element={<AuthenticationGuard component={DirectItems} />} />
                <Route path="item/:itemId" element={<AuthenticationGuard component={Item} />} />
                <Route path="indirect-item/:indirectItemId" element={<AuthenticationGuard component={IndirectItem} />} />
              </Route>
            </Route>
            <Route path="organization/:organizationId/company/:companyId/master" element={<Core />}>
              <Route path="dashboard" element={<AuthenticationGuard component={MasterDashboard} />} />
              <Route path=":estimateId" element={<AuthenticationGuard component={Master} />}>
                <Route path="" element={<Navigate to="resources" />} />
                <Route path="indirect-items" element={<AuthenticationGuard component={InDirectItems} />} />
                <Route path="pricing" element={<AuthenticationGuard component={Pricing} />} />
                <Route path="resources" element={<AuthenticationGuard component={ResourceLanding} />} />
                <Route path="activities" element={<AuthenticationGuard component={ActivityLanding} />} />
                <Route path="subitems" element={<AuthenticationGuard component={SubItemLanding} />} />
                <Route path="vendor" element={<AuthenticationGuard component={Vendor} />} />
                <Route path="reports" element={<AuthenticationGuard component={ReportMain} />} />
                <Route path="direct-items" element={<AuthenticationGuard component={DirectItems} />} />
                <Route path="item/:itemId" element={<AuthenticationGuard component={Item} />} />
                <Route path="indirect-item/:indirectItemId" element={<AuthenticationGuard component={IndirectItem} />} />
              </Route>
            </Route>
            <Route path="organization/:organizationId/company/:companyId/settings" element={<Core />}>
              <Route element={<AuthenticationGuard component={Settings} />}>
                <Route path="" element={<Navigate to="units" />} />
                <Route path="units" element={<AuthenticationGuard component={Units} />} />
                <Route path="currencies" element={<AuthenticationGuard component={Currencies} />} />
                <Route path="master" element={<AuthenticationGuard component={MasterSettings} />} />
                <Route path="user-roles" element={<AuthenticationGuard component={Users} />} />
                <Route path="account" element={<AuthenticationGuard component={Profile} />} />
              </Route>
            </Route>
          </>
        <Route path="*" element={<AuthenticationGuard component={NotFound} />} />
      </Routes>
      <Outlet />
    </>
  );
}

function NotFound() {
  return (
    <>
      <h1>Not found</h1>
    </>
  );
}

function DefaultRouteTo() {
  const { data: user } = useGetUserDetailsQuery();
  return <Navigate to={`organization/${user?.organizationId}/company/${user?.companyId}/estimate/dashboard`} />;
}