import { ICellRendererParams } from "ag-grid-enterprise";
import { ChartData } from "chart.js";
import { EstimateView, ItemStatusCounts } from "Models/estimate";
import { useEffect, useMemo, useState } from "react";
import { Bar } from 'react-chartjs-2'


export function EstimateItemProgressChartRenderer(props: ICellRendererParams<EstimateView, ItemStatusCounts>) {
    const [data, setData] = useState<ChartData<"bar"> | null>(null);
    const options = useMemo(() => ({
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y" as 'y',
        plugins: {
            legend: { display: false },
            tooltip: { enabled: false }
        },
        scales: {
            x: { display: false, stacked: true, beginAtZero: true, max: 100 },
            y: { display: false, stacked: true }
        }
    }), []);


    useEffect(() => {
        if (props.value) {
            const total = props.value.notStarted + props.value.inProgress + props.value.completed;
            const notStartedPercentage = Math.round((props.value.notStarted / total) * 100);
            const inProgressPercentage = Math.round((props.value.inProgress / total) * 100);
            const completedPercentage = 100 - notStartedPercentage - inProgressPercentage; // Ensure total is exactly 100%

            setData({
                labels: ["Status"],
                datasets: [
                    {
                        data: [notStartedPercentage],
                        backgroundColor: "rgba(161, 161, 161, 0.8)"
                    },
                    {
                        data: [inProgressPercentage],
                        backgroundColor: "rgba(247, 214, 54, 0.8)"
                    },
                    {
                        data: [completedPercentage],
                        backgroundColor: "rgba(51, 174, 16, 0.8)"
                    }
                ]
            });
        }
    }, [props.value])

    return <>{data && <Bar data={data} options={options} />}</>
}