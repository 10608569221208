import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { api } from './Services/api';
import itemReducer from './itemSlice';
import indirectItemReducer from './indirectItemSlice';
import subcontractorReducer from './subcontractorSlice';
import supplierReducer from './supplierSlice';
import appStateReducer from './appStateSlice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    items: itemReducer,
    indirectItems: indirectItemReducer,
    subcontractor:subcontractorReducer,
    supplier: supplierReducer,
    appState: appStateReducer
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(api.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;