import * as yup from 'yup';
import { useFormik } from "formik";
import { Box, TextField, Button, IconButton, Autocomplete } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { IRowNode } from 'ag-grid-community';
import { ItemView } from 'Models/item';
import { useGetCategoriesQuery } from 'State/Services/category';
import { useGetUserDetailsQuery } from 'State/Services/user';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { EstimateCategory } from 'Models/estimate';
import { useGetEstimateQuery } from 'State/Services/estimate';
export interface ItemFilterSettings {
    description: string | undefined;
    idFrom: number | undefined;
    idTo: number | undefined;
    category1: EstimateCategory | undefined;
    category2: EstimateCategory | undefined;
    category3: EstimateCategory | undefined;
    category4: EstimateCategory | undefined;
}

export interface FilterProps {
    estimateId: string;
    onFilterChanged: (settings: ItemFilterSettings) => void;
    editing?: IRowNode<ItemView> | undefined;
    relativeWidthPercent?: string | undefined;
}

const filterValidationSchema = yup.object<ItemFilterSettings>({
    category: yup
        .string(),
    idFrom: yup
        .number().typeError("Must be numeric").positive('Must be positive').integer(),
    description: yup
        .string(),
    idTo: yup
        .number().typeError("Must be numeric").positive('Must be positive').integer()
        .when('idFrom', (idFrom, schema) => {
            return schema.test({
                test: (idTo) => {
                    if (!idTo || !idFrom[0]) {
                        return true;
                    }
                    return !!idFrom[0] && !!idTo && idTo > idFrom[0];
                },
                message: "Must be > From ID"
            })
        }),
});

export function ItemFilters(props: FilterProps) {
    const { data: user } = useGetUserDetailsQuery();
    const categoryRef = useRef<HTMLInputElement>();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: storedCategories } = useGetCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [category1s, setCategory1s] = useState<EstimateCategory[]>();
    const [category2s, setCategory2s] = useState<EstimateCategory[]>();
    const [category3s, setCategory3s] = useState<EstimateCategory[]>();
    const [category4s, setCategory4s] = useState<EstimateCategory[]>();
    const filterForm = useFormik<ItemFilterSettings>({
        initialValues: {
            idFrom: undefined,
            idTo: undefined,
            description: undefined,
            category1: undefined,
            category2: undefined,
            category3: undefined,
            category4: undefined,
        },
        validationSchema: filterValidationSchema,
        onSubmit: (values) => {
            props.onFilterChanged(values);
        },
    });

    useEffect(() => {
        if (storedCategories && storedCategories.length > 0) {
            const category1s = new Array<EstimateCategory>();
            const category2s = new Array<EstimateCategory>();
            const category3s = new Array<EstimateCategory>();
            const category4s = new Array<EstimateCategory>();
            storedCategories.forEach((cat) => {
                switch (cat.level) {
                    case 1:
                        category1s.push({ ...cat });
                        break;
                    case 2:
                        category2s.push({ ...cat });
                        break;
                    case 3:
                        category3s.push({ ...cat });
                        break;
                    case 4:
                        category4s.push({ ...cat });
                        break;
                    default:
                        break;
                }
            });
            setCategory1s(category1s);
            setCategory2s(category2s);
            setCategory3s(category3s);
            setCategory4s(category4s);
        }
    }, [storedCategories])

    const submitOnEnter = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            filterForm.handleSubmit();
        }
    }

    const resetForm = () => {
        filterForm.resetForm();
        props.onFilterChanged({
            idFrom: undefined,
            idTo: undefined,
            description: undefined,
            category1: undefined,
            category2: undefined,
            category3: undefined,
            category4: undefined,
        });
    }

    const clearField = useCallback((field: string) => {
        filterForm.setFieldValue(field, '');
        filterForm.handleSubmit();
    }, [filterForm]);

    return <>
        <form noValidate onSubmit={filterForm.handleSubmit} style={{ display: "flex" }} autoComplete="off">
            <Box display="flex" flexDirection="column">
                <Box marginLeft="10px" display="flex" gap="10px">
                    {estimate && estimate.noOfLevels > 0 && <Autocomplete
                        size="small"
                        value={filterForm.values.category1 ?? null}
                        className="ag-input-field-input ag-text-field-input"
                        sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                        options={category1s ?? []}
                        getOptionLabel={(option) => option.category ?? ''}
                        renderInput={(params) => <TextField name="category1Id" placeholder='Select Category 1' {...params} />}
                        isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                        }}
                        ref={categoryRef}
                        onChange={(event, value) => {
                            if (value) {
                                filterForm.setFieldValue('category1', value);
                            } else {
                                filterForm.setFieldValue('category1', undefined);
                            }
                            filterForm.handleSubmit();
                        }}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.category ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.category ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />}
                    {estimate && estimate.noOfLevels > 1 && <Autocomplete
                        size="small"
                        value={filterForm.values.category2 ?? null}
                        className="ag-input-field-input ag-text-field-input"
                        sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                        options={category2s ?? []}
                        getOptionLabel={(option) => option.category ?? ''}
                        renderInput={(params) => <TextField name="category2Id" placeholder='Select Category 2' {...params} />}
                        isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                        }}
                        ref={categoryRef}
                        onChange={(event, value) => {
                            if (value) {
                                filterForm.setFieldValue('category2', value);
                            } else {
                                filterForm.setFieldValue('category2', undefined);
                            }
                            filterForm.handleSubmit();
                        }}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.category ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.category ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />}
                    {estimate && estimate.noOfLevels > 2 && <Autocomplete
                        size="small"
                        value={filterForm.values.category3 ?? null}
                        className="ag-input-field-input ag-text-field-input"
                        sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                        options={category3s ?? []}
                        getOptionLabel={(option) => option.category ?? ''}
                        renderInput={(params) => <TextField name="category3Id" placeholder='Select Category 3' {...params} />}
                        isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                        }}
                        ref={categoryRef}
                        onChange={(event, value) => {
                            if (value) {
                                filterForm.setFieldValue('category3', value);
                            } else {
                                filterForm.setFieldValue('category3', undefined);
                            }
                            filterForm.handleSubmit();
                        }}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.category ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.category ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />}
                    {estimate && estimate.noOfLevels > 3 && <Autocomplete
                        size="small"
                        value={filterForm.values.category4 ?? null}
                        className="ag-input-field-input ag-text-field-input"
                        sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                        options={category4s ?? []}
                        getOptionLabel={(option) => option.category ?? ''}
                        renderInput={(params) => <TextField name="category3Id" placeholder='Select Category 4' {...params} />}
                        isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                        }}
                        ref={categoryRef}
                        onChange={(event, value) => {
                            if (value) {
                                filterForm.setFieldValue('category4', value);
                            } else {
                                filterForm.setFieldValue('category4', undefined);
                            }
                            filterForm.handleSubmit();
                        }}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.category ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.category ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />}
                </Box>
                <Box marginRight="0px" display="flex" width={props.relativeWidthPercent ?? "100%"} marginTop={(estimate && estimate.noOfLevels > 0) ? "20px" : ''}>
                    <Box display="flex" marginLeft="10px">
                        <TextField
                            size="small"
                            label="From ID"
                            value={filterForm.values.idFrom ?? ''}
                            variant="outlined"
                            disabled={!!props?.editing}
                            name="idFrom"
                            InputProps={{
                                type: 'number',
                                endAdornment: <IconButton disabled={!!props?.editing} aria-label="delete" size="small" onClick={() => clearField('idFrom')}>
                                    <ClearIcon />
                                </IconButton>,
                            }}
                            sx={{ marginRight: "10px", width: "120px" }}
                            onKeyDown={submitOnEnter}
                            onChange={filterForm.handleChange}
                            onBlur={filterForm.handleBlur}
                            error={filterForm.touched.idFrom && Boolean(filterForm.errors.idFrom)}
                            helperText={filterForm.touched.idFrom && filterForm.errors.idFrom} />
                        <TextField
                            size="small"
                            label="To ID"
                            value={filterForm.values.idTo ?? ''}
                            variant="outlined"
                            disabled={!!props?.editing}
                            name="idTo"
                            InputProps={{
                                type: 'number',
                                endAdornment: <IconButton disabled={!!props?.editing} aria-label="delete" size="small" onClick={() => clearField('idTo')}>
                                    <ClearIcon />
                                </IconButton>,
                            }}
                            sx={{ marginRight: "10px", width: "120px" }}
                            onKeyDown={submitOnEnter}
                            onChange={filterForm.handleChange}
                            onBlur={filterForm.handleBlur}
                            error={filterForm.touched.idTo && Boolean(filterForm.errors.idTo)}
                            helperText={filterForm.touched.idTo && filterForm.errors.idTo} />
                    </Box>
                    <Box width="100%" display="flex">
                        <TextField
                            fullWidth
                            size="small"
                            value={filterForm.values.description}
                            label="Search"
                            variant="outlined"
                            onKeyDown={submitOnEnter}
                            name="description"
                            disabled={!!props?.editing}
                            sx={{ marginRight: "10px" }}
                            onChange={filterForm.handleChange}
                            onBlur={filterForm.handleBlur}
                            InputProps={{
                                endAdornment: <IconButton aria-label="delete" disabled={!!props?.editing} size="small" onClick={() => clearField('description')}>
                                    <ClearIcon />
                                </IconButton>,
                            }} />
                    </Box>
                    <Box display="flex">
                        <Button
                            sx={{ color: "rgba(0, 0, 0, 0.54)", width: "80px" }}
                            disabled={!!props?.editing}
                            variant="text"
                            onClick={resetForm}
                            type="reset">
                            Clear All
                        </Button>
                    </Box>
                </Box>
            </Box>
        </form>
    </>;
}