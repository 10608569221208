import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Avatar, Box, Tab, useTheme } from "@mui/material";
import ActivityList from "Components/Activities/ActivityList";
import ResourceList from "Components/Resources/ResourceList";
import StandardItemList from "Components/StandardItems/StandardItemList";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { PanelState } from "Models/panel";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { tokens } from "theme";

export function Library() {
    const theme = useTheme();
    const { estimateId } = useParams();
    const { data: user } = useGetUserDetailsQuery();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const { state } = useLocation();
    const [value, setValue] = useState<string>('Resources');
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [panelState] = useState<PanelState>({position: {state: 'full'}});

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                setHasViewAccess(true);
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Resources,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
            }
        } else {
            setHasViewAccess(true);
        }
        setIsReady(true);
    }, [estimate, estimate?.EstimateUserRole, user])

    useEffect(() => {
        if (state) {
            const { tab } = state;
            setValue(tab ?? "Resources");
        }
    }, [state])

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    if (!isReady){
        return <></>
    }

    if (!hasViewAccess){
        return <Box m="10px">Unauthorized access.</Box>
    }

    return <>
        <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} sx={{ height: "50px" }}>
                    <Tab sx={{ textTransform: "none", padding: "15px", minHeight: "50px", maxHeight: "50px" }} icon={<Avatar sx={{ bgcolor: colors.blueAccent[500], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">R</Avatar>} iconPosition="start" label="Resources" value="Resources" />
                    <Tab sx={{ textTransform: "none", padding: "15px", minHeight: "50px", maxHeight: "50px" }} icon={<Avatar sx={{ bgcolor: colors.primary[300], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">A</Avatar>} iconPosition="start" label="Activities" value="Activities" />
                    <Tab sx={{ textTransform: "none", padding: "15px", minHeight: "50px", maxHeight: "50px" }} icon={<Avatar sx={{ bgcolor: colors.purpleAccent[500], width: "22px", height: "18px", fontSize: "smaller" }} variant="rounded">I</Avatar>} iconPosition="start" label="Items" value="Items" />
                </TabList>
            </Box>
            <TabPanel value="Resources" sx={{ height: 'calc(100% - 158px)', padding: "10px" }}>
                <ResourceList estimateId={estimateId} panelState={panelState} />
            </TabPanel>
            <TabPanel value="Activities" sx={{ height: 'calc(100% - 158px)', padding: "10px" }}>
                <ActivityList estimateId={estimateId} panelState={panelState} />
            </TabPanel>
            <TabPanel value="Items" sx={{ height: 'calc(100% - 158px)', padding: "10px" }}>
                <StandardItemList estimateId={estimateId} panelState={panelState}></StandardItemList>
            </TabPanel>
        </TabContext>
    </>;
}