import { Typography, Link, Box, Breadcrumbs, useTheme, Button, Autocomplete, TextField, IconButton, OutlinedInput, InputAdornment, Popover, Select, MenuItem, Modal } from "@mui/material";
import { BaseItem } from "Models/item";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import React, { KeyboardEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CalculateIcon from '@mui/icons-material/Calculate';
import {
    Link as RouterLink,
} from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { tokens } from "theme";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import * as yup from 'yup';
import { useFormik } from "formik";
import { GridTextField } from "Components/GridTextField";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import SaveIcon from '@mui/icons-material/Save';
import { useGetItemDetailsForItemQuery, useGetItemNavQuery, useGetItemIdQuery, useSaveItemAsStandardItemMutation, useSyncItemsForCodeMutation, useUpdateItemMutation, useLazyGetFilteredItemNavsQuery } from "State/Services/item";
import CircularProgress from '@mui/material/CircularProgress';
import CategoryEdit, { SelectedCategories } from "./CategoryEdit";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ItemDetails from "./ItemDetail";
import { ItemForm } from "./item-form";
import { rounder } from "Helpers/rounder";
import SplitButton, { MenuOption } from "Components/SplitButton";
import { useConfirm } from "material-ui-confirm";
import { useGetItemCodesQuery } from "State/Services/item-code";
import SyncIcon from '@mui/icons-material/Sync';
import LoadingButton from '@mui/lab/LoadingButton';
import { ProjectPrice } from "Components/Items/ProjectPrice";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import CloseIcon from '@mui/icons-material/Close';
import QuantityCalculator from "Components/Shared/QuantityCalculator";
import { NavItem } from "Models/nav";

const baseItemValidationSchema = yup.object<ItemForm>({
    itemBoqId: yup
        .string()
        .nullable()
        .optional(),
    page: yup
        .string()
        .nullable()
        .optional(),
    code: yup.string()
        .nullable()
        .optional(),
    category1: yup
        .string()
        .nullable()
        .optional(),
    category2: yup
        .string()
        .nullable()
        .optional(),
    category3: yup
        .string()
        .nullable()
        .optional(),
    category4: yup
        .string()
        .nullable()
        .optional(),
    category1Id: yup
        .number()
        .nullable()
        .optional(),
    category2Id: yup
        .number()
        .nullable()
        .optional(),
    category3Id: yup
        .number()
        .nullable()
        .optional(),
    category4Id: yup
        .number()
        .nullable()
        .optional(),
    description: yup
        .string()
        .nullable()
        .optional(),
    unitId: yup
        .string()
        .nullable()
        .optional(),
    customUnit: yup
        .string()
        .nullable()
        .optional(),
    quantity: yup
        .number()
        .nullable()
        .optional(),
    estimatedQuantity: yup
        .number()
        .nullable()
        .optional(),
    markup: yup
        .number()
        .nullable()
        .optional(),
    remarks: yup
        .string()
        .nullable()
        .optional(),
    preliminaryRate: yup
        .number()
        .nullable()
        .optional(),
});

export default function Item() {
    const confirm = useConfirm();
    const { estimateId, itemId } = useParams();
    const { data: user } = useGetUserDetailsQuery();
    const { data: nav } = useGetItemNavQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '', itemId: itemId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId || !itemId })
    const { data: item } = useGetItemIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '', itemId: itemId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId || !itemId });
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: itemCodes } = useGetItemCodesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId });
    const [updateItem] = useUpdateItemMutation();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const navigate = useNavigate();
    const [itemDisabled, setItemDisabled] = useState(true);
    const itemFieldRef = useRef<HTMLInputElement>();
    const [itemFocus, setItemFocus] = useState(false);
    const [pageDisabled, setPageDisabled] = useState(true);
    const [itemCodeDisabled, setItemCodeDisabled] = useState(true);
    const [itemCodeFocus, setItemCodeFocus] = useState(false);
    const pageFieldRef = useRef<HTMLInputElement>();
    const itemCodeFieldRef = useRef<HTMLInputElement>();
    const [pageFocus, setPageFocus] = useState(false);
    const [open, setOpen] = useState<boolean>(false);
    const unitInputRef = useRef<any>();
    const [isSaving, setIsSaving] = useState(false);
    const categoryRef = useRef<HTMLDivElement>();
    const [openCategoryPopover, setOpenCategoryPopover] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
    const [showForm, setShowForm] = useState(true);
    const [unitCost, setUnitCost] = useState<number>(0);
    const [unitPrice, setUnitPrice] = useState<number>(0);
    const [estCost, setEstCost] = useState<number>(0);
    const [estPrice, setEstPrice] = useState<number>(0);
    const [cost, setCost] = useState<number>(0);
    const [price, setPrice] = useState<number>(0);
    const { data: storedItemDetails } = useGetItemDetailsForItemQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: estimateId ?? '', itemId: item?.id ?? '' }, { skip: !user?.companyId || !user?.organizationId || !estimateId || !item?.id });
    const [saveMenuItems, setSaveMenuItems] = useState<Array<MenuOption>>([{ option: "Save to library items", disabled: false }]);
    const [saveItemAsStandardItem] = useSaveItemAsStandardItemMutation();
    const [syncItemsForCode] = useSyncItemsForCodeMutation();
    const [syncing, setSyncing] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const [editDisabled, setEditDisabled] = useState(false);
    const [editEstQuantityDisabled, setEditEstQuantityDisabled] = useState(false);
    const [openCalculateModal, setOpenCalculateModal] = useState(false);
    const [getFilteredNavItems] = useLazyGetFilteredItemNavsQuery();
    const [options, setOptions] = useState<Array<NavItem>>([]);

    const handleOpenCalculateModal = () => {
        setOpenCalculateModal(true);
    };
    const handleCloseCalculateModal = () => {
        setOpenCalculateModal(false);
    };

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                setEditDisabled(true);
                setHasViewAccess(true);
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
                const hasEstQuantityEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [504]
                });
                setEditEstQuantityDisabled(!hasEstQuantityEditAccess);
                if (item?.assignedTo && item.assignedTo.length > 0) {
                    if (item.assignedTo.some((assignedTo) => (assignedTo.id === user?.userId)) && hasEstQuantityEditAccess) {
                        setEditEstQuantityDisabled(false);
                    } else {
                        setEditEstQuantityDisabled(true);
                    }
                }
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [502]
                });
                setEditDisabled(!hasEditAccess);
            }
        } else {
            setEditDisabled(true);
            setHasViewAccess(true);
        }
        setIsReady(true);
    }, [estimate, estimate?.EstimateUserRole, item?.assignedTo, user])

    const handleOpenCategoryPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setOpenCategoryPopover(true);
        if (categoryRef.current) {
            setAnchorEl(categoryRef.current);
        }
    }

    const handleCloseCategoryPopover = () => {
        setOpenCategoryPopover(false);
        setAnchorEl(null);
    };
    const formik = useFormik<ItemForm>({
        enableReinitialize: true,
        initialValues: (item) ? {
            itemBoqId: item.itemBoqId ?? '',
            description: item.description ?? '',
            page: item.page ?? '',
            customUnit: item.customUnit ?? '',
            unitId: item.unitId ?? '',
            quantity: item.quantity?.toString() ?? '',
            estimatedQuantity: item.estimatedQuantity?.toString() ?? '',
            remarks: item.remarks ?? '',
            category1Id: item.category1Id?.toString() ?? '',
            category2Id: item.category2Id?.toString() ?? '',
            category3Id: item.category3Id?.toString() ?? '',
            category4Id: item.category4Id?.toString() ?? '',
            category1: item.category1 ?? '',
            category2: item.category2 ?? '',
            category3: item.category3 ?? '',
            category4: item.category4 ?? '',
            preliminaryRate: item.preliminaryRate?.toString() ?? '',
            markup: item.markup?.toString() ?? '',
            id: item.id,
            code: item.code ?? '',
            status: item.status ?? ''
        } : {
            id: '',
            itemBoqId: '',
            description: '',
            page: '',
            customUnit: '',
            unitId: '',
            quantity: '',
            estimatedQuantity: '',
            remarks: '',
            category1Id: '',
            category2Id: '',
            category3Id: '',
            category4Id: '',
            category1: '',
            category2: '',
            category3: '',
            category4: '',
            preliminaryRate: '',
            markup: '',
            code: '',
            status: ''
        },
        validationSchema: baseItemValidationSchema,
        onSubmit: async (values) => {
            if (values) {
                try {
                    if (values && item) {
                        setIsSaving(true);
                        await updateItem({
                            companyId: user?.companyId,
                            estimateId: estimateId,
                            itemId: item?.id,
                            orgId: user?.organizationId,
                            clearCosts: false,
                            body: {
                                description: values.description,
                                displayId: item.displayId,
                                id: item.id,
                                itemBoqId: values.itemBoqId,
                                page: values.page,
                                quantity: parseFloat(values.quantity),
                                category1: values.category1,
                                category1Id: parseInt(values.category1Id),
                                category2: values.category2,
                                category2Id: parseInt(values.category2Id),
                                category3: values.category3,
                                category3Id: parseInt(values.category3Id),
                                category4: values.category4,
                                category4Id: parseInt(values.category4Id),
                                unitId: values.unitId,
                                customUnit: (!values.unitId) ? values.customUnit : undefined,
                                remarks: values.remarks,
                                estimatedQuantity: parseFloat(values.estimatedQuantity),
                                markup: parseFloat(values.markup),
                                preliminaryRate: parseFloat(values.preliminaryRate),
                                code: values.code,
                                status: values.status as "Not Priced" | "In Progress" | "In Review" | "Done"
                            }
                        });

                    }

                } catch (error) {
                    console.log(error);
                } finally {
                    setIsSaving(false);
                }
            }
        },
    });

    const onAmountUpdate = useCallback((amount: number) => {
        const decs = (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2;
        setUnitCost(rounder(amount, decs));
        const prelimRate = (!isNaN(parseFloat(formik.values.preliminaryRate))) ? parseFloat(formik.values.preliminaryRate) : 0;
        const markup = (!isNaN(parseFloat(formik.values.markup))) ? parseFloat(formik.values.markup) : 0;
        const estQuantity = (!isNaN(parseFloat(formik.values.estimatedQuantity))) ? parseFloat(formik.values.estimatedQuantity) : 0;
        const quantity = (!isNaN(parseFloat(formik.values.quantity))) ? parseFloat(formik.values.quantity) : 0;
        const unitPrice = amount + prelimRate + (amount * (markup / 100));
        setUnitPrice(rounder(unitPrice, decs));
        setEstCost(rounder(amount * estQuantity, decs));
        setEstPrice(rounder(unitPrice * estQuantity, decs));
        setCost(rounder(amount * quantity, decs));
        setPrice(rounder(unitPrice * quantity, decs));
    }, [estimate?.CompanyCurrency?.Currency?.minorUnit, formik.values.estimatedQuantity, formik.values.markup, formik.values.preliminaryRate, formik.values.quantity])

    const breadcrumbs = useMemo(() => {
        if (estimate?.isMaster) {
            return [
                <Link
                    component={RouterLink}
                    to={`/organization/${user?.organizationId}/company/${user?.companyId}/master/dashboard`}
                    underline="hover"
                    key="1"
                    color="inherit"
                >
                    Template
                </Link>,
                <Link
                    component={RouterLink}
                    to={`/organization/${user?.organizationId}/company/${user?.companyId}/master/${estimateId}/direct-items`}
                    underline="hover"
                    key="1"
                    color="inherit"
                >
                    {estimate?.name}
                </Link>,
                <Typography key="2" color="text.primary">
                    Item: {item?.displayId}
                </Typography>
            ]
        } else {
            return [
                <Link
                    component={RouterLink}
                    to={`/organization/${user?.organizationId}/company/${user?.companyId}/estimate/dashboard`}
                    underline="hover"
                    key="1"
                    color="inherit"
                >
                    Estimates
                </Link>,
                <Link
                    component={RouterLink}
                    to={`/organization/${user?.organizationId}/company/${user?.companyId}/estimate/${estimateId}/direct-items`}
                    underline="hover"
                    key="1"
                    color="inherit"
                >
                    {estimate?.name}
                </Link>,
                <Typography key="2" color="text.primary">
                    Item: {item?.displayId}
                </Typography>
            ]
        }
    }, [estimate?.isMaster, estimate?.name, estimateId, item?.displayId, user?.companyId, user?.organizationId]);

    useEffect(() => {
        if ((storedItemDetails && storedItemDetails.some((itemDetail) => (itemDetail.type === "item"))) || !item?.quantity) {
            setSaveMenuItems([{ option: "Save to library items", disabled: true }]);
        } else {
            setSaveMenuItems([{ option: "Save to library items", disabled: false }]);
        }
    }, [breadcrumbs, estimateId, item?.quantity, itemId, storedItemDetails, user])

    const toItem = useCallback((value: NavItem | null) => {
        if (value) {
            navigate(`../item/${value.itemId}`);
        }
    }, [navigate])

    const onInputChange = useCallback(async (event: SyntheticEvent, newValue: string | null) => {
        try {
            if (user) {
                const items = await getFilteredNavItems({
                    companyId: user.companyId ?? '',
                    estimateId: estimateId ?? '',
                    filterText: newValue ?? '',
                    organizationId: user.organizationId
                }).unwrap();
                setOptions(items);
            }
        } catch (error) {
            console.log(error);
        }
    }, [estimateId, getFilteredNavItems, user])

    const onChange = useCallback(async (event: SyntheticEvent, newValue: NavItem | null) => {
        if (newValue) {
            toItem(newValue);
        }
        setOpen(false);
    }, [toItem]);

    const next = useCallback(() => {
        if (nav && item) {
            toItem(nav.next);
        }
    }, [nav, item, toItem])

    const previous = useCallback(() => {
        if (nav && item) {
            toItem(nav.previous)
        }
    }, [nav, item, toItem])

    const saveCategories = useCallback((selectedCategories: SelectedCategories) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (selectedCategories.category1) {
                    formik.setFieldValue('category1Id', selectedCategories.category1.id);
                    formik.setFieldValue('category1', selectedCategories.category1.category);
                } else {
                    formik.setFieldValue('category1Id', undefined);
                    formik.setFieldValue('category1', undefined);
                }
                if (selectedCategories.category2) {
                    formik.setFieldValue('category2Id', selectedCategories.category2.id);
                    formik.setFieldValue('category2', selectedCategories.category2.category);
                } else {
                    formik.setFieldValue('category2Id', undefined);
                    formik.setFieldValue('category2', undefined);
                }
                if (selectedCategories.category3) {
                    formik.setFieldValue('category3Id', selectedCategories.category3.id);
                    formik.setFieldValue('category3', selectedCategories.category3.category);
                } else {
                    formik.setFieldValue('category3Id', undefined);
                    formik.setFieldValue('category3', undefined);
                }
                if (selectedCategories.category4) {
                    formik.setFieldValue('category4Id', selectedCategories.category4.id);
                    formik.setFieldValue('category4', selectedCategories.category4.category);
                } else {
                    formik.setFieldValue('category4Id', undefined);
                    formik.setFieldValue('category4', undefined);
                }
                await formik.submitForm();
                resolve();
            } catch (error) {
                reject();
            }
        });
    }, [formik]);

    const renderSwitch = useCallback((item: BaseItem) => {
        let category = '';
        switch (estimate?.noOfLevels) {
            default:
            case 1:
                category = item.category1 ?? '';
                break
            case 2:
                category = `${item.category1} / ${item.category2}`;
                break;
            case 3:
                category = `${item.category1} / ${item.category2} / ${item.category3}`;
                break;
            case 4:
                category = `${item.category1} / ${item.category2} / ${item.category3} / ${item.category4}`;
                break;
        }
        return <OutlinedInput
            inputRef={categoryRef}
            size="small"
            disabled={true}
            endAdornment={
                <InputAdornment position="end">
                    <Box display="flex" width="16px" height="16px" color={`${colors.gray[500]}`}>
                        <IconButton disabled={editDisabled} aria-label="edit item" size="small" color="inherit" onClick={handleOpenCategoryPopover}>
                            <ModeEditIcon fontSize="inherit" />
                        </IconButton>
                    </Box>
                </InputAdornment>
            }
            sx={{
                '& .MuiInputBase-input': {
                    color: "black",
                    fontColor: "black",
                    WebkitTextFillColor: "black"
                },
                width: "320px",
                height: "28px",
                borderRadius: "7px",
                fontWeight: "600",
                border: `1px solid ${colors.gray[800]}`,

            }}
            value={category} />
    }, [colors.gray, editDisabled, estimate?.noOfLevels])

    const itemFieldEdit = useCallback(() => {
        setItemDisabled(false);
        setItemFocus(true);
    }, [])

    const itemBlur = useCallback((event: React.FocusEvent) => {
        setItemDisabled(true);
        setItemFocus(false);
        formik.handleBlur(event);
        formik.submitForm();
    }, [formik])

    const pageFieldEdit = useCallback(() => {
        setPageDisabled(false);
        setPageFocus(true);
    }, [])

    const itemCodeFieldEdit = useCallback(() => {
        setItemCodeDisabled(false);
        setItemCodeFocus(true);
    }, [])

    const itemCodeBlur = useCallback((event: React.FocusEvent) => {
        setItemCodeDisabled(true);
        setItemCodeFocus(false);
        formik.handleBlur(event);
        formik.submitForm();
    }, [formik])

    const pageBlur = useCallback((event: React.FocusEvent) => {
        setPageDisabled(true);
        setPageFocus(false);
        formik.handleBlur(event);
        formik.submitForm();
    }, [formik])

    useEffect(() => {
        if (itemFocus && itemFieldRef.current) {
            itemFieldRef.current.focus();
        }
    }, [itemFocus])

    useEffect(() => {
        if (pageFocus && pageFieldRef.current) {
            pageFieldRef.current.focus();
        }
    }, [pageFocus])

    useEffect(() => {
        if (itemCodeFocus && itemCodeFieldRef.current) {
            itemCodeFieldRef.current.focus();
        }
    }, [itemCodeFocus])

    const routeToItemOnEnter = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            const item = options?.find((i) => (i.displayId.toString() === (event.target as HTMLInputElement).value));
            toItem(item ?? null);
        }
    }

    const saveOnEnter = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            (event.target as HTMLInputElement).blur();
        }
    }, [])

    const showHideForm = useCallback(() => {
        setShowForm(!showForm);
    }, [showForm])

    const saveAsStandard = useCallback(async (overwrite: boolean) => {
        try {

            if (user) {
                await saveItemAsStandardItem({
                    orgId: user.organizationId,
                    companyId: user.companyId,
                    estimateId: estimateId,
                    itemId: item?.id,
                    overwrite: overwrite
                }).unwrap();
            }
        } catch (error: any) {
            if (('status' in error) &&
                error.status === 400 &&
                ('data' in error) &&
                ('description' in error.data)) {
                try {
                    await confirm({ cancellationText: "No, cancel", confirmationText: "Yes, continue", description: `A library item with the same description already exists. Do you want to overwrite it?`, title: 'Overwrite confirmation' });
                    await saveAsStandard(true);
                } catch (error) {
                    // User has cancelled. Do nothing
                }
            }
        }
    }, [confirm, estimateId, item?.id, saveItemAsStandardItem, user])

    const handleClose = useCallback(async (option?: string) => {
        try {
            if (option === "Save to library items") {
                await confirm({ cancellationText: "No, cancel", confirmationText: "Yes, continue", description: `Do you want to save this item to the estimate library?`, title: 'Save confirmation' });
                await saveAsStandard(false);
            } else {
                formik.handleSubmit();
            }
        } catch (error: any) {
            // User has cancelled. Do nothing
        }

    }, [confirm, formik, saveAsStandard])

    const sync = useCallback(async () => {
        if (user) {
            try {
                await confirm({ cancellationText: "No, cancel", confirmationText: "Yes, continue", description: `Do you want to sync this item estimate to items with a matching code? This will overwrite any existing estimate for these items.`, title: 'Sync confirmation' });
                setSyncing(true);
                const countInfo = await syncItemsForCode({
                    code: formik.values.code,
                    companyId: user.companyId,
                    estimateId: estimateId,
                    itemId: item?.id,
                    orgId: user.organizationId
                }).unwrap();
                setSyncing(false);
                if ('count' in countInfo) {
                    await confirm({ hideCancelButton: true, confirmationText: "Ok", description: `This item estimate has been synced to ${countInfo.count} item${countInfo.count > 1 ? 's' : ''}.`, title: 'Confirmation' });
                }
            } catch (error) {
                // User clicks cancel. Do Nothing.
            }

        }
    }, [confirm, estimateId, formik.values.code, item?.id, syncItemsForCode, user])

    if (!isReady) {
        return <></>
    }

    if (!hasViewAccess) {
        return <Box m="10px">Unauthorized access.</Box>
    }

    return <>{item && nav &&
        <>
            <form noValidate onSubmit={formik.handleSubmit}>
                <Box display="flex" alignItems='center' justifyContent="space-between" paddingLeft="24px" paddingRight="0px" borderBottom={`1px solid ${colors.gray[800]}`} height="57px">
                    <Breadcrumbs
                        sx={{
                            " & .MuiBreadcrumbs-li": {
                                color: `${colors.gray[500]}`
                            },
                            " & .MuiBreadcrumbs-separator": {
                                color: `${colors.gray[700]}`
                            }
                        }}
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                    <Box display="flex">
                        <Button
                            variant="outlined"
                            disabled={!nav?.previous}
                            onClick={previous}
                            sx={{
                                marginRight: "4px",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                minWidth: "15px",
                                padding: "7px",
                                ' & .MuiButton-startIcon': {
                                    marginRight: "0px",
                                    marginLeft: "0px"
                                },
                            }}
                            startIcon={<KeyboardArrowLeftIcon sx={{ marginRight: "0px" }} />} >
                        </Button>
                        <Autocomplete
                            size="small"
                            autoHighlight
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            onClose={() => {
                                setOpen(false);
                            }}
                            onKeyDown={routeToItemOnEnter}
                            value={nav.current ?? undefined}
                            isOptionEqualToValue={(option, value) => (option.itemId === value.itemId)}
                            getOptionLabel={(i) => (i.displayId.toString())}
                            onChange={onChange}
                            onInputChange={onInputChange}
                            options={options}
                            filterOptions={(x) => x}
                            sx={{ width: 100 }}
                            renderInput={(params) => <TextField label="Item ID" {...params} />}
                        />
                        <Button
                            variant="outlined"
                            disabled={!nav.next}
                            onClick={next}
                            sx={{
                                marginLeft: "4px",
                                paddingLeft: "0px",
                                paddingRight: "0px",
                                minWidth: "15px",
                                padding: "7px",
                                ' & .MuiButton-startIcon': {
                                    marginRight: "0px",
                                    marginLeft: "0px"
                                },
                            }}
                            startIcon={<KeyboardArrowRightIcon sx={{ marginRight: "0px" }} />} >
                        </Button>
                    </Box>
                    <ProjectPrice estimateId={estimateId} />
                </Box>
                <Box display="flex" width="100%" justifyContent="space-between" alignContent="center" alignItems="center" padding="20px 24px" borderBottom={`1px solid ${colors?.gray[800]}`}>
                    <Box display="flex">
                        <Box
                            display="flex"
                            fontSize="16px"
                            fontWeight="600"
                            marginRight="12px"
                            alignItems="center">
                            Item ID: {item?.displayId}
                        </Box>

                        <OutlinedInput
                            size="small"
                            inputRef={itemFieldRef}
                            disabled={itemDisabled || editDisabled}
                            onBlur={itemBlur}
                            autoComplete="off"
                            id="itemBoqId"
                            name="itemBoqId"
                            onKeyDown={saveOnEnter}
                            onChange={formik.handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Box display="flex" width="16px" height="16px" color={`${colors.gray[500]}`}>
                                        <IconButton disabled={editDisabled} aria-label="edit item" size="small" color="inherit" onClick={itemFieldEdit}>
                                            <ModeEditIcon fontSize="inherit" />
                                        </IconButton>
                                    </Box>
                                </InputAdornment>
                            }
                            sx={{
                                '& .MuiInputBase-input': {
                                    color: "black",
                                    fontColor: "black",
                                    WebkitTextFillColor: "black"
                                },
                                marginRight: "12px",
                                width: "120px",
                                height: "28px",
                                borderRadius: "7px",
                                fontWeight: "600",
                                border: `1px solid ${colors.gray[800]}`,

                            }}
                            value={(itemDisabled) ? `Item: ${formik.values.itemBoqId}` : formik.values.itemBoqId} />
                        <OutlinedInput
                            size="small"
                            id="page"
                            name="page"
                            autoComplete="off"
                            onKeyDown={saveOnEnter}
                            onChange={formik.handleChange}
                            inputRef={pageFieldRef}
                            disabled={pageDisabled || editDisabled}
                            onBlur={pageBlur}
                            endAdornment={
                                <InputAdornment position="end">
                                    <Box display="flex" width="16px" height="16px" color={`${colors.gray[500]}`}>
                                        <IconButton disabled={editDisabled} aria-label="edit page" size="small" color="inherit" onClick={pageFieldEdit}>
                                            <ModeEditIcon fontSize="inherit" />
                                        </IconButton>
                                    </Box>
                                </InputAdornment>
                            }
                            sx={{
                                '& .MuiInputBase-input': {
                                    color: "black",
                                    fontColor: "black",
                                    WebkitTextFillColor: "black"
                                },
                                marginRight: "12px",
                                width: "120px",
                                height: "28px",
                                borderRadius: "7px",
                                fontWeight: "600",
                                border: `1px solid ${colors.gray[800]}`
                            }}
                            value={(pageDisabled) ? `Page: ${formik.values.page}` : formik.values.page} />
                        {estimate && (estimate?.noOfLevels > 0) &&
                            <Box justifyContent="space-between"
                                ref={categoryRef}
                                display="flex"
                                alignItems="center"
                                width="auto"
                                marginRight="12px"
                                height="28px">
                                {renderSwitch(item)}
                            </Box>
                        }
                        <Box display="flex" flexDirection="row" borderRadius="7px" height="28px" border={`1px solid ${colors.gray[800]}`}>
                            {itemCodeDisabled &&
                                <OutlinedInput
                                    size="small"
                                    id="code"
                                    name="code"
                                    autoComplete="off"
                                    disabled={true}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Box display="flex" width="16px" height="16px" color={`${colors.gray[500]}`}>
                                                <IconButton disabled={editDisabled} aria-label="edit code" size="small" color="inherit" onClick={itemCodeFieldEdit}>
                                                    <ModeEditIcon fontSize="inherit" />
                                                </IconButton>
                                            </Box>
                                        </InputAdornment>
                                    }
                                    sx={{
                                        borderRight: "0px",
                                        '& .MuiInputBase-input': {
                                            color: "black",
                                            fontColor: "black",
                                            WebkitTextFillColor: "black"
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderRight: "0px",
                                            borderTopRightRadius: "0px",
                                            borderBottomRightRadius: "0px"
                                        },
                                        width: "250px",
                                        borderRadius: "7px",
                                        fontWeight: "600",
                                    }}
                                    value={(pageDisabled) ? `Item code: ${formik.values.code}` : formik.values.code} />
                            }
                            {!itemCodeDisabled && <Autocomplete
                                fullWidth
                                freeSolo
                                onBlur={itemCodeBlur}
                                sx={{ border: `1px solid ${colors.gray[800]}`, borderRight: "0px", borderRadius: "7px", width: "250px" }}
                                value={formik.values.code ?? null}
                                size="small"
                                disabled={editDisabled}
                                onChange={(event: React.SyntheticEvent, newValue: string | null) => {
                                    if (newValue) {
                                        if (itemCodes) {
                                            const itemCode = itemCodes?.find((itemCode) => (itemCode.code === newValue));
                                            if (itemCode) {
                                                formik.setFieldValue('code', itemCode.code);
                                            } else {
                                                formik.setFieldValue('code', newValue);
                                            }
                                        }
                                    } else {
                                        formik.setFieldValue('code', '');
                                    }
                                }}
                                onInputChange={(event: React.SyntheticEvent, newValue: string | null) => {
                                    if (newValue) {
                                        if (itemCodes) {
                                            const itemCode = itemCodes?.find((itemCode) => (itemCode.code === newValue));
                                            if (itemCode) {
                                                formik.setFieldValue('code', itemCode.code);
                                            } else {
                                                formik.setFieldValue('code', newValue);
                                            }
                                        }
                                    } else {
                                        formik.setFieldValue('code', '');
                                    }
                                }}
                                options={itemCodes?.map((itemCode) => itemCode.code) ?? []}
                                renderInput={(params) => <GridTextField
                                    name="code"
                                    onKeyDown={saveOnEnter}
                                    inputRef={itemCodeFieldRef}
                                    sx={{
                                        fontWeight: "600",
                                        borderRight: "0px",
                                        '& .MuiInputBase-input': {
                                            color: "black",
                                            fontColor: "black",
                                            WebkitTextFillColor: "black"
                                        },
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderRight: "0px",
                                            borderTopRightRadius: "0px",
                                            borderBottomRightRadius: "0px"
                                        },
                                        width: "250px",
                                        borderRadius: "7px",
                                    }}
                                    placeholder='Item code' {...params} />}
                                renderOption={(props, option, { inputValue }) => {
                                    const matches = match(option, inputValue, {
                                        insideWords: true,
                                    });
                                    const parts = parse(option, matches);

                                    return (
                                        <li {...props}>
                                            <div>
                                                {parts.map((part, index) => (
                                                    <span
                                                        key={index}
                                                        style={{
                                                            fontWeight: part.highlight ? 700 : 400,
                                                        }}
                                                    >
                                                        {part.text}
                                                    </span>
                                                ))}
                                            </div>
                                        </li>
                                    );
                                }}
                            />}
                            <LoadingButton
                                onClick={sync}
                                sx={{
                                    borderRadius: "0px",
                                    borderTopRightRadius: "7px",
                                    borderBottomRightRadius: "7px",
                                    maxWidth: "30px",
                                    width: "20px",
                                    minWidth: "30px"
                                }}
                                disabled={!formik.values.code || storedItemDetails?.length === 0 || editDisabled}
                                loading={syncing}
                                aria-label="sync"
                                color="primary"
                                variant="contained">
                                <SyncIcon />
                            </LoadingButton>
                        </Box>
                    </Box>
                    <Box>
                        <SplitButton buttonIcon={!isSaving ? <SaveIcon /> : <CircularProgress sx={{ color: "white" }} size="1rem" />} disabled={isSaving} buttonHeight="35px" options={saveMenuItems} buttonText="Save" onButtonClick={handleClose} onMenuItemClick={handleClose} />
                    </Box>
                </Box>
                <Box flexDirection="column" sx={{ display: (showForm) ? 'flex' : 'none' }}>
                    <Box display="flex" width="100%" gap="24px" padding="6px 24px 25px 24px" >
                        <Box flex="0.7">
                            <Box display="flex" flexDirection="column" marginBottom="15px" height="100%">
                                <label htmlFor="description" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Description</label>
                                <OutlinedInput
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            border: "0",
                                            height: "100% !important"
                                        },
                                        border: `1px solid ${colors.gray[800]}`,
                                        borderRadius: "10px",
                                        padding: "3.5px 5px"
                                    }}
                                    disabled={editDisabled}
                                    size="small"
                                    id="description"
                                    name="description"
                                    placeholder="Description"
                                    autoComplete="off"
                                    rows="5"
                                    style={{ height: "100%" }}
                                    multiline
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description} />
                            </Box>
                        </Box>
                        <Box flex="0.4">
                            <Box display="flex" flexDirection="column" height="100%">
                                <label htmlFor="remarks" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Remarks</label>
                                <OutlinedInput
                                    size="small"
                                    id="remarks"
                                    name="remarks"
                                    disabled={editDisabled}
                                    rows="5"
                                    sx={{
                                        height: "100%",
                                        border: `1px solid ${colors.gray[800]}`,
                                        padding: "3.5px 5px",
                                        '& .MuiInputBase-input': {
                                            height: "100% !important"
                                        },
                                    }}
                                    multiline
                                    autoComplete="off"
                                    placeholder="Enter Remarks"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.remarks} />
                            </Box>
                        </Box>
                        <Box flex="1">
                            <Box display="flex" flexDirection="row" marginBottom="10px" gap="12px">
                                <Box display="flex" flexDirection="column" width="100%">
                                    <label htmlFor="unit" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Unit</label>
                                    {units && units.length > 0 && <Autocomplete
                                        fullWidth
                                        freeSolo
                                        disabled={editDisabled}
                                        sx={{ borderRadius: "10px", height: "40px" }}
                                        value={((formik.values.customUnit) ? formik.values.customUnit : ((units.some((unt) => (unt.id === formik.values.unitId))) ? units.find((unt) => (unt.id === formik.values.unitId))?.description : '')) || null}
                                        size="small"
                                        onChange={(event: React.SyntheticEvent, newValue: string | null) => {
                                            if (newValue) {
                                                if (units) {
                                                    const unit = units.find((unt) => (unt.description === newValue));
                                                    if (unit) {
                                                        formik.setFieldValue('unitId', unit.id);
                                                        formik.setFieldValue('customUnit', '');
                                                    } else {
                                                        formik.setFieldValue('customUnit', newValue);
                                                        formik.setFieldValue('unitId', '');
                                                    }
                                                }
                                            } else {
                                                formik.setFieldValue('unitId', '');
                                                formik.setFieldValue('customUnit', '');
                                            }
                                        }}
                                        onInputChange={(event: React.SyntheticEvent, newValue: string | null) => {
                                            if (newValue) {
                                                if (units) {
                                                    const unit = units.find((unt) => (unt.description === newValue));
                                                    if (unit) {
                                                        formik.setFieldValue('customUnit', '');
                                                        formik.setFieldValue('unitId', unit.id);
                                                    } else {
                                                        formik.setFieldValue('customUnit', newValue);
                                                        formik.setFieldValue('unitId', '');
                                                    }
                                                }
                                            } else {
                                                formik.setFieldValue('unitId', '');
                                                formik.setFieldValue('customUnit', '');
                                            }
                                        }}
                                        options={units.map((unit) => unit.description)}
                                        renderInput={(params) => <GridTextField name="unit" sx={{ height: "100%" }} inputRef={unitInputRef} placeholder='Select/Enter Unit' {...params} />}
                                        renderOption={(props, option, { inputValue }) => {
                                            const matches = match(option, inputValue, {
                                                insideWords: true,
                                            });
                                            const parts = parse(option, matches);

                                            return (
                                                <li {...props}>
                                                    <div>
                                                        {parts.map((part, index) => (
                                                            <span
                                                                key={index}
                                                                style={{
                                                                    fontWeight: part.highlight ? 700 : 400,
                                                                }}
                                                            >
                                                                {part.text}
                                                            </span>
                                                        ))}
                                                    </div>
                                                </li>
                                            );
                                        }}
                                    />
                                    }
                                </Box>
                                <Box display="flex" flexDirection="column" width="100%">
                                    <label htmlFor="quantity" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Quantity</label>
                                    <OutlinedInput
                                        sx={{
                                            height: "40px"
                                        }}
                                        size="small"
                                        id="quantity"
                                        type="number"
                                        autoComplete="off"
                                        disabled={editDisabled}
                                        name="quantity"
                                        placeholder="Quantity"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.quantity} />
                                </Box>
                                <Box display="flex" flexDirection="column" width="100%">
                                    <label htmlFor="estimatedQuantity" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Estimated Quantity</label>
                                    <OutlinedInput
                                        sx={{
                                            height: "40px",
                                            paddingRight: "0px"
                                        }}
                                        size="small"
                                        id="estimatedQuantity"
                                        type="number"
                                        autoComplete="off"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleOpenCalculateModal} color="primary" sx={{ padding: "0px", paddingRight: "5px" }}>
                                                    <CalculateIcon />
                                                </IconButton>
                                            </InputAdornment>}
                                        name="estimatedQuantity"
                                        disabled={editEstQuantityDisabled}
                                        placeholder="Estimated Quantity"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.estimatedQuantity} />
                                </Box>
                                <Box display="flex" flexDirection="column" width="100%">
                                    <label htmlFor="markup" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Markup %</label>
                                    <OutlinedInput
                                        sx={{ height: "40px" }}
                                        size="small"
                                        autoComplete="off"
                                        id="markup"
                                        type="number"
                                        disabled={editDisabled}
                                        name="markup"
                                        placeholder="Markup"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.markup} />
                                </Box>
                                <Box display="flex" flexDirection="column" width="100%">
                                    <label htmlFor="preliminaryRate" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Preliminary Rate</label>
                                    <OutlinedInput
                                        sx={{ height: "40px" }}
                                        size="small"
                                        id="preliminaryRate"
                                        type="number"
                                        autoComplete="off"
                                        disabled={editDisabled}
                                        name="preliminaryRate"
                                        placeholder="Preliminary Rate"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.preliminaryRate} />
                                </Box>
                                <Box display="flex" flexDirection="column" width="100%">
                                    <label htmlFor="status" color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Status</label>

                                    <Select
                                        sx={{ height: "40px" }}
                                        size="small"
                                        onChange={(event) => {
                                            formik.setFieldValue("status", event.target.value);
                                        }}
                                        labelId="status-label"
                                        onBlur={formik.handleBlur}
                                        id="status"
                                        name="status"
                                        disabled={editDisabled}
                                        value={formik.values.status}
                                        label="Status"
                                    >
                                        <MenuItem value={"Not Priced"}>Not Priced</MenuItem>
                                        <MenuItem value={"In Progress"}>In Progress</MenuItem>
                                        <MenuItem value={"In Review"}>In Review</MenuItem>
                                        <MenuItem value={"Done"}>Done</MenuItem>
                                    </Select>
                                </Box>
                            </Box>
                            <Box display="flex" flexDirection="row" gap="12px">
                                <Box flex="1" flexDirection="column" display="flex" padding="3px 6px" border={`1px solid ${colors.primary[300]}`} borderRadius="5px" gap="24px">
                                    <Box display="flex" gap="10px">
                                        <Box padding="6px 0px" flex="1">
                                            <Box color={colors.gray[100]} fontSize="11px" fontStyle="normal" lineHeight="160%" fontWeight="600">Unit Cost:</Box>
                                            <Box display="flex" justifyContent="flex-start">{unitCost}</Box>
                                            <hr />
                                        </Box>
                                        <Box padding="6px 0px" flex="1">
                                            <Box color={colors.gray[100]} fontSize="11px" fontStyle="normal" lineHeight="160%" fontWeight="600">Cost:</Box>
                                            <Box display="flex" justifyContent="flex-start">{cost}</Box>
                                            <hr />
                                        </Box>
                                        <Box padding="6px 0px" flex="1">
                                            <Box color={colors.gray[100]} fontSize="11px" fontStyle="normal" lineHeight="160%" fontWeight="600">Est Cost:</Box>
                                            <Box display="flex" justifyContent="flex-start">{estCost}</Box>
                                            <hr />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box flex="1" flexDirection="column" display="flex" padding="3px 6px" border={`1px solid ${colors.primary[300]}`} borderRadius="5px" gap="24px">
                                    <Box display="flex" gap="10px">
                                        <Box padding="6px 0px" flex="1">
                                            <Box color={colors.gray[100]} fontSize="11px" fontStyle="normal" lineHeight="160%" fontWeight="600">Unit Price:</Box>
                                            <Box display="flex" justifyContent="flex-start">{unitPrice}</Box>
                                            <hr />
                                        </Box>
                                        <Box padding="6px 0px" flex="1">
                                            <Box color={colors.gray[100]} fontSize="11px" fontStyle="normal" lineHeight="160%" fontWeight="600">Price:</Box>
                                            <Box display="flex" justifyContent="flex-start">{price}</Box>
                                            <hr />
                                        </Box>
                                        <Box padding="6px 0px" flex="1">
                                            <Box color={colors.gray[100]} fontSize="11px" fontStyle="normal" lineHeight="160%" fontWeight="600">Est Price:</Box>
                                            <Box display="flex" justifyContent="flex-start">{estPrice}</Box>
                                            <hr />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box borderBottom={`1px solid ${colors.gray[800]}`} position="relative">
                    <Box
                        sx={{ backgroundColor: 'white', cursor: 'pointer' }}
                        alignItems="center"
                        display="flex"
                        position="absolute"
                        left="50%"
                        bottom="-12px"
                        height="24px"
                        border={`1px solid ${colors.gray[800]}`}
                        padding="4px 8px"
                        fontSize="11px"
                        fontWeight="600"
                        fontStyle="normal"
                        lineHeight="145%"
                        onClick={showHideForm}
                        color={`${colors.gray[500]}`}
                        borderRadius="7px">{(showForm) ? <>Hide Info <ExpandLessIcon /></> : <>Show Info <ExpandMoreIcon /></>} </Box>
                </Box>
            </form>
            <Box height={(showForm) ? `calc(100vh - 325px)` : `calc(100vh - 150px)`}>
                <ItemDetails previousItem={nav.previous} estimateId={estimateId} item={item} currentForm={formik.values} onAmountUpdate={onAmountUpdate} />
            </Box>
            <Popover
                anchorEl={anchorEl}
                open={openCategoryPopover}
                onClose={handleCloseCategoryPopover}
                sx={{
                    zIndex: 900,
                    background: "transparent",
                    '& .MuiPaper-root': {
                        background: "transparent",
                        boxShadow: "none",
                    }
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box width="320px" height="auto" boxShadow={`0px 4px 12px 0px rgba(6, 22, 55, 0.12), 0px 2px 8px 0px rgba(6, 22, 55, 0.08)`} borderRadius="14px" border={`1px solid ${colors.primary[300]}`} sx={{ backgroundColor: "white" }}>
                    <CategoryEdit
                        save={saveCategories}
                        close={handleCloseCategoryPopover}
                        category1Id={item.category1Id}
                        category2Id={item.category2Id}
                        category3Id={item.category3Id}
                        category4Id={item.category4Id}
                        estimateId={estimateId}
                        noOfLevels={estimate?.noOfLevels} />
                </Box>
            </Popover>
            <Modal
                open={openCalculateModal}
                onClose={handleCloseCalculateModal}
                aria-labelledby="edit-items-title"
                aria-describedby="edit-items-description"
            >
                <Box className="calculator-modal">
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignContent="center"
                        alignItems="center"
                        marginBottom="10px"
                        borderBottom={`1px solid ${colors?.gray[800]}`}>
                        <Box padding="10px">
                            {colors && <Typography variant="h4" color="primary">Calculate Quantity</Typography>}
                        </Box>
                        <Box>
                            <IconButton aria-label="edit" color="primary" onClick={handleCloseCalculateModal}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                    <QuantityCalculator directItem={item} type="item" estimateId={estimateId} directIndirectItemId={itemId} close={handleCloseCalculateModal} />
                </Box>
            </Modal>
        </>
    }
    </>
}