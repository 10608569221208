import { Alert, Box, Link, useTheme } from "@mui/material";
import { AdvancedFilterModel, CellClickedEvent, CellEditingStartedEvent, CellEditingStoppedEvent, CellKeyDownEvent, ColDef, ExcelExportParams, FilterChangedEvent, GetRowIdParams, GridReadyEvent, ICellRendererParams, PaginationChangedEvent, ProcessCellForExportParams, RowNode, SelectionChangedEvent, SortChangedEvent, SuppressKeyboardEventParams } from "ag-grid-community";
import { IRowNode, IServerSideDatasource, IServerSideGetRowsParams } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import { useConfirm } from "material-ui-confirm";
import { ServerError } from "Models/error-info";
import { Entity, EstimateCategory } from "Models/estimate";
import { BaseItem, ItemView } from "Models/item";
import { useState, useMemo, useRef, MutableRefObject, useEffect, useCallback, forwardRef, memo, useImperativeHandle } from "react";
import { useGetEstimateQuery, useGetPagedItemsForEstimateMutation } from "State/Services/estimate";
import { useCreateItemMutation, useDeleteItemMutation, useUpdateItemMutation } from "State/Services/item";
import { useGetUserDetailsQuery } from "State/Services/user";
import { tokens } from "theme";
import { v4 as uuidv4 } from 'uuid';
import ItemIdEditCellRenderer from "./ItemIdEditCellRenderer";
import ItemEditCellRenderer from "./ItemEditCellRenderer";
import UnitEditCellRenderer from "./UnitEditCellRenderer";
import UnitCellRenderer from "./UnitCellRenderer";
import ItemNumberEditCellRenderer from "./ItemNumberEditCellRenderer";
import { rounder } from "Helpers/rounder";
import { useGetSettingsQuery } from "State/Services/settings";
import CodeEditCellRenderer from "./CodeEditCellRenderer";
import SaveCancelItemCellRenderer from "./SaveCancelItemCellRenderer";
import { ItemFilterSettings } from "Components/Items/ItemFilters";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import ItemCategoryCellRenderer from "./ItemCategoryCellRenderer";
import ItemCategoryEditCellRenderer from "./ItemCategoryEditCellRenderer";
import { useGetCategoriesQuery } from "State/Services/category";
import { flushSync } from "react-dom";
import AssignedToCellRenderer from "./AssignedToCellRenderer";
import { Errors } from "Models/errors";
import {
    Link as RouterLink,
} from 'react-router-dom';
import { useGetUnitsQuery } from "State/Services/unit";
export interface ItemFlatProps {
    estimateId: string | undefined;
    onSelected: (rows: Array<ItemView>) => void;
    onEdit: (editing: boolean) => void;
}

export default memo(forwardRef((props: ItemFlatProps, ref) => {
    const theme = useTheme();
    const confirm = useConfirm();
    const [colors] = useState<any>(tokens(theme.palette.mode));
    const { data: user } = useGetUserDetailsQuery();
    const gridRef = useRef<AgGridReact<ItemView>>(null);
    const [deleteItem] = useDeleteItemMutation();
    const [createItem] = useCreateItemMutation();
    const [updateItem] = useUpdateItemMutation();
    const [currentEditing, setCurrentEditing] = useState<{ node: IRowNode<ItemView> | undefined, column?: string }>();
    const [isCancelClicked, setIsCancelClicked] = useState(false);
    const editDisabledRef = useRef<boolean>();
    const deleteDisabledRef = useRef<boolean>();
    const [unitRef, setUnitRef] = useState<MutableRefObject<any> | undefined>();
    const [errors, setErrors] = useState<Array<{ field: string, error: string }>>([]);
    const [getPagedItems] = useGetPagedItemsForEstimateMutation();
    const [currentItems, setCurrentItems] = useState<Array<BaseItem>>([]);
    const [itemCodeRef, setItemCodeRef] = useState<MutableRefObject<any> | undefined>();
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [isReady, setIsReady] = useState(false);
    const [hasViewAccess, setHasViewAccess] = useState(false);
    const { data: storedCategories } = useGetCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId });
    const [autoRowHeightEnabled, setAutoRowHeightEnabled] = useState(false);
    const [category1Ref, setCategory1Ref] = useState<MutableRefObject<any> | undefined>();
    const [category2Ref, setCategory2Ref] = useState<MutableRefObject<any> | undefined>();
    const [category3Ref, setCategory3Ref] = useState<MutableRefObject<any> | undefined>();
    const [category4Ref, setCategory4Ref] = useState<MutableRefObject<any> | undefined>();
    const [pageError, setPageError] = useState<string | undefined>();
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });

    /* Component Editor Lifecycle methods */
    useImperativeHandle(ref, () => {
        return {
            setAutoheight(autoHeight: boolean) {
                switchRowHeight(autoHeight);
                setAutoRowHeightEnabled(autoHeight);
            },
            addNewRow() {
                addRow();
            },
            setFilterChanged(settings: ItemFilterSettings) {
                filterChanged(settings);
            },
            reloadAndClearFilters() {
                gridRef.current!.api.onFilterChanged();
                gridRef.current!.api.deselectAll();
            },
            exportAsExcel() {
                gridRef.current!.api.exportDataAsExcel();
            }
        };
    });

    const getUnitDescription = useCallback((unitId: string) => {
        if (units && units.length > 0) {
            const unit = units?.find((u) => (u.id === unitId));
            return unit?.description;
        }
        return '';
    }, [units])
    const getUnitDescriptionRef = useRef<(unitId: string) => string | undefined>();
    getUnitDescriptionRef.current = getUnitDescription;

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
                setHasViewAccess(true);
                deleteDisabledRef.current = true;
            } else {
                const hasViewAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [501]
                });
                setHasViewAccess(hasViewAccess);
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
                const hasDeleteAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Direct,
                    requiredPermissions: [503]
                });
                deleteDisabledRef.current = !hasDeleteAccess;
            }
        } else {
            editDisabledRef.current = true;
            setHasViewAccess(true);
            deleteDisabledRef.current = true;
        }
        setIsReady(true);

    }, [estimate, estimate?.EstimateUserRole, user])

    useEffect(() => {
        if (errors.length > 0) {
            errors.forEach((errorDetails) => {
                switch (errorDetails.field) {
                    case 'name':
                        const nameInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['name']
                        });
                        if (nameInstances && nameInstances.length > 0 && nameInstances[0] && typeof (nameInstances[0] as any).setError === 'function') {
                            (nameInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    case 'displayId':
                        const idInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['displayId']
                        });
                        if (idInstances && idInstances.length > 0 && idInstances[0] && typeof (idInstances[0] as any).setError === 'function') {
                            (idInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    case 'unit':
                        const unitInstances = gridRef.current!.api.getCellEditorInstances({
                            columns: ['unit']
                        });
                        if (unitInstances && unitInstances.length > 0 && unitInstances[0] && typeof (unitInstances[0] as any).setError === 'function') {
                            (unitInstances[0] as any).setError(errorDetails.error)
                        }
                        break;
                    default:
                        break;
                }
            });
        }
    }, [errors])

    const addRow = useCallback(async () => {
        if (currentEditing) {
            try {
                await confirm({ description: "You have unsaved changes. If you proceed, your current edits will be lost. Do you want to continue?" });
                gridRef.current!.api.stopEditing(true);
                if (currentEditing && currentEditing.node?.data) {
                    if (currentEditing.node.data.id) {
                        const itemBeforeEditing = currentItems?.find((item) => (item.id === currentEditing?.node?.data?.id));
                        if (itemBeforeEditing) {
                            gridRef.current!.api.applyServerSideTransaction({ update: [{ ...itemBeforeEditing }] });
                        } else {
                            gridRef.current!.api.applyServerSideTransaction({ remove: [currentEditing.node.data] });
                        }
                    } else {
                        gridRef.current!.api.applyServerSideTransaction({ remove: [currentEditing.node.data] });
                    }
                }
            } catch (error) {
                return;
            }
        }
        const filters = gridRef.current!.api.getFilterModel();
        let category1: EstimateCategory | undefined;
        let category2: EstimateCategory | undefined;
        let category3: EstimateCategory | undefined;
        let category4: EstimateCategory | undefined;
        if (filters?.category1 && filters.category1?.filter) {
            category1 = storedCategories?.find((cat) => (cat.category === filters.category1.filter && cat.level === 1));
        }
        if (filters?.category2 && filters.category2?.filter) {
            category2 = storedCategories?.find((cat) => (cat.category === filters.category2.filter && cat.level === 2));
        }
        if (filters?.category3 && filters.category3?.filter) {
            category3 = storedCategories?.find((cat) => (cat.category === filters.category3.filter && cat.level === 3));
        }
        if (filters?.category4 && filters.category4?.filter) {
            category4 = storedCategories?.find((cat) => (cat.category === filters.category4.filter && cat.level === 4));
        }
        const currentPage = gridRef.current!.api.paginationGetCurrentPage();
        const currentPageSize = gridRef.current!.api.paginationGetPageSize();
        const rowDataItem: ItemView = {
            id: uuidv4(),
            category1: category1?.category,
            category1DisplayId: category1?.displayId,
            category1Id: category1?.id,
            category2: category2?.category,
            category2Id: category2?.id,
            category2DisplayId: category2?.displayId,
            category3: category3?.category,
            category3DisplayId: category3?.displayId,
            category3Id: category3?.id,
            category4: category4?.category,
            category4DisplayId: category4?.displayId,
            category4Id: category4?.id,
            isNew: true
        };
        let itemRow = gridRef.current!.api.applyServerSideTransaction({
            add: [rowDataItem],
            addIndex: currentPage * currentPageSize
        });
        if (itemRow && itemRow.add && itemRow.add.length > 0 && itemRow.add[0] && typeof itemRow.add[0].rowIndex === 'number' && itemRow.add[0].id) {
            if (itemRow.add[0].id) {
                queueMicrotask(() => flushSync(() => {
                    if (itemRow && itemRow.add && itemRow.add[0].id) {
                        const node = gridRef.current!.api.getRowNode(itemRow.add[0].id);
                        if (node) {
                            gridRef.current!.api.ensureNodeVisible(node, "middle");
                            setCurrentEditing({ node: node, column: 'displayId' });
                        }
                    }
                }))
            }
        }
    }, [confirm, currentEditing, currentItems, storedCategories])

    const getItems = useCallback(async (params: IServerSideGetRowsParams<ItemView>) => {
        try {
            if (user) {
                const response = await getPagedItems({
                    companyId: user.companyId,
                    estimateId: props.estimateId,
                    orgId: user.organizationId,
                    body: {
                        ...params.request
                    }
                }).unwrap();
                if (params?.request) {
                    const rows = new Array<ItemView>();
                    setCurrentItems(response.results);
                    response.results.forEach((item) => {
                        rows.push({
                            category1: item.category1,
                            category2: item.category2,
                            category3: item.category3,
                            category4: item.category4,
                            category1Id: item.category1Id,
                            category2Id: item.category2Id,
                            category3Id: item.category3Id,
                            category4Id: item.category4Id,
                            category1DisplayId: item.category1DisplayId,
                            category2DisplayId: item.category2DisplayId,
                            category3DisplayId: item.category3DisplayId,
                            category4DisplayId: item.category4DisplayId,
                            itemBoqId: item.itemBoqId,
                            page: item.page,
                            amount: item.amount,
                            description: item.description,
                            displayId: item.displayId,
                            quantity: item.quantity,
                            estimatedQuantity: item.estimatedQuantity,
                            rate: item.rate,
                            id: item.id,
                            assignedTo: item.assignedTo,
                            status: item.status,
                            unit: {
                                unitDescription: item.customUnit,
                                unitId: item.unitId
                            },
                            isNew: false,
                            code: item.code
                        });
                    });

                    params.success({
                        rowData: rows,
                        rowCount: response.totalCount
                    });
                }
            }
        } catch (error) { }

    }, [getPagedItems, props.estimateId, user]);
    const getItemsRef = useRef<(params: IServerSideGetRowsParams<ItemView>) => void>(() => (null));
    getItemsRef.current = getItems;

    const createServerSideDatasource = useCallback((): IServerSideDatasource => {
        return {
            getRows: (params) => getItemsRef.current(params),
        };
    }, []);

    const onGridReady = useCallback((params: GridReadyEvent) => {
        const datasource = createServerSideDatasource();
        params.api!.setGridOption("serverSideDatasource", datasource);
    }, [createServerSideDatasource]);

    const getRowId = useCallback(function (params: GetRowIdParams<ItemView>) {
        if (params.data.id) {
            return params.data.id;
        }
        return '';
    }, []);

    const saveRow = useCallback((nodeToSave: IRowNode<ItemView>, toEditAfterSave?: { nodeToEditAfterSave?: IRowNode<ItemView>, column?: string }) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                setPageError(undefined);
                gridRef.current!.api.stopEditing();
                if (!nodeToSave.data?.displayId) {
                    const error: ServerError = { data: { displayId: 'ID is required' } };
                    throw error;
                }
                if (!nodeToSave.data.description) {
                    const error: ServerError = { data: { description: 'Description is required' } };
                    throw error;
                }

                if (nodeToSave.data) {
                    const cat1 = storedCategories?.find((c) => (c.displayId === nodeToSave.data?.category1DisplayId && c.level === 1));
                    const cat2 = storedCategories?.find((c) => (c.displayId === nodeToSave.data?.category2DisplayId && c.level === 2));
                    const cat3 = storedCategories?.find((c) => (c.displayId === nodeToSave.data?.category3DisplayId && c.level === 3));
                    const cat4 = storedCategories?.find((c) => (c.displayId === nodeToSave.data?.category4DisplayId && c.level === 4));
                    if (!nodeToSave.data?.isNew && user && props.estimateId) {
                        await updateItem({
                            companyId: user.companyId,
                            estimateId: props.estimateId,
                            itemId: nodeToSave.data?.id,
                            orgId: user.organizationId,
                            clearCosts: false,
                            body: {
                                description: nodeToSave.data.description,
                                displayId: nodeToSave.data.displayId,
                                id: nodeToSave.data.id,
                                itemBoqId: nodeToSave.data.itemBoqId,
                                page: nodeToSave.data.page,
                                quantity: nodeToSave.data.quantity,
                                category1: cat1?.category,
                                category1Id: cat1?.id,
                                category2: cat2?.category,
                                category2Id: cat2?.id,
                                category3: cat3?.category,
                                category3Id: cat3?.id,
                                category4: cat4?.category,
                                category4Id: cat4?.id,
                                unitId: nodeToSave.data.unit?.unitId,
                                customUnit: (!nodeToSave.data.unit?.unitId) ? nodeToSave.data.unit?.unitDescription : undefined,
                                estimatedQuantity: nodeToSave.data.estimatedQuantity,
                                code: nodeToSave.data.code
                            }
                        }).unwrap();
                    } else {
                        await createItem({
                            companyId: user?.companyId,
                            estimateId: props.estimateId,
                            orgId: user?.organizationId,
                            body: {
                                id: nodeToSave.data.id,
                                description: nodeToSave.data.description,
                                itemBoqId: nodeToSave.data.itemBoqId,
                                page: nodeToSave.data.page,
                                displayId: nodeToSave.data.displayId,
                                quantity: nodeToSave.data.quantity,
                                category1: cat1?.category,
                                category1Id: cat1?.id,
                                category2: cat2?.category,
                                category2Id: cat2?.id,
                                category3: cat3?.category,
                                category3Id: cat3?.id,
                                category4: cat4?.category,
                                category4Id: cat4?.id,
                                unitId: nodeToSave.data.unit?.unitId,
                                estimatedQuantity: nodeToSave.data.estimatedQuantity,
                                customUnit: (!nodeToSave.data.unit?.unitId) ? nodeToSave.data.unit?.unitDescription : undefined,
                                code: nodeToSave.data.code
                            }
                        }).unwrap();
                    }
                    nodeToSave.updateData({ ...nodeToSave.data, amount: (nodeToSave?.data?.rate ?? 0) * (nodeToSave?.data?.quantity ?? 0) });
                    if (nodeToSave.rowPinned) {
                        gridRef.current!.api.applyServerSideTransaction({ add: [nodeToSave.data] });
                        nodeToSave.updateData({
                            description: '',
                            itemBoqId: '',
                            page: '',
                            displayId: undefined,
                            id: uuidv4(),
                            quantity: undefined,
                            estimatedQuantity: undefined,
                            rate: undefined,
                            category1: undefined,
                            category1Id: undefined,
                            category2: undefined,
                            category2Id: undefined,
                            category3: undefined,
                            category3Id: undefined,
                            category4: undefined,
                            category4Id: undefined,
                            code: undefined,
                            unit: {
                                unitDescription: '',
                                unitId: ''
                            },
                            isNew: true
                        });
                    }
                }

                resolve();
                if (toEditAfterSave) {
                    setCurrentEditing({ node: toEditAfterSave?.nodeToEditAfterSave, column: toEditAfterSave.column });
                } else {
                    setCurrentEditing(undefined);
                }
            } catch (error: any) {
                if (error.status === 500) {
                    setPageError(Errors.generic);
                    return;
                }
                if (typeof nodeToSave.rowIndex === 'number') {
                    gridRef.current!.api.startEditingCell({
                        rowIndex: nodeToSave.rowIndex,
                        colKey: 'displayId',
                    });
                }
                if (error.data.displayId) {
                    setErrors([{ field: 'displayId', error: error.data.displayId }]);
                } else if (error.data.name) {
                    setErrors([{ field: 'name', error: error.data.name }]);
                }
                else if (error?.data?.unitId) {
                    setErrors([{ field: 'unit', error: error?.data?.unitId }]);
                } else if (error.data.page) {
                    setPageError(error.data.page);
                }

                reject();
            }
        });
    }, [storedCategories, user, props.estimateId, updateItem, createItem])
    const saveEditingRef = useRef<any>();
    saveEditingRef.current = saveRow;

    const onSelectionChanged = useCallback((event: SelectionChangedEvent<ItemView>) => {
        const selectedRows = new Array<ItemView>();
        event.api.forEachNode((node: any) => {
            if (node.data && node.isSelected()) {
                selectedRows.push(node.data);
            }
        });
        props.onSelected(selectedRows);
    }, [props])

    const cancelEditing = useCallback((node: IRowNode<ItemView>) => {
        if (node && node.data) {
            setIsCancelClicked(true);
            const item = currentItems?.find((c) => (c.id === node.data?.id));
            if (item) {
                node.updateData({
                    ...item,
                    unit: {
                        unitDescription: item.customUnit,
                        unitId: item.unitId
                    },
                });
            }
            gridRef.current!.api.stopEditing(true);
            if (node.data.isNew) {
                gridRef.current!.api.applyServerSideTransaction({ remove: [node.data] });
            }

            setCurrentEditing(undefined);
        }
    }, [currentItems])
    const cancelEditingRef = useRef<any>();
    cancelEditingRef.current = cancelEditing;

    const deleteRow = useCallback((params: RowNode<ItemView>) => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                if (params.rowPinned) {
                    resolve();
                    return;
                }
                await confirm({ description: `Are you sure you want to delete this item?`, title: 'Delete confirmation' });
                if (params.data?.id) {
                    await deleteItem({
                        companyId: user?.companyId,
                        estimateId: estimate?.id,
                        itemId: params.data.id,
                        orgId: user?.organizationId
                    });
                    gridRef.current!.api.applyServerSideTransaction({ remove: [params.data] });
                } else {
                    if (params.data) {
                        gridRef.current!.api.applyServerSideTransaction({ remove: [params.data] });
                    }
                }
                resolve();
            } catch (error) {
                reject('Cancelled by user.');
            }
        });
    }, [confirm, estimate, deleteItem, user?.companyId, user?.organizationId])

    const saveOnEnter = useCallback((params: SuppressKeyboardEventParams<ItemView>) => {
        if (params.event.key === 'Enter' && params.node) {
            if (params.event.target === unitRef?.current
                || params.event.target === category1Ref?.current
                || params.event.target === category2Ref?.current
                || params.event.target === category3Ref?.current
                || params.event.target === category4Ref?.current
                || params.event.target === itemCodeRef?.current) {
                return true;
            }
            params.event.stopPropagation();
            const save = async () => {
                try {
                    await saveRow(params.node);
                } catch (error) {

                }
            }
            save();
        }
        return true;
    }, [category1Ref, category2Ref, category3Ref, category4Ref, itemCodeRef, saveRow, unitRef])
    const saveOnEnterRef = useRef<any>();
    saveOnEnterRef.current = saveOnEnter;

    const switchRowHeight = useCallback((autoHeight: boolean) => {
        if (gridRef.current && gridRef.current!.api) {
            const colDefs = gridRef.current!.api.getColumnDefs();
            const descColDef = colDefs?.find((col) => (col.headerName === 'Description')) as any;
            if (descColDef) {
                descColDef['autoHeight'] = autoHeight;
                descColDef['wrapText'] = autoHeight;
            }
            gridRef.current!.api.setGridOption('columnDefs', colDefs);
        }
    }, [])

    const onCellKeyDown = useCallback((e: CellKeyDownEvent) => {
        if (!e.event) {
            return;
        }
        const keyboardEvent = e.event as unknown as KeyboardEvent;
        const key = keyboardEvent.key;
        if (key.length && key === 'Escape') {
            cancelEditing(e.node);
            setIsCancelClicked(false);
        }
    }, [cancelEditing]);

    const getParams: () => ExcelExportParams = () => ({
        processCellCallback(params: ProcessCellForExportParams): string {
            if (params.column.getColId() === 'unit' && ('unitId' in params.value) && getUnitDescriptionRef?.current) {
                const unitDesc = getUnitDescriptionRef.current(params.value.unitId);
                return unitDesc ?? '';
            }
            return params.value;
        },
    });

    const getBaseDefs = useCallback((): Array<ColDef<ItemView>> => {
        return [
            {
                field: 'id',
                hide: true,
                filter: 'agTextColumnFilter',
                suppressColumnsToolPanel: true
            },
            {
                field: 'assignedTo',
                hide: true,
                suppressColumnsToolPanel: true
            },
            {
                field: 'displayId',
                headerName: 'ID',
                hide: false,
                filter: 'agTextColumnFilter',
                suppressKeyboardEvent: (params) => saveOnEnterRef.current(params),
                sort: 'asc',
                cellEditor: ItemIdEditCellRenderer,
                cellEditorParams: {
                    estimateId: props.estimateId
                },
                editable: (params) => {
                    return !editDisabledRef.current;
                },
                headerCheckboxSelection: true,
                checkboxSelection: true,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                width: 95,
                autoHeight: true,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` },
            },
            {
                field: 'page',
                width: 80,
                cellEditor: ItemEditCellRenderer,
                cellEditorParams: {
                    placeholder: 'Page'
                },
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                editable: (params) => {
                    return !editDisabledRef.current;
                },
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'itemBoqId',
                headerName: 'Item',
                width: 80,
                cellEditorParams: {
                    placeholder: 'Item'
                },
                cellEditor: ItemEditCellRenderer,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                editable: (params) => {
                    return !editDisabledRef.current;
                },
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'description',
                headerName: 'Description',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                filter: 'agTextColumnFilter',
                flex: 1,
                contextMenuItems: () => ([]),
                cellRenderer: (params: ICellRendererParams<ItemView>) => {
                    return <Link
                        to={`../item/${params.data?.id}`}
                        sx={{ textAlign: 'left' }}
                        component={RouterLink}
                        className="Ignore-Click"
                        variant="body2">{params.data?.description}</Link>
                },
                autoHeight: autoRowHeightEnabled,
                wrapText: autoRowHeightEnabled,
                cellEditor: ItemEditCellRenderer,
                cellEditorParams: {
                    placeholder: 'Description'
                },
                suppressKeyboardEvent: saveOnEnter,
                editable: (params) => {
                    return !editDisabledRef.current;
                },
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'unit',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                width: 75,
                cellEditor: UnitEditCellRenderer,
                cellRenderer: UnitCellRenderer,
                cellEditorParams: {
                    setRef: setUnitRef
                },
                headerName: 'Unit',
                editable: (params) => {
                    return !editDisabledRef.current;
                },
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'quantity',
                width: 125,
                aggFunc: 'sum',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellEditor: ItemNumberEditCellRenderer,
                cellEditorParams: {
                    placeholder: 'Quantity'
                },
                suppressKeyboardEvent: saveOnEnter,
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                cellDataType: "number",
                editable: (params) => {
                    return !editDisabledRef.current;
                },
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'estimatedQuantity',
                suppressKeyboardEvent: saveOnEnter,
                width: 125,
                headerName: 'Est. Quantity',
                aggFunc: 'sum',
                cellEditor: ItemNumberEditCellRenderer,
                cellEditorParams: {
                    placeholder: 'Est. Quantity'
                },
                valueFormatter: (params) => rounder(params.value, (settings?.quantityDecimals) ? settings?.quantityDecimals : 3),
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                cellDataType: "number",
                editable: (params) => {
                    return !editDisabledRef.current;
                },
                cellStyle: { textAlign: "right", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'code',
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                width: 85,
                cellEditor: CodeEditCellRenderer,
                cellEditorParams: {
                    setRef: setItemCodeRef,
                    estimateId: props.estimateId
                },
                headerName: 'Code',
                editable: (params) => {
                    return !editDisabledRef.current;
                },
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'rate',
                suppressKeyboardEvent: saveOnEnter,
                width: 150,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                sortable: false,
                cellStyle: {
                    textAlign: "right",
                    borderRight: `1px solid ${colors?.gray[1000]}`,
                    background: `repeating-linear-gradient(
                        -45deg,
                        transparent,
                        transparent 4px,
                        transparent 1px,
                        ${colors?.gray[1000]} 7px
                      ),
                      linear-gradient(
                        to bottom,
                        transparent,
                        transparent
                      )`
                }
            },
            {
                field: 'amount',
                suppressKeyboardEvent: saveOnEnter,
                width: 150,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                aggFunc: 'sum',
                sortable: false,
                valueFormatter: (params) => rounder(params.value, (estimate?.CompanyCurrency?.Currency?.minorUnit) ? estimate?.CompanyCurrency?.Currency?.minorUnit : 2),
                cellStyle: {
                    textAlign: "right",
                    borderRight: `1px solid ${colors?.gray[1000]}`,
                    background: `repeating-linear-gradient(
                        -45deg,
                        transparent,
                        transparent 4px,
                        transparent 1px,
                        ${colors?.gray[1000]} 7px
                      ),
                      linear-gradient(
                        to bottom,
                        transparent,
                        transparent
                      )`
                }
            },
            {
                field: 'assignedTo',
                width: 120,
                cellRenderer: AssignedToCellRenderer,
                menuTabs: ["filterMenuTab", "generalMenuTab"],
                suppressKeyboardEvent: saveOnEnter,
                cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
            },
            {
                field: 'actions',
                width: 80,
                headerName: 'Actions',
                menuTabs: [],
                cellStyle: { textAlign: "left", padding: "0px" } as any,
                cellRendererSelector: (params) => {
                    if (params.node.rowPinned && estimate && estimate.noOfLevels > 0) {
                        return undefined;
                    } else {
                        // rows that are not pinned don't use any cell renderer
                        return {
                            component: SaveCancelItemCellRenderer,
                            params: {
                                delete: deleteRow,
                                save: saveRow,
                                cancel: cancelEditing,
                                disabled: deleteDisabledRef.current
                            },
                        };
                    }
                },
            },
        ];
    }, [props.estimateId, colors?.gray, saveOnEnter, autoRowHeightEnabled, settings?.quantityDecimals, estimate, deleteRow, saveRow, cancelEditing])

    const [columnDefs, setColumnDefs] = useState<ColDef[]>(getBaseDefs());
    const defaultColDef = useMemo<ColDef>(() => {
        return {
            resizable: true
        };
    }, []);


    useEffect(() => {
        let colDefs = new Array<ColDef>();
        const baseDefs = getBaseDefs();
        switch (estimate?.noOfLevels.toString()) {
            case "0":
                colDefs = [
                    ...baseDefs
                ];
                break;
            case "1":
                baseDefs.splice(3, 0,
                    {
                        field: 'category1',
                        hide: true,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'category1DisplayId',
                        headerName: 'Cat. 1',
                        width: 85,
                        editable: true,
                        cellRenderer: ItemCategoryCellRenderer,
                        suppressKeyboardEvent: saveOnEnter,
                        cellRendererParams: {
                            estimateId: props.estimateId,
                            level: 1
                        },
                        cellEditor: ItemCategoryEditCellRenderer,
                        cellEditorParams: {
                            estimateId: props.estimateId,
                            placeholder: 'Category 1',
                            level: 1,
                            setRef: setCategory1Ref
                        },
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    }
                );
                colDefs = [
                    ...baseDefs,
                ];
                break;
            case "2":
                baseDefs.splice(3, 0,
                    {
                        field: 'category1',
                        hide: true,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'category1DisplayId',
                        width: 85,
                        editable: true,
                        cellRenderer: ItemCategoryCellRenderer,
                        suppressKeyboardEvent: saveOnEnter,
                        cellRendererParams: {
                            estimateId: props.estimateId,
                            level: 1,
                            placeholder: 'Cat. 1'
                        },
                        cellEditor: ItemCategoryEditCellRenderer,
                        cellEditorParams: {
                            estimateId: props.estimateId,
                            placeholder: 'Cat. 1',
                            level: 1,
                            setRef: setCategory1Ref
                        },
                        headerName: 'Cat. 1',
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    },
                    {
                        field: 'category2',
                        hide: true,
                        filter: 'agTextColumnFilter',
                    },
                    {
                        field: 'category2DisplayId',
                        width: 85,
                        editable: true,
                        cellRenderer: ItemCategoryCellRenderer,
                        suppressKeyboardEvent: saveOnEnter,
                        cellRendererParams: {
                            estimateId: props.estimateId,
                            level: 2,
                            placeholder: 'Cat. 2'
                        },
                        cellEditor: ItemCategoryEditCellRenderer,
                        cellEditorParams: {
                            estimateId: props.estimateId,
                            placeholder: 'Cat. 2',
                            level: 2,
                            setRef: setCategory2Ref
                        },
                        headerName: 'Cat. 2',
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    }
                );
                colDefs = [
                    ...baseDefs
                ];
                break;
            case "3":
                baseDefs.splice(3, 0,
                    {
                        field: 'category1',
                        hide: true,
                        filter: true,
                    },
                    {
                        field: 'category1DisplayId',
                        headerName: 'Cat. 1',
                        editable: true,
                        width: 85,
                        cellRenderer: ItemCategoryCellRenderer,
                        suppressKeyboardEvent: saveOnEnter,
                        cellRendererParams: {
                            estimateId: props.estimateId,
                            level: 1,
                            placeholder: 'Cat. 1',
                        },
                        cellEditor: ItemCategoryEditCellRenderer,
                        cellEditorParams: {
                            estimateId: props.estimateId,
                            placeholder: 'Cat. 1',
                            level: 1,
                            setRef: setCategory1Ref
                        },
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    },
                    {
                        field: 'category2',
                        hide: true,
                        filter: true,
                    },
                    {
                        field: 'category2DisplayId',
                        headerName: 'Cat. 2',
                        editable: true,
                        width: 85,
                        cellRenderer: ItemCategoryCellRenderer,
                        suppressKeyboardEvent: saveOnEnter,
                        cellRendererParams: {
                            estimateId: props.estimateId,
                            level: 2,
                            placeholder: 'Cat. 2'
                        },
                        cellEditor: ItemCategoryEditCellRenderer,
                        cellEditorParams: {
                            estimateId: props.estimateId,
                            placeholder: 'Cat. 2',
                            level: 2,
                            setRef: setCategory2Ref
                        },
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    },
                    {
                        field: 'category3',
                        hide: true,
                        filter: true,
                    },
                    {
                        field: 'category3DisplayId',
                        headerName: 'Cat. 3',
                        editable: true,
                        width: 85,
                        cellRenderer: ItemCategoryCellRenderer,
                        suppressKeyboardEvent: saveOnEnter,
                        cellRendererParams: {
                            estimateId: props.estimateId,
                            level: 3,
                            placeholder: 'Cat. 3'
                        },
                        cellEditor: ItemCategoryEditCellRenderer,
                        cellEditorParams: {
                            estimateId: props.estimateId,
                            placeholder: 'Cat. 3',
                            level: 3,
                            setRef: setCategory3Ref
                        },
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    });
                colDefs = [
                    ...baseDefs
                ];
                break;
            case "4":
                baseDefs.splice(3, 0,
                    {
                        field: 'category1',
                        hide: true,
                        filter: true,
                    },
                    {
                        field: 'category1DisplayId',
                        headerName: 'Cat. 1',
                        editable: true,
                        width: 85,
                        cellRenderer: ItemCategoryCellRenderer,
                        suppressKeyboardEvent: saveOnEnter,
                        cellRendererParams: {
                            estimateId: props.estimateId,
                            level: 1,
                            placeholder: 'Cat. 1'
                        },
                        cellEditor: ItemCategoryEditCellRenderer,
                        cellEditorParams: {
                            estimateId: props.estimateId,
                            placeholder: 'Cat. 1',
                            level: 1,
                            setRef: setCategory1Ref
                        },
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    },
                    {
                        field: 'category2',
                        hide: true,
                        filter: true,
                    },
                    {
                        field: 'category2DisplayId',
                        headerName: 'Cat. 2',
                        editable: true,
                        width: 85,
                        cellRenderer: ItemCategoryCellRenderer,
                        suppressKeyboardEvent: saveOnEnter,
                        cellRendererParams: {
                            estimateId: props.estimateId,
                            level: 2,
                            placeholder: 'Cat. 2'
                        },
                        cellEditor: ItemCategoryEditCellRenderer,
                        cellEditorParams: {
                            estimateId: props.estimateId,
                            placeholder: 'Cat. 2',
                            level: 2,
                            setRef: setCategory2Ref
                        },
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    },
                    {
                        field: 'category3',
                        hide: true,
                        filter: true,
                    },
                    {
                        field: 'category3DisplayId',
                        headerName: 'Cat. 3',
                        editable: true,
                        width: 85,
                        cellRenderer: ItemCategoryCellRenderer,
                        suppressKeyboardEvent: saveOnEnter,
                        cellRendererParams: {
                            estimateId: props.estimateId,
                            level: 3,
                            placeholder: 'Cat. 3'
                        },
                        cellEditor: ItemCategoryEditCellRenderer,
                        cellEditorParams: {
                            estimateId: props.estimateId,
                            placeholder: 'Cat. 3',
                            level: 3,
                            setRef: setCategory3Ref
                        },
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    },
                    {
                        field: 'category4',
                        hide: true,
                        filter: true,
                    },
                    {
                        field: 'category4DisplayId',
                        headerName: 'Cat. 4',
                        editable: true,
                        width: 85,
                        cellRenderer: ItemCategoryCellRenderer,
                        suppressKeyboardEvent: saveOnEnter,
                        cellRendererParams: {
                            estimateId: props.estimateId,
                            level: 4,
                            placeholder: 'Cat. 4'
                        },
                        cellEditor: ItemCategoryEditCellRenderer,
                        cellEditorParams: {
                            estimateId: props.estimateId,
                            placeholder: 'Cat. 4',
                            level: 4,
                            setRef: setCategory4Ref
                        },
                        cellStyle: { textAlign: "left", borderRight: `1px solid ${colors?.gray[800]}` }
                    });
                colDefs = [
                    ...baseDefs
                ];
                break;
            default:
                break;
        }
        setColumnDefs(colDefs);
    }, [colors?.gray, estimate?.noOfLevels, getBaseDefs, props.estimateId, saveOnEnter])

    const filterChanged = useCallback(async (filterSettings: ItemFilterSettings) => {
        let filters: AdvancedFilterModel = {} as any;
        if (filterSettings.category1) {
            const catFilter = {
                category1: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.category1.category
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.category2) {
            const catFilter = {
                category2: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.category2.category
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.category3) {
            const catFilter = {
                category3: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.category3.category
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.category4) {
            const catFilter = {
                category4: {
                    filterType: 'text',
                    type: 'equals',
                    filter: filterSettings.category4.category
                }
            };
            filters = { ...filters, ...catFilter };
        }
        if (filterSettings.description) {
            const descriptionFilter = {
                description: {
                    filterType: 'text',
                    type: 'contains',
                    filter: filterSettings.description
                }
            };
            filters = { ...filters, ...descriptionFilter };
        }
        if (filterSettings.idFrom) {
            const idFromFilter = {
                id: {
                    filterType: 'number',
                    type: '>=',
                    filter: filterSettings.idFrom
                }
            };
            filters = { ...filters, ...idFromFilter };
        }
        if (filterSettings.idTo) {
            const idToFilter = {
                displayId: {
                    filterType: 'number',
                    type: '<=',
                    filter: filterSettings.idTo
                }
            };
            filters = { ...filters, ...idToFilter };
        }
        gridRef.current!.api.setFilterModel(filters);
        gridRef.current!.api.onFilterChanged();
    }, [])

    const onRowEditingStarted = (event: CellEditingStartedEvent<ItemView>) => {
        setIsCancelClicked(false);
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
        setTimeout(() => {
            if (event.node.data?.unit?.unitDescription === 'PCT') {
                const factorInstances = gridRef.current!.api.getCellEditorInstances({
                    columns: [currentEditing?.column ?? 'displayId']
                });
                if (factorInstances && factorInstances.length > 0 && factorInstances[0] && typeof (factorInstances[0] as any).setFocusOnAdd === 'function') {
                    (factorInstances[0] as any).setFocusOnAdd();
                }
            } else {
                const quantityInstances = gridRef.current!.api.getCellEditorInstances({
                    columns: [currentEditing?.column ?? 'displayId']
                });
                if (quantityInstances && quantityInstances.length > 0 && quantityInstances[0] && typeof (quantityInstances[0] as any).setFocusOnAdd === 'function') {
                    (quantityInstances[0] as any).setFocusOnAdd();
                }
            }
        }, 100);
    }

    const onRowEditingStopped = useCallback(async (event: CellEditingStoppedEvent<ItemView>) => {
        event.api.refreshCells({
            columns: ["actions"],
            rowNodes: [event.node],
            force: true
        });
    }, [])

    const onCellClicked = useCallback(async (event: CellClickedEvent<ItemView>) => {
        try {
            if (isCancelClicked) {
                setIsCancelClicked(false);
                return;
            };

            if (editDisabledRef.current) {
                setCurrentEditing(undefined);
                return;
            };

            if (currentEditing?.node === event.node) {
                return;
            }
            if (event.column.getColId() === 'actions') {
                return;
            }
            if (event.column.getColId() === 'description' && event?.event?.target instanceof HTMLElement) {
                const classList = event?.event?.target.classList;
                if (classList.contains('Ignore-Click')) {
                    return;
                }
            }
            if (!currentEditing?.node) {
                if (event.column.isCellEditable(event.node)) {
                    setCurrentEditing({ node: event.node, column: event.column.getColId() });
                } else {
                    setCurrentEditing({ node: event.node, column: undefined });
                }
            } else {
                await saveRow(currentEditing.node, { nodeToEditAfterSave: event.node, column: event.column.getColId() });
            }
        } catch (error) {

        }
    }, [currentEditing?.node, isCancelClicked, saveRow])

    useEffect(() => {
        if (currentEditing && typeof currentEditing.node?.rowIndex === 'number') {
            props.onEdit(true);
            gridRef.current!.api.startEditingCell({
                rowIndex: currentEditing.node.rowIndex,
                colKey: currentEditing.column ?? 'displayId',
            });
        } else {
            props.onEdit(false);
        }
    }, [currentEditing, props])

    const onPageChanged = useCallback((event: PaginationChangedEvent<ItemView>) => {
        if (currentEditing && currentEditing.node?.data && event.newPage) {
            cancelEditing(currentEditing.node);
        }
    }, [cancelEditing, currentEditing])

    const onPageFilterSortChanged = useCallback((event: SortChangedEvent<ItemView> | FilterChangedEvent<ItemView>) => {
        if (currentEditing && currentEditing.node?.data) {
            cancelEditing(currentEditing.node);
        }
    }, [cancelEditing, currentEditing])

    if (!isReady) {
        return <></>
    }

    if (!hasViewAccess) {
        return <Box m="10px">Unauthorized access.</Box>
    }

    return <Box height="100%">
        {pageError &&
            <Box marginBottom="10px">
                <Alert severity="error">{pageError}</Alert>
            </Box>
        }
        <Box
            className="ag-theme-alpine ag-theme-bidbow inner-col"
            width="100%" height={pageError ? 'calc(100% - 58px)' : '100%'}>
            <AgGridReact<ItemView>
                ref={gridRef}
                animateRows={false}
                rowSelection="multiple"
                editType={'fullRow'}
                suppressScrollOnNewData={true}
                onSelectionChanged={onSelectionChanged}
                rowDragManaged={true}
                suppressRowClickSelection={true}
                columnDefs={columnDefs}
                onGridReady={onGridReady}
                rowModelType={"serverSide"}
                getRowId={getRowId}
                pagination={true}
                paginationPageSize={15}
                defaultColDef={defaultColDef}
                cacheBlockSize={15}
                suppressClickEdit={true}
                paginationPageSizeSelector={[15, 25, 50, 100]}
                onRowEditingStarted={onRowEditingStarted}
                onRowEditingStopped={onRowEditingStopped}
                onCellKeyDown={onCellKeyDown}
                onCellClicked={onCellClicked}
                onPaginationChanged={onPageChanged}
                onSortChanged={onPageFilterSortChanged}
                onFilterChanged={onPageFilterSortChanged}
                defaultExcelExportParams={getParams()}
            />
        </Box>
    </Box>
})
)