import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'

// Define a type for the slice state
interface ItemState {
  isRowValid: boolean;
  errorMessages: Array<string>;
}

// Define the initial state using that type
const initialState: ItemState = {
    isRowValid: true,
    errorMessages: []
}

export const itemSlice = createSlice({
  name: 'item',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setItemRowValidState: (state, action: PayloadAction<boolean>) =>{
        state.isRowValid  = action.payload;
    },
    setItemRowValidStateWithError: (state, action: PayloadAction<{isValid: boolean, errors: Array<string>}>) =>{
        state.isRowValid  = action.payload.isValid;
        state.errorMessages = action.payload.errors;
    },
    clearItemRowValidState: (state, action: PayloadAction) =>{
        state.isRowValid  = true;
        state.errorMessages = [];
    }
  },
})

export const { setItemRowValidStateWithError } = itemSlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectItemState = (state: RootState) => state.items;

export default itemSlice.reducer