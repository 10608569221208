import { Box, IconButton, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import { useCallback, useState } from "react";
import { IndirectItemCategoryView } from "Models/estimate";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
export interface ICancelCellRendererProps extends ICellRendererParams<IndirectItemCategoryView> {
    delete: (node: IRowNode<IndirectItemCategoryView>) => Promise<void>;
    save: (node: IRowNode<IndirectItemCategoryView>) => Promise<void>;
    cancel: (node: IRowNode<IndirectItemCategoryView>) => void;
}

export default function SaveCancelCategoryCellRenderer(props: ICancelCellRendererProps) {
    const { node } = props;
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    let editingCells = props.api.getEditingCells();
    let isCurrentRowEditing = editingCells.some((cell) => {
        return cell.rowIndex === node.rowIndex;
    });

    const deleteRec = async () => {
        if (isCurrentRowEditing || props.node.rowPinned) {
            props.cancel(node);
        } else {
            setIsDeleting(true);
            await props.delete(node);
            setIsDeleting(false);
        }
    }

    const saveRec = async () => {
        try {
            setIsSaving(true);
            await props.save(node);
            setIsSaving(false);
        } catch (error) {
            setIsSaving(false);
        }
    }

    const shouldDisable = useCallback(() => {
        if (isCurrentRowEditing) {
            return { state: false, message: "Cancel" }
        }

        if (node.data?.hasItems) {
            return { state: true, message: 'Deleting a category associated with items is not permitted.' }
        }

        return { state: false, message: "Delete" }

    }, [isCurrentRowEditing, node.data?.hasItems])

    return (<>
        {!node.group &&
            <Box display="flex" alignItems="center" justifyContent="center">
                {isCurrentRowEditing && !isSaving &&
                    <IconButton aria-label="save" color="primary" onClick={saveRec}>
                        <SaveIcon />
                    </IconButton>
                }
                {isSaving &&
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress size="1rem" />
                    </Box>
                }
                {!isDeleting && (isCurrentRowEditing || !props.node.isRowPinned()) &&
                    <Tooltip title={shouldDisable().message}>
                        <span>
                            <IconButton aria-label="delete" onClick={deleteRec} disabled={shouldDisable().state}>
                                {isCurrentRowEditing && <CloseIcon />}
                                {!isCurrentRowEditing && <DeleteOutlineIcon />}
                            </IconButton>
                        </span>
                    </Tooltip>
                }
                {isDeleting &&
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress size="1rem" />
                    </Box>
                }
            </Box>}
    </>
    );
};
