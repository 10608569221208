import { Autocomplete, Box, Button, TextField, useTheme } from "@mui/material";
import { FilterField, FilteredResource } from "Models/resource";
import { ResourceCategory, ResourceSubCategoryOption } from "Models/resource-category";
import { useGetResourcesByFilterQuery, useGetResourcesPagedQuery } from "State/Services/resource";
import { useGetResourceCategoriesQuery } from "State/Services/resource-category";
import { useGetUserDetailsQuery } from "State/Services/user";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { tokens } from "theme";

export interface ResourceFilterProps {
    estimateId?: string;
    addResources: (resources: Array<FilteredResource & { searchText: string }>) => void;
    disabled: boolean;
}

export default function SupplierResourceFilter(props: ResourceFilterProps) {
    const theme = useTheme();
    const [colors, setColors] = useState<any>();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const { data: user } = useGetUserDetailsQuery();
    const [searchValue, setSearchValue] = useState<FilteredResource & { searchText: string }>({ description: '', searchText: '' });
    const [selectedCategory, setSelectedCategory] = useState<ResourceCategory>();
    const [selectedSubCategory, setSelectedSubCategory] = useState<ResourceSubCategoryOption>();
    const { data: storedResourceCategories } = useGetResourceCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId })
    const [resourceSubCategoryOptions, setResourceSubCategoryOptions] = useState<Array<ResourceSubCategoryOption>>([]);
    const { data: filteredResources } = useGetResourcesByFilterQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', filterText: searchValue?.description ?? '', field: FilterField.both, estimateId: (props && props.estimateId) ? props.estimateId : '', categoryId: selectedCategory?.id, subCategoryId: selectedSubCategory?.id }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId || (!searchValue?.description && !selectedCategory?.id && !selectedSubCategory?.id) });
    const { data: defaultResources } = useGetResourcesPagedQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: (props && props.estimateId) ? props.estimateId : '', skip: 0, take: 100 }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId });
    const [selectedResource, setSelectedResource] = useState<FilteredResource & { searchText: string }>();
    const [resources, setResources] = useState<Array<FilteredResource & { searchText: string }>>([]);
    const [open, setOpen] = useState<boolean>(false);
    const inputRef = useRef<any>();

    useEffect(() => {
        if (defaultResources && defaultResources.length > 0) {
            setResources(defaultResources.map((resource) => ({ ...resource, searchText: `${resource.displayId} - ${resource.description}` })));
        }
    }, [defaultResources])

    useEffect(() => {
        if (filteredResources === undefined) return;
        if (filteredResources.length > 0) {
            setResources(filteredResources.map((resource) => ({ ...resource, searchText: `${resource.displayId} - ${resource.description}` })));
        } else {
            setResources([]);
        }
    }, [filteredResources])

    const onCategoryChanged = useCallback((event: React.SyntheticEvent, newValue: ResourceCategory | null) => {
        if (newValue?.description) {
            setSelectedCategory(newValue);
            const options = new Array<ResourceSubCategoryOption>();
            if (storedResourceCategories && storedResourceCategories.length > 0) {
                const filtered = storedResourceCategories.find((cat) => (cat.id === newValue.id));
                if (filtered) {
                    filtered.resourceSubCategories?.forEach((cat) => {
                        options.push({
                            ...cat,
                            resourceCategoryName: filtered.description ?? ''
                        });
                    });
                }
                setResourceSubCategoryOptions(options);
            }
        } else {
            setSelectedCategory(undefined);
            setResourceSubCategoryOptions([]);
            if (defaultResources && defaultResources.length > 0) {
                setResources(defaultResources?.map((resource) => ({ ...resource, searchText: `${resource.displayId} - ${resource.description}` })));
            }
        }
        setSelectedSubCategory(undefined);
        setSearchValue({ description: '', searchText: '' });
    }, [defaultResources, storedResourceCategories])

    const onSubCategoryChanged = useCallback((event: React.SyntheticEvent, newValue: ResourceSubCategoryOption | null) => {
        setSearchValue({ description: '', searchText: '' });
        if (newValue) {
            setSelectedSubCategory(newValue);
        }else{
            setSelectedSubCategory(undefined);
            setSearchValue({ description: '', searchText: '' });
        }
    }, [])

    const onResourceChanged = useCallback(async (event: React.SyntheticEvent, newValue: FilteredResource & { searchText: string } | null) => {
        if (newValue) {
            setSelectedResource(newValue);
        } else {
            setSelectedResource(undefined);
        }
    }, [])

    const addResources = useCallback(() => {
        if (selectedResource) {
            props.addResources([selectedResource]);
        } else {
            props.addResources(resources)
        }
        setSearchValue({ description: '', searchText: '' });
    }, [props, resources, selectedResource])

    const onInputChange = useCallback(async (event: SyntheticEvent, newValue: string | null) => {
        if (newValue) {
            setSearchValue({ description: newValue, searchText: newValue });
        } else {
            setSearchValue({ description: '', searchText: '' });
            setSelectedResource(undefined);
            setOpen(false);
            if ((selectedCategory || selectedSubCategory) && filteredResources && filteredResources.length > 0) {
                if (defaultResources && defaultResources.length > 0) {
                    if (selectedCategory || selectedSubCategory) {
                        setResources(filteredResources.map((resource) => ({ ...resource, searchText: `${resource.displayId} - ${resource.description}` })));
                    } else {
                        setResources(defaultResources?.map((resource) => ({ ...resource, searchText: `${resource.displayId} - ${resource.description}` })));
                    }
                }
            }
        }
    }, [defaultResources, filteredResources, selectedCategory, selectedSubCategory])

    const isDisabled = useCallback(() => {
        if (props.disabled) return true;

        if (selectedCategory || selectedSubCategory) return false;

        if (selectedResource) return false;

        return true;

    }, [props.disabled, selectedCategory, selectedResource, selectedSubCategory])

    return <>{colors && <Box display="flex" gap="15px">
        <Autocomplete
            fullWidth
            sx={{ width: "245px" }}
            value={selectedCategory ?? null}
            size="small"
            disabled={props.disabled}
            onChange={onCategoryChanged}
            getOptionLabel={(option) => option.description ?? ''}
            options={storedResourceCategories ?? []}
            renderInput={(params) => <TextField {...params} name="category" InputProps={{
                ...params.InputProps
            }} label="Category" />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.description ?? '', inputValue, {
                    insideWords: true,
                });
                const parts = parse(option.description ?? '', matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
        <Autocomplete
            fullWidth
            sx={{ width: "245px" }}
            value={selectedSubCategory ?? null}
            size="small"
            getOptionLabel={(option) => option.description ?? ''}
            onChange={onSubCategoryChanged}
            disabled={props.disabled}
            options={resourceSubCategoryOptions ?? []}
            renderInput={(params) => <TextField {...params} name="category" InputProps={{
                ...params.InputProps
            }} label="Sub Category" />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.description ?? '', inputValue, {
                    insideWords: true,
                });
                const parts = parse(option.description ?? '', matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
        <Autocomplete
            fullWidth
            sx={{ width: "245px" }}
            disableClearable
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            disabled={props.disabled}
            value={searchValue}
            isOptionEqualToValue={(option, value) => option.resourceId === value.resourceId}
            getOptionLabel={(option) => option.searchText ?? ''}
            filterOptions={(x) => x}
            size="small"
            onChange={onResourceChanged}
            onInputChange={onInputChange}
            options={resources}
            renderInput={(params) =>
                <TextField
                    {...params}
                    inputRef={inputRef}
                    placeholder='Search...' />}
            renderOption={(props, option, { inputValue }) => {
                const matches = match(option.searchText ?? '', inputValue, { insideWords: true });
                const parts = parse(option.searchText ?? '', matches);

                return (
                    <li {...props}>
                        <div>
                            {parts.map((part, index) => (
                                <span
                                    key={index}
                                    style={{
                                        fontWeight: part.highlight ? 700 : 400,
                                    }}
                                >
                                    {part.text}
                                </span>
                            ))}
                        </div>
                    </li>
                );
            }}
        />
        <Button variant="contained" onClick={addResources} disabled={isDisabled()}>Add</Button>
    </Box>}</>
}