import { Box } from "@mui/material";
import ActivityList from "Components/Activities/ActivityList";
import { PanelState } from "Models/panel";
import { useState } from "react";
import { useParams } from "react-router-dom";

export default function ActivityLanding(){
    const { estimateId } = useParams();
    const [panelState] = useState<PanelState>({position: {state: 'full'}});
    
    return <Box height="calc(100% - 130px)" margin="10px"><ActivityList estimateId={estimateId} panelState={panelState}/></Box>
}