import { LockedStatus } from "./company";

export interface UserDetails extends UserRolePermissions {
  organizationId: string;
  companyId?: string;
  email: string;
  outsetaAccessToken: string;
  userLocked: boolean;
  companyLocked: boolean;
  companyLockedStatus: LockedStatus;
  accountUnavailable: boolean;
}

export interface UserRolePermissions {
  userId: string;
  role: SystemRole;
  systemPermissions: number[];
}

export enum SystemRole {
  Estimator = 'Estimator',
  MasterEstimator = 'Template Manager',
  Admin = 'Admin',
  AccountOwner = 'Account Owner'
}

export interface UserInfo {
  id: string;
  name: string;
  email: string;
  systemRole: SystemRole;
  locked?: boolean;
}

export interface UserInfoView extends UserInfo {
  actions: string;
}

export interface Owner {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}