import { Box, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import { MarkupItem } from "Models/item";
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import { useState } from "react";
import { useAppSelector } from "State/hooks";
import { selectItemState } from "State/itemSlice";

export interface IMarkupSaveCancelCellRendererProps extends ICellRendererParams<MarkupItem> {
    delete: (node: IRowNode<MarkupItem>) => Promise<void>;
    save: (node: IRowNode<MarkupItem>) => Promise<void>;
    cancel: () => void;
}

export default function MarkupSaveCancelCellRenderer(props: IMarkupSaveCancelCellRendererProps) {
    const { node } = props;
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting] = useState(false);
    const itemState = useAppSelector(selectItemState);
    let editingCells = props.api.getEditingCells();
    let isCurrentRowEditing = editingCells.some((cell) => {
        return cell.rowIndex === node.rowIndex;
    });

    const deleteRec = async () => {
        props.cancel();
    }

    const saveRec = async () => {
        try {
            setIsSaving(true);
            await props.save(node);
            setIsSaving(false);
        } catch (error) {
            setIsSaving(false);
        }
    }

    return (<>
        {!node.group && node.data &&
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                {isCurrentRowEditing && !isSaving &&
                    <IconButton aria-label="save" color="primary" onClick={saveRec} disabled={!itemState.isRowValid}>
                        <SaveIcon />
                    </IconButton>
                }
                {isSaving &&
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress size="1rem" />
                    </Box>
                }
                {isCurrentRowEditing && !isDeleting &&
                    <IconButton aria-label="delete" onClick={deleteRec}>
                        <CloseIcon />
                    </IconButton>
                }
                {isDeleting &&
                    <Box sx={{ display: 'flex', alignItems: "center" }}>
                        <CircularProgress size="1rem" />
                    </Box>
                }
            </Box>}
    </>
    );
};
