export interface EstimatePermission{
    userId: string;
    estimateRole: EstimateRole;
}

export enum EstimateRole {
    View = 'View',
    ManageData = 'ManageData',
    EstimateItems = 'EstimateItems',
    Full = 'Full',
  }
  