import { Box, useTheme } from "@mui/material";
import { FileDetails } from "Models/file";
import { useAnalyzeQuery } from "State/Services/file";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useMemo, useState } from "react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { tokens } from "theme";
import CircularProgress from '@mui/material/CircularProgress';
export interface AnalyzeProps {
    fileDetails: FileDetails;
    estimateId: string;
    onAnalysisDone: () => void;
}

export function Analyze(props: AnalyzeProps) {
    const [colors, setColors] = useState<any>();
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const { data: analysis, isLoading } = useAnalyzeQuery({
        companyId: (user && user.companyId) ? user.companyId : '',
        organizationId: (user && user.organizationId) ? user.organizationId : '',
        estimateId: (props && props.estimateId) ? props.estimateId : '',
        fileId: (props && props?.fileDetails?.id) ? props.fileDetails.id : ''
    });
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);

    return <Box sx={{ width: '100%', height: '100%' }}
        display="flex" flexDirection="column"
        alignContent="center"
        alignItems="center"
        justifyContent="center">
        {isLoading &&
            <>
                <Box>Analyzing...</Box>
                <Box width='100%' display="flex" alignContent="center"
                    alignItems="center"
                    justifyContent="center">
                    <Box><CircularProgress color="primary" /></Box>
                </Box>
            </>
        }
        {analysis && colors &&
            <Box display="flex" flexDirection="column" alignItems="center">
                <CheckCircleIcon sx={{ color: colors.greenAccent[300], width: '4em', height: '4em' }} />
                <Box>Analysis Complete!</Box>
                <Box>Successfully imported {analysis.itemCount} records</Box>
                <Box>To verify import, add up all the quantities in your file and confirm that the total is equal to {analysis.quantityTotal}</Box>
                {analysis.undefinedExists && <Box>There are items that did not map to categories. Review items in List View to find and edit any items with “undefined” categories.</Box>}
            </Box>
        }
    </Box>
}