import { Alert, Autocomplete, Box, Button, TextField, useTheme } from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useFormik } from "formik";
import * as yup from 'yup';
import { ActivityView } from "Models/activity";
import { useState, useMemo, useEffect, useCallback, useRef } from "react";
import { tokens } from "theme";
import { useGetUnitsQuery } from "State/Services/unit";
import { useGetUserDetailsQuery } from "State/Services/user";
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import SaveIcon from '@mui/icons-material/Save';
import { useCreateActivityMutation, useGetActivityMaxIdQuery, useUpdateActivityMutation } from "State/Services/activity";
import { UnitView } from "Models/unit";
import AddEditActivityResource from "./AddEditActivityResource";
import { ActivityResource, ActivityResourceView } from "Models/resource";
import { useLazyGetActivityCategoriesQuery } from "State/Services/activity-category";
import { ActivityCategory } from "Models/activity-category";
import SplitButton from "Components/SplitButton";
import { SaveOptions } from "Models/button-options";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useGetSettingsQuery } from "State/Services/settings";
import { hasEstimatePermission } from "Helpers/estimate-permissions";
import { Entity } from "Models/estimate";
import { PanelState } from "Models/panel";
import { Errors } from "Models/errors";
export interface AddActivityProps {
    estimateId: string;
    activity: ActivityView | undefined;
    backToList: () => void;
    resetAndAddNew: (categoryId: string | undefined) => void;
    panelState: PanelState;
}

const activityValidationSchema = yup.object<ActivityView>({
    id: yup
        .string()
        .optional(),
    description: yup
        .string()
        .required(),
    displayId: yup
        .string()
        .required(),
    output: yup
        .number()
        .required(),
});
export default function AddActivity(props: AddActivityProps) {
    const theme = useTheme();
    const { data: user } = useGetUserDetailsQuery();
    const { data: settings } = useGetSettingsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const { data: maxId } = useGetActivityMaxIdQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [saveActivity] = useCreateActivityMutation();
    const [updateActivity] = useUpdateActivityMutation();
    const [unitViews, setUnitViews] = useState<Array<UnitView>>([]);
    const { data: units } = useGetUnitsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [colors, setColors] = useState<any>();
    useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);
    const [activity, setActivity] = useState<ActivityView | undefined>(props.activity);
    const [rate, setRate] = useState<string>();
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [getActivityCategories] = useLazyGetActivityCategoriesQuery();
    const [storedActivityCategories, setStoredActivityCategories] = useState<Array<ActivityCategory>>([]);
    const [currentActivityResources, setCurrentActivityResources] = useState<Array<ActivityResourceView>>();
    const categoryRef = useRef<HTMLInputElement>();
    const unitRef = useRef<HTMLInputElement>();
    const [afterSaveOption, setAfterSaveOption] = useState<SaveOptions | undefined>();
    const [locked] = useState(!!props.activity?.masterReferenceId);
    const editDisabledRef = useRef<boolean>();
    const [pageError, setPageError] = useState<string | undefined>();

    useEffect(() => {
        if (user && estimate?.EstimateUserRole) {
            if (estimate?.EstimateUserRole?.length === 0) {
                editDisabledRef.current = true;
            } else {
                const hasEditAccess = hasEstimatePermission(user?.userId, estimate.EstimateUserRole, {
                    entity: Entity.Activities,
                    requiredPermissions: [502]
                });
                editDisabledRef.current = !hasEditAccess;
            }
        } else {
            editDisabledRef.current = true;
        }
    }, [estimate, estimate?.EstimateUserRole, user])

    useEffect(() => {
        const unitViews = units?.map((unit) => ({
            unitDescription: unit.description,
            unitId: unit.id
        }));
        if (unitViews) {
            setUnitViews(unitViews);
        }
    }, [units])

    useEffect(() => {
        if (user?.companyId && user.organizationId) {
            getActivityCategories({
                companyId: user?.companyId,
                estimateId: props.estimateId,
                organizationId: user?.organizationId
            }, true).then((activityCategories) => {
                if (activityCategories.data) {
                    setStoredActivityCategories(activityCategories.data);
                } else {
                    setStoredActivityCategories([]);
                }
            });
        }
    }, [getActivityCategories, props.estimateId, user?.companyId, user?.organizationId])

    const getDisplayId = useCallback(() => {
        const id = (maxId) ? (maxId + 1)?.toString() : '1';
        return estimate?.isMaster ? `${settings?.masterIdPrefix}-${id}` : id;
    }, [estimate?.isMaster, maxId, settings?.masterIdPrefix])

    const { values, isValid, setFieldValue, setErrors, handleSubmit, handleChange, handleBlur, touched, errors } = useFormik<ActivityView>({
        enableReinitialize: true,
        initialValues: (activity) ? {
            id: activity.id ?? '',
            description: activity.description ?? '',
            unitId: activity.unitId ?? '',
            output: activity.output,
            displayId: activity.displayId ?? getDisplayId(),
            rate: activity.rate,
            masterReferenceId: activity.masterReferenceId,
            unit: unitViews.find((unit) => (unit.unitId === activity.unitId)),
            categoryId: activity.categoryId,
            category: (activity.categoryId && storedActivityCategories && storedActivityCategories.length > 0 && activity) ? storedActivityCategories.find((option) => (option.id === activity?.categoryId)) : undefined
        } : {
            id: '',
            description: '',
            unitId: undefined,
            output: 0,
            displayId: getDisplayId(),
            rate: undefined,
            unit: undefined,
            categoryId: undefined,
            category: undefined
        },
        validationSchema: activityValidationSchema,
        onSubmit: async (activity) => {
            await save(activity, afterSaveOption ?? SaveOptions.Save);
        },
    });

    const save = useCallback(async (activityToSave: ActivityView, option: SaveOptions) => {
        setPageError(undefined);
        if (activityToSave) {
            try {
                let response: any;
                if (activityToSave.output) {
                    if (activityToSave.id) {
                        response = await updateActivity({
                            activityId: activityToSave.id,
                            companyId: user?.companyId,
                            estimateId: props.estimateId,
                            orgId: user?.organizationId,
                            body: {
                                id: activityToSave.id,
                                output: parseFloat(activityToSave.output.toString()),
                                description: activityToSave.description,
                                displayId: activityToSave.displayId,
                                unitId: activityToSave.unit?.unitId,
                                categoryId: activityToSave.categoryId,
                                ...(currentActivityResources && {
                                    activityResources: currentActivityResources?.map((actRes: ActivityResource) => ({
                                        activityId: activityToSave.id,
                                        quantity: actRes.quantity,
                                        quantityPerUnit: actRes.quantityPerUnit,
                                        production: actRes.production,
                                        resourceId: actRes.id,
                                    }))
                                })
                            }
                        }).unwrap();
                    } else {
                        response = await saveActivity({
                            companyId: user?.companyId,
                            estimateId: props.estimateId,
                            orgId: user?.organizationId,
                            body: {
                                output: parseFloat(activityToSave.output.toString()),
                                description: activityToSave.description,
                                displayId: activityToSave.displayId,
                                unitId: activityToSave.unit?.unitId,
                                categoryId: activityToSave.categoryId,
                            }
                        }).unwrap();
                    }
                    if (response) {
                        switch (option) {
                            case SaveOptions.SaveAndClose:
                                props.backToList();
                                break;
                            case SaveOptions.SaveAndAddAnother:
                                setAfterSaveOption(undefined);
                                props.resetAndAddNew(activityToSave.categoryId);
                                break;
                            default:
                            case SaveOptions.Save:
                                setActivity({ ...response });
                                setFieldValue('id', response.id);
                                break;
                        }
                    }
                }

            } catch (error: any) {
                if (error.status === 500) {
                    setPageError(Errors.generic);
                    return;
                }
                if (error && ('data' in error)) {
                    if (error.data?.description) {
                        setErrors({
                            description: error.data?.description
                        });
                    } else if (error.data.displayId) {
                        setErrors({
                            displayId: error.data?.displayId
                        });
                    } else if (error.data.page) {
                        setPageError(error.data.page);
                    }
                }
            }
        }
    }, [currentActivityResources, props, saveActivity, setErrors, setFieldValue, updateActivity, user?.companyId, user?.organizationId])

    const onActivityResourceEditing = useCallback((state: boolean) => {
        setFieldDisabled(state);
    }, [])

    const calculateAmount = useCallback((activityResource: ActivityResourceView) => {
        if (!activityResource.quantity || !activityResource.rate) return;
        return activityResource.quantity * activityResource.rate;
    }, [])

    useEffect(() => {
        if (currentActivityResources && currentActivityResources.length > 0) {
            let total = 0;
            currentActivityResources.forEach((det: any) => {
                const amount = calculateAmount(det);
                if (amount) {
                    total += amount;
                }
            });
            const output = values.output;
            if (output) {
                setRate((total / output).toString());
            } else {
                setRate(total.toString());
            }
        } else {
            setRate('');
        }
    }, [calculateAmount, currentActivityResources, values.output])

    const onEnter = useCallback((event: React.KeyboardEvent) => {
        if (
            (categoryRef.current &&
                categoryRef.current.contains(event.target as HTMLElement)) ||
            (unitRef.current &&
                unitRef.current.contains(event.target as HTMLElement)) || event.key !== 'Enter'
        ) {
            return;
        }
        handleSubmit();
    }, [handleSubmit])

    const handleClose = useCallback(async (option?: string) => {
        const opt = (option ?? SaveOptions.Save) as SaveOptions;
        setAfterSaveOption(opt);
        await save(values, opt);
    }, [values, save]);

    const getContainerHeight = useCallback(() => {
        if (!isValid) {
            return pageError ? 'calc(100% - 78px)' : 'calc(100% - 20px)';
        }

        return pageError ? 'calc(100% - 58px)' : '100%';
    }, [isValid, pageError]);

    return <>{colors && <Box height={getContainerHeight()}>
        <form onSubmit={handleSubmit} noValidate onKeyDown={onEnter}>
            <Box display="flex" justifyContent="space-between">
                <Button sx={{ marginTop: "10px", marginBottom: "10px" }} startIcon={<ArrowBackIosIcon />} onClick={props.backToList}>
                    Back to list
                </Button>
                <Box marginTop="10px" marginBottom="10px">
                    <SplitButton disabled={editDisabledRef.current} buttonIcon={<SaveIcon />} options={[{ option: SaveOptions.SaveAndAddAnother, disabled: false }, { option: SaveOptions.SaveAndClose, disabled: false }]} buttonText="Save" onButtonClick={handleClose} onMenuItemClick={handleClose} />
                </Box>
            </Box>
            {pageError && <Box marginBottom="10px"><Alert severity="error">{pageError}</Alert></Box>}
            <Box display="flex" justifyContent="space-between">
                <Box display="flex" flexDirection="column" flex="0.2" marginRight="10px">
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>ID</label>
                    <TextField
                        size="small"
                        id="displayId"
                        name="displayId"
                        autoComplete="off"
                        placeholder="ID"
                        disabled={fieldDisabled || locked || editDisabledRef.current}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.displayId}
                        error={touched.displayId && Boolean(errors.displayId)}
                        helperText={touched.displayId && errors.displayId} />
                </Box>
                <Box display="flex" flexDirection="column" marginBottom="15px" flex="1" marginRight="10px">
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Description</label>
                    <TextField
                        size="small"
                        id="description"
                        name="description"
                        placeholder="Description"
                        disabled={fieldDisabled || locked || editDisabledRef.current}
                        autoComplete="off"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.description && Boolean(errors.description)}
                        helperText={touched.description && errors.description}
                        value={values.description} />
                </Box>
                <Box display="flex" flexDirection="column" marginBottom="15px" flex="0.5" marginRight="10px">
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Category</label>
                    <Autocomplete
                        size="small"
                        value={values.category ?? null}
                        className="ag-input-field-input ag-text-field-input"
                        sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                        disabled={fieldDisabled || locked || editDisabledRef.current}
                        options={storedActivityCategories}
                        getOptionLabel={(option) => option.description ?? ''}
                        renderInput={(params) => <TextField name="categoryId" placeholder='Select Category' {...params} />}
                        isOptionEqualToValue={(option, value) => {
                            return option.id === value.id;
                        }}
                        onChange={(event, value) => {
                            if (value) {
                                setFieldValue('category', value);
                                setFieldValue('categoryId', value.id);
                            } else {
                                setFieldValue('category', undefined);
                                setFieldValue('categoryId', undefined);
                            }
                        }}
                        ref={categoryRef}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.description ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.description ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />
                </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
                {unitViews && unitViews.length > 0 && <Box display="flex" flexDirection="column" marginBottom="15px" flex="1" marginRight="10px">
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Unit</label>
                    <Autocomplete
                        fullWidth
                        className="ag-input-field-input ag-text-field-input"
                        sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                        size="small"
                        disabled={fieldDisabled || locked || editDisabledRef.current}
                        getOptionLabel={(option) => option.unitDescription ?? ''}
                        onChange={(event, value) => {
                            setFieldValue('unit', value);
                        }}
                        ref={unitRef}
                        options={unitViews}
                        isOptionEqualToValue={(option, value) => (option.unitId === value.unitId)}
                        value={values.unit ?? null}
                        renderInput={(params) => <TextField name="unit" sx={{ height: "100%" }} placeholder='Select/Enter Unit' {...params} />}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.unitDescription ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.unitDescription ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />
                </Box>
                }
                <Box display="flex" flexDirection="column" flex="1" marginRight="10px">
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Output</label>
                    <TextField
                        size="small"
                        id="output"
                        InputProps={{
                            type: 'number'
                        }}
                        name="output"
                        autoComplete="off"
                        placeholder="Output"
                        disabled={fieldDisabled || locked || editDisabledRef.current}
                        error={touched.output && Boolean(errors.output)}
                        helperText={touched.output && errors.output}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.output} />
                </Box>

                <Box display="flex" flexDirection="column" flex="1" marginRight="10px">
                    <label color={colors.gray[100]} style={{ fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Rate</label>
                    <TextField
                        size="small"
                        InputProps={{ readOnly: true }}
                        value={rate} />
                </Box>
            </Box>
        </form>
        {activity?.id && <AddEditActivityResource onEdit={onActivityResourceEditing} currentActivity={values} estimateId={props.estimateId} setActivityResources={setCurrentActivityResources} panelState={props.panelState} />}
        {!activity?.id && <Box display="flex" alignItems="center" sx={{ background: colors.gray[1000] }} justifyContent="center" width="100%" height="calc(100% - 195px)">Please save the activity to add resources.</Box>}
    </Box>}</>;
}