import { Autocomplete, Box, Button, CircularProgress, TextField, Typography, useTheme } from "@mui/material";
import { CompanyCurrency } from "Models/company-currency";
import { SupplierPackageResourceDetails, SupplierPackageResourceDetailsRateCalculationView } from "Models/package";
import { SupplierPackageVendor } from "Models/supplier";
import { Vendor } from "Models/vendor";
import { useGetCompanyCurrenciesQuery } from "State/Services/company-currency";
import { useGetEstimateQuery } from "State/Services/estimate";
import { useUpdateSupplierResourceMutation } from "State/Services/supplier";
import { useGetUserDetailsQuery } from "State/Services/user";
import { useGetVendorsQuery } from "State/Services/vendor";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import { tokens } from "theme";
import * as yup from 'yup';

export interface SupplierRateProps {
    estimateId: string;
    packageVendor: SupplierPackageVendor;
    close: () => void;
    resource: SupplierPackageResourceDetails;
    disabled: boolean;
}

const supplierValidationSchema = yup.object<SupplierPackageVendor>({
    factor: yup
    .number()
    .nullable()
    .optional()
    .test(
        'is-greater-than-zero',
        'Factor must be greater than zero',
        value => value === null || value === undefined || value > 0
    )
});

export default function SupplierRate(props: SupplierRateProps) {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const { data: user } = useGetUserDetailsQuery();
    const { data: existingVendors } = useGetVendorsQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId })
    const { data: companyCurrencies } = useGetCompanyCurrenciesQuery({ companyId: (user && user.companyId) ? user.companyId : '', orgId: (user && user.organizationId) ? user.organizationId : '' }, { skip: !user?.companyId || !user?.organizationId });
    const [isSaving] = useState(false);
    const [vendor, setVendor] = useState<Vendor>();
    const [companyCurrenciesExtended, setCompanyCurrenciesExtended] = useState<Array<CompanyCurrency>>([]);
    const { data: estimate } = useGetEstimateQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
    const [exchangeRate, setExchangeRate] = useState<number>(1);
    const [supplierFactor, setSupplierFactor] = useState<number>();
    const [updateSupplierResource] = useUpdateSupplierResourceMutation();

    useEffect(() => {
        if (existingVendors && existingVendors.length > 0 && props.packageVendor) {
            const vendor = existingVendors?.find((ven: Vendor) => (ven.id === props.packageVendor.vendorId));
            setVendor(vendor);
            setSupplierFactor(props.packageVendor?.factor);
        }
    }, [existingVendors, props])

    const initializeValues = useCallback(() => {
        const rate = (parseFloat(props.resource.rate?.toString() ?? "0")) * (exchangeRate ?? 1) * parseFloat((props.resource.factor?.toString() ?? "1")) * parseFloat((props.packageVendor?.factor?.toString() ?? "1")) + (props?.resource?.adjustment ?? 0);
        return (props.packageVendor) ? {
            adjustment: props.resource.adjustment,
            factor: props.resource.factor,
            isChecked: props.resource.isChecked,
            resourceDisplayId: props.resource.resourceDisplayId,
            resourceId: props.resource.resourceId,
            resourceName: props.resource.resourceName,
            quantity: props.resource.quantity,
            rate: props.resource.rate,
            remarks: props.resource.remarks,
            vendorId: props.packageVendor.vendorId,
            vendorName: props.resource.vendorName,
            companyCurrency: undefined,
            totalRate: rate
        } : {
            adjustment: undefined,
            adjustmentOverride: undefined,
            factor: undefined,
            isChecked: false,
            resourceDisplayId: undefined,
            resourceId: undefined,
            resourceName: undefined,
            quantity: undefined,
            rate: undefined,
            remarks: undefined,
            vendorId: undefined,
            vendorName: undefined,
            companyCurrency: undefined,
            adjustmentType: undefined,
            totalRate: undefined
        };
    }, [exchangeRate, props.packageVendor, props.resource.adjustment, props.resource.factor, props.resource.isChecked, props.resource.quantity, props.resource.rate, props.resource.remarks, props.resource.resourceDisplayId, props.resource.resourceId, props.resource.resourceName, props.resource.vendorName])

    const { setFieldValue, resetForm, ...formik } = useFormik<SupplierPackageResourceDetailsRateCalculationView>({
        enableReinitialize: false,
        validateOnMount: true,
        validationSchema: supplierValidationSchema,
        initialValues: initializeValues(),
        onSubmit: async (subItem) => {
            if (user) {
                await updateSupplierResource({
                    companyId: user?.companyId,
                    estimateId: props.estimateId,
                    resourceId: props.resource.resourceId,
                    orgId: user.organizationId,
                    packageId: props.packageVendor.supplierPackageId,
                    supplierPackageVendorId: props.packageVendor.id,
                    body: {
                        adjustment: (subItem.adjustment?.toString()) ? parseFloat(subItem.adjustment?.toString()) : undefined,
                        companyCurrencyId: subItem.companyCurrency?.id,
                        factor: (subItem.factor?.toString()) ? parseFloat(subItem.factor.toString()) : undefined,
                        isChecked: subItem.isChecked,
                        resourceId: subItem.resourceId,
                        rate: (subItem.rate?.toString()) ? parseFloat(subItem.rate?.toString()) : undefined,
                        remarks: subItem.remarks,
                        totalRate: subItem.totalRate,
                        vendorId: subItem.vendorId
                    }
                });
                props.close();
            }
        },
    });

    
    useEffect(() => {
        if (companyCurrencies) {
            setCompanyCurrenciesExtended([...companyCurrencies])
            if (props.resource.companyCurrencyId) {
                const currency = companyCurrencies.find((curr) => (curr.id === props.resource.companyCurrencyId));
                if (currency) {
                    setFieldValue('companyCurrency', currency);
                }
            } else {
                if (estimate?.CompanyCurrency?.Currency) {
                    setFieldValue('companyCurrency', companyCurrencies.find((curr) => (curr.id === estimate?.CompanyCurrency?.id)));
                }
            }
        }
    }, [companyCurrencies, estimate?.CompanyCurrency, props.resource.companyCurrencyId, setFieldValue])

    const calculateRate = useCallback(() => {
        if (formik.values.rate) {
            const rate = (parseFloat(formik.values.rate.toString())) * (exchangeRate ?? 1) * parseFloat((formik.values.factor?.toString() ?? "1")) * parseFloat((props.packageVendor?.factor?.toString() ?? "1")) + (formik.values.adjustment ?? 0);
            setFieldValue('totalRate', rate);
        } else {
            setFieldValue('totalRate', 0);
        }
    }, [exchangeRate, formik.values.adjustment, formik.values.factor, formik.values.rate, props.packageVendor?.factor, setFieldValue])

    useEffect(() => {
        if (parseFloat(formik.values.companyCurrency?.primaryToSecondaryRate?.toString() ?? "1") !== 0) {
            let numerator = estimate?.CompanyCurrency?.primaryToSecondaryRate ?? 1;
            let denominator = formik.values.companyCurrency?.primaryToSecondaryRate ?? 1;
            setExchangeRate(parseFloat((numerator / denominator).toFixed(2)));
        }
    }, [estimate?.CompanyCurrency?.primaryToSecondaryRate, formik.values.companyCurrency?.primaryToSecondaryRate])

    const currencyChanged = useCallback((event: any, value: CompanyCurrency | null) => {
        if (value) {
            let numerator = estimate?.CompanyCurrency?.primaryToSecondaryRate ?? 1;
            let denominator = value.primaryToSecondaryRate ?? 1;
            setExchangeRate(parseFloat((numerator / denominator).toFixed(2)));
            setFieldValue('companyCurrency', value);
        }
        calculateRate();
    }, [calculateRate, estimate?.CompanyCurrency?.primaryToSecondaryRate, setFieldValue])

    const handleQuotedRateChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue('rate', event.target.value);
        if (event.target.value) {
            const rate = (parseFloat(event.target.value)) * (exchangeRate ?? 1) * parseFloat((formik.values?.factor?.toString() ?? "1")) * parseFloat((props.packageVendor?.factor?.toString() ?? "1")) + (formik.values.adjustment ?? 0);
            setFieldValue('totalRate', rate);
        } else {
            setFieldValue('totalRate', 0);
        }
    }, [exchangeRate, formik.values?.adjustment, formik.values?.factor, props.packageVendor?.factor, setFieldValue])

    const handleFactorChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue('factor', event.target.value);
        const rate = (parseFloat(formik.values.rate?.toString() ?? "0")) * (exchangeRate ?? 1) * parseFloat((event.target.value ?? "1")) * parseFloat((props.packageVendor?.factor?.toString() ?? "1")) + (formik.values.adjustment ?? 0);
        setFieldValue('totalRate', rate);
    }, [exchangeRate, formik.values.adjustment, formik.values.rate, props.packageVendor?.factor, setFieldValue])

    const handleAdjustmentChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setFieldValue('adjustment', event.target.value);
        const rate = (parseFloat(formik.values.rate?.toString() ?? "0")) * (exchangeRate ?? 1) * parseFloat((formik.values.factor?.toString() ?? "1")) * parseFloat((props.packageVendor?.factor?.toString() ?? "1")) + parseFloat(event.target.value ?? "0");
        setFieldValue('totalRate', rate);
    }, [exchangeRate, formik.values.factor, formik.values.rate, props.packageVendor?.factor, setFieldValue])

    const currencyOptionEqual = useCallback((option: CompanyCurrency, value: CompanyCurrency) => {
        return option.id === value.id;
    }, [])

    useEffect(() => {
        return () => {
            resetForm();
            setVendor(undefined);
            setFieldValue('companyCurrency', undefined);
            setSupplierFactor(1);
        }
    }, [resetForm, setFieldValue])

    return <>{colors && vendor && <form onSubmit={formik.handleSubmit} noValidate style={{ height: "100%" }}>
        <Box width="45vw" height="100%">
            <Box display="flex" justifyContent="space-between" paddingTop="16px" paddingBottom="16px" paddingLeft="24px" paddingRight="24px" borderBottom={`1px solid ${colors.gray[800]}`}>
                <Box>
                    <Typography component="div" sx={{ display: "flex" }}>
                        <Box sx={{ fontWeight: 600, fontSize: "16px", color: `${colors.gray[100]} !important` }}>{vendor.name}</Box>
                    </Typography>
                </Box>
                <Box>
                    <Button disabled={props.disabled} type="submit" color="primary" variant="contained" >{isSaving ? <CircularProgress sx={{ color: "white" }} size="1rem" /> : 'Save'}</Button>
                </Box>
            </Box>
            <Box display="flex" gap="10px" padding="16px" flexDirection="column" height="calc(100% - 80px)">
                <Box display="flex" flexDirection="column" flex="0.1" marginRight="10px" gap="16px">
                    <Box fontWeight="bold">Resource ID: {props.resource.resourceDisplayId}</Box>
                    <Box>Resource Description: {props.resource.resourceName}</Box>
                </Box>
                <Box display="flex" flexDirection="column" flex="0.1" marginRight="10px" gap="16px" justifyContent="center">
                    <Box display="flex" alignItems="center" flexDirection="row" gap="8px">
                        <Box display="flex" alignItems="center" flex="0.4">
                            <label color={colors.gray[100]} style={{ width: "80px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Basic Rate:</label>
                            <TextField
                                id="rate"
                                name="rate"
                                disabled={props.disabled}
                                size="small"
                                value={formik.values.rate}
                                autoComplete="off"
                                placeholder="Basic rate"
                                inputProps={{ type: "number" }}
                                onChange={handleQuotedRateChange}
                                onBlur={formik.handleBlur}
                            />
                        </Box>
                        <Box display="flex" alignItems="center" gap="10px" flex="0.6" justifyItems="center" alignContent="center">
                            <Box display="flex" alignItems="center" flex="0.5">
                                <label color={colors.gray[100]} style={{ width: "80px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Currency:</label>
                                {companyCurrenciesExtended && companyCurrenciesExtended.length > 0 && <Autocomplete
                                    fullWidth
                                    size="small"
                                    className="ag-input-field-input ag-text-field-input"
                                    sx={{ height: "inherit", "& .MuiTextField-root:": { height: "inherit" } }}
                                    onChange={currencyChanged}
                                    disabled={props.disabled}
                                    options={companyCurrenciesExtended}
                                    getOptionLabel={(option) => option.alphabeticalCode ?? ''}
                                    isOptionEqualToValue={currencyOptionEqual}
                                    value={formik.values.companyCurrency ?? null}
                                    renderInput={(params) => <TextField name="Seccurrency" sx={{ height: "100%" }} placeholder='Select Currency' {...params} />}
                                    renderOption={(props, option, { inputValue }) => {
                                        const matches = match(option.alphabeticalCode ?? '', inputValue, {
                                            insideWords: true,
                                        });
                                        const parts = parse(option.alphabeticalCode ?? '', matches);

                                        return (
                                            <li {...props}>
                                                <div>
                                                    {parts.map((part, index) => (
                                                        <span
                                                            key={index}
                                                            style={{
                                                                fontWeight: part.highlight ? 700 : 400,
                                                            }}
                                                        >
                                                            {part.text}
                                                        </span>
                                                    ))}
                                                </div>
                                            </li>
                                        );
                                    }}
                                />
                                }
                            </Box>
                            <Box display="flex" flex="0.2" alignItems="center">
                                <label color={colors.gray[100]} style={{ width: "150px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Exchange Rate:</label>
                                <TextField
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    disabled={true}
                                    value={exchangeRate} />
                            </Box>
                        </Box>
                    </Box>
                    <Box display="flex" alignItems="center" gap="8px">
                        <Box flex="0.4" display="flex" alignItems="center">
                            <label color={colors.gray[100]} style={{ width: "80px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Factor:</label>
                            <TextField
                                id="factor"
                                name="factor"
                                size="small"
                                disabled={props.disabled}
                                value={formik.values.factor}
                                autoComplete="off"
                                placeholder="Factor"
                                inputProps={{ type: "number" }}
                                onChange={handleFactorChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.factor && Boolean(formik.errors.factor)}
                                helperText={formik.touched.factor && formik.errors.factor}
                            />
                        </Box>
                        <Box flex="0.3" display="flex" alignItems="center">
                            <label color={colors.gray[100]} style={{ width: "120px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Supplier Factor:</label>
                            <TextField
                                id="supplierFactor"
                                name="supplierFactor"
                                size="small"
                                value={supplierFactor}
                                autoComplete="off"
                                disabled={true}
                                inputProps={{ type: "number" }}
                            />
                        </Box>
                        <Box flex="0.3" display="flex" alignItems="center">
                            <label color={colors.gray[100]} style={{ width: "120px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Adjustment:</label>
                            <TextField
                                id="adjustment"
                                name="adjustment"
                                size="small"
                                disabled={props.disabled}
                                onChange={handleAdjustmentChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.adjustment}
                                autoComplete="off"
                                inputProps={{ type: "number" }}
                            />
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" gap="8px">
                    <Box flex="0.4" display="flex" alignItems="center">
                        <label color={colors.gray[100]} style={{ width: "80px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Rate:</label>
                        <TextField
                            id="totalRate"
                            name="totalRate"
                            size="small"
                            disabled={true}
                            value={formik.values.totalRate}
                            autoComplete="off"
                            inputProps={{ type: "number" }}
                        />
                    </Box>
                </Box>
                <Box display="flex" alignItems="center" gap="8px">
                    <Box flex="1" display="flex" alignItems="center">
                        <label color={colors.gray[100]} style={{ width: "90px", fontSize: "11px", fontWeight: "600", fontStyle: "normal", lineHeight: "160%" }}>Remarks:</label>
                        <TextField
                            id="remarks"
                            name="remarks"
                            fullWidth
                            size="small"
                            disabled={props.disabled}
                            value={formik.values.remarks}
                            autoComplete="off"
                            multiline
                            rows={2}
                            placeholder="Remarks"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                            helperText={formik.touched.remarks && formik.errors.remarks}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    </form>}</>
}