import { forwardRef, useCallback, useEffect, useRef } from 'react';
import { ICellRendererParams, IRowNode } from 'ag-grid-community';
import { Box, IconButton } from '@mui/material';
import CalculateIcon from '@mui/icons-material/Calculate';
import { ItemDetail } from 'Models/item';
export interface IItemDetailQuantityPerUnitCellRendererParams extends ICellRendererParams<ItemDetail> {
    calculate: (node: IRowNode<ItemDetail>) => void;
}

export default forwardRef((props: IItemDetailQuantityPerUnitCellRendererParams, ref) => {
    const buttonRef = useRef<any>();

    const calculate = useCallback((event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        props.calculate(props.node);
    }, [props])

    useEffect(() => {
        if (buttonRef?.current) {
            buttonRef.current.onclick = calculate;
        }
    }, [calculate])

    return <><Box display="flex" justifyContent="space-between">
        <Box>
            {props.value}
        </Box>
        <Box>
            {props.node.level === 0 && <IconButton ref={buttonRef} color="primary">
                {<CalculateIcon />}
            </IconButton>}
        </Box>
    </Box></>;
});