
import { api } from './api'
import { ItemCode } from 'Models/item-code';

export const itemCodeApi = api.injectEndpoints({
    endpoints: (build) => ({
        getItemCodes: build.query<Array<ItemCode>, { organizationId: string, companyId: string, estimateId: string }>({
            query: ({ organizationId, companyId, estimateId }: { organizationId: string, companyId: string, estimateId: string }) => ({ url: `organization/${organizationId}/company/${companyId}/estimate/${estimateId}/item-code` }),
            providesTags: ['ItemCodes']
        }),

    })
});

export const {
    useGetItemCodesQuery
} = itemCodeApi