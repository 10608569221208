import { Box, IconButton, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { ICellRendererParams, IRowNode } from "ag-grid-community";
import SaveIcon from '@mui/icons-material/Save';
import CircularProgress from '@mui/material/CircularProgress';
import { useCallback, useState } from "react";
import { ResourceCategoryView } from "Models/resource-category";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
export interface ICancelCellRendererProps extends ICellRendererParams<ResourceCategoryView> {
    delete: (node: IRowNode<ResourceCategoryView>) => Promise<void>;
    save: (node: IRowNode<ResourceCategoryView>) => Promise<void>;
    cancel: (node: IRowNode<ResourceCategoryView>) => void;
    disabled: () => boolean;
    select: (node: IRowNode<ResourceCategoryView>) => void;
}

export default function SaveCancelCategoryCellRenderer(props: ICancelCellRendererProps) {
    const { node } = props;
    const [isSaving, setIsSaving] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    let editingCells = props.api.getEditingCells();
    let isCurrentRowEditing = editingCells.some((cell) => {
        return cell.rowIndex === node.rowIndex;
    });

    const deleteRec = async () => {
        if (isCurrentRowEditing || props.node.rowPinned) {
            props.cancel(node);
        } else {
            setIsDeleting(true);
            await props.delete(node);
            setIsDeleting(false);
        }
    }

    const saveRec = async () => {
        try {
            setIsSaving(true);
            await props.save(node);
            setIsSaving(false);
        } catch (error) {
            setIsSaving(false);
        }
    }

    const selectCategory = useCallback(() => {
        props.select(props.node);
    }, [props])

    const shouldDisable = useCallback(() => {
        if (isCurrentRowEditing) {
            return { state: false, message: "Cancel" }
        }

        if ((!node.data?.isNew && !node.data?.canDelete) || !!node.data.masterReferenceId || props.disabled()) {
            return { state: true, message: 'Deleting a category with sub categories is not permitted.' }
        }

        return { state: false, message: "Delete" }

    }, [isCurrentRowEditing, node.data?.canDelete, node.data?.isNew, node.data?.masterReferenceId, props])

    return (<>
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
            {isCurrentRowEditing && !isSaving &&
                <IconButton aria-label="save" color="primary" onClick={saveRec}>
                    <SaveIcon />
                </IconButton>
            }
            {isSaving &&
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress size="1rem" />
                </Box>
            }
            {!isDeleting && (isCurrentRowEditing || !props.node.isRowPinned()) &&
                <Tooltip title={shouldDisable().message}>
                    <span>
                        <IconButton aria-label="delete" onClick={deleteRec} disabled={shouldDisable().state}>
                            <>
                                {isCurrentRowEditing && <CloseIcon />}
                                {!isCurrentRowEditing && <DeleteOutlineIcon />}
                            </>
                        </IconButton>
                    </span>
                </Tooltip>
            }
            {isDeleting &&
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress size="1rem" />
                </Box>
            }
            {!props.node.isRowPinned() && <>
                <Box borderLeft="1px solid black" sx={{ opacity: "0.5" }} width="1px" height="100%" flex="1"></Box>
                <Box display="flex">
                    <IconButton aria-label="select" onClick={selectCategory}>
                        <ArrowForwardIosOutlinedIcon />
                    </IconButton>
                </Box></>
            }
        </Box>
    </>
    );
};
