import { Box, Button, useTheme } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDownloadFileMutation, useGetFilesQuery, useUploadFileMutation } from 'State/Services/file';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { FileDetails } from 'Models/file';
import { tokens } from 'theme';
import CircularProgress from '@mui/material/CircularProgress';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { format } from "date-fns";
import Link from '@mui/material/Link';
import { AgGridReact } from 'ag-grid-react';
export interface FileUploadProps {
  fileUploaded: (fileDetails: FileDetails) => void;
  estimateId: string;
}

export default function FileUpload(props: FileUploadProps) {
  const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
  const [uploadFile] = useUploadFileMutation();
  const gridRef = useRef<AgGridReact<FileDetails>>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [colors, setColors] = useState<any>();
  const { data: user } = useGetUserDetailsQuery();
  const [downloadFile] = useDownloadFileMutation();
  const { data: fileDetails } = useGetFilesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props.estimateId });
  const {
    open,
    acceptedFiles, getInputProps } = useDropzone({
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      accept: {
        'application/pdf': ['.pdf'],
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
      }
    });
  const theme = useTheme();
  useMemo(() => { setColors(tokens(theme.palette.mode)) }, [theme.palette.mode]);

  const [columnDefs] = useState<ColDef<FileDetails>[]>([
    { field: 'id', hide: true },
    {
      headerName: 'Actions', width: 130, cellRenderer: (params: ICellRendererParams<FileDetails>) => (<>{params.data &&
        <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center" height="100%">
          <Link component="button" variant="body2" onClick={() => selectFile(params.data)}>Select</Link>
          <Link component="button" variant="body2" onClick={() => getFile(params.data)}>Download</Link>
        </Box>

      }</>)
    },
    { field: 'originalName', headerName: 'Name', flex: 1},
    {
      field: 'createDate', valueGetter: (params) => {
        if (params.data && params.data.createDate) {
          return format(new Date(params.data.createDate).getTime(), 'MM/dd/yyyy');
        }
        return ''
      },
    }
  ]);

  useEffect(() => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const formData = new FormData();
      if (acceptedFiles) {
        formData.append("file", acceptedFiles[0]);
        if (user && user.companyId) {
          setIsUploading(true);
          uploadFile({
            companyId: user.companyId,
            estimateId: props.estimateId,
            fileData: formData,
            organizationId: user?.organizationId
          }).unwrap().then((fileDetails) => {
            props.fileUploaded(fileDetails);
            setIsUploading(false);
          });
        }
      }
    }
  }, [acceptedFiles, props, props.estimateId, uploadFile, user])

  const selectFile = useCallback((fileDetail: FileDetails | undefined) => {
    if (fileDetail) {
      props.fileUploaded(fileDetail);
    }
  }, [props])

  const getFile = useCallback(async (fileDetail: FileDetails | undefined) => {
    if (fileDetail && user) {
      setIsUploading(true);
      await downloadFile({
        companyId: user?.companyId,
        estimateId: props.estimateId,
        fileId: fileDetail.id,
        organizationId: user.organizationId
      });
      setIsUploading(false);
    }
  }, [downloadFile, props.estimateId, user])

  return (<>
    <Box width="100%" padding="25px 10px 10px 10px" height="100%" display="flex">
      {isUploading && <Box sx={{ color: 'gray', backgroundColor: colors?.gray[800] }} display="flex" alignItems="center" justifyContent="center" width="100%">
        <CircularProgress color="primary" />
      </Box>
      }
      {!isUploading && <>
        <Box display="flex" flexDirection="column" flex="1">
          <Box marginBottom="10px">
            <input {...getInputProps()} />
            <Button type="button" color="primary" variant='contained' onClick={open} startIcon={<CloudUploadIcon />}>
              Browse
            </Button>
          </Box>
          <div style={gridStyle} className="ag-theme-alpine ag-theme-bidbow">
            <AgGridReact<FileDetails>
              ref={gridRef}
              rowData={fileDetails}
              columnDefs={columnDefs}
              animateRows={true}
              onRowSelected={(params) => (selectFile(params.data))}
              suppressRowClickSelection={true}
            />
          </div>
        </Box>
      </>
      }
    </Box>
  </>
  );
}