import { Autocomplete, Box, Button, IconButton, TextField, darken, lighten, styled } from '@mui/material';
import { useGetUserDetailsQuery } from 'State/Services/user';
import { useFormik } from 'formik';
import { useCallback, useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { useGetResourceCategoriesQuery } from 'State/Services/resource-category';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { ResourceCategory, ResourceSubCategoryOption } from 'Models/resource-category';

export interface FilterSettings {
    categoryDescription: string | undefined;
    subCategoryDescription: string | undefined;
    searchText: string | undefined;
}

export interface FilterSettingsView extends FilterSettings {
    category?: ResourceCategory;
    subCategory?: ResourceSubCategoryOption;
}

export interface FiltersProps {
    estimateId?: string;
    onFilterChanged: (settings: FilterSettings) => void;
}
const GroupHeader = styled('div')(({ theme }) => ({
    position: 'sticky',
    top: '-8px',
    padding: '4px 10px',
    color: theme.palette.primary.main,
    backgroundColor:
        theme.palette.mode === 'light'
            ? lighten(theme.palette.primary.light, 0.85)
            : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
    padding: 0,
});


export default function ResourceCategoryFilters(props: FiltersProps) {
    const { data: user } = useGetUserDetailsQuery();
    const { data: storedResourceCategories } = useGetResourceCategoriesQuery({ companyId: (user && user.companyId) ? user.companyId : '', organizationId: (user && user.organizationId) ? user.organizationId : '', estimateId: props?.estimateId ?? '' }, { skip: !user?.companyId || !user?.organizationId || !props?.estimateId })
    const [resourceSubCategoryOptions, setResourceSubCategoryOptions] = useState<Array<ResourceSubCategoryOption>>([]);
    const filterForm = useFormik<FilterSettingsView>({
        initialValues: {
            categoryDescription: undefined,
            subCategoryDescription: undefined,
            searchText: undefined,
            category: undefined,
            subCategory: undefined
        },
        enableReinitialize: true,
        onSubmit: (values) => {
            submit(values);
        },
    });

    const submit = useCallback((values: FilterSettingsView) => {
        props.onFilterChanged({
            categoryDescription: values.category?.description,
            subCategoryDescription: values.subCategory?.description,
            searchText: values.searchText
        });
    }, [props])

    const submitOnEnter = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            filterForm.handleSubmit();
        }
    }

    const resetForm = () => {
        setResourceSubCategoryOptions([]);
        filterForm.resetForm();
        filterForm.handleSubmit();
    }
    const clearField = useCallback((field: string) => {
        filterForm.setFieldValue(field, '');
        filterForm.handleSubmit();
    }, [filterForm]);

    const onCategoryChanged = useCallback((event: React.SyntheticEvent, newValue: ResourceCategory | null) => {
        if (newValue?.description) {
            filterForm.setFieldValue("category", newValue);
            const options = new Array<ResourceSubCategoryOption>();
            if (storedResourceCategories && storedResourceCategories.length > 0) {
                const filtered = storedResourceCategories.find((cat) => (cat.id === newValue.id));
                if (filtered) {
                    filtered.resourceSubCategories?.forEach((cat) => {
                        options.push({
                            ...cat,
                            resourceCategoryName: filtered.description ?? ''
                        });
                    });
                }
                setResourceSubCategoryOptions(options);
            }
            filterForm.handleSubmit();
        } else {
            filterForm.setFieldValue("category", undefined);
            filterForm.setFieldValue("subCategory", undefined);
            setResourceSubCategoryOptions([]);
            filterForm.handleSubmit();
        }
    }, [filterForm, storedResourceCategories])

    const onSubCategoryChanged = useCallback((event: React.SyntheticEvent, newValue: ResourceSubCategoryOption | null) => {
        if (newValue?.description) {
            filterForm.setFieldValue("subCategory", newValue);
        } else {
            filterForm.setFieldValue("subCategory", undefined);
        }
        filterForm.handleSubmit();
    }, [filterForm])

    return <>
        <form onSubmit={filterForm.handleSubmit} autoComplete="off" style={{ display: "flex", flex: 1 }} noValidate>
            <Box width="100%" display="flex" alignItems="center">
                <Box flex="1.5">
                    <Autocomplete
                        size="small"
                        value={filterForm.values.category ?? null}
                        options={storedResourceCategories ?? []}
                        onKeyDown={submitOnEnter}
                        onChange={onCategoryChanged}
                        getOptionLabel={(option) => option.description ?? ''}
                        sx={{ width: "auto", marginRight: "10px" }}
                        renderInput={(params) => <TextField label="Category" {...params} />}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.description ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.description ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />
                </Box>
                <Box flex="2">
                    <Autocomplete
                        size="small"
                        value={filterForm.values.subCategory ?? null}
                        onKeyDown={submitOnEnter}
                        options={resourceSubCategoryOptions ?? []}
                        onChange={onSubCategoryChanged}
                        groupBy={(option) => option.resourceCategoryName}
                        getOptionLabel={(option) => option.description ?? ''}
                        sx={{ width: "auto", marginRight: "10px" }}
                        renderInput={(params) => <TextField label="Subcategory" {...params} />}
                        renderGroup={(params) => (
                            <li key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                        )}
                        renderOption={(props, option, { inputValue }) => {
                            const matches = match(option.description ?? '', inputValue, {
                                insideWords: true,
                            });
                            const parts = parse(option.description ?? '', matches);

                            return (
                                <li {...props}>
                                    <div>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                    </div>
                                </li>
                            );
                        }}
                    />
                </Box>
                <Box flex="2">
                    <TextField
                        fullWidth
                        size="small"
                        value={filterForm.values.searchText}
                        label="Search"
                        variant="outlined"
                        onKeyDown={submitOnEnter}
                        name="searchText"
                        sx={{ marginRight: "10px" }}
                        onChange={filterForm.handleChange}
                        onBlur={filterForm.handleBlur}
                        InputProps={{
                            endAdornment: <IconButton aria-label="delete" size="small" onClick={() => clearField('searchText')}>
                                <ClearIcon />
                            </IconButton>,
                        }} />
                </Box>
                <Box flex="1">
                    <Button
                        sx={{ color: "rgba(0, 0, 0, 0.54)", width: "80px" }}
                        variant="text"
                        onClick={resetForm}
                        type="reset">
                        Clear all
                    </Button>
                </Box>
            </Box>


        </form>

    </>;;
}