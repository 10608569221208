import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './store'
import { ItemView } from 'Models/item';

// Define a type for the slice state
interface PackageState {
  packageId: string | null | undefined;
  items: ItemView[];
  option: "create" | "edit" | "none";
}

// Define the initial state using that type
const initialState: PackageState = {
  packageId: undefined,
  items: [],
  option: "none"
}

export const subcontractorState = createSlice({
  name: 'subcontractor',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setSubcontractorState: (state, action: PayloadAction<PackageState>) => {
      state.items = action.payload.items;
      state.packageId = action.payload.packageId;
      state.option = action.payload.option;
    },
    clearSubcontractorState: (state) => {
      state.items = [];
      state.packageId = undefined;
      state.option = "none";
    }
  },
})

export const { setSubcontractorState, clearSubcontractorState } = subcontractorState.actions

// Other code such as selectors can use the imported `RootState` type
export const selectSubcontractorState = (state: RootState) => state.subcontractor;

export default subcontractorState.reducer